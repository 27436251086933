<template>
  <div>
    <div>
      <div
        class="mt-1 flex cursor-pointer justify-between px-2 py-2 text-sm font-medium duration-100 hover:border-blue-400 hover:bg-blue-900/25"
        :class="`${selected ? 'bg-blue-900/25' : ''} ${external ? 'py-1.5' : 'py-2'}`"
      >
        <div class="flex w-full select-none items-center space-x-2">
          <div class="flex-no-shrink" :class="muted && type != 'requests' && !archived ? 'opacity-75' : ''">
            <ChatImage
              :size="external ? 'xs' : 'sm'"
              :item="channel"
              :channel-name="title"
              :is-channel="true"
              :show-online="true"
            ></ChatImage>
          </div>
          <div
            class="w-11/12"
            :class="muted && type != 'requests' && !archived ? 'opacity-75' : ''"
            style="max-width: 77%"
          >
            <div class="flex items-center">
              <div @click="setSelected()" class="flex flex-1 items-center justify-between space-x-3">
                <BaseTooltip v-if="title" :position="external ? 'bottom' : 'top'">
                  <template #target>
                    <p
                      class="truncate whitespace-nowrap font-semibold leading-6"
                      :class="external ? 'text-sm' : 'text-base'"
                    >
                      {{ truncate(title, chatNameSize) }}
                    </p>
                  </template>
                  <template #default v-if="title.length > chatNameSize">{{ title }}</template>
                </BaseTooltip>
                <p class="whitespace-nowrap text-right text-xs leading-none text-gray-200">
                  {{ toChatTime(time, 'simple') }}
                </p>
              </div>
            </div>
            <div @click="setSelected()" class="flex justify-between text-gray-400">
              <div
                class="flex items-center pb-1 text-sm font-normal leading-5"
                :class="type == 'requests' ? '' : 'w-11/12'"
              >
                <div
                  v-if="
                    (type == 'requests' && !channel.data.direct_messaging) ||
                    (type != 'requests' && !message.activity_status && !channel.data.direct_messaging)
                  "
                  class="mr-1 whitespace-nowrap leading-none"
                >
                  {{ messageSender
                  }}<span v-if="(type != 'requests' && Object.keys(message).length != 0) || channel.data.note">:</span>
                </div>
                <div v-if="type != 'requests' && !displayMessage(message)" class="overflow-hidden">
                  <div v-if="isGIF(message)" class="flex items-center space-x-1 leading-5">
                    <IconImage /><span class="flex-1 truncate">GIF</span>
                  </div>
                  <div v-else-if="isImage(message)" class="flex items-center space-x-1 leading-5">
                    <IconImage /> <span class="flex-1 truncate">Image</span>
                  </div>
                  <div class="flex items-center space-x-1 leading-5" v-else-if="isVideo(message)">
                    <IconVideo /><span class="flex-1 truncate">Video</span>
                  </div>
                  <div class="flex items-center space-x-1 leading-5" v-else-if="isFile(message)">
                    <IconFile size="h-3 w-3 mb-0.5" /><span class="flex-1 truncate">File</span>
                  </div>
                </div>
                <p
                  v-else-if="type != 'requests'"
                  class="leading-2 flex-1 overflow-y-hidden whitespace-normal break-words text-sm text-gray-400 line-clamp-1"
                >
                  <span v-if="message.activity_status" v-html="activityMessage(message, channel)"></span>
                  <span v-else v-html="message.text"></span>
                </p>
                <span v-else-if="channel.data.note" class="break-words text-sm font-medium text-gray-400 line-clamp-1">
                  <BaseTooltip :position="external ? 'bottom' : 'right-end'">
                    <template #target>{{ channel.data.note }}</template>
                    <template #default v-if="channel.data.note.length > 26">{{ channel.data.note }}</template>
                  </BaseTooltip>
                </span>
                <span
                  v-else-if="channel.data.direct_messaging"
                  class="leading-2 flex-1 overflow-y-hidden whitespace-normal break-words text-sm text-gray-400 line-clamp-1"
                >
                  wants to connect.
                </span>
                <span
                  v-else
                  class="leading-2 flex-1 overflow-y-hidden whitespace-normal break-words text-sm text-gray-400 line-clamp-1"
                >
                  has invited you to {{ title }}
                </span>
              </div>
              <div
                class="flex items-center justify-center truncate rounded-full bg-blue-900 p-1 leading-none text-blue-100"
                :class="channel.state.unreadCount < 10 ? 'h-4 w-4 text-xs' : 'h-5 w-5 text-xxs'"
                v-if="showUnreadCount && !muted"
              >
                <span>{{ channel.state.unreadCount > 9 ? '9+' : channel.state.unreadCount }}</span>
              </div>
              <IconMute v-else-if="muted && type != 'requests' && !archived" size="h-3 w-3 text-gray-400 ml-2" />
              <IconFilledPin v-else-if="pinned && !muted" size="h-3 w-3 text-gray-400 ml-2" />
            </div>
          </div>
          <div class="flex items-center">
            <DropdownTableCell
              v-if="type != 'requests'"
              :items="filteredDropdownOptions"
              class="-mt-2 h-4 w-4 text-gray-400"
              :advance="true"
              @pin-channel="togglePin"
              @archive-channel="toggleArchive"
              @leave-chat="emitter.$emit('leave-channel', { channel: channel, id: userId, invited: false })"
              @mark-all-read="markRead"
              @mute-channel="toggleMute(muted)"
              @add-members-to-chat="emitter.$emit('add-members', channel.cid)"
            />
            <div
              v-if="type == 'requests'"
              @click="
                selectedRequest = !selectedRequest;
                $emit('request-selected');
              "
              :style="!requestEditMode ? 'visibility: hidden' : ''"
            >
              <input type="checkbox" class="h-3.5 w-3.5 bg-gray-900 text-sm" v-model="selectedRequest" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, inject } from 'vue';
import DropdownTableCell from '@/components/dropdown/DropdownTableCell.vue';
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import ChatImage from './ChatImage.vue';
import { useMapGetter } from '@/store/map-state';
import {
  getChannelName,
  getUserName,
  isImage,
  isVideo,
  isGIF,
  isFile,
  displayMessage,
  activityMessage
} from '@/composeables/chat';
import { toChatTime, truncate } from '@/composeables/filters';
import { useStore } from 'vuex';
import { saveNotificationPreference, notInCurrentChat } from '@/composeables/chat';
const { channels, client, streamUser, folders, mutedChannels } = useMapGetter();
const store = useStore();
const emitter = inject('eventHub');
const $emit = defineEmits(['expand-channels', 'pinned', 'request-selected']);
const props = defineProps({
  channelCid: {
    type: String,
    required: true
  },
  selected: {
    type: Boolean,
    required: true
  },
  type: {
    type: String,
    default: 'unpinned'
  },
  external: {
    type: Boolean,
    default: false
  },
  requestEditMode: Boolean
});
onMounted(() => {
  title.value = getChannelName(channel.value);
  setItemsText();
  channelMembers.value = Object.values(channel.value.state.members);
  setDropdownFolders();
  channel.value.on('channel.updated', () => {
    if (channel.value) {
      channelMembers.value = Object.values(channel.value.state.members);
      title.value = getChannelName(channel.value);
      setItemsText();
    }
  });
  client.value.on('user.updated', () => {
    if (channel.value) {
      channelMembers.value = Object.values(channel.value.state.members);
      title.value = getChannelName(channel.value);
      setItemsText();
    }
  });
  client.value.on('notification.channel_mutes_updated', event => {
    if (event.me.channel_mutes.length) {
      let muteChannels = event.me.channel_mutes.map(c => c.channel.cid);
      if (muted.value && !muteChannels.includes(props.channelCid)) {
        store.dispatch('removeMutedChannel', props.channelCid);
      } else if (!muted.value && muteChannels.includes(props.channelCid)) {
        store.dispatch('addMutedChannel', props.channelCid);
      }
    }
  });
});

const dropdownItems = ref([
  { id: 'pinChat', text: 'Pin', event: 'pin-channel', icon: 'IconFilledPin', group: 0 },
  { id: 'muteChannel', text: 'Mute', event: 'mute-channel', icon: 'IconMute', group: 0 },
  { id: 'markAllRead', text: 'Mark as Read', event: 'mark-all-read', icon: 'IconDoubleTick', group: 0 },
  { id: 'addToFolder', text: 'Add to Folder', icon: 'IconFolder', group: 1, childs: [] },
  { id: 'archiveChat', text: 'Archive', event: 'archive-channel', icon: 'IconArchive', group: 1 },
  { id: 'leaveChat', text: 'Leave', event: 'leave-chat', icon: 'IconBackCircle', group: 2 }
]);
const channelMembers = ref(null);
const title = ref('');
const selectedRequest = ref(false);

//computed
const channel = computed(() => {
  return channels.value.find(x => x.cid == props.channelCid);
});

const userId = computed(() => {
  return store.getters.userId.toString();
});

const message = computed(() => {
  return channel.value.state.messages.length > 0
    ? channel.value.state.messages[channel.value.state.messages.length - 1]
    : {};
});

const showUnreadCount = computed(() => {
  if (channel.value.state.unreadCount > 0 && props.type != 'requests' && notInCurrentChat(channel.value.cid)) {
    return true;
  }
  return false;
});
const messageSender = computed(() => {
  return props.type === 'requests' ? getInvitedBy(channel.value) : message.value.user ? message.value.user.name : '';
});

const time = computed(() => {
  if (props.type == 'requests') {
    return Object.values(channel.value.state.members).find(x => x.user_id == streamUser.value.id).updated_at;
  }
  return channel.value.state.last_message_at || channel.value.data.created_at;
});

const unreadCount = computed(() => {
  return channel.value.state.unreadCount;
});

const hasPermissions = computed(() => {
  return (
    channel.value.data.add_member_access ||
    channel.value.data.direct_messaging ||
    channel.value.data.created_by.id == userId.value ||
    (channel.value.data.channel_admins && channel.value.data.channel_admins.includes(userId.value))
  );
});

const filteredDropdownOptions = computed(() => {
  let filterItems = [];
  if (unreadCount.value == 0) {
    filterItems.push('markAllRead');
  }
  if (channel.value.data.direct_messaging || channel.value.data.broadcast_channel) {
    filterItems.push('leaveChat');
  }
  if (props.external) {
    filterItems.push('addToFolder');
  }
  if (archived.value) {
    filterItems.push('addToFolder', 'muteChannel');
  }
  return dropdownItems.value.filter(item => !filterItems.includes(item.id));
});

const chatNameSize = computed(() => {
  return props.external ? 17 : 15;
});

const pinned = computed(() => {
  return (
    streamUser.value && streamUser.value.pinned_channels && streamUser.value.pinned_channels.includes(props.channelCid)
  );
});

const archived = computed(() => {
  return (
    streamUser.value &&
    streamUser.value.archive_channels &&
    streamUser.value.archive_channels.includes(props.channelCid)
  );
});

const muted = computed(() => {
  return mutedChannels.value.includes(props.channelCid);
});

//methods
function getInvitedBy(channel) {
  if (Array.isArray(channel.data.invited_users)) {
    let invited = channel.data.invited_users.find(x => x.invited_users.includes(streamUser.value.id));
    return invited ? getUserName(invited.invited_by) : channel.data.created_by.name;
  }
  return channel.data.created_by.name;
}

function togglePin() {
  var pinnedChannels = streamUser.value?.pinned_channels;
  if (pinnedChannels) {
    pinnedChannels = JSON.parse(JSON.stringify(pinnedChannels));
    if (pinnedChannels.includes(channel.value.cid)) {
      pinnedChannels = pinnedChannels.filter(c => c != channel.value.cid);
    } else {
      pinnedChannels.push(channel.value.cid);
    }
  } else {
    pinnedChannels = [0, channel.value.cid];
  }
  $emit('expand-channels', 'pinned');
  pinnedChannels = [...new Set(pinnedChannels)]; // ensure uniqueness
  client.value.partialUpdateUser({ id: userId.value, set: { pinned_channels: pinnedChannels } });
}

function toggleArchive() {
  var archiveChannels = streamUser.value?.archive_channels;
  if (archiveChannels) {
    archiveChannels = JSON.parse(JSON.stringify(archiveChannels));
    if (archiveChannels.includes(channel.value.cid)) {
      archiveChannels = archiveChannels.filter(c => c != channel.value.cid);
      toggleMute(true);
    } else {
      archiveChannels.push(channel.value.cid);
      toggleMute(false);
    }
  } else {
    archiveChannels = [0, channel.value.cid];
  }
  archiveChannels = [...new Set(archiveChannels)]; // ensure uniqueness
  client.value.partialUpdateUser({ id: userId.value, set: { archive_channels: archiveChannels } });
}

async function markRead() {
  await channel.value.markRead({ user_id: userId.value });
}

function setSelected() {
  emitter.$emit('change-channel', channel.value);
}

function setPinnedItemText() {
  dropdownItems.value.find(i => i.id === 'pinChat').text = pinned.value ? 'Unpin' : 'Pin';
  dropdownItems.value.find(i => i.id === 'pinChat').icon = pinned.value ? 'IconUnpin' : 'IconFilledPin';
}

function setArchiveItemText() {
  dropdownItems.value.find(i => i.id === 'archiveChat').text = archived.value ? 'Unarchive' : 'Archive';
  dropdownItems.value.find(i => i.id === 'archiveChat').icon = archived.value ? 'IconUnarchive' : 'IconArchive';
}

function setMutedItemText() {
  dropdownItems.value.find(i => i.id === 'muteChannel').text = muted.value ? 'Unmute' : 'Mute';
  dropdownItems.value.find(i => i.id === 'muteChannel').icon = muted.value ? 'IconUnmute' : 'IconMute';
}

function setItemsText() {
  setPinnedItemText();
  setArchiveItemText();
  setMutedItemText();
}

async function toggleMute(isMuted) {
  saveNotificationPreference([client.value.user.id], channel.value, isMuted ? 'all' : 'none');
  const channelData = client.value.channel('messaging', channel.value.id);
  if (isMuted) {
    await channelData.unmute();
    store.dispatch('removeMutedChannel', props.channelCid);
  } else {
    await channelData.mute();
    store.dispatch('addMutedChannel', props.channelCid);
  }
  setMutedItemText();
}

function setDropdownFolders() {
  dropdownItems.value.find(i => i.id === 'addToFolder').childs = [];
  dropdownItems.value
    .find(i => i.id === 'addToFolder')
    .childs.unshift({
      id: 'new_folder_default',
      text: 'New Folder',
      event: 'create-folder',
      icon: 'IconAddFolder',
      group: 0
    });
  folders.value
    .filter(f => !f.channels.includes(props.channelCid))
    .forEach(folder => {
      dropdownItems.value
        .find(i => i.id === 'addToFolder')
        .childs.push({ ...folder, event: 'add-to-folder', channelCid: props.channelCid });
    });
}
watch(
  () => folders.value,
  () => {
    setDropdownFolders();
  },
  { deep: true }
);
watch(
  () => props.requestEditMode,
  () => {
    if (!props.requestEditMode) {
      selectedRequest.value = false;
    }
  }
);
</script>
