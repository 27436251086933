<template>
  <div :id="`message_${message.id}`" @click="$emit('click', message)">
    <div
      v-if="message"
      class="rounded px-1"
      :class="message.activity_status ? '' : 'hover:bg-blue-900/25'"
      @mouseover="messageHovered = true"
      @mouseleave="messageHovered = false"
    >
      <div v-if="!message.activity_status" class="relative ml-1 flex flex-col">
        <div v-if="message.quoted_message" class="ml-4 flex items-end pt-1.5 opacity-75">
          <div class="flex-no-shrink flex w-7">
            <IconReplyIndicator size="text-gray-400 w-7 h-3.5" />
          </div>
          <span class="flex items-end space-x-1 pl-1 pb-2" :class="external ? 'text-xxs' : 'text-xs'">
            <ChatImage size="xxs" :show-online="true" :item="message.quoted_message.user"></ChatImage>
            <span
              class="whitespace-nowrap text-xs font-semibold leading-4"
              :class="currentUser.user_id == message.quoted_message.user.id ? 'text-blue-500' : 'cursor-pointer'"
              @click="emitter.$emit('show-user-bio', message.quoted_message.user)"
            >
              {{ message.quoted_message.user.name }}
            </span>
            <div
              @click="emitter.$emit('search-message', { id: message.quoted_message_id, channelId: channel.cid })"
              class="flex-1 cursor-pointer"
            >
              <div
                v-if="!displayMessage(message.quoted_message)"
                class="flex items-center space-x-1 text-gray-500"
                :class="external ? 'text-xxs' : 'text-xs'"
              >
                <span>Click to see attachment</span>
                <IconImage />
              </div>
              <span
                v-else
                v-html="message.quoted_message.text"
                :class="external ? 'text-xxs' : 'text-xs'"
                class="pr-4 font-medium leading-4 text-gray-500 line-clamp-1"
              >
              </span>
            </div>
          </span>
        </div>
        <div class="flex items-start" :class="external ? 'space-x-2 p-0.5' : 'space-x-3 p-1'">
          <div class="flex-no-shrink">
            <ChatImage
              @click="emitter.$emit('show-user-bio', message.user)"
              :size="external ? 'xs' : 'sm'"
              :class="message.user.id == streamUser.id ? '' : 'cursor-pointer'"
              :show-online="true"
              :item="message.user"
            ></ChatImage>
          </div>
          <div class="flex flex-col" style="width: 93%">
            <div class="flex items-center text-sm font-semibold text-white">
              <span
                @click="emitter.$emit('show-user-bio', message.user)"
                :class="[
                  currentUser.user_id == message.user.id ? 'text-blue-400' : 'cursor-pointer',
                  external ? 'text-xs line-clamp-1' : ''
                ]"
              >
                {{ message.user.name }}
              </span>
              <div
                v-if="message.user.id == 'bot_user'"
                class="ml-2 mb-0.5 rounded bg-gray-800 p-1 text-xxs text-gray-400"
              >
                BOT
              </div>
              <BaseTooltip>
                <template #target>
                  <span
                    class="ml-2 font-medium text-gray-500"
                    :class="external ? 'whitespace-nowrap text-xxs' : 'text-xs'"
                  >
                    {{ toChatTime(message.created_at) }}
                  </span>
                </template>
                <template #default>{{ readableDateTime(message.created_at) }}</template>
              </BaseTooltip>
            </div>
            <MessageText v-if="displayMessage(message)" :message-text="message.text" :external="external"></MessageText>
            <div v-for="(attachment, idx) in message.attachments" :key="idx" class="pt-1">
              <AttachmentPreview :attachment="attachment" :external="external"></AttachmentPreview>
            </div>
          </div>
        </div>

        <div
          v-if="!message.activity_status && messageHovered && !hideReply && !channel.data.broadcast_channel"
          @click="$emit('reply-message', message)"
          title="Reply to this message"
          :class="external ? 'right-1 h-6 w-6' : 'right-5 h-8 w-8 p-1'"
          class="absolute flex cursor-pointer items-center justify-center rounded border border-gray-800 bg-blue-900 shadow duration-200 hover:bg-blue-700"
          :style="external ? 'top: -16px' : 'top: -24px'"
        >
          <IconReply :size="`${external ? 'w-3 h-3' : 'w-4 h-4'} text-white`" />
        </div>
      </div>
      <div
        v-else-if="message.activity_status"
        class="flex items-center py-1 px-2"
        :class="external ? 'space-x-1' : 'space-x-2'"
      >
        <div class="h-3.5 w-3.5">
          <IconCreate
            v-if="message.text.includes('created') && message.activity_status != 'created'"
            class="h-3.5 w-3.5 text-gray-100"
          />
          <component v-else :is="activityIcons[message.activity_status]" size="h-3.5 w-3.5 text-gray-200" />
        </div>
        <div
          class="flex text-xs font-semibold leading-4 text-gray-400"
          :class="external ? 'items-start' : 'items-center'"
        >
          <span v-html="activityMessage(message, channel)"></span>
          <BaseTooltip class="whitespace-nowrap">
            <template #target>
              <span class="ml-1 font-medium text-gray-500" :class="external ? 'text-xxs' : 'text-xs'">
                {{ toChatTime(message.created_at) }}
              </span>
            </template>
            <template #default>{{ readableDateTime(message.created_at) }}</template>
          </BaseTooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue';
import { DateTime } from 'luxon';
import MessageText from './ChatMessageText.vue';
import 'linkifyjs/lib/linkify-string';
import FilterMixin from '@/mixins/filters';
import AttachmentPreview from './ChatAttachmentPreview.vue';
import ChatImage from './ChatImage.vue';
import { useMapGetter } from '@/store/map-state';
import { useStore } from 'vuex';
import { activityMessage, displayMessage } from '@/composeables/chat';
import { toChatTime, readableDateTime } from '@/composeables/filters';

const store = useStore();
const { channels, streamUser } = useMapGetter();
const emitter = inject('eventHub');

const $emit = defineEmits(['click', 'reply-message']);
const props = defineProps({
  message: {
    type: Object
  },
  external: {
    type: Boolean,
    default: false
  },
  hideReply: {
    type: Boolean,
    default: false
  }
});
const dropdownOptions = ref([{ id: 'reply', text: 'Reply', event: 'replyToMessage' }]);
const messageHovered = ref(false);
const activityIcons = ref({
  created: 'IconCreate',
  added: 'IconUserAdd',
  removed: 'IconUserRemove',
  demoted: 'IconDoubleArrowDown',
  promoted: 'IconDoubleArrowUp',
  join: 'IconUserAdd',
  leave: 'IconCircleBackArrow',
  invited: 'IconUserAdd'
});

//computed
const currentUser = computed(() => {
  return store.getters.session;
});

const channel = computed(() => {
  return channels.value.find(x => x.cid == props.message.cid);
});
</script>
<style>
.message_content > a {
  color: rgb(59 130 246);
}
</style>
