<template>
  <div :id="randomId" class="h-full w-full pt-4" v-if="pieSeries.length"></div>
</template>
<script>
import FilterMixin from '@/mixins/filters';
import Highcharts from 'highcharts/highcharts';
//this is work in progress
window.Highcharts = Highcharts;
import global from '@/mixins/global';
import colors from 'tailwindcss/colors';
import { downloadToCSV } from '@/util/csvDownload';

import * as jp from 'jsonpath';
Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E']
  }
});
export default {
  name: 'WidgetCustomTemplatePieChart',
  mixins: [global, FilterMixin],
  mounted() {
    const self = this;
    if (this.pieSeries.length > 0) {
      self.generateChart();
    }
    this.$eventHub.$on(`download_${this.widget.id}`, () => {
      this.downloadData();
    });
    self.$eventHub.$on('reflow-highchart', function () {
      self.$nextTick(() => {
        self.chartObj.reflow();
      });
    });
  },
  beforeUnmount() {
    this.$eventHub.$off('reflow-highchart');
  },
  data() {
    return {
      randomId: Math.random().toString(),
      waterMarkFontSize: 16,
      chartObj: undefined,
      colorScheme: [
        { id: 'red', colors: ['#F87171', '#EF4444', '#DC2626', '#B91C1C', '#991B1B'] },
        { id: 'blue', colors: ['#60A5FA', '#3B82F6', '#2563EB', '#1D4ED8', '#1E40AF'] },
        { id: 'green', colors: ['#34D399', '#10B981', '#059669', '#047857', '#065F46'] },
        { id: 'orange', colors: ['#FBBF24', '#F59E0B', '#D97706', '#845309', '#92400E'] },
        { id: 'magenta', colors: ['#E879F9', '#D946EF', '#C026D3', '#A21CAF', '#86198F'] }
      ]
    };
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    dataSet: {
      type: [Object, Array],
      default: () => []
    }
  },

  watch: {
    config: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.generateChart();
        });
      }
    },
    dataSet: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.generateChart();
        });
      }
    }
  },

  computed: {
    seriesColor() {
      let arrangeColorScheme = [];
      // Find selected color scheme and set selected color on top
      this.colorScheme.map(scheme => {
        scheme.colors.map((color, index) => {
          if (color == this.config.dataSeries[0].color) {
            scheme.colors.splice(0, 1, scheme.colors.splice(index, 1, scheme.colors[0])[0]);
            arrangeColorScheme.push(scheme);
          }
        });
      });
      // Re-arrange color scheme as per selected
      this.colorScheme.map(x => {
        // eslint-disable-next-line chai-friendly/no-unused-expressions
        x.id != arrangeColorScheme[0].id ? arrangeColorScheme.push(x) : '';
      });
      return arrangeColorScheme
        .map(x => {
          return x.colors;
        })
        .flat();
    },
    chartType() {
      return this.widget.widget_template.default_configuration.config.type;
    },
    config() {
      return this.widget.widget_template.default_configuration.config.chartConfigs;
    },
    pieSeries() {
      let xAxisAttribute = this.config.xAxis.attribute;
      let names = xAxisAttribute != '' ? jp.query(this.dataSet, xAxisAttribute) : [];

      let dSeries = this.config.dataSeries[0];
      let values = dSeries.attribute != '' ? jp.query(this.dataSet, dSeries.attribute) : [];
      return names.map((x, index) => {
        return { name: x, y: parseFloat(values[index]) };
      });
    },
    chartConfig() {
      const self = this;
      return {
        chart: {
          type: self.chartType,
          backgroundColor: 'transparent',
          zoomType: '',
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false
        },
        credits: {
          enabled: false
        },
        title: {
          text: '',
          style: {
            fontWeight: 'bold',
            color: colors.gray[200],
            fontSize: '14px'
          }
        },
        legend: {
          enabled: self.config.legendStatus != 'none' ? true : false,
          align: ['top', 'bottom'].includes(this.config.legendStatus) ? 'center' : this.config.legendStatus,
          verticalAlign: ['top', 'bottom'].includes(this.config.legendStatus) ? this.config.legendStatus : 'middle',
          itemHoverStyle: {
            color: colors.gray[200]
          },
          layout: ['top', 'bottom'].includes(this.config.legendStatus) ? 'horizontal' : 'vertical',
          itemStyle: {
            color: colors.gray[400],
            fontWeight: '400',
            fontSize: '10px'
          }
        },
        plotOptions: {
          pie: {
            opacity: 0.9,
            allowPointSelect: true,
            cursor: 'pointer',
            colors: this.seriesColor,
            dataLabels: {
              useHTML: true,
              color: 'white',
              formatter: function () {
                let title = this.key;
                let roundTo = this.point.y > 1 ? 2 : 6;
                const fromattedPnts = `
                <span><b>${title}</b><br/> ${self.formatNumber(this.point.y, roundTo)}</span>`;
                return fromattedPnts + '<br/>';
              }
            },
            showInLegend: true,
            borderWidth: 0,
            shadow: false
          },
          series: {
            animation: false
          }
        },
        tooltip: {
          shared: true,
          useHTML: true,
          backgroundColor: colors.gray[900],
          style: {
            color: 'white',
            pointerEvents: 'auto'
          },
          formatter: function () {
            let title = this.key;
            const tipHtml = `<b>${title}</b><br/>`;
            let roundTo = this.point.y > 1 ? 2 : 6;
            const fromattedPnts = `<b> ${this.point.series.name}:</b>
                <span style="color:${this.point.color}; margin-left: 0.5rem;">${self.formatNumber(
              this.point.y,
              roundTo
            )}</span>`;
            return tipHtml + fromattedPnts + '<br/>';
          }
        },
        navigation: {
          buttonOptions: {
            enabled: false
          }
        }
      };
    }
  },
  methods: {
    generateChart() {
      const self = this;
      let config = self.chartConfig;
      config.series = [
        {
          name: this.config.dataSeries[0].label,
          data: this.pieSeries
        }
      ];
      if (config.series[0] && config.series[0].data) {
        config.series[0].data[0].color = this.config.dataSeries[0].color;
      }
      if (config.series && config.series.length > 0) {
        self.chartObj = window.Highcharts.chart(self.randomId, config);
        self.$emit('chart', self.chartObj);
      }
    },
    addWatermark(chart) {
      var xCords = chart.plotWidth / 2 + chart.plotLeft - 75,
        yCords = chart.plotHeight / 2 + chart.plotTop + 5;
      chart.renderer
        .text('The Tie Terminal', xCords, yCords)
        .css({
          fontSize: this.waterMarkFontSize + 'px',
          color: colors.gray[700]
        })
        .add();
    },
    downloadData() {
      let xAxisTitle = this.config.xAxis['title'];
      let yAxisTitle = this.config.dataSeries[0]['label'];
      let columns = [xAxisTitle, yAxisTitle];

      let columnsMap = {};
      columns.forEach((e, i) => (columnsMap[i] = e));
      let csvKeys = columns.map(e => {
        return {
          key: e,
          as: e.toUpperCase()
        };
      });

      var seriesData = this.pieSeries.map(e => [e.name, e.y]);

      let csvData = seriesData.map(e => {
        let obj = {};
        columns.forEach((key, j) => {
          obj[key] = e[j];
        });
        return obj;
      });

      downloadToCSV(csvKeys, csvData, this.widget.title);
    }
  }
};
</script>
