<template>
  <div class="mx-4 mt-4 cursor-pointer overflow-hidden bg-gray-800 shadow-lg" @click="openChatroom()">
    <!-- this is to avoid purging of classes DO NOT REMOVE -->
    <!-- <div class="border-yellow-400 border-red-400 border-green-400 border-blue-400"/> -->
    <div class="flex w-full flex-col justify-center border-l-4 border-l-blue-600 p-3 pr-5">
      <div class="flex items-center justify-between">
        <span class="text-xs font-medium uppercase tracking-wider text-gray-400 line-clamp-1">
          {{ notification.payload.title }}
        </span>
        <button @click.stop="close(notification.id)" class="flex text-gray-400 hover:text-gray-100 focus:outline-none">
          <IconCross></IconCross>
        </button>
      </div>
      <div v-if="notification.payload.channel" class="mt-2 mb-1 flex items-center space-x-2">
        <ChatImage size="md" :show-online="true" :item="invitor">
          <template #status>
            <div class="absolute bottom-0 right-0">
              <div
                class="h-2.5 w-2.5 rounded-full border"
                :class="invitor.online ? 'bg-green-600' : 'bg-gray-600'"
              ></div>
            </div>
          </template>
        </ChatImage>
        <div class="flex flex-1 flex-col">
          <div class="mb-0.5 flex items-center justify-between">
            <div
              v-if="channelName"
              class="text-sm font-semibold leading-4 text-gray-100 line-clamp-1"
              :title="channelName.length > 40 ? channelName : null"
            >
              {{ channelName }}
            </div>
            <span class="whitespace-nowrap text-xs font-medium leading-4 text-gray-400"> Just now </span>
          </div>

          <div v-if="notification.payload.channel.note" class="text-sm leading-4 text-gray-400 line-clamp-1">
            <span v-if="invitedBy">{{ invitedBy.name }}:</span>
            {{ notification.payload.channel.note }}
          </div>
          <span v-else-if="streamChannel.direct_messaging" class="text-sm text-gray-400 line-clamp-1">
            wants to send you a message
          </span>
          <span v-else-if="invitedBy" class="text-sm text-gray-400 line-clamp-1">
            <span class="font-semibold">{{ invitedBy.name }}</span> wants to add you to the chat
          </span>
        </div>
      </div>
      <div v-else-if="notification.payload.message">
        <span class="mt-2 text-sm font-medium text-gray-300">
          {{ notification.payload.member.name }}
        </span>
        <span v-if="notification.payload.message" class="text-xs text-white line-clamp-1" style="white-space: pre-line">
          <span>{{ notification.payload.message.text }}</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import { useMapGetter } from '@/store/map-state';
import ChatImage from '../chat/ChatImage.vue';
import useEmitter from '../../composeables/emitter';
import { getChannelName, getUserByName } from '@/composeables/chat';
import { Inertia } from '@inertiajs/inertia';
const emitter = useEmitter();
const { client, streamUser } = useMapGetter();
const props = defineProps({
  notification: {
    type: Object
  },
  close: {
    type: Function
  }
});
const streamChannel = ref(props.notification.payload.channel || null);
const invitedBy = ref(null);
const channelName = ref(null);

onMounted(() => {
  if (streamChannel.value) {
    fetchChannelName();
    if (streamChannel.value.direct_messaging || streamChannel.value.direct_group_messaging) {
      invitedBy.value = streamChannel.value.created_by;
    } else {
      let invitedUsers = streamChannel.value.invited_users || [];
      let invitedUser = invitedUsers.find(x => x.invited_users.includes(streamUser.value.id));
      invitedBy.value = getUserByName(invitedUser.invited_by);
    }
  }
});

//computed
const invitor = computed(() => {
  return streamChannel.value.direct_messaging ? invitedBy.value : streamChannel.value;
});
// methods

async function fetchChannelName() {
  channelName.value = streamChannel.value.direct_messaging
    ? streamChannel.value.created_by.name
    : await getChannelName(streamChannel.value);
}
async function openChatroom() {
  props.close(props.notification.id);
  let channelId = props.notification.payload.channel_id || props.notification.payload.channel.id;
  if (!window.location.pathname.includes('/messenger')) {
    Inertia.visit(`/messenger?channel=${channelId}`);
  } else {
    const streamChannel = client.value.channel('messaging', channelId);
    emitter.$emit('change-channel', streamChannel);
  }
}
</script>
