<template>
  <div class="inline-flex items-center p-1 text-green-500">
    <div><IconDollar /></div>
    <p class="pr-3 pl-1">{{ item }}</p>
  </div>
</template>
<script>
export default {
  name: 'CellTags',
  props: {
    item: String
  }
};
</script>
