<template>
  <div class="space-y-4">
    <div>
      <span class="text-xs text-gray-400">Select Feed (required)</span>
      <DropdownSimple
        width="w-full"
        rounded-class="rounded text-sm"
        placeholder="Select a feed"
        :truncate-value="25"
        :active-item="selectedFeed"
        :items="feedOptions"
        @selected="x => (selectedFeed = x.id)"
      />
    </div>
    <BaseCheckbox v-model="hideTags">Hide Tags</BaseCheckbox>
  </div>
</template>
<script>
import DropdownSimple from '@/components/dropdown/DropdownSimple.vue';
import { isEmpty } from 'lodash';
export default {
  name: 'WidgetConfigsNews',
  emits: ['update'],
  mounted() {
    Promise.all([this.fetchNewsFeeds(), this.fetchRecommendedFeeds()]).finally(() => {
      this.preselectFeed();
      this.hideTags = this.config.hide_tags || false;
    });
  },
  props: {
    config: {
      type: Object
    }
  },
  components: {
    DropdownSimple
  },
  data() {
    return {
      userFeeds: [],
      defaultFeeds: [],
      recommendedFeeds: [],
      selectedFeed: null,
      hideTags: false
    };
  },
  computed: {
    feedOptions() {
      let options = [];
      this.defaultFeeds.forEach(x => {
        options.push({ id: x.id, html: `${x.name}<span class='block text-xs text-gray-400'>Default</span>` });
      });
      this.userFeeds.forEach(x => {
        options.push({ id: x.id, html: `${x.name}<span class='block text-xs text-gray-400'>My Feeds</span>` });
      });
      this.recommendedFeeds.forEach(x => {
        options.push({ id: x.id, html: `${x.name}<span class='block text-xs text-gray-400'>Recommended</span>` });
      });
      return options;
    },
    configuration() {
      if (this.selectedFeed) {
        const defaultFeed = this.defaultFeeds.find(x => x.id == this.selectedFeed);
        const userFeed = this.userFeeds.find(x => x.id == this.selectedFeed);
        const recommendedFeed = this.recommendedFeeds.find(x => x.id == this.selectedFeed);

        if (defaultFeed) {
          const wantFirehose = defaultFeed.name.includes('Firehose') ? true : false;
          return {
            ...defaultFeed,
            defaultFeed: true,
            wantFirehose: wantFirehose,
            hide_tags: this.hideTags,
            valid: true
          };
        }
        if (userFeed) {
          return {
            ...userFeed,
            userFeed: true,
            hide_tags: this.hideTags,
            valid: true
          };
        }
        if (recommendedFeed) {
          return {
            config: { ...recommendedFeed.config },
            id: recommendedFeed.id,
            recommendedFeed: true,
            hide_tags: this.hideTags,
            valid: true
          };
        }
      }
      return { valid: false };
    }
  },
  watch: {
    configuration() {
      this.$emit('update', this.configuration);
    }
  },
  methods: {
    preselectFeed() {
      if (this.config && !isEmpty(this.config)) {
        let validFeed = [this.defaultFeeds, this.userFeeds, this.recommendedFeeds]
          .flat()
          .some(x => x.id == this.config.id);
        if (validFeed) {
          this.selectedFeed = this.config.id;
        }
      }
    },
    async fetchNewsFeeds() {
      try {
        const response = await this.$http.get('/news_feeds');
        this.defaultFeeds = response.data.news_feeds.filter(x =>
          ['Firehose', 'Trending', 'Starred by My Team'].includes(x.name)
        );
        this.userFeeds = response.data.news_feeds.filter(x => !this.$store.getters.defaultFeeds.includes(x.name));
      } catch {
        // do nothing
      }
    },
    async fetchRecommendedFeeds() {
      try {
        const response = await this.$http.get('/recommended_feeds');
        this.recommendedFeeds = response.data.map(x => {
          return {
            id: `recommended_${x.id}`,
            name: x.name,
            config: {
              ...x.config
            }
          };
        });
      } catch {
        // do nothing
      }
    }
  }
};
</script>
