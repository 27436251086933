<template>
  <div class="float-right flex items-center text-white">
    <div class="ml-4 flex items-center">
      <a :href="url" :class="`text-gray-400 ${url ? 'hover:text-white' : 'cursor-not-allowed'} flex`" target="_blank">
        <IconOpen />
        <span class="ml-1 -mt-0.5">{{ urlText }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CellUrl',
  props: {
    url: {
      type: String,
      default: '#'
    },
    icon: {
      type: Boolean,
      default: true
    },
    urlText: {
      type: String,
      default: ''
    }
  }
};
</script>
