<template>
  <BaseSidebar v-model="open" size-class="w-64">
    <template #header>NFTs</template>
    <template #default>
      <div class="px-3">
        <div class="mb-2">
          <DropdownBlockchain @select="changeOption($event)" :supported-chains="['ethereum', 'avalanche']">
          </DropdownBlockchain>
        </div>
        <BaseSearchbox placeholder="Search Collection" v-model="search" @keyup.enter="onEnter" />
      </div>
      <BaseNavRadio v-if="search.length === 0" v-model="selectedPage">
        <template #default>
          <BaseNavRadioOption v-for="(option, index) in pages" :key="index" :option="option">
            {{ option.label }}
          </BaseNavRadioOption>
        </template>
      </BaseNavRadio>
      <div v-else-if="loading" class="flex items-center justify-center pt-10">
        <BaseLoader />
      </div>
      <div
        v-else-if="results.length === 0"
        class="mt-16 h-full overflow-y-scroll py-2 px-6 text-center text-sm text-gray-400"
      >
        Please enter a valid contract address or name and press Enter
      </div>
      <div v-else class="pt-1">
        <InertiaLink
          v-for="result in searchResults"
          :key="result.address"
          class="flex items-center py-2 px-4"
          :href="`/nft/explore/general?contract=${result.address}`"
        >
          <img :src="result.image_url" class="h-5 w-5 rounded-full bg-gray-800" loading="lazy" />
          <div class="pl-2 pr-1 text-sm font-medium">{{ result.collection_name }}</div>
          <div
            @click="
              $event.preventDefault();
              onCollectionSaved(result);
            "
            class="cursor-pointer"
          >
            <IconBookmarked class="h-4 w-4 text-white" v-if="result.saved" />
            <IconBookmark class="h-4 w-4 text-gray-400" v-else />
          </div>
        </InertiaLink>
      </div>
    </template>
  </BaseSidebar>
</template>

<script setup>
import { useStore } from 'vuex';
import { Inertia } from '@inertiajs/inertia';
import { useGetSidebarStatus } from '@/composeables/sidebar';
import { Link as InertiaLink } from '@inertiajs/inertia-vue3';
import { ref, computed, onMounted, watch, inject } from 'vue';
import DropdownBlockchain from '@/components/dropdown/DropdownBlockchain.vue';

const $http = inject('http');
const store = useStore();

const emit = defineEmits(['select-chain']);

const addUserCollection = inject('addUserCollection');
const userCollections = inject('userCollections');

const selectedPageSet = ref(false);

const pages = computed(() => {
  const data = [
    { label: 'Market', id: 'market', href: '/nft/market/general' },
    { label: 'Collection Explorer', id: 'collections', href: '/nft/collections' },
    {
      label: 'Watchlist',
      id: 'watchlist',
      href: '/nft/watchlist/collection',
      isParent: watchlists.value.length > 0
    },
    ...watchlists.value
  ];
  return data;
});

onMounted(async () => {
  selectedPage.value = pages.value.find(p => p.id == 'market');
  await fetchCollections();
  refreshRoute();
});

const { open } = useGetSidebarStatus('nfts');
const search = ref('');
const results = ref([]);
const searchIndex = ref(0);
const loading = ref(false);
const contract = ref('');
const selectedChainOption = ref('ethereum');
const watchlistCollections = ref([]);
const nftChain = computed(() => store.getters.nftChain);

const searchResults = computed(() => {
  const collections = (userCollections.value || []).map(col => {
    return col.contract.toLowerCase();
  });

  return results.value.map(result => {
    return {
      ...result,
      saved: collections.includes(result.address.toLowerCase())
    };
  });
});

const selectedPage = ref(null);

watch(selectedPage, () => {
  if (selectedPageSet.value) {
    Inertia.visit(selectedPage.value.href);
    window.localStorage.setItem('nftSelectedPage', selectedPage.value.id);
  }
  selectedPageSet.value = true;
});

const renderKey = ref(true);

watch(search, async () => {
  loading.value = true;
  searchIndex.value++;
  const searchIndexBefore = searchIndex.value;
  const res = await $http.get(
    `/nft_data?method=collection_search&collection_name=${search.value}&selected_chain=${selectedChainOption.value}`
  );

  if (searchIndex.value === searchIndexBefore) {
    results.value = res.data.data;
    loading.value = false;
  }
});

watch(nftChain, () => {
  search.value = '';
  results.value = [];
  loading.value = false;
  fetchCollections();
});

watch(userCollections, () => fetchCollections(), { deep: true });

const changeOption = id => {
  emit('select-chain', id);
  selectedChainOption.value = id;
};

const getContract = () => {
  const url = new URL(window.location.href);
  return url.searchParams.get('contract');
};

const refreshRoute = () => {
  contract.value = getContract();
};

const onCollectionSaved = col => {
  addUserCollection({
    name: col.collection_name,
    contract: col.address
  });
};

const onEnter = () => {
  // check if eth address

  if (search.value.length === 42 && search.value[0] === '0' && search.value[1].toLowerCase() === 'x') {
    Inertia.visit(`/nft/explore/general?contract=` + search.value);
  } else if (results.value.length > 0) {
    Inertia.visit(`/nft/explore/general?contract=` + results.value[0].address);
  }
};

const watchlists = ref([]);

const fetchCollections = async () => {
  const collections = userCollections.value.map(c => c.contract.toLowerCase());
  const response = await $http.get('/nft_data', {
    params: {
      method: 'my_collection_watchlist',
      collections: collections.join(','),
      selected_chain: nftChain.value
    }
  });

  const watchlistCollections =
    response && response.data && response.data.data && Array.isArray(response.data.data) ? response.data.data : [];

  const collection_names = watchlistCollections.map(c => c.collection_name);
  const filteredUserCollections = userCollections.value.filter(x => collection_names.includes(x.name));

  watchlists.value = filteredUserCollections.map(collection => {
    return {
      label: collection.name,
      id: collection.contract,
      href: `/nft/explore/general?contract=${collection.contract}`,
      parent: 'watchlist'
    };
  });
};
</script>
