import { ref, watch } from 'vue';

export function useGetSidebarStatus(title) {
  const open = ref(true);
  const sidebar = `sidebar_${title.toLowerCase().replaceAll(' ', '_')}`;

  if (!localStorage[sidebar] || localStorage[sidebar] === 'true') {
    open.value = true;
  } else {
    open.value = false;
  }

  watch(open, () => {
    localStorage.setItem(sidebar, open.value);
  });

  return { open };
}

export function useGetActiveSidebarItem(page, defaultItem = null) {
  const activeSidebarItem = ref(null);

  let storage = JSON.parse(localStorage[page] || '{}');
  activeSidebarItem.value = storage['activeSidebarItem'] || defaultItem;

  watch(activeSidebarItem, () => {
    storage['activeSidebarItem'] = activeSidebarItem.value;
    localStorage.setItem(page, JSON.stringify(storage));
  });

  return { activeSidebarItem };
}
