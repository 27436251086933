<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :overrides="overrides"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      @expand="goToExpandLink"
    >
      <template #body>
        <template v-if="dexToken">
          <TableDexTransactions :token="dexToken" :filters="widget.configuration" />
        </template>
        <template v-else-if="showError">
          <WidgetsErrorMessage>
            <span v-if="notSupported">Token does not support On-Chain Transactions</span>
            <span v-else>Select a token to load transactions</span>
          </WidgetsErrorMessage>
        </template>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';
import TableDexTransactions from '@/components/table/TableDexTransactions.vue';
export default {
  name: 'WidgetsDexTransactions',
  mixins: [WidgetsMixin],
  components: {
    TableDexTransactions
  },
  mounted() {
    this.getTokens();
  },
  data() {
    return {
      onChainTokens: [],
      dexToken: null,
      notSupported: false,
      showError: false
    };
  },
  watch: {
    'widget.configuration'() {
      this.setTokenFromConfig();
    },
    connectedToChannel(newVal) {
      if (!newVal) {
        this.setTokenFromConfig();
      } else {
        this.setTokenFromChannel();
      }
    },
    widgetChannelOutput() {
      this.setTokenFromChannel();
    }
  },
  methods: {
    setToken() {
      this.showError = false;
      if (this.connectedToChannel) {
        this.setTokenFromChannel();
      } else {
        this.setTokenFromConfig();
      }
    },
    setTokenFromChannel() {
      if (this.widgetChannelOutput) {
        const coin = this.widgetChannelOutput[0];
        const onChainToken = this.onChainTokens.find(x => x.coin_uid == coin.coin_uid);
        if (onChainToken) {
          this.dexToken = onChainToken.token;
        } else {
          this.dexToken = null;
          this.showError = true;
          this.notSupported = true;
        }
      } else {
        this.dexToken = null;
        this.showError = true;
      }
    },
    setTokenFromConfig() {
      if (this.widget.configuration && this.widget.configuration.token) {
        this.dexToken = this.widget.configuration.token;
      } else {
        this.showError = true;
      }
    },
    async getTokens() {
      let response = await this.$http.get('/coins_token');
      this.onChainTokens = response.data;
      this.$nextTick(() => {
        this.setToken();
      });
    },
    goToExpandLink() {
      window.open(`/coin/${this.widget.configuration.coin_uid}`, '_blank');
    }
  }
};
</script>
