<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 172 172" fill="currentColor">
    <path
      d="M86,17.2c-37.92867,0 -68.8,30.87133 -68.8,68.8c0,37.92867 30.87133,68.8 68.8,68.8c37.92867,0 68.8,-30.87133 68.8,-68.8h11.46667l-17.2,-22.93333l-17.2,22.93333h11.46667c0,31.73133 -25.60201,57.33333 -57.33333,57.33333c-31.73133,0 -57.33333,-25.60201 -57.33333,-57.33333c0,-31.73133 25.60201,-57.33333 57.33333,-57.33333c19.35106,0 36.33718,9.57849 46.72891,24.23229c1.18396,1.67286 3.17056,2.58686 5.21124,2.3976c2.04068,-0.18926 3.82531,-1.45302 4.68143,-3.31508c0.85612,-1.86206 0.65361,-4.03944 -0.53121,-5.71168c-12.44987,-17.55607 -32.94596,-29.06979 -56.09036,-29.06979zM86,45.86667c-14.18427,0 -20.07213,9.86689 -20.07786,21.74636c0,3.99613 1.76927,8.01771 1.76927,8.01771c-0.7052,0.44147 -1.87131,1.8396 -1.57891,4.3336c0.5504,4.64973 2.40773,5.83169 3.59453,5.93489c0.45293,4.3344 4.7575,9.88561 6.25964,10.69401v7.24505c-2.57427,8.36493 -15.03172,5.69876 -23.69479,13.14636c8.38786,9.12173 20.38666,14.88203 33.72813,14.88203c13.34147,0 25.34026,-5.7603 33.72812,-14.88203c-8.66306,-7.4476 -21.12052,-4.79262 -23.69479,-13.15755v-7.24505c1.50787,-0.8084 5.8067,-6.35961 6.25964,-10.69401c1.1868,-0.1032 3.04413,-1.27396 3.59453,-5.9237c0.2924,-2.494 -0.87917,-3.90333 -1.59011,-4.34479c0,0 1.76927,-3.63171 1.76927,-8.01771c0,-8.80067 -3.18773,-16.30417 -10.03333,-16.30417c0,0 -2.3736,-5.43099 -10.03333,-5.43099z"
    ></path>
  </svg>
</template>
<script>
export default {
  name: 'IconImpersonate'
};
</script>
