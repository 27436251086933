<template>
  <div class="flex items-center text-white">
    <div class="flex items-center space-x-1">
      <template v-if="imageOnly">
        <a :href="profile.externalLink" target="_blank">
          <BaseTooltip>
            <template #target>
              <BaseProfileImage :url="profile.imageUrl" :size="imageSize" :show-default="profile.defaultImage" />
            </template>
            <template #default>
              <div class="max-w-md">{{ profile.name }}</div>
            </template>
          </BaseTooltip>
        </a>
      </template>
      <template v-else>
        <a
          :href="profile.externalLink"
          :class="[
            profile.created_at || profile.subtext ? 'text-gray-300' : 'text-gray-400',
            'flex items-center hover:text-white'
          ]"
          target="_blank"
        >
          <BaseProfileImage :url="profile.imageUrl" :size="imageSize" :show-default="profile.defaultImage" />
          <BaseTooltip class="ml-2" v-if="userName && userName.length > 0">
            <template #target>
              <div class="flex flex-col text-left">
                <span class="font-semibold">{{ userName }}</span>
                <span v-if="profile.subtext" class="whitespace-normal text-xs font-normal text-gray-400 line-clamp-2">
                  {{ profile.subtext }}
                </span>
                <span class="text-xs" v-if="profile.created_at">{{ relativeTime(profile.created_at) }}</span>
              </div>
            </template>
            <template #default>
              <div class="max-w-md">{{ profile.name }}</div>
            </template>
          </BaseTooltip>
        </a>
      </template>
    </div>
  </div>
</template>
<script>
import FilterMixin from '@/mixins/filters';
export default {
  mixins: [FilterMixin],
  name: 'CellProfile',
  props: {
    imageSize: {
      type: String,
      default: 'w-7 h-7'
    },
    profile: {
      type: Object,
      default: () => {}
    },
    imageOnly: {
      type: Boolean,
      default: false
    },
    nameCharacterLimit: {
      type: Number,
      default: 10
    }
  },
  computed: {
    userName() {
      return this.profile.name ? this.truncate(this.profile.name, this.nameCharacterLimit) : this.profile.name;
    }
  }
};
</script>
