<template>
  <div class="grid grid-cols-4 py-4">
    <div class="col-span-3 col-start-1 space-y-3 overflow-y-auto">
      <div class="flex items-center">
        <div v-if="snap.name || snap.ticker" class="border-r border-gray-700">
          <InertiaLink
            :href="`/coin/${snap.coin_uid}/governance`"
            class="flex cursor-pointer space-x-1 pr-2 text-xs font-semibold"
          >
            <BaseCoinIcon :coin="snap.coin_uid" class="mr-1 h-4 w-4" :style-class="''" />
            <span class="text-gray-200">{{ snap.name || '' }}</span>
            <span class="text-gray-400">{{ snap.ticker || '' }}</span>
            <CoinVerifiedIcon v-if="isCoinVerified(snap.coin_uid)" :target-size="'h-3 w-3'" />
          </InertiaLink>
        </div>
        <div class="flex text-xs font-semibold" :class="snap.name || snap.ticker ? 'pl-2' : ''">
          <span class="text-gray-400"> Proposed by </span>
          <div class="ml-1 flex">
            <a
              :href="`http://www.etherscan.io/address/${snap.author}`"
              target="_blank"
              class="flex text-gray-200 hover:text-white"
            >
              <span>{{ snap.space_id }}</span>
              <IconOpen size="w-4 h-4" class="ml-2 text-gray-400 hover:text-gray-200"
            /></a>
          </div>
        </div>
      </div>
      <div>
        <div class="flex text-gray-200">
          <a :href="snap.link" class="flex items-center" target="_blank">
            <div
              :class="[
                'rounded px-2 py-1 text-xs capitalize',
                snap.state == 'active' ? 'bg-green-800 text-white' : 'bg-gray-800 text-gray-400'
              ]"
            >
              {{ snap.state }}
            </div>
            <div class="ml-2 font-medium hover:text-white">{{ snap.title }}</div>
            <IconOpen size="w-5 h-5" class="ml-2 mt-[2px] text-gray-400 hover:text-gray-200" />
          </a>
        </div>
        <div class="flex flex-col pt-4">
          <p class="text-sm font-medium text-gray-400" :class="expandBody ? '' : 'line-clamp-2'">
            {{ snap.body }}
          </p>
          <div
            v-if="snap.body && snap.body.length > 400"
            class="flex cursor-pointer pt-4 pb-2 text-xs font-medium text-gray-300"
            @click="
              expandBody == false //currently using icon, can also switch to text to show more or less
                ? ((expandBody = true), (currentBodyState = 'Read less'))
                : ((expandBody = false), (currentBodyState = 'Read more'))
            "
          >
            {{ currentBodyState
            }}<iconChevronDown class="text-gray-300 hover:text-white" :class="expandBody ? 'rotate-180' : ''">
            </iconChevronDown>
          </div>
        </div>
        <div class="pt-4 text-xs font-medium text-gray-400">
          <span> {{ endsOn(snap.end_at) }}</span>
        </div>
      </div>
    </div>
    <div class="ml-auto mr-auto mt-4 flex w-[230px] flex-col">
      <div v-for="(choice, i) in snap.choices.slice(0, entries)" :key="i">
        <div>
          <div class="grid grid-cols-3">
            <div class="text-sm font-medium text-gray-200">
              <div v-if="mapChoices(choice, i)">{{ choices[i] }}</div>
              <BaseTooltip v-else>
                <template #target>
                  <span>{{ truncate(choice, 8) }}</span>
                </template>
                <template #default>
                  {{ choice }}
                </template>
              </BaseTooltip>
            </div>
            <div class="mx-auto pr-4 text-gray-400">{{ formatNumber(snap.scores[i]) }}</div>
            <div class="ml-auto text-gray-200">
              {{ roundedPercent((snap.scores[i] / snap.scores_total) * 100) }}
            </div>
          </div>
          <div class="relative pt-1 pb-2">
            <div class="mb-4 flex h-2 w-[230px] overflow-hidden rounded border border-grid bg-gray-800 text-xs">
              <div
                :style="'width: ' + (snap.scores[i] / snap.scores_total) * 100 + '%'"
                class="flex flex-col justify-center whitespace-nowrap bg-blue-500 text-center text-white shadow-none"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          v-if="snap.choices && snap.choices.length > 3"
          class="flex cursor-pointer pt-2 pb-2 text-xs font-normal text-gray-300"
          @click="
            expandChart == false //currently using icon, can also switch to text to show more or less
              ? ((expandChart = true), (currentChartState = 'View less'), (entries = snap.choices.length))
              : ((expandChart = false), (currentChartState = 'View more'), (entries = 3))
          "
        >
          {{ currentChartState }}
          <iconChevronDown
            class="text-gray-300 hover:text-white"
            :class="expandChart ? 'rotate-180' : ''"
          ></iconChevronDown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { relativeTime } from '@/composeables/filters';
import { isCoinVerified } from '@/composeables/helpers';
import { Link as InertiaLink } from '@inertiajs/inertia-vue3';
import moment from 'moment';
import FilterMixin from '@/mixins/filters';
import CoinVerifiedIcon from '@/components/coin/CoinVerifiedIcon.vue';

export default {
  mixins: [FilterMixin],
  name: 'CellGovernance',
  components: {
    CoinVerifiedIcon,
    InertiaLink
  },
  props: {
    snap: {
      type: Object,
      default: () => {}
    }
  },
  data: () => {
    return {
      choices: ['For', 'Against', 'Abstain'],
      currentChartState: 'View more',
      currentBodyState: 'Read more',
      expandBody: false,
      expandChart: false,
      entries: 3
    };
  },
  methods: {
    isCoinVerified,
    endsOn(date) {
      var dateString = this.formatDate(date);
      var endTime = date;
      var currentTime = Date.now();
      let diff = endTime - currentTime;
      return diff > 0 ? `Ends ${relativeTime(date)} (${dateString})` : `Ended ${relativeTime(date)} (${dateString})`;
    },
    formatDate(date) {
      return moment(date).format('MMM D, h:mma');
    },
    mapChoices(choice, choiceNumber) {
      let forTerms = ['Yes', 'yes', 'YAE', 'Yae', 'For', 'FOR', 'Approve', 'Accept', 'YES', 'Adopt'];
      let againstTerms = [
        'No',
        'NAY',
        'NAE',
        'nae',
        'nay',
        'Against',
        'AGAINST',
        'Disapprove',
        'Reject',
        'NO',
        'no',
        'Nothing',
        'Not'
      ];
      let abstainTerms = ['Abstain', 'abstain', 'ABSTAIN'];

      if (forTerms.some(term => term == choice) && choiceNumber == 0) {
        this.choices[0] = 'For';
        return true;
      }
      if (againstTerms.some(term => term == choice) && choiceNumber == 1) {
        this.choices[1] = 'Against';
        return true;
      }
      if (abstainTerms.some(term => term == choice) && choiceNumber == 2) {
        this.choices[2] = 'Abstain';
        return true;
      }
    }
  }
};
</script>
