<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.764 2.35202C9.84464 1.45366 8.58698 0.9 7.2 0.9C4.38335 0.9 2.1 3.18335 2.1 6C2.1 8.81665 4.38335 11.1 7.2 11.1C8.58696 11.1 9.84464 10.5463 10.764 9.64798C9.66736 11.078 7.94135 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C7.94135 0 9.66736 0.922008 10.764 2.35202Z"
      fill="#94A3B8"
    ></path>
    <path
      d="M3.38867 8.91834C4.12419 9.63703 5.13031 10.08 6.23989 10.08C8.49322 10.08 10.3199 8.25328 10.3199 5.99995C10.3199 3.74662 8.49322 1.91995 6.23989 1.91995C5.13031 1.91995 4.12419 2.36287 3.38867 3.08156C4.26601 1.93756 5.64681 1.19995 7.19989 1.19995C9.85086 1.19995 11.9999 3.34898 11.9999 5.99995C11.9999 8.65092 9.85086 10.8 7.19989 10.8C5.64681 10.8 4.26601 10.0623 3.38867 8.91834Z"
      fill="#94A3B8"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5999 6.00002C9.5999 7.98825 7.98813 9.60002 5.9999 9.60002C4.01168 9.60002 2.3999 7.98825 2.3999 6.00002C2.3999 4.0118 4.01168 2.40002 5.9999 2.40002C7.98813 2.40002 9.5999 4.0118 9.5999 6.00002ZM8.3999 6.00002C8.3999 7.32551 7.32539 8.40002 5.9999 8.40002C4.67442 8.40002 3.5999 7.32551 3.5999 6.00002C3.5999 4.67454 4.67442 3.60002 5.9999 3.60002C7.32539 3.60002 8.3999 4.67454 8.3999 6.00002Z"
      fill="#94A3B8"
    ></path>
  </svg>
</template>
