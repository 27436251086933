<template>
  <notification-group group="app">
    <div class="fixed top-5 z-[100] w-full items-center justify-center sm:max-w-md md:top-auto md:right-0">
      <notification v-slot="{ notifications, close }">
        <template v-for="notification in notifications">
          <NotificationAvailableChats
            v-if="notification.type == 'chat_invite'"
            :key="notification.id"
            :notification="notification"
            :close="close"
          />
          <NotificationChat
            v-else-if="notification.type == 'chat'"
            :key="notification.id"
            :notification="notification"
            :close="close"
          />
          <NotificationFlash v-else :key="notification.id" :notification="notification" :close="close" />
        </template>
      </notification>
    </div>
  </notification-group>
</template>

<script setup>
import NotificationFlash from './NotificationFlash.vue';
import NotificationAvailableChats from './NotificationAvailableChats.vue';
import NotificationChat from './NotificationChat.vue';
import { inject, onMounted } from 'vue';

const props = defineProps({
  flashMessages: {
    type: Array
  }
});

const flashMessage = inject('flashMessage');

onMounted(() => {
  if (props.flashMessages.length > 0) {
    props.flashMessages.forEach(msg => {
      if (msg[1] !== '') {
        flashMessage({ type: msg[0], message: msg[1] });
      }
    });
  }
});
</script>
