<template>
  <div class="h-full space-y-4 overflow-y-auto text-xs">
    <template v-if="!loading">
      <div v-for="sourceType in ['headers', 'data', 'params']" :key="sourceType">
        <template v-if="sourceCanBeModified(sourceType)">
          <label class="mt-4 text-xs capitalize text-gray-300">{{ sourceType }}</label>
          <div v-for="attribute in apiParams.source[sourceType]" :key="attribute.id" class="mt-2 flex items-center">
            <template v-if="editableAttribute(attribute)">
              <div class="w-1/3 text-gray-400">
                {{ attribute.name }} <span v-if="attribute.hide_default" class="text-red-500">*</span>
              </div>
              <div class="w-2/3">
                <BaseTextbox v-model="attribute.value" />
              </div>
            </template>
          </div>
        </template>
      </div>
      <template v-if="apiParams.source.raw_json_body">
        <div class="space-y-1">
          <label class="mt-4 text-xs capitalize text-gray-200">Body</label>
          <BaseTextarea v-model="apiParams.body.value" rows="5" />
        </div>
      </template>
    </template>
  </div>
</template>
<script>
export default {
  name: 'WidgetConfigsCustomComponent',
  emits: ['update'],
  props: {
    config: {
      type: Object
    },
    createdBy: {
      type: Number
    },
    templateId: {
      type: [String, Number]
    }
  },
  mounted() {
    this.fetchAPIParams();
  },
  data() {
    return {
      apiParams: {},
      loading: true,
      editableConfig: {
        refresh: 0,
        source: {},
        template: '',
        config: {}
      }
    };
  },
  computed: {
    currentUserId() {
      return this.$store.getters.userId;
    },
    configurationIsValid() {
      //WIP
      return true;
    }
  },
  watch: {
    apiParams: {
      deep: true,
      handler() {
        this.$emit('update', { ...this.apiParams, valid: this.configurationIsValid });
      }
    }
  },
  methods: {
    async fetchAPIParams() {
      let response = await this.$http.post('/custom_component_api/list_params', {
        type: typeof this.templateId == 'string' ? 'widget' : 'template',
        id: this.templateId
      });
      this.apiParams = response.data;
      this.loading = false;
    },
    editableAttribute(attribute) {
      if (attribute.name.trim() == '' || (attribute.hide_default === true && this.createdBy != this.currentUserId)) {
        return false;
      }
      return true;
    },
    sourceCanBeModified(type) {
      if (type == 'body') {
        return this.apiParams?.source?.body.value != '';
      }
      if (['headers', 'params', 'data'].includes(type)) {
        return (
          this.apiParams?.source[type].filter(x => !x.hide_default).length >= 1 &&
          this.apiParams?.source[type][0].name !== ''
        );
      }
      return false;
    }
  }
};
</script>
