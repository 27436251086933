<template>
  <div class="flex flex-wrap">
    <div
      v-for="tag in tags"
      :key="tag.id"
      @click="toggle(tag)"
      :class="['selected-none rounded font-medium duration-100', disabled ? '' : 'cursor-pointer', getTagStyles(tag)]"
    >
      {{ tag.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    tags: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] }
  },
  data() {
    return {};
  },
  methods: {
    getTagStyles(tag) {
      let background = this.selected.includes(tag.id) ? 'bg-blue-900' : 'bg-gray-800';
      let text = this.selected.includes(tag.id) ? 'text-blue-100' : 'text-gray-300';
      let hover = 'hover:bg-gray-700 hover:text-gray-100';
      if (tag.backgroundColor) {
        background = tag.backgroundColor;
      }
      if (tag.textColor) {
        text = tag.textColor;
      }
      if (tag.hover) {
        hover = tag.hoverColor;
      }
      return [background, hover, text, 'px-2 py-1 text-xs mr-4 mb-2'].join(' ');
    },
    toggle: function (t) {
      if (this.disabled) {
        return;
      }

      this.$emit('toggle', t.id);
    }
  }
};
</script>
