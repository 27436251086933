<template>
  <div class="bg-gray-800/50">
    <div class="flex h-10 items-center px-5">
      <InertiaLink v-if="sponsor == 'falconx'" href="/" class="flex-shrink-0">
        <img src="../images/falconx-logo.svg" class="h-6 flex-shrink-0" />
      </InertiaLink>
      <InertiaLink v-else href="/" class="flex-shrink-0">
        <img src="../images/logo-sm.svg" class="h-6 flex-shrink-0" />
      </InertiaLink>
      <BasePriorityBar
        :items="items.filter(i => i.id != 'gas_fees')"
        :key="`cn${customNavigation} + hk${holdingOption}`"
        class="ml-6"
      >
        <BasePriorityBarItem
          class="flex items-center"
          v-for="(item, index) in items"
          :key="item.id"
          :priority="item.priority"
          @mouseenter="hoveredNavItem = item.id"
          @mouseleave="hoveredNavItem = null"
        >
          <NavigationNotifications v-if="item.id == 'alerts'" class="pr-4" />
          <div v-else-if="item.id == 'gas_fees'">
            <NavigationGasFees />
          </div>
          <div v-else class="relative items-center justify-center pr-4">
            <InertiaLink
              data-nav-link
              :href="item.link"
              class="flex items-center text-xs font-medium"
              :class="url.startsWith(item.link) ? 'text-white' : 'text-gray-300 hover:text-white'"
              :key="item.id"
            >
              <div
                :class="[
                  'mr-1.5 h-4 w-0.5 rounded-full from-blue-500 to-blue-600',
                  url.startsWith(item.link) ? 'bg-gradient-to-t' : ''
                ]"
              ></div>
              <div class="flex items-center" v-html="tabName(item.name)"></div>
              <span v-if="holdingOption && index < 10" class="text-slate-400 ml-0.5 font-mono text-xs">
                [{{ index == 9 ? 0 : index + 1 }}]
              </span>
              <IconChevronDown
                :class="{ 'rotate-180': hoveredNavItem == item.id }"
                class="duration-0 transform ease-in-out"
                v-if="item.subNavItems && item.subNavItems.length > 0"
              />
            </InertiaLink>
            <div
              class="absolute z-51 w-28 cursor-pointer rounded-md border border-gray-700 bg-gray-800 text-xs"
              v-if="hoveredNavItem == item.id && item.subNavItems && item.subNavItems.length > 0"
            >
              <InertiaLink
                v-for="subTabs in item.subNavItems"
                :href="subTabs.link"
                :key="subTabs.id"
                class="h-full cursor-pointer"
              >
                <div
                  :class="[
                    'cursor-pointer py-2 px-2',
                    url.startsWith(subTabs.link)
                      ? 'bg-gray-700 text-white'
                      : 'border-gray-700 text-gray-300 hover:bg-blue-900/50 hover:text-white'
                  ]"
                >
                  {{ tabName(subTabs.name) }}
                </div>
              </InertiaLink>
            </div>
          </div>
        </BasePriorityBarItem>
      </BasePriorityBar>
      <div class="flex items-center space-x-4 px-5">
        <div class="flex items-center space-x-4" v-if="!apiRole && !props.onlyCompliance">
          <BaseTooltip v-if="customNavigation" class="flex items-center">
            <template #target>
              <div class="flex flex-row items-center">
                <IconBookmark
                  class="text-slate-400 hover:text-slate-100 h-3.5 w-3.5 cursor-pointer duration-200"
                  @click="createLinkModalOpen = true"
                />
                <span v-if="holdingOption" class="text-slate-400 ml-0.5 font-mono text-xs">[D]</span>
              </div>
            </template>
            <template #default>
              <span class="whitespace-normal px-4 py-2 text-xs font-semibold normal-case text-gray-300">
                Add Navigation Link [Option+D]
              </span>
            </template>
          </BaseTooltip>
          <BaseMenu
            :items="createLinks"
            width-class="w-32"
            class="whitespace-nowrap rounded-full bg-blue-900 py-0.5 pb-0.5 pr-2.5 hover:bg-blue-800"
            @action="handleCreateLinksClick"
          >
            <span class="pl-3">+ Create</span>
          </BaseMenu>
          <GlobalSearch />
          <div
            class="flex cursor-pointer hover:text-white"
            @click="$eventHub.$emit('toggle-chat-dropdown', toggleChatDropdown())"
          >
            <IconChatAlt
              v-if="messagingOption"
              class="hover:text-white"
              :class="chatDropdown ? 'text-white' : 'text-gray-400'"
              size="w-4 h-4"
            />
            <span
              v-if="unreadChannelsCount > 0"
              class="fixed -mt-1 ml-2 rounded-full bg-blue-600 px-1 text-blue-100"
              style="font-size: 0.5rem"
            >
              {{ unreadChannelsCount > 9 ? '9+' : unreadChannelsCount }}
            </span>
          </div>
          <BaseTooltip>
            <template #target>
              <a
                class="text-gray-400 hover:text-white"
                href="https://docs.thetie.io/docs/metric-definitions"
                target="_blank"
              >
                <IconQuestionMark />
              </a>
            </template>
            <template #default>Help Docs and Guides</template>
          </BaseTooltip>

          <span class="headway-badge text-gray-400" id="headway-badge">
            <IconAnnouncements />
          </span>
        </div>

        <div class="flex flex-shrink-0 justify-end text-right text-xs font-medium text-white">
          <DropdownProfile
            :user-email="userEmail"
            :user-first-name="userFirstName"
            :user-last-name="userLastName"
            :savvycal-link="savvycalLink"
          />
        </div>
      </div>
    </div>
  </div>
  <BaseDialog v-model="createLinkModalOpen" size-class="!max-w-sm">
    <template #header>Add Navigation Link</template>
    <template #default>
      <form class="flex flex-row space-x-4 pt-2 pb-5" @submit.prevent="createItem">
        <div class="flex w-1/2 flex-col">
          <span>Name</span>
          <BaseTextbox v-model="name" required />
        </div>
        <div class="flex w-1/2 flex-col">
          <span>Link</span>
          <BaseTextbox v-model="link" required disabled class="opacity-50" />
        </div>
        <input type="submit" class="hidden" />
      </form>
    </template>
    <template #footer>
      <div class="flex w-full justify-end space-x-2">
        <BaseButton type="secondary" size="sm" @press="createLinkModalOpen = false">Cancel</BaseButton>
        <BaseButton size="sm" @press="createItem">+ Add Link</BaseButton>
      </div>
    </template>
  </BaseDialog>
  <ModalChatLaunch v-if="showLaunchModal" @close="closeChatLaunch()" />
  <ChatOnboarding v-if="showProfileModal" @close="onCancelProfileSave()"></ChatOnboarding>
</template>

<script setup>
import { useStore } from 'vuex';
import NavigationNotifications from '@/components/navigation/NavigationNotifications.vue';
import NavigationGasFees from '@/components/navigation/NavigationGasFees.vue';
import GlobalSearch from '@/components/GlobalSearch.vue';
import DropdownProfile from '@/components/dropdown/DropdownProfile.vue';
import ModalChatLaunch from '@/components/modal/ModalChatLaunch.vue';
import moment from 'moment';
import IconBookmark from './icon/IconBookmark.vue';
import { Link as InertiaLink, usePage } from '@inertiajs/inertia-vue3';
import { Inertia } from '@inertiajs/inertia';
import ChatOnboarding from '@/components/chat/ChatOnboardingModal.vue';

import {
  computed,
  inject,
  ref,
  reactive,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  watch,
  getCurrentInstance
} from 'vue';
import { methods } from 'vue3-vt-notifications/src/notifications';

const url = computed(() => usePage().url.value);
const store = useStore();
const instance = getCurrentInstance();
const $root = instance.root.proxy;
const $eventHub = inject('eventHub');
const $http = inject('http');

const props = defineProps({
  apiToken: { type: String, default: '' },
  permissions: { type: Array, default: () => [] },
  roles: { type: Array, default: () => [] },
  userId: { type: Number, default: null },
  userEmail: { type: String, default: '' },
  userFirstName: { type: String, default: '' },
  userLastName: { type: String, default: '' },
  teamId: { type: Number, default: null },
  savvycalLink: { type: String, default: '' },
  sponsor: { type: String, default: '' },
  userToken: String,
  streamKey: String,
  giphyKey: String,
  showTerminal: Boolean,
  impersonating: Boolean,
  status: { type: String, default: '' },
  onlyCompliance: { type: Boolean, default: false },
  messengerLaunchDate: String,
  teamName: String,
  currentUser: { type: Object, default: null },
  launchMessengerCancelled: String
});

const showLaunchModal = ref(false);

onBeforeMount(() => {
  store.dispatch('setApiToken', props.apiToken);
  store.dispatch('setGiphyKey', props.giphyKey);
  store.dispatch('setUser', {
    user_id: props.userId,
    team_id: props.teamId,
    user_email: props.userEmail,
    roles: props.roles,
    permissions: props.permissions,
    status: props.status,
    team_name: props.teamName,
    user: props.currentUser
  });
  if (props.userToken != '') {
    store.dispatch('setStreamClient', { token: props.userToken, key: props.streamKey });
  }
  if (
    props.messengerLaunchDate &&
    moment().isSameOrAfter(moment(props.messengerLaunchDate).subtract(1, 'week'), 'day') &&
    moment().isSameOrBefore(moment(props.messengerLaunchDate), 'day') &&
    !props.teamName.toLowerCase().includes('[edu]') &&
    !store.getters.roles.includes('messaging') &&
    store.getters.roles.some(x => ['admin', 'super_admin'].includes(x)) &&
    props.launchMessengerCancelled != 'true'
  ) {
    showLaunchModal.value = true;
  }
});

// create new custom link
const customNavigation = ref(JSON.parse(window.localStorage.getItem('customNavigation') || 'false'));
const createLinkModalOpen = ref(false);
watch(createLinkModalOpen, () => {
  name.value = '';
  holdingOption.value = false;
  link.value = window.location.pathname;
});
watch(customNavigation, () => {
  getCustomNavItems();
});
async function createItem() {
  await $http
    .post('/user_navigation_items', { name: name.value, link: link.value, priority: 0 })
    .then(() => getCustomNavItems().then(() => updateRowData(items.value)));
}
async function updateRowData(rowData) {
  const updatedRowData = rowData.map((row, index) => {
    return {
      ...row,
      priority: rowData.length - index
    };
  });
  await $http
    .patch('/user_navigations/0', {
      name: 'Default',
      user_navigation_items: updatedRowData
    })
    .then(() => getCustomNavItems());
}

// displaying navbar items
const items = ref([]);
const name = ref('');
const link = ref('');
const navItems = inject('navItems');
watch(navItems, () => {
  getCustomNavItems();
});
async function getCustomNavItems() {
  createLinkModalOpen.value = false;
  items.value = JSON.parse(window.localStorage.getItem('navigation')) || [];
  if (!customNavigation.value) {
    items.value = store.state.navItems.slice();
    items.value.push({ id: 'gas_fees', link: '', name: 'Gas Fees', priority: 1 });
  } else {
    await $http
      .get('/user_navigations')
      .then(response => {
        items.value = response.data[0].user_navigation_items.sort((a, b) => b.priority - a.priority);

        window.localStorage.setItem('navigation', JSON.stringify(items.value));
      })
      .catch(() => {
        items.value = store.state.navItems;
      });
  }

  items.value = items.value
    .filter(item => !item.hidden)
    .map(item => {
      return {
        ...item,
        label: item.name,
        href: item.link
      };
    });
  holdingOption.value = false; // force PriorityBar refresh

  name.value = '';
  link.value = '';
}

// new tab
const expire = ref('2022-08-1');
function tabName(name) {
  const tabs = ['NFTs'];
  return tabs.includes(name) && moment().isBefore(expire.value)
    ? `${name} <div class='ml-1'><span class="rounded bg-blue-900 text-blue-100 px-1 py-0.5" style="font-size: 0.6rem">New</span></div>`
    : name;
}

//roles
const apiRole = computed(() => props.roles.includes('api_user'));
const showProfileModal = ref(false);
onMounted(async () => {
  store.dispatch('setMessagingAccess', props.showTerminal);
  if (!apiRole.value && !props.onlyCompliance) {
    await getCustomNavItems();
  }
  if (!props.currentUser.profile_completed && store.getters.client && store.getters.roles.includes('messaging')) {
    showProfileModal.value = true;
  }
});

// keyboard shortcuts for nav bar
const holdingOption = ref(false);
function handleKeyup(e) {
  if (['input', 'textarea', 'select'].includes(e.target.nodeName.toLowerCase())) return;
  if (e.key === 'Alt' && holdingOption.value) holdingOption.value = false;
}
function handleHotkey(e) {
  if (['input', 'textarea', 'select'].includes(e.target.nodeName.toLowerCase())) return;
  if (e.altKey) holdingOption.value = true;
  if (e.code === 'KeyD' && e.altKey) {
    createLinkModalOpen.value = true;
  } else if (e.code === 'KeyN' && e.altKey) {
    customNavigation.value = !customNavigation.value;
    window.localStorage.setItem('customNavigation', customNavigation.value);
  } else if (e.altKey && [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => `Digit${i}`).includes(e.code)) {
    const digit = e.code[5];
    window.location.pathname = digit == 0 ? items.value[9].link : items.value[digit - 1].link;
  }
}
onMounted(() => {
  window.addEventListener('keydown', handleHotkey);
  window.addEventListener('keyup', handleKeyup);
});
onBeforeUnmount(() => {
  window.removeEventListener('keyup', handleKeyup);
  window.removeEventListener('keydown', handleHotkey);
});

// messenger
const chatDropdown = ref(false);
const unreadChannelsCount = computed(() => store.getters.unreadChannelsCount);
const messagingOption = computed(() => (props.userToken ? true : false));
function toggleChatDropdown() {
  chatDropdown.value = !chatDropdown.value;
  return chatDropdown.value;
}
onMounted(() => {
  $eventHub.$on('chat-dropdown-toggle', data => {
    chatDropdown.value = data;
  });
  $eventHub.$on('toggle-navbar-messaging', data => {
    messagingOption.value = data;
  });
  $eventHub.$on('toggle-messaging-modal', () => {
    showLaunchModal.value = true;
  });
});

// navbar create links
const createLinks = reactive([
  { label: '+ Watchlist', action: 'watchlist' },
  { label: '+ News Feed', action: 'news' },
  { label: '+ Alert', action: 'alerts' }
]);
function handleCreateLinksClick(action) {
  const links = {
    watchlist: '/watchlists/create',
    news: '/news/firehose/news',
    alerts: '/alerts/new'
  };
  Inertia.visit(links[action]);
}

// active items
const hoveredNavItem = ref('null');

// storing user data
function storeUserData() {
  if (navigator && !localStorage.getItem('userDataSet')) {
    $root.createEvent({
      title: 'User Data',
      properties: { operating_ystem: userOperatingSystem(), browser: userBrowser() }
    });
    localStorage.setItem('userDataSet', true);
  }
}
function userBrowser() {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'Opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'Edge';
  } else {
    browserName = 'No browser detection';
  }
  return browserName;
}
function userOperatingSystem() {
  var Name = 'Not known';
  if (navigator.appVersion.indexOf('Win') != -1) Name = 'Windows OS';
  if (navigator.appVersion.indexOf('Mac') != -1) Name = 'MacOS';
  if (navigator.appVersion.indexOf('X11') != -1) Name = 'UNIX OS';
  if (navigator.appVersion.indexOf('Linux') != -1) Name = 'Linux OS';
  return Name;
}
onBeforeMount(() => {
  storeUserData();
});

// go to docs
function goToDocs() {
  window.open('https://docs.thetie.io/docs/metric-definitions');
}

function closeChatLaunch() {
  showLaunchModal.value = false;
  const response = $http.patch('/chat/cancel_launch');
}

function onCancelProfileSave() {
  showProfileModal.value = false;
  if (!props.currentUser.profile_completed) {
    const response = $http.patch('/profile', {
      no_flash: true,
      user: {
        profile_completed: true
      }
    });
  }
}
</script>
