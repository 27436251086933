<template>
  <div class="flex h-full w-full justify-start">
    <ChartSettings
      class="relative h-full"
      :class="settings && settings.sources.length > 0 ? 'w-full overflow-y-auto' : ''"
      :settings="settings"
      :widget-only="true"
      :saved-layouts="layouts"
      :metric-options="chartMetricOptions"
      :current-layout="currentLayout"
      :anchor="'left'"
      @change="(settings = { ...settings, ...$event }), resetLayout()"
      @selected="selectLayout($event)"
    />
  </div>
</template>

<script>
import ChartSettings from '@/components/chart/ChartSettings.vue';
import FilterMixin from '@/mixins/filters';
import { isEmpty } from 'lodash';

export default {
  name: 'WidgetConfigsResearchChart',
  emits: ['update'],
  mixins: [FilterMixin],
  props: {
    config: {
      type: Object
    }
  },
  components: {
    ChartSettings
  },
  mounted() {
    this.getLayouts();
    if (this.config && !isEmpty(this.config)) {
      this.settings = this.config.settings;
      this.selectedLayout = this.config.selectedLayout;
    }
  },
  data() {
    return {
      selectedLayout: null,
      layouts: [],
      settings: { sources: [], sameAxis: true },
      chartMetricOptions: [
        { id: 'price', name: 'Price' },
        { id: 'daily_sentiment', name: 'Daily Sentiment' },
        { id: 'tweet_volume', name: 'Tweet Volume' },
        { id: 'trading_volume', name: 'Trading Volume' },
        { id: 'price_return_24_hours', name: 'Price Change 24 Hours' },
        { id: 'price_return_1_hour', name: 'Price Change 1 Hour' },
        { id: 'daily_sentiment_avg_30_days', name: 'Daily Sentiment Avg 30 Days' },
        { id: 'daily_sentiment_change_7_days', name: 'Daily Sentiment Avg 7 Days' },
        { id: 'daily_sentiment_momentum', name: 'Daily Sentiment Momentum' },
        { id: 'hourly_sentiment', name: 'Hourly Sentiment' },
        { id: 'long_term_sentiment', name: 'Long Term Sentiment' },
        { id: 'market_cap', name: 'Market Cap' },
        { id: 'price_btc', name: 'Price BTC' },
        { id: 'price_return_30_days', name: 'Price Change 30 Days' },
        { id: 'price_return_btc_1_hour', name: 'Price Change BTC 1 hour' },
        { id: 'trading_volume_30_day_avg', name: 'Trading Volume 30 Days Avg' },
        { id: 'tweet_volume_30_day_avg', name: 'Tweet Volume 30 Days Avg' },
        { id: 'tweet_volume_dominance', name: 'Tweet Volume Dominance' },
        { id: 'trading_volume_dominance', name: 'Trading Volume Dominance' },
        { id: 'price_return_btc_24_hours', name: 'Price Change BTC 24 Hours' },
        { id: 'price_return_btc_30_days', name: 'Price Change BTC (30D)' },
        { id: 'relative_trade_volume', name: 'Relative Trading Volume' },
        { id: 'simple_moving_average_50_days', name: '50D SMA' },
        { id: 'simple_moving_average_change_50_days', name: '50D SMA Change' },
        { id: 'hype_to_activity_ratio', name: 'Hype To Activity Ratio' },
        { id: 'nvtweet_ratio', name: 'NVTweet Ratio' },
        { id: 'relative_tweet_volume', name: 'Relative Tweet Volume' },
        { id: 'tweet_unique_sources_percent', name: 'Tweet Unique Sources %' },
        { id: 'tweet_unique_sources_percent_30_day_avg', name: '30D Avg Unique Tweet Sources' },
        { id: 'twitter_accounts', name: 'Twitter Accounts' },
        { id: 'twitter_accounts_30_day_avg', name: 'Twitter Accounts 30D AVG' },
        { id: 'tweet_volume_1_minute', name: 'Tweet Volume 1 Minute' },
        { id: 'tweet_unique_sources_percent_1_minute', name: 'Tweet Unique Sources % 1 Min' },
        { id: 'news_stories_v2_1_day', name: 'News Stories 1 Day' },
        { id: 'news_stories_v2_30_day_avg', name: 'News Stories 30 Day' },
        { id: 'relative_news_v2', name: 'Relative News' },
        { id: 'market_cap_dominance', name: 'Market Cap Dominance' },
        { id: 'tvl', name: 'Total Value Locked', onlyCoin: true },
        { id: 'mcap_/_tvl', name: 'MCAP/TVL', onlyCoin: true },
        { id: 'youtube_view_count', name: 'YouTube Views' },
        { id: 'tiktok_play_count', name: 'TikTok Views' },
        { id: 'fourchan_post_count', name: '4chan Mentions' },
        { id: 'youtube_view_count_30d_avg', name: 'YouTube Views (30d Avg)' },
        { id: 'tiktok_play_count_30d_avg', name: 'TikTok Views (30d Avg)' },
        { id: 'fourchan_post_count_30d_avg', name: '4chan Mentions (30d Avg)' },
        { id: 'num_inc_24', name: 'Addresses with Increased Balance' },
        { id: 'num_dec_24', name: 'Addresses with Decreased Balance' },
        { id: 'num_txs_24h', name: 'Transactions' },
        { id: 'num_active_addrs_24h', name: 'Active Addresses' },
        { id: 'num_inc_30d_avg', name: 'Addresses with Increased Balance (30d Avg)' },
        { id: 'num_dec_30d_avg', name: 'Addresses with Decreased Balance (30d Avg)' },
        { id: 'num_tx_30d_avg', name: 'Transactions (30d Avg)' },
        { id: 'num_active_addrs_30d_avg', name: 'Active Addresses (30d Avg)' },
        { id: 'supply_in_circulation', name: '% of Supply in Circulation' },
        { id: 'commit_count', name: 'Monthly Commits' },
        { id: 'active_users_30d', name: 'Monthly Active Developers' }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters.session;
    },
    currentLayout() {
      return this.layouts.find(l => l.id == this.selectedLayout);
    },
    configuration() {
      return {
        settings: this.settings,
        selectedLayout: this.selectedLayout,
        user_id: this.user.user_id,
        valid: true
      };
    }
  },
  watch: {
    configuration: {
      deep: true,
      handler: function () {
        this.$emit('update', this.configuration);
      }
    }
  },
  methods: {
    async getLayouts() {
      let response = await this.$http.post('/shared_resources', { entity: 'chart', id: null });
      this.layouts = response.data.resource.map(x => {
        return {
          ...x,
          label: x.name
        };
      });
    },
    selectLayout(layout) {
      if (this.selectedLayout != layout.id) {
        this.selectedLayout = layout.id;
        this.settings = { sources: [], sameAxis: true };
      }
    },
    resetLayout() {
      if (this.selectedLayout) {
        this.selectedLayout = null;
      }
    }
  }
};
</script>
