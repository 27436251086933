<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
    />
  </svg>
</template>
<script setup>
import { ref, onMounted } from 'vue';
const interval = ref(null);

onMounted(() => {
  interval.value = setInterval(() => {
    if (window.Headway) {
      window.Headway.init({
        selector: '.headway-badge',
        account: '7wjwZx',
        translations: {
          title: 'Latest Updates 🎉'
        },
        callbacks: {
          onWidgetReady: widget => {
            if (widget.getUnseenCount() > 0) {
              document.getElementById('headway-badge').classList.remove('text-gray-400');
            }
          }
        }
      });
      clearInterval(interval.value);
    }
  }, 1000);
});
</script>
