<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9559_5356)">
      <path
        d="M2.90959 6.66515C2.90958 6.58696 2.92501 6.50954 2.95499 6.43734C2.98498 6.36514 3.02892 6.29958 3.08431 6.24442C3.1397 6.18925 3.20544 6.14559 3.27775 6.11591C3.35006 6.08624 3.42751 6.07116 3.50567 6.07152L4.4939 6.07476C4.65147 6.07476 4.80259 6.13738 4.91401 6.24884C5.02543 6.3603 5.08802 6.51147 5.08802 6.6691V10.4073C5.19935 10.3743 5.34212 10.3392 5.49853 10.3025C5.60713 10.2769 5.70391 10.2154 5.77319 10.1279C5.84246 10.0404 5.88017 9.93209 5.8802 9.82048V5.18352C5.88019 5.02587 5.94277 4.87467 6.05419 4.76319C6.16561 4.6517 6.31673 4.58906 6.47432 4.58903H7.46456C7.62214 4.58907 7.77326 4.65172 7.88467 4.7632C7.99609 4.87469 8.05868 5.02587 8.05868 5.18352V9.48725C8.05868 9.48725 8.30649 9.38685 8.54805 9.28488C8.63775 9.24692 8.71429 9.18338 8.76813 9.10219C8.82197 9.02099 8.85073 8.92574 8.85081 8.82831V3.6974C8.8508 3.61936 8.86616 3.54208 8.89601 3.46997C8.92586 3.39787 8.96961 3.33235 9.02477 3.27716C9.07993 3.22197 9.14542 3.17819 9.21749 3.14832C9.28957 3.11845 9.36682 3.10307 9.44483 3.10306H10.4351C10.5926 3.10306 10.7438 3.16568 10.8552 3.27714C10.9666 3.3886 11.0292 3.53977 11.0292 3.6974V7.92243C11.8877 7.30006 12.7577 6.55145 13.4482 5.65133C13.5484 5.52067 13.6146 5.36724 13.6411 5.20473C13.6676 5.04221 13.6535 4.87566 13.6 4.71994C13.1328 3.35798 12.2565 2.17361 11.0909 1.32877C9.92536 0.483931 8.52737 0.0198644 7.08812 2.42649e-05C3.20711 -0.0521301 -0.000347662 3.11713 3.35818e-05 7.00005C-0.0037771 8.22873 0.316788 9.43664 0.929316 10.5016C1.01379 10.6473 1.13806 10.7658 1.28754 10.8433C1.43701 10.9208 1.60548 10.9539 1.77315 10.9389C1.96049 10.9224 2.19371 10.8991 2.47097 10.8666C2.59165 10.8528 2.70306 10.7952 2.78399 10.7046C2.86492 10.614 2.90971 10.4968 2.90983 10.3753V6.66515"
        fill="rgb(148 163 184)"
      ></path>
      <path
        d="M2.88799 12.6608C3.93174 13.4204 5.16519 13.8763 6.45188 13.9782C7.73858 14.08 9.02835 13.8238 10.1785 13.2379C11.3287 12.6519 12.2944 11.7591 12.9688 10.6582C13.6433 9.55733 14.0001 8.29125 13.9999 7.00006C13.9999 6.83888 13.9924 6.67951 13.9817 6.521C11.4253 10.3348 6.70534 12.1181 2.88794 12.661"
        fill="rgb(148 163 184)"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_9559_5356">
        <rect width="14" height="14" fill="rgb(148 163 184)"></rect>
      </clipPath>
    </defs>
  </svg>
</template>
