<template>
  <div class="inline-block items-center">
    <BaseTooltip v-if="name" :position="placement">
      <template #target>
        <slot />
      </template>
      <template #default>
        <div class="max-w-[30rem] break-words px-2 text-left">
          <div class="mb-0.5 font-bold normal-case text-white">{{ name }}</div>
          <div
            v-html="definition"
            :class="['whitespace-normal break-words font-semibold normal-case text-gray-300']"
          ></div>
        </div>
      </template>
    </BaseTooltip>
    <div v-if="!name"><slot /></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    id: String,
    placement: String
  },
  computed: {
    ...mapGetters(['metricTooltips']),
    definition() {
      if (this.metricTooltips.length) {
        const index = this.metricTooltips.findIndex(x => x.metric_key == this.id);
        return index >= 0 ? this.metricTooltips[index].definition : '';
      }
      return '';
    },
    name() {
      if (this.metricTooltips.length) {
        const index = this.metricTooltips.findIndex(x => x.metric_key == this.id);
        return index >= 0 ? this.metricTooltips[index].name : '';
      }
      return '';
    }
  }
};
</script>
