<template>
  <div class="mt-4">
    <div class="space-y-4">
      <div class="space-y-1">
        <label for="content" class="text-xs text-gray-400">Link or Embed Code</label>
        <textarea
          rows="5"
          placeholder="Copy and paste the link or embed code of source"
          class="mt-1 mb-2 text-xs"
          name="content"
          v-model="content"
        />
      </div>
      <div class="space-y-1">
        <label for="content" class="text-xs text-gray-400">Background Color</label>
        <DropdownSelectColor class="text-xs" :active-item="bgColor" @selected="bgColor = $event" />
      </div>
      <div class="space-y-1">
        <label for="content" class="text-xs text-gray-400">Scale</label>
        <DropdownSimple class="text-xs" :active-item="scale" :items="scalingOptions" @selected="scale = $event.id" />
      </div>
    </div>
  </div>
</template>
<script>
import DropdownSelectColor from '@/components/dropdown/DropdownSelectColor.vue';
import DropdownSimple from '@/components/dropdown/DropdownSimple.vue';
import { isEmpty } from 'lodash';
export default {
  name: 'WidgetConfigsIframe',
  emits: ['update'],
  props: {
    config: {
      type: Object
    }
  },
  components: {
    DropdownSelectColor,
    DropdownSimple
  },
  mounted() {
    if (this.config && !isEmpty(this.config)) {
      this.content = this.config.content;
      this.scale = this.config.scale;
      this.bgColor = this.config.backgroundColor;
    }
  },
  data() {
    return {
      bgColor: '#FFFFFF',
      content: '',
      scale: 'scale-100'
    };
  },
  computed: {
    configuration() {
      return {
        scale: this.scale,
        backgroundColor: this.bgColor,
        content: this.content,
        valid: this.content.trim() != ''
      };
    },
    scalingOptions() {
      return [
        { id: 'scale-50', text: '50%' },
        { id: 'scale-75', text: '75%' },
        { id: 'scale-90', text: '90%' },
        { id: 'scale-95', text: '95%' },
        { id: 'scale-100', text: '100%' },
        { id: 'scale-105', text: '105%' },
        { id: 'scale-110', text: '110%' },
        { id: 'scale-125', text: '125%' },
        { id: 'scale-150', text: '150%' }
      ];
    }
  },
  watch: {
    configuration() {
      this.$emit('update', this.configuration);
    }
  }
};
</script>
