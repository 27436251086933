<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="10"
    height="10"
    viewBox="0 0 172 172"
    style="fill: #000000"
    class="inline-block"
  >
    <g
      fill="none"
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="rgb(52, 211, 153)">
        <path
          d="M170.43229,142.64046h-0.0035c-11.44637,-23.48763 -30.6333,-56.79443 -47.56665,-86.17497c-8.86735,-15.39713 -17.24129,-29.93343 -23.29517,-40.97738c-2.85547,-5.21403 -7.92952,-8.32145 -13.57048,-8.32145c-5.64095,0 -10.71501,3.10742 -13.57048,8.32145c-6.05037,11.04395 -14.41732,25.56624 -23.27767,40.94938c-16.93685,29.39453 -36.12728,62.70833 -47.58065,86.20296c-1.03931,2.1416 -1.56771,4.40218 -1.56771,6.71875c0,8.53141 6.89022,15.47412 15.36214,15.47412l70.63786,-0.014l70.63786,0.014c8.47192,0 15.36214,-6.94271 15.36214,-15.47412c0,-2.31657 -0.5284,-4.57715 -1.56771,-6.71875z"
        ></path>
      </g>
    </g>
  </svg>
</template>
