<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :overrides="overrides"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      :connectable="componentIsConnectable"
      :hidden-actions="['expand']"
      :additional-actions="additionalActions"
      :horizontal-padding="false"
    >
      <template #body>
        <div v-if="hasHeader" class="sticky top-0 z-30 bg-gray-800/25">
          <WidgetsHeader
            :advanced-settings="widget.widget_template.default_configuration.advancedSettings"
            :tab="widget.widget_template.default_configuration.config.tab"
            :params="apiParams.source.params"
            @update-search="updateSearchAPIResponse($event)"
            @update-dropdown="updateDropdownAPIResponse($event)"
          />
        </div>
        <div
          :style="hasHeader ? `height: calc(100% - 26px)` : 'height: 100%'"
          class="overflow-y-auto"
          @scroll="handleScroll"
          :id="id"
        >
          <div v-if="reloading" class="mt-10 flex items-center justify-center">
            <BaseLoader />
          </div>
          <WidgetsErrorMessage v-else-if="showPrivateValuesError">
            Required parameters are missing.
            <span v-if="!viewOnlyMode">
              Click on <IconThreeDots class="inline h-4 w-4" /> and then Edit to add those.
            </span>
          </WidgetsErrorMessage>
          <component
            v-else
            :is="widget.widget_template.default_configuration.template"
            :widget="widget"
            :data-set="apiResponseBody"
            :width="width"
            :height="height"
            :subscribe-only-mode="subscribeOnlyMode"
            :overrides="overrides"
            :pagination="pagination"
          />
        </div>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';
import WidgetCustomTemplateTable from '@/components/widget_custom_templates/WidgetCustomTemplateTable.vue';
import WidgetCustomTemplateHtml from '@/components/widget_custom_templates/WidgetCustomTemplateHtml.vue';
import WidgetCustomTemplateLineChart from '@/components/widget_custom_templates/WidgetCustomTemplateLineChart.vue';
import WidgetCustomTemplateScatterChart from '@/components/widget_custom_templates/WidgetCustomTemplateScatterChart.vue';
import WidgetCustomTemplatePieChart from '@/components/widget_custom_templates/WidgetCustomTemplatePieChart.vue';
import { debounce } from 'lodash';

export default {
  name: 'WidgetsCustomComponent',
  mixins: [WidgetsMixin],
  mounted() {
    const self = this;
    self.fetchAPIParams();
    self.$nextTick(() => {
      self.fetchAPIResponse();
    });
    self.interval = setInterval(() => {
      self.fetchAPIResponse();
    }, self.widget.widget_template.default_configuration?.refresh * 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  components: {
    WidgetCustomTemplateTable,
    WidgetCustomTemplateHtml,
    WidgetCustomTemplateLineChart,
    WidgetCustomTemplateScatterChart,
    WidgetCustomTemplatePieChart
  },
  data() {
    return {
      loading: true,
      reloading: true,
      interval: null,
      apiResponseBody: {},
      apiParams: {},
      editableApiParams: {},
      id: Math.random().toString(),
      pagination: {
        page: 1,
        perPage: 50
      }
    };
  },
  computed: {
    hasHeader() {
      let advanced = this.widget.widget_template.default_configuration.advancedSettings;
      return (
        advanced &&
        ((advanced.searchDropdowns?.length && advanced.searchDropdowns[0].param) || advanced.searchParam?.param)
      );
    },
    defaultConfig() {
      return this.widget.widget_template.default_configuration;
    },
    writeMode() {
      if (this.defaultConfig?.config.tab == 'table') {
        let cols = this.defaultConfig.config.tableColumns;
        return cols.some(x => x.type == 'coin');
      }
      return false;
    },
    readMode() {
      return this.apiParams?.source?.params?.findIndex(s => s.name == 'coin_uid') >= 0 || false;
    },
    componentIsConnectable() {
      return this.readMode || this.writeMode;
    },
    additionalActions() {
      let keys = Object.keys(this.defaultConfig);
      if (!keys.includes('allow_csv_download') || this.defaultConfig?.allow_csv_download == true) {
        return ['download'];
      }
      return [];
    },
    showPrivateValuesError() {
      let statuses = [];
      if (this.apiParams?.source) {
        ['headers', 'params', 'data'].forEach(x => {
          statuses.push(this.apiParams.source[x].some(x => x.hide_default && x.value == ''));
        });
        if (
          this.apiParams.source.raw_json_body &&
          this.apiParams.source.body.hide_default &&
          this.apiParams.source.body.value == ''
        ) {
          statuses.push(true);
        }
      }
      return statuses.some(x => x);
    }
  },
  watch: {
    'widget.configuration'() {
      this.apiParams = this.widget.configuration;
      this.editableApiParams = JSON.parse(JSON.stringify(this.apiParams));
      this.handleConfigUpdate();
    },
    widgetChannelOutput() {
      if (this.widgetChannelOutput && this.widgetChannelOutput.length) {
        this.replaceCoinUidFromChannel();
      }
    }
  },
  methods: {
    updateSearchAPIResponse(event) {
      if (this.editableApiParams.source || this.editableApiParams.params) {
        if (event.searchTerm.trim() != '') {
          let index = this.editableApiParams.source.params.findIndex(x => x.id == event.param);
          if (index >= 0) {
            this.editableApiParams.source.params[index].value = event.searchTerm;
          }
        } else {
          this.editableApiParams = JSON.parse(JSON.stringify(this.apiParams));
        }
        this.reloading = true;
        this.fetchAPIResponse();
      }
    },
    async updateDropdownAPIResponse(event) {
      if (this.editableApiParams.source) {
        let index = this.apiParams.source.params.findIndex(x => x.id == event.param);
        if (index >= 0) {
          this.apiParams.source.params[index].value = event.option.value;
        }
        this.reloading = true;
        let editableWidget = JSON.parse(JSON.stringify(this.widget));
        editableWidget.configuration = this.apiParams;
        editableWidget.configuration.valid = true;
        try {
          let response = await this.$http.patch(`/dashboard_widgets/${editableWidget.id}`, editableWidget);
          await this.fetchAPIParams();
          this.fetchAPIResponse();
        } catch {
          this.reloading = false;
        }
      }
    },
    async fetchAPIParams() {
      let response = await this.$http.post('/custom_component_api/list_params', {
        type: this.widget.uuid ? 'widget' : 'template',
        id: this.widget.uuid ? this.widget.uuid : this.widget.widget_template_id
      });
      this.apiParams = response.data;
      this.editableApiParams = JSON.parse(JSON.stringify(this.apiParams));
      if (this.widget.connected_channel && this.widgetChannelOutput) {
        this.replaceCoinUidFromChannel();
      }
    },
    replaceCoinUidFromChannel() {
      if (this.readMode && this.widgetChannelOutput[0]['coin_uid']) {
        let index = this.editableApiParams?.source?.params?.findIndex(x => x.name == 'coin_uid');
        if (index >= 0) {
          this.editableApiParams.source.params[index]['value'] = this.widgetChannelOutput[0]['coin_uid'];
          this.handleConfigUpdate();
        }
      }
    },
    handleConfigUpdate: debounce(function () {
      this.reloading = true;
      this.fetchAPIResponse();
    }, 500),
    handleScroll: async function (el) {
      if (el.target.offsetHeight + el.target.scrollTop >= el.target.scrollHeight - 200) {
        this.pagination.page++;
      }
    },
    async fetchAPIResponse() {
      try {
        let response = null;
        response = await this.$http.post('/custom_component_api/load', {
          type: this.widget.uuid ? 'widget' : 'template',
          id: this.widget.uuid ? this.widget.uuid : this.widget.widget_template_id,
          overrides: this.editableApiParams ? this.editableApiParams : null
        });
        let transformation = this.defaultConfig?.source.transformation_func;

        const fn = new Function('payload', `return transform(payload);${transformation}`);
        this.apiResponseBody = fn(response.data);

        this.loading = false;
        this.reloading = false;
      } catch {
        this.apiResponseBody = {};
        this.loading = false;
        this.reloading = false;
      }
    }
  }
};
</script>
