<template>
  <svg
    class="inline h-3 w-3 text-gray-300"
    fill="currentColor"
    stroke="currentColor"
    x="0px"
    y="0px"
    width="484.5px"
    height="484.5px"
    viewBox="0 0 484.5 484.5"
    style="enable-background: new 0 0 484.5 484.5"
    xml:space="preserve"
  >
    <g>
      <g>
        <polygon
          points="433.5,114.75 433.5,216.75 96.9,216.75 188.7,124.95 153,89.25 0,242.25 153,395.25 188.7,359.55 96.9,267.75 484.5,267.75 484.5,114.75"
        />
      </g>
    </g>
  </svg>
</template>
