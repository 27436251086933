<template>
  <div>
    <CellCompany v-if="investableType == 'Company'" :company="investedEntity" />
    <CellCoin v-if="investableType == 'Coin'" :coin="investedEntity" />
  </div>
</template>
<script>
import CellCompany from './CellCompany.vue';
import CellCoin from './CellCoin.vue';
export default {
  name: 'CellInvestedIn',
  components: {
    CellCompany,
    CellCoin
  },
  props: {
    investableType: {
      type: String,
      default: 'Coin'
    },
    investedEntity: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
