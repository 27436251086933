<template>
  <BaseDialog :model-value="show" size-class="!max-w-lg" @update:model-value="show = false">
    <template #header> Editing News Items </template>
    <template #default>
      <div>
        <DropdownSelectTag
          :show-only="['coins', 'companies', 'sigdevs', 'tags']"
          placeholder="Search tags"
          :key="item.id || 'none'"
          :tag-ids="tagIds"
          @tag-ids-changed="tagIdsChanged"
          :news-feed-props="newsFeedPrams"
          v-if="newsFeedPrams"
        />
      </div>
    </template>
    <template #footer>
      <BaseButton type="secondary" @press="show = false">Cancel</BaseButton>
      <BaseButton @press="update"> Update </BaseButton>
    </template>
  </BaseDialog>
</template>

<script>
// SigDevs, Coins, Companies
import DropdownSelectTag from '@/components/dropdown/DropdownSelectTag.vue';
export default {
  components: {
    DropdownSelectTag
  },
  data() {
    return {
      newListOfTagIds: [],
      newsFeedPrams: undefined,
      show: false,
      item: undefined
    };
  },
  beforeMount() {
    this.setNewsFeedPrams();
  },
  mounted() {
    this.$eventHub.$on('showEditTagsModal', newsItem => {
      this.show = true;
      this.item = newsItem;
    });
  },
  computed: {
    tagIds() {
      if (!this.item) {
        return [];
      }

      return this.item.tags.map(tag => {
        return tag.id;
      });
    }
  },
  methods: {
    async tagIdsChanged(tagIds) {
      this.newListOfTagIds = tagIds;
    },
    async update() {
      const tagsToAdd = [];
      const tagsToRemove = [];

      // finds tags to add
      this.newListOfTagIds.forEach(newTag => {
        let found = false;

        this.item.tags.forEach(tag => {
          if (tag.id === newTag) {
            found = true;
          }
        });

        if (!found) {
          tagsToAdd.push(newTag);
        }
      });

      // finds tags to remove
      this.item.tags.forEach(tag => {
        let found = false;

        this.newListOfTagIds.forEach(newTag => {
          if (tag.id === newTag) {
            found = true;
          }
        });

        if (!found) {
          tagsToRemove.push(tag.id);
        }
      });

      await Promise.all([
        ...tagsToAdd.map(tagToAdd => {
          return this.$http.post('/admin/news_item_tags', {
            tag_id: tagToAdd,
            news_item_id: this.item.id
          });
        }),
        ...tagsToRemove.map(tagToRemove => {
          return this.$http.delete('/admin/news_item_tags/0', {
            params: {
              tag_id: tagToRemove,
              news_item_id: this.item.id
            }
          });
        })
      ]);

      this.show = false;
    },
    async setNewsFeedPrams() {
      const response = await this.$http.get('/news_feeds');
      this.newsFeedPrams = response.data;
    }
  }
};
</script>
