<template>
  <table
    class="relative w-full border-separate overflow-auto px-2"
    ref="table"
    @scroll.capture="handleTableScroll"
    style="border-collapse: separate important; border-spacing: 0 !important"
  >
    <thead class="font-medium text-secondary">
      <tr class="sticky top-0 px-4 py-2 font-medium">
        <th
          v-for="(header, idx) in tableConfig"
          :class="[idx > 0 ? 'pl-4' : 'pl-1', idx < tableConfig.length - 1 ? 'pr-4' : 'pr-1']"
          class="h-8 cursor-pointer bg-gray-900 px-1 text-xs font-medium text-gray-400"
          :key="idx"
          :style="{ 'text-align': header.textAlign }"
        >
          <span class="truncate">
            <span @click="toggleSort(header)" class="select-none" :title="header.name">
              <span
                v-if="sortOrder && sortCol && sortCol.name === header.name"
                class="inline-block transform duration-100"
                :class="{ 'rotate-180': sortOrder === 'DESC' }"
              >
                ▾
              </span>
              <span class="text-xs font-medium text-gray-400">
                {{ header.name }}
              </span>
            </span>
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <!-- <VirtualList
          :data-key="'row'"
          style="height: 100%; overflow-y: auto"
          :data-sources="tableData"
          :extra-props="{ tableConfig: tableConfig }"
          @coin-clicked="coinClicked"
          :data-component="itemComponent"
        >
        </VirtualList> -->
      <tr
        v-for="(row, idx) in sortedTableData"
        :key="idx"
        class="table-row text-xs font-medium hover:bg-gray-800"
        :class="[idx % 2 === 1 ? '' : 'bg-gray-800/25', selectedRow && selectedRow == idx ? 'bg-blue-900/25' : '']"
      >
        <td
          v-for="(column, i) in tableConfig"
          :key="column.name"
          class="px-1"
          :class="[
            i > 0 ? 'pl-4' : 'pl-1',
            i < tableConfig.length - 1 ? 'pr-4' : 'pr-1',
            column.isNumber ? 'tabular-nums' : ''
          ]"
          :style="{ 'text-align': column.textAlign }"
        >
          <template v-if="column.type == 'text'">
            <span v-if="row[column.name]">{{ row[column.name] }}</span>
          </template>
          <template v-else-if="column.type == 'percent'">
            <span v-if="row[column.name]">
              {{ decimals(row[column.name], 2, getMaxDecimalPlaces(row[column.name])) }}%
            </span>
          </template>
          <template v-else-if="column.type == 'pos_neg_percent'">
            <span v-if="row[column.name]" :class="row[column.name] > 0 ? 'text-green-400' : 'text-red-400'">
              {{ decimals(row[column.name], 2, getMaxDecimalPlaces(row[column.name])) }}%
            </span>
          </template>
          <template v-else-if="column.type == 'number'">
            <span v-if="row[column.name]">
              {{ decimals(row[column.name], 2, row[column.name] > 1 ? 2 : 6) }}
            </span>
          </template>
          <template v-else-if="column.type == 'large_number'">
            <span v-if="row[column.name]">{{ formatNumber(row[column.name]) }}</span>
          </template>
          <template v-else-if="column.type == 'usd'">
            <span v-if="row[column.name]">{{ usdCryptoPrice(row[column.name]) }}</span>
          </template>
          <template v-else-if="column.type == 'date'">
            <span v-if="row[column.name]"> {{ readableDate(formatValue(row[column.name], 'date')) }} </span>
          </template>
          <template v-else-if="column.type == 'timestamp'">
            <span v-if="row[column.name]">
              {{ minimalTimeWithinToday(formatValue(row[column.name], 'timestamp')) }}
            </span>
          </template>
          <template v-else-if="column.type == 'image'">
            <img v-if="row[column.name]" class="h-5 w-5" :src="row[column.name]" />
          </template>
          <template v-else-if="column.type == 'link'">
            <template v-if="row[column.name].text">
              <a v-if="row[column.name]" :href="row[column.name].link" target="_blank">{{ row[column.name].text }}</a>
            </template>
            <template v-else>
              <a :href="row[column.name]" target="_blank" class="text-gray-400 duration-100 hover:text-white">
                Open <IconOpen class="inline h-3 w-3" />
              </a>
            </template>
          </template>
          <template v-else-if="column.type == 'markdown'">
            <Markdown v-if="row[column.name]" :source="row[column.name]" :html="true" :linkify="true" />
          </template>
          <template v-else-if="column.type == 'html'">
            <div v-if="row[column.name]" v-html="row[column.name]"></div>
          </template>
          <template v-else-if="column.type == 'coin'">
            <template v-if="row[column.name] && typeof row[column.name] == 'object'">
              <div>
                <CellWidgetCoin v-if="row[column.name]" :coin="row[column.name]" @coin-clicked="coinClicked" />
              </div>
            </template>
            <template v-else>
              <span>{{ row[column.name] }}</span>
            </template>
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import Item from './WidgetCustomTemplatesTableItem.vue';
import Markdown from 'vue3-markdown-it';
import CellWidgetCoin from '@/components/table/cell/CellWidgetCoin.vue';
import FilterMixin from '@/mixins/filters';
import { downloadToCSV } from '@/util/csvDownload';

import * as jp from 'jsonpath';
export default {
  mixins: [FilterMixin],
  name: 'WidgetCustomTemplateTable',
  components: {
    Markdown,
    CellWidgetCoin
  },
  props: {
    width: {
      type: Number,
      default: 501
    },
    height: {
      type: Number,
      default: 200
    },
    widget: {
      type: Object,
      required: true
    },
    dataSet: {
      type: [Object, Array],
      default: () => []
    },
    subscribeOnlyMode: {
      type: Boolean
    },
    overrides: {
      type: Object
    },
    pagination: {
      type: Object
    }
  },
  mounted() {
    this.$eventHub.$on(`download_${this.widget.id}`, () => {
      this.downloadData();
    });
    this.$refs.table.addEventListener('scroll', this.handleTableScroll);
    this.prepareTableConfig();
    this.prepareTableData();

    if (this.overrides && this.overrides.sort) {
      this.sortOrder = this.overrides.sort.order;
      this.sortCol = this.overrides.sort.col;
    } else if (this.widget.configuration?.config?.sort) {
      this.sortOrder = this.widget.configuration.config.sort.order;
      this.sortCol = this.widget.configuration.config.sort.col;
    }

    if (this.connectedToChannel) {
      if (this.overrides && this.overrides.active_coin) {
        this.coinClicked(this.overrides.active_coin);
      } else if (this.widget.configuration?.active_coin) {
        this.coinClicked(this.widget.configuration.active_coin);
      }
    }
  },
  beforeUnmount() {
    this.$refs.table.removeEventListener('scroll', this.handleTableScroll);
  },
  data() {
    return {
      itemComponent: Item,
      selectedRow: undefined,
      sortOrder: undefined,
      sortCol: undefined,
      tableConfig: [],
      tableData: []
    };
  },
  computed: {
    connectedToChannel() {
      let channel = this.widget.connected_channel;
      if (this.overrides != null && 'connected_channel' in this.overrides) {
        channel = this.overrides.connected_channel;
      }
      return channel;
    },
    config() {
      return this.widget.widget_template.default_configuration.config;
    },
    coins() {
      return this.$store.getters.coins;
    },
    dataByJsonPath() {
      let dataset = {};
      //do not remove the below line
      let dataSet = this.dataSet;
      this.config.tableColumns.forEach((config, idx) => {
        let column = config.name.trim() ? config.name : `Column ${idx + 1}`;
        let data = config.attribute ? jp.query(dataSet, config.attribute) : [];
        dataset[column] = data;
      });
      return dataset;
    },
    sortedTableData() {
      let limit = this.pagination.perPage * this.pagination.page;
      if (!this.sortOrder || !this.sortCol) {
        return this.tableData.slice(0, limit);
      }
      let dataCopy = JSON.parse(JSON.stringify(this.tableData));
      return dataCopy
        .sort((a, b) => {
          let valA = a[this.sortCol.name];
          let valB = b[this.sortCol.name];
          if (this.sortOrder == 'ASC') {
            if (isNaN(valA)) {
              return valB.localeCompare(valA);
            } else {
              return valB - valA;
            }
          } else {
            if (isNaN(valA)) {
              return valA.localeCompare(valB);
            } else {
              return valA - valB;
            }
          }
        })
        .slice(0, limit);
    }
  },
  watch: {
    'widget.connected_channel'(newVal, oldVal) {
      if (!newVal) {
        this.$store.commit('clearWidgetOutputChannel', oldVal);
        this.removeActiveCoinFromConfig();
      }
    },
    config: {
      deep: true,
      handler() {
        this.prepareTableConfig();
        this.prepareTableData();
      }
    },
    width() {
      this.prepareTableData();
    },
    dataSet() {
      this.prepareTableData();
    }
  },
  methods: {
    getMaxDecimalPlaces(value) {
      return Math.abs(value) < 0.0001 ? 6 : 2;
    },
    formatValue(value, type) {
      if (type == 'date') {
        value = new Date(value).getTime();
      }
      return value.toString().length > 12 ? value : value * 1000;
    },
    prepareTableConfig() {
      this.tableConfig = this.config.tableColumns
        .filter(x => x.name !== '')
        .map(x => {
          let data = this.dataByJsonPath[x.name] || [];
          let isNumber = data.length && !isNaN(data[0]);
          return {
            ...x,
            isNumber: isNumber,
            textAlign: isNumber ? 'right !important' : 'left !important'
          };
        });
    },
    prepareTableData() {
      let data = [];
      let rows = this.getTotalRows();
      for (let i = 0; i < rows; i++) {
        let object = {};
        this.tableConfig.forEach(c => {
          object['row'] = i;
          if (c.name.trim() != '') {
            if (c.type == 'coin') {
              object[c.name] = this.getCoinById(this.dataByJsonPath[c.name][i]);
            } else {
              object[c.name] = this.dataByJsonPath[c.name][i] || null;
            }
          }
        });
        data.push(object);
      }
      this.tableData = data;
    },
    toggleSort(col) {
      if (this.sortCol && this.sortCol.name != col.name) {
        this.sortOrder = undefined;
      }
      this.sortCol = col;
      if (this.sortOrder === undefined) {
        this.sortOrder = 'ASC';
      } else if (this.sortOrder === 'ASC') {
        this.sortOrder = 'DESC';
      } else {
        this.sortOrder = undefined;
      }

      if (this.subscribeOnlyMode) {
        this.addWidgetOverride({ sort: { col: this.sortCol, order: this.sortOrder } });
      } else {
        let payload = this.widget.widget_template.default_configuration;
        payload.config.sort = { col: this.sortCol, order: this.sortOrder };
        this.updateWidget({ configuration: payload });
      }
    },
    getTotalRows() {
      return (
        Object.values(this.dataByJsonPath)
          .map(x => x.length)
          .sort((a, b) => b - a)[0] || 0
      );
    },
    getCoinById(id) {
      let coin = this.coins.find(x => x.uid == id);
      if (coin) {
        return {
          id: coin.id,
          coin_uid: coin.uid,
          ticker: coin.ticker,
          name: coin.name,
          container_width: this.width
        };
      } else {
        return id;
      }
    },
    coinClicked(coin) {
      if (this.connectedToChannel) {
        this.$store.commit('setWidgetOutputChannel', {
          channel: this.connectedToChannel,
          value: [coin]
        });
        let configName = this.tableConfig.find(x => x.type == 'coin').name;
        if (configName) {
          let rowIndex = this.sortedTableData.findIndex(x => x[configName].id == coin.id);
          this.selectedRow = rowIndex;
        }

        if (this.subscribeOnlyMode) {
          this.addWidgetOverride({ active_coin: coin });
        } else {
          this.updateWidget({
            configuration: {
              ...this.widget.widget_template.default_configuration,
              active_coin: coin
            }
          });
        }
      } else {
        if (coin.coin_uid) {
          window.open(`/coin/${coin.coin_uid}`, '_blank');
        }
      }
    },
    handleTableScroll() {
      // console.log('Table is scrolling');
    },
    removeActiveCoinFromConfig() {
      this.selectedRow = undefined;
      if (this.subscribeOnlyMode) {
        this.addWidgetOverride({ active_coin: null });
      } else {
        let payload = this.widget.widget_template.default_configuration;
        payload.active_coin = null;
        this.updateWidget(payload);
      }
    },
    async updateWidget(payload) {
      if (this.widget.id) {
        await this.$http.patch(`/dashboard_widgets/${this.widget.id}`, payload);
      }
    },
    async addWidgetOverride(overrides) {
      if (this.widget.id) {
        const payload = {
          dashboard_widget_id: this.widget.id,
          overrides: overrides
        };
        await this.$http.post('/widget_overrides', { widget_override: payload });
      }
    },
    downloadData() {
      let columns = this.tableConfig;
      let columnsMap = {};
      columns.forEach((e, i) => (columnsMap[i] = e.name));
      let csvKeys = columns.map(e => {
        return {
          key: e.name,
          as: e.name.toUpperCase()
        };
      });

      let csvData = this.tableData.map((row, i) => {
        let obj = {};
        csvKeys.forEach((e, j) => {
          obj[e.key] = row[e.key];
        });
        return obj;
      });

      downloadToCSV(csvKeys, csvData, this.widget.title);
    }
  }
};
</script>
