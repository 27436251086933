import _ from 'lodash';

export default {
  install(app) {
    const baseFiles = import.meta.globEager('@/components/base/*.vue');
    registerFiles(app, baseFiles);

    const iconFiles = import.meta.globEager('@/components/icon/*.vue');
    registerFiles(app, iconFiles);

    const widgetFiles = import.meta.globEager('@/components/widgets/*.vue');
    registerFiles(app, widgetFiles);

    const integrationWidgetFiles = import.meta.globEager('@/components/integrations/nexo/widgets/*.vue');
    registerFiles(app, integrationWidgetFiles);
  }
};

function registerFiles(app, files) {
  Object.entries(files).forEach(([path, m]) => {
    const componentName = _.upperFirst(
      _.camelCase(
        path
          .split('/')
          .pop()
          .replace(/\.\w+$/, '')
      )
    );
    if (componentName) {
      app.component(componentName, m.default);
    }
  });
}
