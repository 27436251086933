<template>
  <div class="rounded border border-gray-800">
    <div class="flex flex-shrink-0 items-center bg-gray-800">
      <BaseTextbox v-if="prefix" disabled class="w-8" :model-value="prefix"></BaseTextbox>
      <BaseNumberInput v-model="value" @update:model-value="emit('update:modelValue', $event)" />
      <input
        :class="options.inputClass"
        ref="inputRef"
        :placeholder="options.placeholder"
        @input="$emit('change', modelValue)"
        style="font-size: inherit"
      />
      <BaseTextbox v-if="suffix" disabled class="w-8" :model-value="suffix"></BaseTextbox>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
const props = defineProps({
  modelValue: Number, // Vue 2: value
  options: Object
});

const value = ref(props.modelValue);
const prefix = computed(() => {
  return ['dollar', 'dollar_format', 'price'].includes(props.options.datatype) ? '$' : null;
});
const suffix = computed(() => {
  return ['percent', 'simple_percent'].includes(props.options.datatype) ? '%' : null;
});
</script>
