<template>
  <TableNewsAction :config="{ ...config, ...tableOveride }" :data="data" :loading="loading" :paginate="paginate" />
</template>

<script>
import TableNewsAction from './TableNewsAction.vue';
export default {
  name: 'TableNewsSimple',
  components: {
    TableNewsAction
  },
  props: {
    data: Array,
    loading: Boolean,
    paginate: Function,
    tableOveride: Object
  },
  data() {
    return {
      config: {
        alternateBg: false,
        headerClass: 'hidden',
        cols: [
          {
            type: 'news_expanded',
            size: 12,
            name: 'NEWS',
            id: 'news'
          }
        ],
        perPage: 20
      }
    };
  }
};
</script>
