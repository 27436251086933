<template>
  <TableBase
    :config="config"
    :data="
      filteredCoins.map(c => {
        return { ...c, id: c.uid };
      })
    "
    :loading="loading"
    :default-sort-column="sortCol"
    :default-sort-order="sortOrder"
    @checkbox-clicked="checkboxClicked"
  />
</template>

<script>
export default {
  props: {
    filteredCoins: Array
  },
  methods: {
    checkboxClicked(e) {
      // if checked
      if (e.value) {
        this.$emit('select', e.id);
      } else {
        this.$emit('remove', e.id);
      }
    }
  },
  data() {
    return {
      config: {
        alternateBg: true,
        headerClass: 'z-30',
        rowClass: 'hover:bg-gray-800 duration-100',
        cols: [
          {
            type: 'checkbox',
            size: 2,
            name: 'Selected',
            id: '',
            enabled: true
          },
          {
            type: 'coin',
            size: 2,
            name: 'Coin',
            id: 'coin',
            searchable: true,
            enabled: true
          },
          {
            type: 'price',
            size: 1,
            name: 'Price',
            id: 'price',
            decimals: 2,
            enabled: true
          },
          {
            type: 'number',
            size: 2,
            name: 'Market Cap',
            id: 'market_cap',
            decimals: 0,
            enabled: true
          },
          {
            type: 'percent',
            size: 2,
            name: '24HR Change',
            id: 'price_return_24_hours',
            decimals: 2,
            enabled: true
          },
          {
            type: 'sentiment',
            size: 2,
            name: 'Daily Sentiment',
            id: 'daily_sentiment',
            decimals: 0,
            category: 'sentiment',
            enabled: true
          },
          {
            type: 'dollar_format',
            size: 2,
            name: 'Trading Volume',
            id: 'trading_volume',
            decimals: 0,
            enabled: true
          }
        ]
      }
    };
  },
  computed: {
    loading: function () {
      return this.$store.getters.coinsLoader;
    },
    sortCol: function () {
      return JSON.parse(localStorage.getItem(this.$store.state.firstPath + '_sortCol')) || undefined;
    },
    sortOrder: function () {
      return localStorage.getItem(this.$store.state.firstPath + '_sortOrder');
    }
  }
};
</script>
