<template>
  <svg
    v-if="chain == 'ethereum'"
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.68497 4.14106L3.68498 0L0 5.70474L2.44384e-05 5.70475L0 5.70476L3.68497 7.73609L7.3685 5.70476L7.36849 5.70476L7.36853 5.70474L3.685 0V4.14107L3.68497 4.14106ZM0 7.15615L3.68498 12V9.18748L0 7.15615ZM3.685 12V9.18745L7.37142 7.15613L3.685 12Z"
      fill="#64748B"
    ></path>
  </svg>
  <img
    v-else-if="chain == 'avalanche'"
    src="https://terminal.cloudfront.thetie.io/coin_images/avalanche.png"
    class="inline h-3 w-3 rounded-full"
    loading="lazy"
    alt="..."
  />
</template>
<script setup>
import { computed } from 'vue';
const chain = computed(() => {
  return localStorage.getItem('nftChain') || 'ethereum';
});
</script>
