<template>
  <div class="p-2" :class="{ 'border-l-2 border-blue-400 bg-blue-900/[0.15]': recentNewsItem }">
    <div class="flex justify-between text-xs text-gray-400">
      <BaseTooltip>
        <template #target>
          <span class="text-xs text-gray-400">
            {{ sourceName }}
          </span>
        </template>
        <template #default v-if="item.sources.length > 1">{{ item.sources.map(o => o.name).join(', ') }}</template>
      </BaseTooltip>
      <span class="block whitespace-nowrap">{{ minimalTimeWithinToday(item.timestamp) }}</span>
    </div>
    <a :href="item.link" target="_blank">
      <p
        class="mt-0.5 text-xs font-medium text-gray-100 line-clamp-2"
        v-html="item.translated_headline || item.headline"
      />
    </a>
    <div v-if="!hideTags" class="mt-1 flex w-full justify-between text-xxs text-secondary">
      <div class="flex flex-wrap">
        <NewsFeedItemTags :tags="item.tags" :item="item" @handle-tag-click="handleTagClick" size="xs" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import NewsFeedItemTags from '@/components/news_feeds/NewsFeedItemTags.vue';
import FilterMixin from '@/mixins/filters';
import { Inertia } from '@inertiajs/inertia';
export default {
  mixins: [FilterMixin],
  name: 'VirtualScrollItemSimpleNews',
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default() {
        return {};
      }
    },
    hideTags: {
      type: Boolean,
      default: false
    }
  },
  components: {
    NewsFeedItemTags
  },
  beforeMount() {
    this.item = this.source;
  },
  computed: {
    sourceName() {
      return this.item.sources.length > 1
        ? `${this.item.sources[0]?.name} and ${this.item.sources.length - 1} more`
        : this.item.sources[0]?.name;
    },
    recentNewsItem() {
      var now = moment(new Date()); //todays date
      var duration = moment.duration(now.diff(this.item.timestamp));
      var minutes = duration.asMinutes();
      return minutes < 5 ? true : false;
    },
    companyTagGroups() {
      return this.$store.state.companyTagGroups;
    }
  },
  methods: {
    handleTagClick: function (tag, newsItem) {
      if (tag.tag_subgroup.name == 'Coins' && tag.coin) {
        this.$store.dispatch('enableCoinSidebar', { coin: tag.coin.uid, newsItem: newsItem });
      }

      if (this.companyTagGroups.includes(tag.tag_subgroup.name)) {
        const company = this.$store.state.companySlugs.find(x => x.tag == tag.id);
        if (company) {
          Inertia.visit(`/company/${company.slug}`);
        }
      }
    }
  }
};
</script>
