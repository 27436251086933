<template>
  <div class="h-full bg-gray-900">
    <div class="flex h-full">
      <div class="relative h-full w-96 border-r border-gray-700">
        <div class="h-8 bg-gray-800"></div>
        <div class="">
          <div class="flex justify-between space-x-2 border-b border-gray-700 px-3 py-2.5">
            <div class="h-4 w-28 animate-pulse rounded-full bg-gray-800"></div>
            <div class="h-4 w-4 animate-pulse rounded-full bg-gray-800"></div>
          </div>
          <div class="flex justify-between space-x-2 border-b border-gray-700 px-3 py-2.5">
            <div class="h-4 w-28 animate-pulse rounded-full bg-gray-800"></div>
            <div class="h-4 w-4 animate-pulse rounded-full bg-gray-800"></div>
          </div>
        </div>
        <div class="my-4 space-y-4 px-2">
          <div v-for="i in 4" :key="i">
            <div class="flex space-x-2">
              <div class="w-10 animate-pulse rounded-full bg-gray-800"></div>
              <div class="w-full space-y-2">
                <div class="h-4 w-full animate-pulse rounded-full bg-gray-800"></div>
                <div class="h-3 w-1/2 animate-pulse rounded-full bg-gray-800"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="absolute bottom-0 h-14 w-full bg-gray-800 px-3 py-2">
          <div class="flex w-full justify-between space-x-4">
            <div class="flex space-x-2">
              <div class="h-10 w-10 animate-pulse rounded-full bg-gray-600"></div>
              <div class="space-y-2">
                <div class="h-4 w-20 animate-pulse rounded-full bg-gray-600"></div>
                <div class="h-3 w-28 animate-pulse rounded-full bg-gray-600"></div>
              </div>
            </div>
            <div class="flex items-center space-x-1">
              <div class="h-4 w-4 animate-pulse rounded-full bg-gray-600"></div>
              <div class="h-4 w-4 animate-pulse rounded-full bg-gray-600"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full">
        <div class="border-b border-gray-700 py-1.5">
          <div class="flex items-center justify-between px-4">
            <div class="flex items-center space-x-2">
              <div class="h-8 w-8 animate-pulse rounded-full bg-gray-800"></div>
              <div class="h-4 w-32 animate-pulse rounded-full bg-gray-800"></div>
            </div>
            <div class="flex items-center space-x-2.5">
              <div class="h-5 w-5 animate-pulse rounded-full bg-gray-800"></div>
              <div class="h-5 w-5 animate-pulse rounded-full bg-gray-800"></div>
            </div>
          </div>
        </div>
        <div class="h-full">
          <div class="absolute bottom-0 w-full space-y-4 px-4 py-4">
            <div v-for="i in 5" :key="i">
              <div class="flex space-x-2 p-1">
                <div class="h-10 w-10 animate-pulse rounded-full bg-gray-800"></div>
                <div class="space-y-2">
                  <div class="h-4 w-32 animate-pulse rounded-full bg-gray-800"></div>
                  <div :class="getWidth()" class="h-4 animate-pulse rounded-full bg-gray-800"></div>
                  <div :class="getWidth()" class="h-4 animate-pulse rounded-full bg-gray-800"></div>
                </div>
              </div>
            </div>
            <div class="items-center">
              <div class="h-10 animate-pulse rounded bg-gray-800">
                <div class="flex items-center space-x-1 py-2 px-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadTemplatesMessenger',
  methods: {
    getWidth() {
      let index = Math.floor(Math.random() * 5);
      return ['w-44', 'w-56', 'w-64', 'w-72', 'w-96'][index];
    }
  }
};
</script>
