<template>
  <div
    class="group flex flex-shrink-0 flex-col border-grid bg-gray-900 text-white"
    :class="[
      noContainer ? '' : 'relative',
      collapse ? 'w-10' : maxWidth || 'w-80',
      rightAltHide ? 'border-l' : 'border-r'
    ]"
    @mouseenter="showHideButton = true"
    @mouseleave="showHideButton = false"
  >
    <div
      v-if="altHide && showHideButton"
      @click="updateCollapseStatus()"
      class="absolute right-0 z-40 h-6 w-6 cursor-pointer rounded-full border border-grid bg-gray-900 hover:bg-gray-800"
      style="top: 40%; margin-right: -12px"
    >
      <IconChevronRight v-if="collapse" style="margin-left: 1px" class="-mt-1 inline h-3.5 transform" />
      <IconChevronRight v-else style="margin-left: 1px" class="-mt-1 inline h-3.5 rotate-180 transform" />
    </div>
    <div
      v-if="rightAltHide && showHideButton"
      @click="updateCollapseStatus()"
      class="absolute right-0 z-40 h-6 w-6 cursor-pointer rounded-full border border-grid bg-gray-900 hover:bg-gray-800"
      :style="collapse ? 'top: 40%; margin-right: 30px' : 'top: 40%; left: -12px'"
    >
      <IconChevronRight v-if="collapse" style="margin-left: 1px" class="-mt-1 inline h-3.5 rotate-180 transform" />
      <IconChevronRight v-else style="margin-left: 1px" class="-mt-1 inline h-3.5 transform" />
    </div>
    <div :class="[`bg-${color}-1200`, 'flex h-full cursor-pointer']" v-if="collapse" @click="updateCollapseStatus()">
      <div
        class="absolute whitespace-nowrap text-center text-xs"
        style="top: 40%; margin-top: 12px"
        :class="[textDirection]"
      >
        {{ updatedTitle }}
      </div>
    </div>
    <div :class="`relative ${headerClass}`" v-show="!collapse">
      <div
        v-if="title"
        :class="`w-100 h-8 bg-${color}-900 flex select-none items-center border-b border-grid bg-gray-800 pl-4`"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        @click="$emit('clicked-title')"
      >
        <div :class="`tie-card-header truncate text-${color}-100 flex-1 py-2 pr-3 text-xs font-medium`">
          {{ updatedTitle }}
        </div>
        <span
          v-if="!altHide && !rightAltHide"
          @click="updateCollapseStatus()"
          class="mr-5 cursor-pointer whitespace-nowrap text-xs font-medium hover:text-white"
        >
          <IconChevronRight v-if="direction == 'left'" class="inline h-3.5 rotate-180 transform" />
          <span> Hide</span>
          <IconChevronRight v-if="direction == 'right'" class="inline h-3.5" />
        </span>
      </div>
    </div>
    <div class="relative flex flex-1 overflow-y-auto" v-show="!noContainer && !collapse" style="max-height: 95%">
      <div
        :class="`overflow-y-${scroll ? 'scroll' : 'hidden'} absolute top-0 left-0 right-0 bottom-0 overflow-x-hidden`"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['collapse', 'clicked-title'],
  props: {
    title: String,
    scroll: Boolean,
    noContainer: Boolean,
    dropdown: Array,
    altHide: Boolean,
    rightAltHide: Boolean,
    headerClass: String,
    collapseKey: String,
    maxWidth: String,
    direction: {
      type: String,
      default: 'left'
    },
    color: {
      type: String,
      default: 'gray'
    }
  },
  data() {
    return {
      collapse: '',
      hover: false,
      hoverOverride: false,
      showHideButton: false
    };
  },
  mounted() {
    let storageItem = this.collapseKey ? this.collapseKey : this.cardTitle;
    this.collapse = localStorage.getItem(this.sidebarCollapseKey) == 'true';
  },
  computed: {
    sidebarCollapseKey() {
      return this.collapseKey ? this.collapseKey : this.cardTitle;
    },
    cardTitle() {
      return this.title.includes('Coin Individual Sidebar')
        ? this.title.split('_')?.[1].toLowerCase().replace(/\s+/g, '_')
        : this.title.toLowerCase().replace(/\s+/g, '_');
    },
    updatedTitle() {
      return ['Company Sidebar', 'Coins Sidebar'].includes(this.title)
        ? this.title.split(' ')[0]
        : this.title.includes('Coin Individual Sidebar')
        ? this.title.split('_')?.[1] === 'undefined'
          ? ''
          : this.title.split('_')?.[1]
        : this.title;
    },
    textDirection() {
      return {
        left: 'top-1/2 left-4 rotate-left',
        right: 'top-1/2 left-4 rotate-right',
        top: '',
        bottom: ''
      }[this.direction];
    }
  },
  methods: {
    updateCollapseStatus() {
      this.collapse = !this.collapse;
      localStorage.setItem(this.sidebarCollapseKey, this.collapse);
      this.$emit('collapse', this.collapse);
    }
  }
};
</script>
<style scoped>
.rotate-right {
  -moz-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}
.rotate-left {
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}
</style>
