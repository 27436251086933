export default {
  props: {
    widget: {
      type: Object,
      required: true
    },
    overrides: {
      type: Object
    },
    w: {
      type: Number,
      default: 30
    },
    h: {
      type: Number,
      default: 20
    },
    viewOnlyMode: {
      type: Boolean,
      default: false
    },
    subscribeOnlyMode: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setWidgetSize();
    });
    this.$eventHub.$on('widget-resize', id => {
      if (this.widget.id == id) {
        this.setWidgetSize();
      }
    });
  },
  computed: {
    connectedToChannel() {
      let channel = this.widget.connected_channel;
      if (this.overrides != null && 'connected_channel' in this.overrides) {
        channel = this.overrides.connected_channel;
      }
      return channel;
    },
    widgetChannelOutput() {
      if (this.connectedToChannel) {
        return this.$store.getters.widgetChannelOutput(this.connectedToChannel);
      }
      return null;
    }
  },
  data() {
    return {
      loading: false,
      id: this.widget.uuid || Math.random(),
      width: 0,
      height: 0,
      showConfigurationModal: false
    };
  },
  methods: {
    setWidgetSize() {
      const widgetElem = document.getElementById(this.id);
      if (widgetElem) {
        //temp hack need to verify this
        if (widgetElem.clientWidth == 80) {
          this.width = this.w * 10;
        } else {
          this.width = widgetElem.clientWidth;
        }
        this.height = widgetElem.clientHeight;
      } else {
        this.width = this.w * 10;
        this.height = this.h * 4;
      }
    }
  }
};
