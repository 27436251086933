<template>
  <div class="flex items-center text-white">
    <InertiaLink :href="`/company/${company.slug}`" class="flex flex-1 items-center justify-between space-x-2">
      <div :class="`flex cursor-pointer items-center space-x-1`">
        <img
          :src="company.imageUrl"
          class="inline h-4 w-4 rounded-full"
          @error="imageAvailable = false"
          loading="lazy"
          v-if="imageAvailable"
        />
        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
          <circle cx="12" cy="12" r="12" fill="#C4C4C4" />
        </svg>
        <BaseTooltip position="top">
          <template #target>
            <span class="truncate">{{ truncate(company.name, 20) }}</span>
          </template>
          <template #default>
            {{ company.name }}
          </template>
        </BaseTooltip>
      </div>
    </InertiaLink>

    <div class="ml-4 flex items-center space-x-1">
      <DropdownTableCell
        :items="dropdownItems"
        :cell-info="company"
        x-anchor="left"
        :global-emit="false"
        @add="addToWatchlist"
        :custom-style="true"
        @remove="removeFromWatchlist"
        v-if="!this.adminRoute"
      />
    </div>
  </div>
</template>

<script>
import FilterMixin from '@/mixins/filters';
import DropdownTableCell from '@/components/dropdown/DropdownTableCell.vue';
import { Link as InertiaLink, usePage } from '@inertiajs/inertia-vue3';
export default {
  mixins: [FilterMixin],
  name: 'CellCompany',
  components: {
    DropdownTableCell,
    InertiaLink
  },
  props: {
    company: Object
  },
  data() {
    return {
      page: usePage(),
      hover: false,
      hoverOverride: false,
      imageAvailable: false
    };
  },
  mounted() {
    this.imageAvailable = this.company.imageUrl;
  },
  methods: {
    dropdownChange(dropdownState) {
      this.hoverOverride = dropdownState;
      this.hover = dropdownState;
    },
    addToWatchlist(company) {
      this.$store.commit('openModal', {
        name: 'addToWatchlist',
        status: true,
        payload: {
          payload: company,
          mode: 'add',
          entity: 'companies'
        }
      });
    },
    removeFromWatchlist(company) {
      this.$store.commit('openModal', {
        name: 'addToWatchlist',
        status: true,
        payload: {
          payload: company,
          mode: 'remove',
          entity: 'companies'
        }
      });
    }
  },
  computed: {
    adminRoute() {
      return this.page.url.includes('admin');
    },
    dropdownItems() {
      let dItems = [
        { id: 'remove', text: 'Remove from Watchlist', event: 'remove' },
        { id: 'add', text: 'Add to Watchlist', event: 'add' }
      ];
      return dItems;
    }
  }
};
</script>
