<template>
  <div class="flex items-center justify-between border-b border-gray-700 px-4 py-2">
    <div class="flex items-center space-x-2">
      <ChatImage
        :item="channel"
        size="xs"
        :show-online="true"
        :channel-name="channelName"
        :is-channel="true"
      ></ChatImage>
      <BaseTooltip position="bottom-start">
        <template #target>
          <span class="text-sm font-medium leading-5">{{ truncate(channelName, 120) }}</span>
        </template>
        <template #default v-if="channelName.length > 120">{{ channelName }}</template>
      </BaseTooltip>
    </div>

    <div class="flex items-center space-x-2.5 text-gray-400">
      <!-- <IconDownload :size="`h-4 w-4`" /> -->
      <div @click="$emit('showNotificationModal')" class="hover:text-white">
        <IconBellFilled class="cursor-pointer text-gray-400 hover:text-white" />
      </div>
      <div v-if="broadcastChannelAndAdmin(channel)" @click="$emit('toggle-chat-settings')" class="hover:text-white">
        <IconGearFilled size="h-4 w-4 cursor-pointer text-gray-400 hover:text-white" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import ChatImage from './ChatImage.vue';
import { broadcastChannelAndAdmin } from '@/composeables/chat';
import { truncate } from '@/composeables/filters';
const props = defineProps({
  channel: Object,
  channelName: String
});

const inviteUserDropdownActive = ref(false);
//methods
async function addMember(id) {
  await props.channel.addMembers([id]);
  inviteUserDropdownActive.value = false;
}

function teamMemberAlreadyInChannel(id) {
  return channelMembers.value.map(u => u.user.id).includes(id);
}

//computed
const channelMembers = computed(() => {
  return Object.values(props.channel.state.members);
});
</script>
