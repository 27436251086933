<template>
  <BaseDialog
    :model-value="true"
    size-class="!max-w-3xl h-[42rem]"
    @update:model-value="noMandatoryData ? '' : $emit('close')"
  >
    <template #header>
      <span class="px-10">
        {{ onBoarding ? 'Welcome to SigDev Messenger' : 'Profile' }}
      </span>
    </template>
    <template #default>
      <div class="px-10 pt-4 text-xs text-gray-400">
        Note: Your team admin has enabled Messenger for your team. Fill out the fields below to optimize your experience
        on Messenger.
      </div>
      <div class="flex h-full w-full">
        <div class="flex px-10 pt-2">
          <div class="flex h-full space-x-16">
            <div class="space-y-4 pt-3">
              <div class="flex w-96 space-x-4">
                <div class="space-y-2">
                  <div class="text-xs font-medium text-gray-400">First Name <span class="text-red-500">*</span></div>
                  <BaseTextbox v-model="profile.first_name" />
                  <span v-if="firstNameError && firstNameError.length > 0" class="text-xs text-red-400">
                    {{ firstNameError }}
                  </span>
                </div>
                <div class="space-y-2">
                  <div class="text-xs font-medium text-gray-400">Last Name <span class="text-red-500">*</span></div>
                  <BaseTextbox v-model="profile.last_name" />
                  <span v-if="lastNameError && lastNameError.length > 0" class="text-xs text-red-400">
                    {{ lastNameError }}
                  </span>
                </div>
              </div>
              <div class="w-96 space-y-2">
                <div class="text-xs font-medium text-gray-400">Title <span class="text-red-500">*</span></div>
                <BaseTextbox v-model="profile.role_title" />
                <span v-if="roleError && roleError.length > 0" class="text-xs text-red-400">
                  {{ roleError }}
                </span>
              </div>
              <div class="w-96 space-y-2">
                <div class="text-xs font-medium text-gray-400">Department</div>
                <BaseTextbox v-model="profile.department" />
              </div>
              <div class="w-96 space-y-2">
                <div class="text-xs font-medium text-gray-400">Telegram</div>
                <BaseTextbox v-model="profile.telegram_url" />
              </div>
              <div class="w-96 space-y-2">
                <div class="text-xs font-medium text-gray-400">Linkedin Profile</div>
                <BaseTextbox v-model="profile.linkedin_url" />
              </div>
              <div class="w-96 space-y-2">
                <div class="text-xs font-medium text-gray-400">About Me</div>
                <BaseTextarea v-model="profile.about" :rows="2" />
              </div>
            </div>
            <div class="flex w-2/5 flex-col items-center justify-center" v-if="streamUser">
              <div class="mb-2 text-xs font-medium text-gray-400">Profile Photo</div>
              <ImageSelector :settings-modal="true" :user="streamUser" @selected-image="setImage" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full items-center justify-end space-x-2 px-10 pt-5">
        <BaseButton type="secondary" :disabled="noMandatoryData" @press="$emit('close')">Cancel</BaseButton>
        <BaseButton :busy="buttonBusy" @press="saveProfile()">Save</BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { ref, onMounted, watch, inject, computed } from 'vue';
import ImageSelector from './ChatImageSelector.vue';
import { useMapGetter } from '@/store/map-state';
import { useStore } from 'vuex';
const props = defineProps({ onBoarding: { type: Boolean, default: false } });
const { streamUser } = useMapGetter();
const $http = inject('http');
const $emit = defineEmits(['close']);
const store = useStore();

const roleError = ref('');
const firstNameError = ref('');
const lastNameError = ref('');
const userData = ref(store.getters.session.user);

const profile = ref({
  first_name: userData.value.first_name || '',
  last_name: userData.value.last_name || '',
  role_title: userData.value.role_title || '',
  department: userData.value.department || '',
  telegram_url: userData.value.telegram_url || '',
  linkedin_url: userData.value.linkedin_url || '',
  about: userData.value.about || '',
  image: ''
});

async function saveProfile() {
  if (!profile.value.role_title || profile.value.role_title.trim().length <= 0) {
    roleError.value = "Job title can't be blank.";
  } else if (!profile.value.first_name || profile.value.first_name.trim().length <= 0) {
    firstNameError.value = "First Name can't be blank.";
  } else if (!profile.value.last_name || profile.value.last_name.trim().length <= 0) {
    lastNameError.value = "Last Name can't be blank.";
  } else {
    let formData = new FormData();

    Object.entries(profile.value).forEach(([key, value]) => formData.append(`user[${key}]`, value));
    formData.append(`user[profile_completed]`, true);
    formData.append('_method', 'PATCH');

    await $http.patch('/profile', formData);
    $emit('close');
  }
}

function setImage(data) {
  profile.value.image = data.file;
}

watch(
  () => profile.value.role_title,
  (newVal, oldVal) => {
    roleError.value = '';
  }
);
watch(
  () => profile.value.first_name,
  (newVal, oldVal) => {
    firstNameError.value = '';
  }
);
watch(
  () => profile.value.last_name,
  (newVal, oldVal) => {
    lastNameError.value = '';
  }
);

const noMandatoryData = computed(() => {
  return !(userData.value.first_name && userData.value.last_name && userData.value.role_title);
});
</script>
