import store from '@/store/index';
import useHttp from '@/composeables/http';
import notify from '@/composeables/notify';
import { usePage } from '@inertiajs/inertia-vue3';
import messageSound from '../audio/tadah_16.ogg';
import { copyToClipboard } from '@/composeables/helpers';
import { orderBy } from 'lodash';

var channelEventListener = {};
var streamListener = null;

export async function setupChat() {
  const $http = useHttp();
  if (store.getters.session.user_id && store.getters.client) {
    const userID = String(store.getters.session.user_id);
    let user = await store.getters.client.connectUser(
      {
        id: String(store.getters.session.user_id)
      },
      async () => {
        const response = await $http.post('/chat_token', { userID });
        return response.data.token;
      }
    );
    store.dispatch('updateStreamUser', user.me);
    if (user && user.me.unread_channels !== undefined) {
      var unreadChannelsCount = user.me.unread_channels || 0;
    }
    const res = await $http.get('/messaging_folders');
    store.dispatch('setFolders', res.data.folders);
    await store.dispatch('setChannels');
    store.dispatch('setChannelLoaded');
    store.dispatch(
      'setUnreadChannelsCount',
      unreadChannelsCount - getRequestChannelsUnreadCount() + requestChannelsCount()
    );
    store.dispatch('setStreamUsers');
    watchChannels();
    streamListener = store.getters.client.on(event => {
      if (event.cid) {
        // that's a hack for reactivity dont remove this
        let channel = store.getters.channels.find(c => c.cid === event.cid);
        if (channel) {
          store.dispatch('addStreamChannel', channel);
        }
      }
      if ((event.unread_channels !== undefined && event.cid) || event.type === 'notification.invited') {
        let eventChannel = store.state.channels.find(c => c.cid === event.cid);
        if (eventChannel && (notInCurrentChat(event.cid) || event.type != 'message.new')) {
          store.dispatch(
            'setUnreadChannelsCount',
            event.unread_channels - getRequestChannelsUnreadCount() + requestChannelsCount()
          );
        }
      }
      if (
        (event.type == 'notification.removed_from_channel' && event.member.invited) ||
        event.type == 'notification.invite_rejected'
      ) {
        store.dispatch('setUnreadChannelsCount', store.getters.unreadChannelsCount - 1);
      }
      if (event.type === 'notification.invited') {
        store.dispatch('setUnreadChannelsCount', store.getters.unreadChannelsCount + 1);
        notify(
          {
            group: 'app',
            type: 'chat',
            payload: {
              title: 'Chat Request',
              channel: event.channel,
              member: event.member,
              received_at: event.received_at
            }
          },
          store.getters.longNotification
        );
      } else if (event.type === 'user.updated') {
        if (store.getters.streamUser.id === event.user.id) {
          store.dispatch('updateStreamUser', event.user);
        }
      }
    });
  }
}

function watchChannels() {
  store.state.channels.forEach(channel => {
    listenToMessages(channel);
  });
}

export function listenToMessages(channel) {
  const client = store.getters.client;
  channelEventListener[channel.cid] = channel.on('message.new', event => {
    let user = client.user;
    let channelMuted = store.state.mutedChannels && store.state.mutedChannels.includes(channel.cid);
    if (channelMuted) {
      channel.markRead();
    }
    if (
      event.user.id != user.id &&
      channel.data.notification_users &&
      channel.data.notification_users.includes(user.id) &&
      !event.message.activity_status &&
      notInCurrentChat(event.cid) &&
      !channelMuted
    ) {
      notify(
        {
          group: 'app',
          type: 'chat',
          payload: {
            title: 'New Message',
            message: event.message,
            member: event.user,
            channel_id: event.channel_id,
            received_at: event.received_at
          }
        },
        store.getters.shortNotification
      );
      if (client.user.message_notification_sound) {
        var audio = new Audio(messageSound);
        audio.play();
      }
    }
  });
}

export function unsubscribeToMessages(channelId) {
  if (channelEventListener[channelId]) {
    channelEventListener[channelId].unsubscribe();
  }
}

export function unsubscribeStreamChannel() {
  if (streamListener) {
    streamListener.unsubscribe();
  }
}

export function getChannelName(channel, userData = null) {
  let user = userData || store.getters.streamUser;
  let channelData = channel.data || channel;
  let channelMembers = Object.values(channel.members || channel.state.members);
  if (channelData.direct_messaging) {
    let cMember = channelMembers.find(x => x.user.id != user.id);
    return cMember ? cMember.user.name : 'Unnamed';
  } else if (channelData.direct_group_messaging) {
    var channelName = channelData.nameUpdated
      ? channelData.name
      : Object.values(channelMembers)
          .filter(x => x.user_id != user.id && !x.invite_rejected_at)
          .map(x => x.user.name.trim())
          .sort()
          .join(', ');
    return channelName != '' ? channelName : 'Unnamed';
  }
  return channelData.name;
}

export function getUserName(member, streamUsers) {
  let user = store.getters.streamUsers.find(x => x.id === member.id);
  if (user) {
    return user.name;
  } else {
    return member.name;
  }
}

export function getUserSubtitle(user) {
  if (user.companies && user.companies.length > 0 && user.role_title !== null && user.role_title !== '') {
    return `${user.role_title} at ${user.companies.join(', ')}`;
  } else if (user.role_title !== null && user.team_name !== null && user.role_title !== '') {
    return `${user.role_title} at ${user.team_name}`;
  } else if (user.companies && user.companies.length > 0) {
    return `Works at ${user.companies.join(', ')}`;
  } else if (user.team_name) {
    return `Works at ${user.team_name}`;
  } else if (user.role_title !== '') {
    return user.role_title;
  } else {
    return '';
  }
}

export function getCompaniesByCategory(category, users, selectedCompanies = []) {
  let usersByCategory = [];
  let companies = [];
  if (category === 'All Companies') {
    usersByCategory = users;
  } else {
    usersByCategory = users.filter(user =>
      user.company_categories ? user.company_categories.includes(category) : false
    );
  }
  let data = {};
  usersByCategory.forEach(user => {
    if (user.companies && user.companies.length > 1) {
      user.companies.forEach(company => {
        data = setCompanyData(company, data, user);
      });
    } else if (user.companies && user.companies.length == 1) {
      data = setCompanyData(user.companies[0], data, user);
    }
  });
  if (data) {
    Object.keys(data).map(comp =>
      companies.push({
        name: comp,
        image: data[comp].company_image,
        users: data[comp].users.sort((a, b) => a.name.localeCompare(b.name)),
        description: data[comp].description,
        founding_year: data[comp].founding_year,
        location: data[comp].location,
        slug: data[comp].slug,
        category: getCompanyCategory(data[comp].users),
        selected: selectedCompanies.includes(comp),
        opened: selectedCompanies.includes(comp)
      })
    );
  }
  return orderBy(
    companies,
    function (c) {
      return c.name;
    },
    'asc'
  );
}

export function setCompanyData(company, dataObj, user) {
  let data = dataObj;
  data[company] = data[company] || {};
  if (data[company].users) {
    data[company].users.push(user);
  } else {
    data[company].users = [user];
  }
  data[company].company_image = data[company].company_image ? data[company].company_image : user.company_image;
  if (!data[company].slug) {
    data[company].slug =
      user.companies_data && user.companies_data.some(c => c.name === company)
        ? user.companies_data.find(c => c.name === company).slug
        : null;
  }
  if (!data[company].description) {
    data[company].description =
      user.companies_data && user.companies_data.some(c => c.name === company)
        ? user.companies_data.find(c => c.name === company).description
        : null;
  }
  if (!data[company].founding_year) {
    data[company].founding_year =
      user.companies_data && user.companies_data.some(c => c.name === company)
        ? user.companies_data.find(c => c.name === company).founding_year
        : null;
  }
  if (!data[company].location) {
    data[company].location =
      user.companies_data && user.companies_data.some(c => c.name === company)
        ? user.companies_data.find(c => c.name === company).location
        : null;
  }
  return data;
}

export function getCompanyCategory(users) {
  let categories = [];
  let userWithCategory = users.filter(x => x.company_categories && x.company_categories.length > 0);
  userWithCategory.map(user => {
    user.company_categories.map(x => categories.push(x));
  });
  let uniqCategories = [...new Set(categories)];
  return uniqCategories.join(', ');
}

export function getImageObject(channel) {
  if (channel.data.direct_messaging) {
    let channelMembers = Object.values(channel.state.members);
    return channelMembers.filter(x => x.user_id !== store.getters.streamUser.id)[0].user;
  } else {
    return channel.data;
  }
}

export function isGIF(message) {
  let attachments = message.attachments || [];
  attachments = attachments.filter(a => !a.type || a.type == 'giphy').length;
  return attachments ? true : false;
}

export function isImage(message) {
  let attachments = message.attachments || [];
  attachments = attachments.filter(a => a.type && a.type == 'image').length;
  return attachments ? true : false;
}

export function isVideo(message) {
  let attachments = message.attachments || [];
  attachments = attachments.filter(a => a.type && a.type == 'video').length;
  return attachments ? true : false;
}

export function isFile(message) {
  let attachments = message.attachments || [];
  attachments = attachments.filter(a => a.type && a.type == 'file').length;
  return attachments ? true : false;
}

export function displayMessage(message) {
  if (message.attachments && message.attachments.length) {
    if (!message.text || (!message.attachments[0].title && message.attachments[0].og_scrape_url === message.text)) {
      return false;
    }
  }
  return true;
}

export function activityMessage(message, channel) {
  const activityProminentText = {
    created: ['created'],
    added: [','],
    removed: ['removed', ','],
    demoted: ['demoted to member'],
    promoted: ['promoted to admin'],
    join: [],
    leave: ['left'],
    invited: []
  };
  if (message) {
    let messageText = message.text;
    if (channel && messageText) {
      messageText = messageText.replace(/(&lt;([^>]+)>)/gi, '');
      if (message.activity_status) {
        activityProminentText[message.activity_status].forEach(replaceText => {
          messageText = messageText.replace(
            new RegExp(replaceText, 'gi'),
            `<span class="text-gray-200 font-medium">${replaceText}</span>`
          );
        });
      }
      messageText = messageText.replace(/{{channel_name}}/gi, channel.data.name);
    }
    if (message.activity_members) {
      message.activity_members.forEach(member => {
        messageText = messageText.replace(
          new RegExp(`{{user:${member.id}}}`, 'gi'),
          `<span class="text-gray-200 font-medium">${getUserName(member)}</span>`
        );
      });
    }
    return messageText;
  }
  return '';
}

export function getUserByName(member) {
  let user = store.getters.streamUsers.find(x => x.id === member.id);
  if (user) {
    return user;
  } else {
    return member;
  }
}

export function getColor() {
  let color = '#';
  for (let i = 0; i < 3; i++)
    color += ('0' + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
  return color;
}

export async function saveNotificationPreference(users, channel, notificationSelected) {
  let notificationUsers = channel.data.notification_users || [];
  if (notificationSelected == 'all') {
    notificationUsers.push(...users);
  } else {
    notificationUsers = notificationUsers.filter(x => !users.includes(x));
  }
  await channel.updatePartial({
    set: {
      notification_users: notificationUsers
    }
  });
  store.dispatch('setChannels');
}

export function notInCurrentChat(cid) {
  const page = usePage();
  const url = page.url.value;
  let miniChats =
    (localStorage.getItem('selectedChats') && !localStorage.getItem('selectedChats').includes(cid)) ||
    (localStorage.getItem('closedChats') && localStorage.getItem('closedChats').includes(cid)) ||
    !localStorage.getItem('selectedChats');
  return (
    (url.includes('/messenger') && localStorage.getItem('chosenChannel') !== cid) ||
    (!url.includes('/messenger') && miniChats)
  );
}

export function getRequestChannelsUnreadCount() {
  let count = 0;
  store.state.channels.map(c =>
    Object.values(c.state.members).some(
      u => u.invited && !u.request_rejected_at && u.user_id === store.getters.client.user.id
    )
      ? (count += c.state.unreadCount > 0 ? 1 : 0)
      : null
  );
  return count;
}

export function requestChannelsCount() {
  let count = 0;
  store.state.channels.map(c =>
    Object.values(c.state.members).some(
      u => u.invited && !u.invite_accepted_at && !u.invite_rejected_at && u.user_id === store.getters.client.user.id
    )
      ? (count += 1)
      : null
  );
  return count;
}

export function broadcastChannelAndAdmin(channel) {
  return (
    !channel.data.broadcast_channel ||
    (channel.data.broadcast_channel &&
      channel.data.channel_admins &&
      channel.data.channel_admins.includes(store.getters.client.userID))
  );
}

export function generateShareLink(channelId) {
  let link = window.location.href.replace(window.location.search, '') + '?uuid=' + channelId;
  copyToClipboard(link);
}

export default {
  getChannelName,
  getUserName,
  getUserSubtitle,
  getCompaniesByCategory,
  getImageObject,
  displayMessage,
  isGIF,
  isImage,
  isVideo,
  isFile,
  activityMessage,
  getUserByName,
  getColor,
  saveNotificationPreference,
  notInCurrentChat,
  getRequestChannelsUnreadCount,
  requestChannelsCount,
  setupChat,
  unsubscribeToMessages,
  listenToMessages,
  unsubscribeStreamChannel,
  broadcastChannelAndAdmin
};
