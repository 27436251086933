<template>
  <div class="space-y-4">
    <div>
      <span class="text-xs text-gray-400">Default Symbol (Official TradingView)</span>
      <BaseTextbox v-model="symbol" placeholder="Ex: COINBASE:BTCUSD" />
    </div>
    <div class="flex items-center space-x-4">
      <div>
        <span class="text-xs text-gray-400">Default Interval</span>
        <DropdownSimple :active-item="interval" :items="intervals" @selected="x => (interval = x.id)" class="text-xs" />
      </div>
      <BaseCheckbox v-model="showTopBar">Show Top Bar</BaseCheckbox>
      <BaseCheckbox v-model="showBottomBar">Show Bottom Bar</BaseCheckbox>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import DropdownSimple from '@/components/dropdown/DropdownSimple.vue';
export default {
  name: 'WidgetConfigsTradingviewChart',
  emits: ['update'],
  components: {
    DropdownSimple
  },
  props: {
    config: {
      type: Object
    }
  },
  data() {
    return {
      symbol: this.config?.symbol || 'COINBASE:BTCUSD',
      interval: this.config?.interval || '5',
      intervals: [
        { text: '1m', id: '1' },
        { text: '5m', id: '5' },
        { text: '30m', id: '30' },
        { text: '1h', id: '60' },
        { text: '2h', id: '120' },
        { text: '3h', id: '180' },
        { text: '4h', id: '240' },
        { text: '1D', id: 'D' },
        { text: '1W', id: 'W' }
      ],
      showTopBar: this.config?.showTopBar == true,
      showBottomBar: this.config?.showBottomBar == true,
      configuration: {}
    };
  },
  watch: {
    showTopBar() {
      this.updateConfiguration();
    },
    showBottomBar() {
      this.updateConfiguration();
    },
    interval() {
      this.updateConfiguration();
    },
    symbol() {
      this.updateSearch();
    }
  },
  methods: {
    updateSearch: debounce(function () {
      this.updateConfiguration();
    }, 1000),
    updateConfiguration() {
      this.configuration = {
        symbol: this.symbol,
        interval: this.interval,
        showTopBar: this.showTopBar,
        showBottomBar: this.showBottomBar,
        valid: true
      };
      this.$emit('update', this.configuration);
    }
  }
};
</script>
