<template>
  <div class="cursor-pointer" @click="droppedDown = !droppedDown" :id="dropdownId">
    <DropdownAlign :on="droppedDown">
      <template #trigger>
        <slot />
      </template>

      <template #dropdown>
        <div :class="baseDropdownParentDivClass">
          <DropdownBase
            v-if="droppedDown"
            :items="dropdownItems"
            @clicked="handleAction"
            :use-parent-container="true"
            :dropdown-width="dropdownWidth"
            :max-height="maxHeight"
            :custom-classes="baseDropdownClass"
            :sort-options="sortOptions"
            :x-anchor="xAnchor"
            :sort-key="sortKey"
            :sort-order="sortOrder"
          />
        </div>
      </template>
    </DropdownAlign>
  </div>
</template>

<script>
import DropdownAlign from '@/components/dropdown/DropdownAlign.vue';
import DropdownBase from '@/components/dropdown/DropdownBase.vue';
export default {
  components: {
    DropdownAlign,
    DropdownBase
  },
  props: {
    dropdownItems: Array,
    dropdownClass: String,
    dropdownWidth: String,
    baseDropdownParentDivClass: { type: String, default: 'pl-4' },
    baseDropdownClass: { type: String, default: '' },
    direction: { type: String, default: 'right' },
    backgroundColor: { type: String, default: 'bg-gray-900' },
    maxHeight: String,
    sortOptions: { type: Boolean, default: false },
    sortKey: { type: String, default: 'text' },
    sortOrder: { type: String, default: 'asc' }
  },
  methods: {
    close: function (e) {
      const drop = document.getElementById(this.dropdownId);
      if (drop && !drop.contains(e.target) && this.droppedDown && e.target.id !== this.dropdownId) {
        this.droppedDown = false;
        this.$emit('dropdownChange', false);
      }
    },
    handleAction: function (action) {
      this.$emit('handleAction', action);

      let shouldClose = true;
      this.dropdownItems.forEach(item => {
        if (item.hold && item.id === action) {
          shouldClose = false;
        }
      });

      if (!shouldClose) {
        return;
      }

      this.droppedDown = false;
      this.$emit('dropdownChange', false);
    },
    toggleDropdown: function (e) {
      if (e.target.id !== this.dropdownId) {
        return;
      }

      this.droppedDown = !this.droppedDown;
      this.$emit('dropdownChange', this.droppedDown);
    }
  },
  data() {
    return {
      dropdownId: `dropdown_${Math.random()}`,
      droppedDown: false
    };
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.close);
  }
};
</script>
