<template>
  <div :id="dropdownId">
    <div class="absolute z-50" :class="searchDropdown ? 'w-full' : 'pl-4'" v-if="droppedDown">
      <DropdownBase
        :items="filteredItems"
        @clicked="selectedMetric($event)"
        :use-parent-container="true"
        :tooltip="true"
        :trancate-limit="40"
        :sort-options="false"
        :dropdown-width="searchDropdown ? '' : '500px'"
        class="h-56 cursor-pointer"
        :class="[searchDropdown ? 'py-1' : '', !filteredItems.length ? 'overflow-y-hidden' : '']"
      >
        <input
          v-if="inPlaceSearch"
          v-model="search"
          :class="`sticky top-0 w-full border-b border-grid bg-gray-900 px-4 py-2 outline-none`"
          placeholder="Search"
        />
        <div
          v-if="!filteredItems.length && !loading"
          class="flex h-full items-center justify-center text-gray-400"
          :class="inPlaceSearch ? 'pb-8' : ''"
        >
          No Result Found
        </div>
        <div v-if="loading" class="flex h-full items-center justify-center text-gray-400">
          <BaseLoader :background="800" />
        </div>
      </DropdownBase>
    </div>
  </div>
  <ModalMessenger
    v-if="showModal"
    :show="true"
    @save="selectedMetric($event)"
    @close="
      showModal = false;
      $emit('close', false);
    "
    :alert-detail="alertDetail"
  />
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import DropdownBase from '@/components/dropdown/DropdownBase.vue';
import ModalMessenger from '@/components/modal/ModalMessenger.vue';
import { useMapGetter } from '@/store/map-state';
import { getChannelName } from '@/composeables/chat';
import { truncate } from '@/composeables/filters';
const { channels } = useMapGetter();
const $emit = defineEmits(['close', 'select']);
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  items: {
    type: Array,
    default: () => []
  },
  alertDetail: {
    type: Object,
    default: () => {}
  },
  activeChatOption: {
    type: Object,
    default: () => {}
  },
  searchText: {
    type: String,
    default: ''
  },
  searchDropdown: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  inPlaceSearch: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  }
});

const showFeedbackModal = ref(false);
const dropdownId = ref(`profile_dropdown_${Math.random()}`);
const droppedDown = ref(false);
const showDropdown = ref(false);
const showModal = ref(false);
const initialRender = ref(true);
const search = ref(props.searchText);

onMounted(() => {
  document.addEventListener('click', close);
});
onBeforeUnmount(() => {
  document.removeEventListener('click', close);
});

const filteredItems = computed(() => {
  if (search.value.trim().length > 0) {
    return props.items.filter(i => i.text && i.text.toLowerCase().includes(search.value.toLowerCase()));
  } else {
    return props.items;
  }
});

//methods
function selectedMetric(event) {
  if (event == 'new') {
    showModal.value = true;
  } else {
    $emit('select', event);
  }
  droppedDown.value = false;
}

const close = function (e) {
  const drop = document.getElementById(dropdownId.value);
  if (initialRender.value && props.searchDropdown) {
    droppedDown.value = true;
    initialRender.value = false;
  } else if (
    !drop.contains(e.target) &&
    droppedDown.value &&
    e.target.id !== dropdownId.value &&
    props.searchDropdown
  ) {
    droppedDown.value = false;
    $emit('close');
  }
};

const toggleDropdown = function () {
  droppedDown.value = !droppedDown.value;
  showDropdown.value = !showDropdown.value;
};

watch(
  () => props.open,
  () => {
    droppedDown.value = props.open;
  }
);

watch(
  () => props.searchText,
  () => {
    search.value = props.searchText;
  }
);
</script>
