<template>
  <div class="flex flex-wrap">
    <div v-for="tag in orderedTags" :key="tag.id">
      <span
        :class="[
          'mr-2 font-medium',
          getTagColor(tag.tag_subgroup.name),
          size == 'xs' ? 'flex items-center' : '',
          clickableTag(tag) ? 'cursor-pointer' : ''
        ]"
        @click="$emit('handle-tag-click', tag, item)"
      >
        <template v-if="size == 'xs'">
          <div :class="[getTagBackground(tag.tag_subgroup.name), 'mr-1 rounded-full p-0.5']"></div>
        </template>
        <template v-else>
          <IconTagCoin v-if="tag.tag_subgroup.name == 'Coins'" />
          <IconTagSigDev v-else-if="tag.tag_subgroup.name == 'SigDevs'" />
          <IconTagCompany v-else-if="tag.tag_subgroup.name == 'Companies'" />
          <IconTagTag v-else />
        </template>
        {{ tag.name }}
      </span>
    </div>
  </div>
</template>

<script>
import global from '../../mixins/global';

export default {
  mixins: [global],
  props: {
    tags: {
      type: Array,
      required: true
    },
    item: Object,
    size: {
      type: String,
      default: 'sm'
    }
  },
  methods: {
    clickableTag(tag) {
      if (tag.tag_subgroup.name == 'Coins' && tag.coin) {
        return true;
      }
      if (this.companyTagGroups.includes(tag.tag_subgroup.name)) {
        return this.$store.state.companySlugs.some(x => x.tag == tag.id);
      }
      return false;
    }
  },
  computed: {
    companyTagGroups() {
      return this.$store.state.companyTagGroups;
    },
    textSize() {
      return {
        xs: 'text-xs',
        sm: 'text-sm'
      }[this.size];
    },
    orderedTags() {
      const res = [];

      const addSubgroup = subgroup => {
        (this.tags || []).forEach(tag => {
          if ((tag.tag_subgroup || {}).name === subgroup) {
            res.push(tag);
          }
        });
      };

      addSubgroup('SigDevs');
      addSubgroup('Coins');
      addSubgroup('Companies');

      (this.tags || []).forEach(tag => {
        let found = false;

        res.forEach(haveTag => {
          if (tag.id === haveTag.id) {
            found = true;
          }
        });

        if (!found) {
          res.push(tag);
        }
      });

      return res;
    }
  }
};
</script>
