<template>
  <img
    v-if="available"
    :src="url"
    class="inline rounded-full"
    :class="size"
    @error="available = false"
    loading="lazy"
  />
  <svg :class="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="showDefault">
    <circle cx="12" cy="12" r="12" fill="#4a5568" />
  </svg>
  <img :src="DefaultUserImage" :class="size" v-else />
</template>
<script setup>
import { ref, onMounted } from 'vue';
import DefaultUserImage from '@/images/user.png';
const props = defineProps({
  size: {
    type: String,
    default: 'w-7 h-7'
  },
  url: {
    type: String
  },
  showDefault: {
    type: Boolean,
    default: false
  }
});
const available = ref(true);
onMounted(() => {
  available.value = typeof props.url === 'string' && props.url !== '';
});
</script>
