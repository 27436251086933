<template>
  <div class="flex items-center" :class="[getSentimentClassAndText(sentiment), textAlign]">
    <div v-if="sentiment >= 60" :class="`overflow-hidden rounded-full ${iconSize} border border-gray-600 bg-gray-700`">
      <div class="h-full bg-green-400" :style="`width: ${sentiment}%`"></div>
    </div>
    <div v-if="sentiment <= 40" :class="`overflow-hidden rounded-full ${iconSize} border border-gray-600 bg-gray-700`">
      <div class="h-full bg-red-400" :style="`width: ${sentiment}%`"></div>
    </div>
    <span class="ml-1.5">{{ Math.floor(sentiment).toFixed(0) }}</span>
  </div>
</template>

<script>
import global from '../../../mixins/global';
export default {
  name: 'CellSentiment',
  mixins: [global],
  props: {
    sentiment: String,
    textAlign: {
      type: String,
      default: 'justify-end'
    },
    iconSize: {
      type: String,
      default: 'w-6 h-2'
    }
  }
};
</script>
