<template>
  <div>
    <label for="coin" class="mt-4 text-xs text-gray-400">Coin (required)</label>
    <DropdownSelectCoin class="my-1" :starting-coins="preSelectedCoins" only-one @update="coinSelected" />
  </div>
</template>
<script>
import DropdownSelectCoin from '@/components/dropdown/DropdownSelectCoin.vue';
import { isEmpty } from 'lodash';
export default {
  name: 'WidgetConfigsCoinProfile',
  emits: ['update'],
  props: {
    config: {
      type: Object
    }
  },
  mounted() {
    if (this.config && !isEmpty(this.config)) {
      this.editableConfig = JSON.parse(JSON.stringify(this.config));
      this.preSelectedCoins = this.$store.getters.coins.filter(x => x.coin_uid == this.editableConfig.coin[0]);
    }
  },
  components: {
    DropdownSelectCoin
  },
  data() {
    return {
      editableConfig: { coin: [], valid: false },
      preSelectedCoins: []
    };
  },
  methods: {
    coinSelected(payload) {
      this.editableConfig.coin = [payload];
      this.editableConfig.valid = true;

      this.$emit('update', this.editableConfig);
    }
  }
};
</script>
