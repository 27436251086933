<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="false"
      :connectable="false"
      @expand="goToExpandLink"
    >
      <template #body>
        <template v-if="loading">
          <div class="mt-10 flex w-full justify-center">
            <BaseLoader />
          </div>
        </template>
        <template v-else>
          <div class="px-2">
            <table class="w-full px-4 text-xs font-medium text-gray-200">
              <thead>
                <tr class="sticky top-0 truncate border-0 bg-gray-900 text-gray-400">
                  <th
                    v-for="(col, index) in cols"
                    :key="index"
                    class="h-8 cursor-pointer bg-gray-900 px-1 pl-1 pr-4 text-xs font-medium text-gray-400"
                  >
                    {{ col.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="recentAlerts.length">
                  <tr
                    class="border-none"
                    v-for="(alert, idx) in recentAlerts"
                    :key="idx"
                    :class="idx % 2 == 0 ? 'bg-gray-800/25' : ''"
                  >
                    <td class="pr-2">
                      <p class="max-w-sm line-clamp-3">{{ alert.msg_text }}</p>
                    </td>
                    <td class="pr-2">
                      <span>{{ alert.alert_type }}</span>
                    </td>
                    <td class="pr-2">
                      <CellIcon :icons="alert.destination" :label="true" />
                    </td>
                    <td class="pr-2">
                      <span>{{ readableDate(alert.sent_on) }}</span>
                    </td>
                    <td class="pr-2">
                      <span>
                        <CellRowAction :obj="alert" @action-clicked="actionClicked($event)" />
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="12">
                      <NoDataFound class="text-xs" :is-focus-out="false" />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </template>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';
import FilterMixin from '@/mixins/filters';
import CellRowAction from '@/components/table/cell/CellRowAction.vue';
import CellIcon from '@/components/table/cell/CellIcon.vue';
export default {
  name: 'WidgetsAlertingLogs',
  mixins: [WidgetsMixin, FilterMixin],
  components: {
    CellRowAction,
    CellIcon
  },
  mounted() {
    const self = this;
    self.fetchRecentAlerts();
    self.$eventHub.$on('refreshAlertLogs', function () {
      self.noMoreResults = false;
      self.fetchRecentAlerts();
    });
  },
  beforeUnmount() {
    this.$eventHub.$off('refreshAlertLogs');
  },
  data() {
    // min 3x5
    return {
      loading: false,
      recentAlerts: [],
      perPage: 50,
      entities: {
        Coin: 'Coin',
        Calendar: 'Calendar',
        Gas: 'Gas',
        News: 'News',
        SEC: 'SEC',
        ResearchArticle: 'Research'
      },
      cols: [
        { type: 'text', name: 'Alert', id: 'msg_text' },
        { type: 'text', name: 'Alert Type', id: 'entity_type' },
        {
          type: 'icons',
          name: 'Destination',
          id: 'destination',
          title: true,
          headerClass: 'text-right',
          cellClass: 'w-24'
        },
        { type: 'timestamp', name: 'Time Triggered', id: 'sent_on', headerClass: 'text-left' },
        { type: 'action', name: 'Action', id: 'action', label: false }
      ]
    };
  },
  methods: {
    goToExpandLink() {
      window.open('/alerts', '_blank');
    },
    actionClicked(event) {
      if (event.action == 'IconRestart') {
        this.$store.dispatch('confirm', 'Please confirm you want to reset this alert?').then(() => {
          let success_message = `alert reset successfully`;
          this.$http
            .patch(`/alerts/${event.obj.alert.id}`, {
              is_disabled: false,
              expiration_date: '',
              success_message: success_message
            })
            .then(() => {
              this.recentAlerts = this.recentAlerts.map(x => {
                if (x.id == event.obj.id) {
                  x.action = ['IconDelete'];
                }
                return x;
              });
              this.$eventHub.$emit('resetActiveLogs');
            });
        });
      } else if (event.action == 'IconDelete') {
        this.$store.dispatch('confirm', 'Please confirm you want to delete this notification?').then(() => {
          this.$http.post('/notifications/delete', { id: event.obj.id }).then(() => {
            this.recentAlerts = this.recentAlerts.filter(x => x.id != event.obj.id);
            this.$store.commit('removeUserNotification', event.obj.id);
          });
        });
      }
    },
    removeNotification(id) {
      const self = this;
      self.recentAlerts.splice(
        self.recentAlerts.findIndex(v => v.id === id),
        1
      );
    },
    async fetchRecentAlerts(pageParams = { page: 1, perPage: this.perPage }) {
      const self = this;
      if (!self.noMoreResults) {
        self.loading = true;
        try {
          let response = await this.$http.get('/notifications', {
            params: {
              limit: pageParams.perPage,
              offset: pageParams.perPage * (pageParams.page - 1)
            }
          });
          let parseResponse = response.data.map(a => {
            let destination = [];
            if (a.alert.deliver_onsite) {
              destination.push({ id: 0, channel: 'onsite', name: 'OnSite', checked: true });
            }
            destination.push(...a.destination);
            let action = ['IconDelete'];
            if (a.alert.is_disabled) {
              action.unshift('IconRestart');
            }
            var entity_type = self.entities[a.alert.entity];
            return {
              id: a.id,
              msg_text: a.message,
              alert_type: a.alert.entity,
              entity_type: entity_type,
              alert: a.alert,
              sent_on: a.created_at,
              destination: destination,
              read: a.read,
              action: action
            };
          });
          if (parseResponse.length == 0) {
            self.noMoreResults = true;
          }
          if (pageParams.page == 1) {
            self.recentAlerts = parseResponse;
          } else {
            self.recentAlerts.push(...parseResponse);
          }
        } catch {
          self.recentAlerts = [];
        } finally {
          self.loading = false;
        }
      }
      return pageParams;
    }
  }
};
</script>
