<template>
  <div class="h-full self-stretch" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :overrides="overrides"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      :hidden-actions="['expand']"
      :horizontal-padding="false"
    >
      <template #body>
        <template v-if="widget.configuration">
          <BaseButton
            v-if="showScrollToTop"
            @press="scrollToTop()"
            type="primary"
            size="sm"
            class="absolute bottom-4 right-4 z-30"
          >
            <IconArrowUp />
          </BaseButton>
          <VirtualList
            ref="scroller"
            :id="`news-widget-${Math.random()}`"
            v-if="news.length"
            style="height: 100%; overflow-y: auto"
            :data-key="'link_hash'"
            :data-sources="news"
            :extra-props="{ 'hide-tags': widget.configuration.hide_tags }"
            @tobottom="getOlderNews"
            :data-component="itemComponent"
          >
          </VirtualList>
          <div v-else-if="!loading">
            <p class="mt-10 px-10 text-center text-sm text-gray-400">No news found for this feed's criteria.</p>
          </div>
        </template>
        <template v-else>
          <WidgetsErrorMessage>No feed selected</WidgetsErrorMessage>
        </template>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import WidgetsMixin from '@/mixins/widgets';
import VirtualList from 'vue3-virtual-scroll-list';
import VirtualScrollItemSimpleNews from '@/components/virtual_scroll_item/VirtualScrollItemSimpleNews.vue';
export default {
  name: 'WidgetsNews',
  mixins: [WidgetsMixin],
  mounted() {
    if (this.widget && this.widget.configuration) {
      this.newsfeed = this.widget.configuration;
    }
    if (!this.recommendedFeedSelected) {
      this.getEntity().then(() => {
        this.loading = true;
        this.getNews();
      });
    } else {
      this.loading = true;
      this.getNews();
    }
    this.pollingInterval = setInterval(() => {
      this.getNews();
    }, 20000);
  },
  beforeUnmount() {
    clearInterval(this.pollingInterval);
  },
  components: {
    VirtualList
  },
  data() {
    return {
      news: [],
      newsfeed: null,
      pollingInterval: null,
      latestTimestamp: null,
      endTimestamp: null,
      showScrollToTop: false,
      hideTags: false
    };
  },
  computed: {
    itemComponent() {
      return VirtualScrollItemSimpleNews;
    },
    newsParams() {
      const widgetConfig = this.widget.configuration;
      if (widgetConfig) {
        let params = { ...this.newsfeed };
        if (widgetConfig.recommendedFeed) {
          params = { ...widgetConfig.config };
        }
        params = Object.fromEntries(
          Object.entries(params).filter(([key]) => !['id', 'name', 'updated_at', 'created_at'].includes(key))
        );
        if (this.widgetChannelOutput && this.widgetChannelOutput.length) {
          if (!params.coins) {
            params.coins = [];
          }
          params.coins = params.coins.concat(this.widgetChannelOutput.map(x => x.id));
        }
        if (!params.is_starred_by_my_team) {
          params.want_firehose = true;
        }
        return params;
      }
      return null;
    },
    recommendedFeedSelected() {
      return this.widget.configuration && this.widget.configuration.recommendedFeed;
    }
  },
  watch: {
    'widget.configuration'() {
      this.latestTimestamp = null;
      this.endTimestamp = null;
      this.loading = true;
      this.news = [];
      if (this.recommendedFeedSelected) {
        this.getNews();
      } else {
        this.getEntity().then(() => {
          this.getNews();
        });
      }
    },
    widgetChannelOutput() {
      this.loading = true;
      this.latestTimestamp = null;
      this.endTimestamp = null;
      this.news = [];
      this.getNews();
    }
  },
  methods: {
    async getEntity() {
      try {
        let response = await this.$http.post('/shared_resources', {
          entity: 'newsfeed',
          id: this.widget.configuration.id
        });
        this.newsfeed = response.data.resource;
      } catch {
        //do nothing
      }
    },
    scrollToTop() {
      this.showScrollToTop = false;
      this.$refs.scroller.scrollToOffset(0);
    },
    fetch(payload) {
      return this.$http.get('/news.json', {
        params: payload
      });
    },
    getNews() {
      let payload = this.newsParams;
      if (payload) {
        if (this.latestTimestamp) {
          payload.start_datetime = DateTime.fromISO(this.latestTimestamp).plus({ seconds: 1 }).toISO();
        }
        payload.limit = 25;
        this.fetch(payload)
          .then(response => {
            let newNews = response.data.data;
            if (newNews.length > 0) {
              newNews.reverse().forEach(item => {
                this.news.unshift(item);
              });
            }
            if (this.news && this.news[0]) {
              this.latestTimestamp = this.news[0].timestamp;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
    getOlderNews() {
      this.showScrollToTop = true;
      let payload = this.newsParams;
      if (payload) {
        payload.start_datetime = null;
        payload.end_datetime = this.news[this.news.length - 1].timestamp;
        payload.limit = 50;
        this.fetch(payload).then(response => {
          let newNews = response.data.data;
          if (newNews.length > 0) {
            newNews.forEach(item => {
              this.news.push(item);
            });
          }
        });
      }
    }
  }
};
</script>
