<template>
  <div class="w-100 relative pb-6">
    <div class="w-100 relative mt-2 h-3">
      <div class="w-100 absolute right-0 bottom-0 top-0 left-0 rounded-full bg-green-400" :style="`right: 0%;`" />
      <div class="w-100 absolute right-0 bottom-0 top-0 left-0 rounded-l-full bg-yellow-400" :style="`right: 40%;`" />
      <div class="w-100 absolute right-0 bottom-0 top-0 left-0 rounded-l-full bg-red-400" :style="`right: 60%;`" />
    </div>
    <div class="absolute" :style="`right: calc(${100 - value}% - 10px);`" style="margin-top: -5px">
      <div class="text-xs">▲</div>
      <div class="text-sm font-medium">
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Number
  }
};
</script>
