<template>
  <div class="py-2 px-4" :class="{ 'border-l-4 border-green-500 bg-green-900 bg-opacity-80': latestNewsItem }">
    <div
      :class="`flex space-x-2.5 text-xs  ${latestNewsItem ? 'text-green-100' : 'text-secondary'}`"
      :style="latestNewsItem ? 'opacity: 0.65' : ''"
    >
      <span>
        <BaseTooltip v-if="item.sources && item.sources.length >= 1">
          <template #target>
            {{ sourceName }}
          </template>
          <template #default>
            <div class="max-w-md">{{ item.sources.map(s => s.name).join(', ') }}</div>
          </template>
        </BaseTooltip>
      </span>
      <!-- <span class="text-gray-700">|</span> -->
      <!-- <span :class="`flex items-center space-x-0.5`">
        <svg xmlns="http://www.w3.org/2000/svg" :class="`h-3.5 w-3.5`" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
            clip-rule="evenodd"
          />
        </svg>
        <div>
          {{ minimalTimeWithinToday(item.timestamp) }}
        </div>
      </span> -->
    </div>
    <a :href="item.link" target="_blank">
      <p class="mt-0.5 break-words line-clamp-2" v-html="item.translated_headline || item.headline"></p>
    </a>
    <div class="mt-1 flex w-full justify-between text-xs text-secondary">
      <div class="flex flex-wrap">
        <div v-for="tag in item.tags" :key="tag.id">
          <span
            :class="['mr-2 font-medium', getTagColor(tag.tag_subgroup.name), clickableTag(tag) ? 'cursor-pointer' : '']"
            @click="handleTagClick(tag, item)"
          >
            <svg
              v-if="tag.tag_subgroup.name == 'Coins'"
              xmlns="http://www.w3.org/2000/svg"
              class="mb-px inline h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
              />
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else-if="tag.tag_subgroup.name == 'SigDevs'"
              xmlns="http://www.w3.org/2000/svg"
              class="mb-px inline h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else-if="tag.tag_subgroup.name == 'Companies'"
              xmlns="http://www.w3.org/2000/svg"
              class="mb-px inline h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else-if="tag.tag_subgroup.name == 'Social'"
              xmlns="http://www.w3.org/2000/svg"
              class="mb-px inline h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="mb-px inline h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg>
            {{ tag.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from '@/mixins/global';
import moment from 'moment';
import FilterMixin from '@/mixins/filters';
import { Inertia } from '@inertiajs/inertia';
export default {
  name: 'CellNews',
  mixins: [global, FilterMixin],
  props: {
    item: Object
  },
  computed: {
    latestNewsItem() {
      var now = moment(new Date()); //todays date
      var duration = moment.duration(now.diff(this.item.timestamp));
      var minutes = duration.asMinutes();
      return minutes < 10 ? true : false;
    },
    companyTagGroups() {
      return this.$store.state.companyTagGroups;
    },
    sourceName() {
      return this.item.sources.length > 1
        ? `${this.item.sources[0]?.name} and ${this.item.sources.length - 1} more...`
        : this.item.sources[0]?.name;
    },
    sortedTags: function () {
      if (this.item.tags) {
        const sigdev = this.item.tags.filter(i => i.type === 'sigdev');
        const coins = this.item.tags.filter(i => i.type === 'coin');

        return sigdev.concat(coins);
      }
      return null;
    }
  },
  methods: {
    handleTagClick: function (tag, newsItem) {
      if (tag.tag_subgroup.name == 'Coins' && tag.coin) {
        this.$store.dispatch('enableCoinSidebar', { coin: tag.coin.uid, newsItem: newsItem });
      }
      if (this.companyTagGroups.includes(tag.tag_subgroup.name)) {
        const company = this.$store.state.companySlugs.find(x => x.tag == tag.id);
        if (company) {
          Inertia.visit(`/company/${company.slug}`);
        }
      }
      if (tag.tag_subgroup.name == 'Social') {
        this.$store.dispatch('enableCorrelatedSidebar');
      }
    },
    clickableTag(tag) {
      if (tag.tag_subgroup.name == 'Coins' && tag.coin) {
        return this.$store.state.data.coins.some(x => x.tag_id == tag.id);
      }
      if (this.companyTagGroups.includes(tag.tag_subgroup.name)) {
        return this.$store.state.companySlugs.some(x => x.tag == tag.id);
      }
      if (tag.tag_subgroup.name == 'Social') {
        return true;
      }
      return false;
    }
  },
  data() {
    return {};
  }
};
</script>
