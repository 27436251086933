<template>
  <div class="relative col-span-2 flex-1" :class="column.widthFull ? 'rounded bg-gray-800' : ''">
    <DropdownSearch
      dropdown-height="256px"
      :dropdown-width="column.widthFull ? '' : '200px'"
      :just-text="true"
      :name="categoryName"
      :selected-item="dropdownSearchTitle"
      :hide-clear="true"
      :items="categoryDropdownOptions"
      :input-class="column.widthFull ? 'text-gray-200 text-xs' : 'px-0 py-0 text-xs'"
      :sort-options="column.sortOptions"
      sort-key="text"
      @selected="handleAction(column, $event)"
      class="text-gray-400 duration-100 hover:text-gray-100"
      :width-full="column.widthFull"
    />
  </div>
</template>

<script>
import DropdownSearch from '@/components/dropdown/DropdownSearch.vue';
import { truncate } from '@/composeables/filters';
import { sortByMarketCapAllCategories } from '@/composeables/helpers';
export default {
  name: 'TablePartHeader',
  props: {
    column: Object
  },
  components: { DropdownSearch },
  data: () => {
    return {
      selectedOption: null
    };
  },
  mounted() {
    if (this.column.dropdownType && this.column.selectedOption) {
      this.selectedOption = this.column.selectedOption;
    }
  },
  methods: {
    handleAction(col, option) {
      col.selectedOption = option.id;
      this.selectedOption = option.id;
      this.$emit('handleAction', { id: col.id, selectedOption: option.id });
    },
    handleDropdownAction(col, option) {
      col.selectedOption = option;
      this.$emit('handleAction', { id: col.id, selectedOption: option });
    }
  },
  computed: {
    categoryName() {
      let name = this.column.vs ? 'vs ' + this.dropdownSearchTitle.name : this.dropdownSearchTitle.name;
      return this.column.widthFull ? name : truncate(name, 10);
    },
    categoryDropdownOptions() {
      // Add default to select categories
      if (!this.column.includeCategories && !this.column.includeSubcategories) {
        // eslint-disable-next-line vue/no-mutating-props, vue/no-side-effects-in-computed-properties
        this.column.includeCategories = true;
      }
      return sortByMarketCapAllCategories(this.column);
    },
    allCategories() {
      return this.$store.state.categories.coin || [];
    },
    subCategories() {
      return this.$store.state.categories.coin_sub_categories;
    },
    dropdownSearchTitle() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (!this.selectedOption) this.selectedOption = this.categoryDropdownOptions[0].id;
      return this.categoryDropdownOptions.find(metric => metric.id === this.selectedOption) || {};
    }
  },
  watch: {
    selectedOption(val) {
      if (val) {
        this.$emit('handleAction', { id: this.column.id, selectedOption: val });
      }
    },
    column: {
      handler(val) {
        if (val.selectedOption && this.column.dropdownType) {
          this.selectedOption = this.column.selectedOption;
          this.$emit('handleAction', { id: this.column.id, selectedOption: this.selectedOption });
        }
      },
      deep: true
    }
  }
};
</script>
