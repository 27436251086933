<template>
  <div class="flex space-x-3 text-sm text-white">
    <div v-for="(tag, index) in tags" :key="index" :class="colorMapping[tag]">
      {{ nameMapping[tag] }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CellSigDevTags',
  props: {
    tags: Array
  },
  data() {
    // todo add all colors/sigdevs here
    return {
      nameMapping: {
        listing: 'Listing',
        fork: 'Fork',
        airdrop: 'Airdrop',
        launch: 'Mainnet Update/Launch'
      },
      colorMapping: {
        listing: 'text-green-400',
        airdrop: 'text-yellow-300',
        fork: 'text-purple-400',
        launch: 'text-red-400'
      }
    };
  }
};
</script>
