<template>
  <BasePercentClassic :percent="percentageValue" />
</template>

<script>
export default {
  name: 'CellRelativeVolume',
  props: {
    volume: String,
    average: String
  },
  data() {
    return {};
  },
  computed: {
    percentageValue() {
      return this.average > 0 ? this.volume / this.average - 1 : '';
    }
  }
};
</script>
