<template>
  <div class="flex items-center space-x-2 px-1 text-white">
    <div @click="$emit('coin-clicked', coin)" class="flex flex-1 items-center justify-between space-x-2">
      <div class="flex cursor-pointer items-center space-x-1">
        <template v-if="width >= 500">
          <BaseCoinIcon class="h-4 w-4" :coin="coin.coin_uid" loading="lazy" />
        </template>
        <template v-if="width >= 400">
          <span class="mr-2 truncate whitespace-nowrap">
            {{ coin.name }}
          </span>
        </template>
        <span class="-mb-0.5 text-xs text-gray-400">
          {{ coin.ticker }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CellWidgetCoin',
  props: {
    coin: Object
  },
  computed: {
    width() {
      return this.coin ? this.coin.container_width : 1;
    }
  },
  data() {
    return {};
  }
};
</script>
