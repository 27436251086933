<template>
  <div :id="randomId" class="h-full w-full pt-4" v-if="yAxisSeries.length"></div>
</template>
<script>
import Highcharts from 'highcharts/highcharts';
//this is work in progress
window.Highcharts = Highcharts;
import moment from 'moment';
import global from '@/mixins/global';
import colors from 'tailwindcss/colors';
import FilterMixin from '@/mixins/filters';
import { downloadToCSV } from '@/util/csvDownload';

import * as jp from 'jsonpath';
Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E']
  }
});
export default {
  name: 'WidgetCustomTemplateScatterChart',
  mixins: [global, FilterMixin],
  mounted() {
    const self = this;
    if (this.yAxisSeries.length > 0) {
      self.generateChart();
    }
    this.$eventHub.$on(`download_${this.widget.id}`, () => {
      this.downloadData();
    });
    self.$eventHub.$on('reflow-highchart', function () {
      self.$nextTick(() => {
        self.chartObj.reflow();
      });
    });
  },
  beforeUnmount() {
    this.$eventHub.$off('reflow-highchart');
  },
  data() {
    return {
      randomId: Math.random().toString(),
      waterMarkFontSize: 16,
      chartObj: undefined
    };
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    dataSet: {
      type: [Object, Array],
      default: () => []
    }
  },

  watch: {
    config: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.generateChart();
        });
      }
    },
    dataSet: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.generateChart();
        });
      }
    }
  },

  computed: {
    chartType() {
      return this.widget.widget_template.default_configuration.config.type;
    },
    plotColumnStacking() {
      return this.widget.widget_template.default_configuration.config.type;
    },
    reverseSeries() {
      if (this.xAxisSeries && this.xAxisSeries.format != 'text' && this.xAxisSeries.data) {
        let firstPoint = this.xAxisSeries.data[0];
        let secondPoint = this.xAxisSeries.data[1];
        if (this.xAxisSeries.format == 'datetime') {
          firstPoint = new Date(firstPoint);
          secondPoint = new Date(secondPoint);
        }
        return firstPoint > secondPoint;
      }
      return false;
    },
    config() {
      return this.widget.widget_template.default_configuration.config.chartConfigs;
    },
    xAxisSeries() {
      let xAxisAttribute = this.config.xAxis.attribute;
      let data = xAxisAttribute != '' ? jp.query(this.dataSet, xAxisAttribute) : [];
      let format = this.config.xAxis.format;
      if (format == 'datetime') {
        data = data.map(x => new Date(x).getTime());
      }
      return {
        title: this.config.xAxis.title,
        data: data,
        format: format
      };
    },
    xAxisSeriesReverse() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.reverseSeries ? [...this.xAxisSeries.data.reverse()] : this.xAxisSeries;
    },
    yAxisSeries() {
      let series = [];
      for (var i = 0; i < this.config.dataSeries.length; i++) {
        let dSeries = this.config.dataSeries[i];
        let seriesData = dSeries.attribute != '' ? jp.query(this.dataSet, dSeries.attribute) : [];
        seriesData = seriesData.map(x => {
          return parseFloat(x) != 0 ? parseFloat(x) : x;
        });
        if (dSeries.label && seriesData.length > 0) {
          series.push({
            align: this.config.yAxis[dSeries.axis].align,
            name: this.config.yAxis[dSeries.axis].name,
            axisTitle: dSeries.label,
            data: seriesData,
            color: dSeries.color,
            yAxis: dSeries.axis,
            visible: true
          });
        }
      }
      return series;
    },
    chartConfig() {
      const self = this;
      let xAxisData = self.xAxisSeriesReverse;
      return {
        chart: {
          type: self.chartType,
          reflow: true,
          backgroundColor: 'transparent',
          zoomType: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          title: {
            text: this.xAxisSeries.title,
            style: {
              fontWeight: 'bold',
              color: colors.gray[200],
              fontSize: '10px'
            }
          },
          lineColor: colors.gray[600],
          visible: true,
          categories: xAxisData,
          datetime: this.xAxisSeries.format != 'text',
          labels: {
            enabled: true,
            style: {
              color: colors.gray[200],
              fontSize: '10px'
            },
            formatter:
              this.xAxisSeries.format != 'text'
                ? function () {
                    return window.Highcharts.dateFormat(
                      '%b %e',
                      this.value?.toString().length > 12 ? this.value / 1000 : this.value
                    );
                  }
                : null
          },
          lineWidth: 0,
          tickLength: 0
        },
        yAxis: [],
        title: {
          text: '',
          style: {
            fontWeight: 'bold',
            color: colors.gray[200],
            fontSize: '14px'
          }
        },
        legend: {
          enabled: self.config.legendStatus != 'none' ? true : false,
          align: ['top', 'bottom'].includes(this.config.legendStatus) ? 'center' : this.config.legendStatus,
          verticalAlign: ['top', 'bottom'].includes(this.config.legendStatus) ? this.config.legendStatus : 'middle',
          itemHoverStyle: {
            color: colors.gray[200]
          },
          itemStyle: {
            color: colors.gray[400],
            fontWeight: '400',
            fontSize: '14px'
          }
        },
        plotOptions: {
          scatter: {
            animation: false,
            marker: {
              radius: 4
            }
          }
        },
        tooltip: {
          shared: true,
          useHTML: true,
          backgroundColor: colors.gray[900],
          style: {
            color: 'white',
            pointerEvents: 'auto'
          },
          formatter: function () {
            let title = this.x;
            if (self.xAxisSeries.format != 'text') {
              title = title?.toString().length > 12 ? title / 1000 : title;
              title = moment(title).format('MMM DD, YYYY hh:mm:ss');
            }
            const tipHtml = `<b>${title}</b><br/>`;
            let roundTo = this.point.y > 1 ? 2 : 6;
            const fromattedPnts = `<b> ${this.point.series.name}:</b>
                <span style="color:${this.point.color}; margin-left: 0.5rem;">${self.formatNumber(
              this.point.y,
              roundTo
            )}</span>`;
            return tipHtml + fromattedPnts + '<br/>';
          }
        },
        series: [],
        exporting: {
          enabled: false
        }
      };
    }
  },
  methods: {
    yAxisSeriesReverse(yAxisData) {
      return this.reverseSeries ? [...yAxisData.reverse()] : yAxisData;
    },
    generateChart() {
      const self = this;
      let config = self.chartConfig;
      let yAxisConfigs = [];
      let chartSeries = [];
      let defaultyAxisOptions = {
        visible: true,
        gridLineColor: colors.gray[800],
        lineColor: colors.gray[600],
        scaleShowLabels: true,
        title: {
          text: '',
          style: {
            color: colors.gray[300],
            fontSize: '10px'
          }
        },
        labels: {
          enabled: true,
          style: {
            color: colors.gray[200],
            fontSize: '10px',
            fontWeight: 'bold'
          }
        }
      };
      for (var i = 0; i < self.yAxisSeries.length; i++) {
        let axisConfig = JSON.parse(JSON.stringify(defaultyAxisOptions));
        axisConfig.opposite = self.yAxisSeries[i].align == 'right';
        axisConfig.title.text = self.yAxisSeries[i].name;
        axisConfig.visible = true;
        let axisSeries = {
          name: self.yAxisSeries[i].axisTitle,
          data: self.yAxisSeriesReverse([...self.yAxisSeries[i].data]),
          color: self.yAxisSeries[i].color,
          yAxis: self.yAxisSeries[i].yAxis
        };

        yAxisConfigs.push(axisConfig);
        chartSeries.push(axisSeries);
      }
      config.yAxis = yAxisConfigs;
      config.series = chartSeries;
      if (config.series && config.series.length > 0) {
        self.chartObj = window.Highcharts.chart(self.randomId, config);
        self.$emit('chart', self.chartObj);
      }
    },
    addWatermark(chart) {
      var xCords = chart.plotWidth / 2 + chart.plotLeft - 75,
        yCords = chart.plotHeight / 2 + chart.plotTop + 5;
      chart.renderer
        .text('The Tie Terminal', xCords, yCords)
        .css({
          fontSize: this.waterMarkFontSize + 'px',
          color: colors.gray[700]
        })
        .add();
    },
    downloadData() {
      let xAxisData = this.xAxisSeriesReverse;
      let columns = [this.xAxisSeries.title || ''];
      this.yAxisSeries.forEach(e => columns.push(e.axisTitle));

      let columnsMap = {};
      columns.forEach((e, i) => (columnsMap[i] = e));
      let csvKeys = columns.map(e => {
        return {
          key: e,
          as: e.toUpperCase()
        };
      });

      var seriesData = xAxisData.data.map(e => [e]);
      for (var i = 0; i < seriesData.length; i++) {
        for (var j = 0; j < this.yAxisSeries.length; j++) {
          seriesData[i].push(this.yAxisSeries[j].data[i]);
        }
      }

      let csvData = seriesData.map((e, i) => {
        let obj = {};
        columns.forEach((key, j) => {
          obj[key] = e[j];
        });
        return obj;
      });

      downloadToCSV(csvKeys, csvData, this.widget.title);
    }
  }
};
</script>
