<template>
  <BaseDrawer position="right" size-class="w-[36rem]" class="text-white" :overlay="false" @update:model-value="hide">
    <div class="flex flex-col" v-if="coinData.coin">
      <a
        :href="newsItem.link"
        target="_blank"
        class="flex flex-col space-y-0.5 bg-blue-900/25 px-4 py-2 text-xs font-medium text-blue-100"
        v-if="newsItem"
      >
        <span class="text-xs opacity-50">
          {{ minimalTimeWithinToday(newsItem.timestamp) }}
          <span v-if="newsItem.sources"> - {{ newsItem.sources.map(s => s.name)[0] }} </span>
        </span>
        <div class="flex items-center">
          <p class="line-clamp-2" v-html="newsItem.translated_headline || newsItem.headline"></p>
        </div>
      </a>
      <div class="mt-4 flex flex-row items-center justify-between px-2">
        <div class="flex space-x-1">
          <BaseCoinIcon :coin="coinData.coin_uid" class="h-6 w-6" />
          <div>
            <a class="" :href="`/coin/${coinData.coin_uid}`">
              <div class="cursor-pointer text-lg font-medium leading-none">
                {{ coinData.coin.name }}
              </div>
            </a>
            <div v-if="newsItem" class="mt-0.5 leading-none">
              <div class="ml-auto flex items-center space-x-2">
                <span class="text-xs font-medium text-secondary">Since Article</span>
                <div
                  v-for="tf in timeframes"
                  :key="tf.id"
                  :disabled="tf.disable ? true : false"
                  @click="handleAction(tf.id, tf.disable)"
                  class="rounded px-2 py-0.5 text-xs font-medium"
                  :class="`${
                    tf.id == selectedTimeframe && !tf.disable
                      ? 'bg-blue-900 text-blue-100'
                      : 'bg-gray-800 text-gray-400 duration-100'
                  } ${
                    tf.disable
                      ? 'cursor-not-allowed bg-gray-700'
                      : 'cursor-pointer hover:bg-blue-900 hover:text-blue-100'
                  }`"
                >
                  {{ tf.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="space-y-1 pr-4 text-right">
          <div class="text-lg font-medium leading-none text-white">
            <span :class="coinData.ticker + '_price'">${{ formatPrice(coinData.price) }}</span>
          </div>
          <div class="flex items-center" v-if="priceChange">
            <div class="inline text-xs font-medium leading-none">
              <span class="mr-1 text-gray-400">
                {{ !newsItem || !selectedTimeframe ? 'SA' : selectedTimeframe }}
              </span>
            </div>
            <BasePercentClassic :percent="priceChange" class="text-sm font-medium" />
          </div>
        </div>
      </div>
      <div class="py-3">
        <div class="w-100 flex flex-row px-4">
          <div class="flex-1 whitespace-nowrap px-3 text-center">
            <div class="text-xs font-medium text-secondary">Tweets (24hr)</div>
            <div class="text-xl font-medium">
              <span v-if="noSmaAlias"></span>
              <span v-else> {{ formatNumber(coinData.tweet_volume) }}</span>
            </div>
            <span class="relative inline-flex text-xs font-medium text-gray-400" v-if="tweetsChange && !noSmaAlias">
              <BasePercentClassic :percent="tweetsChange" class="text-sm font-medium" />
              <BaseTooltip>
                <template #target>
                  <span class="ml-0.5 mt-0.5 flex flex-col justify-center">
                    {{ !newsItem || !selectedTimeframe ? ' SA' : selectedTimeframe }}
                  </span>
                </template>
                <template #default>{{ setToolTip() }}</template>
              </BaseTooltip>
            </span>
          </div>
          <div class="flex-1 whitespace-nowrap px-3 text-center">
            <div class="text-xs font-medium text-secondary">Volume (24hr)</div>
            <div class="text-xl font-medium" v-if="coinData.trading_volume">
              ${{ formatNumber(coinData.trading_volume) }}
            </div>
            <span class="inline-flex text-xs font-medium text-gray-400" v-if="volumeChange">
              <BasePercentClassic :percent="volumeChange" class="text-sm font-medium" />
              <BaseTooltip>
                <template #target>
                  <span class="ml-0.5 mt-0.5 flex flex-col justify-center">
                    {{ !newsItem || !selectedTimeframe ? ' SA' : selectedTimeframe }}
                  </span>
                </template>
                <template #default>{{ setToolTip() }}</template>
              </BaseTooltip>
            </span>
          </div>
          <div class="flex-1 whitespace-nowrap px-3 text-center">
            <div class="text-xs font-medium text-secondary">Daily Sentiment</div>
            <div class="text-xl font-medium">
              <span v-if="noSmaAlias"></span>
              <CellSentiment
                :sentiment="coinData.daily_sentiment"
                text-align="justify-center"
                icon-size="w-6 h-2"
                v-else
              />
            </div>
            <span class="inline-flex text-xs font-medium text-gray-400" v-if="sentimentChange && !noSmaAlias">
              <BasePercentClassic :percent="sentimentChange" class="text-sm font-medium" />
              <BaseTooltip>
                <template #target>
                  <span class="ml-0.5 mt-0.5 flex flex-col justify-center">
                    {{ !newsItem || !selectedTimeframe ? ' SA' : selectedTimeframe }}
                  </span>
                </template>
                <template #default>{{ setToolTip() }}</template>
              </BaseTooltip>
            </span>
          </div>
        </div>
      </div>
    </div>
    <NoDataFound class="pt-4 pb-2" v-else />
    <BaseCard class="m-2 h-48 max-h-64" :collapsable="true">
      <template #header>
        {{ `${noSmaAlias ? 'Price' : 'Tweet Volume vs. Price vs. Sentiment'}  ${newsItem ? 'vs. Event' : ''}` }}
      </template>
      <ChartPriceMktSentiment :series="filteredChartData" :loading="isPriceChartLoading" />
    </BaseCard>
    <BaseTabs v-model="tab" :tabs="tabs" class="overflow-auto">
      <TabPanel class="overflow-auto">
        <TableNewsSimple :data="coinNews" :paginate="getCoinNews" class="w-100 pb-10" />
      </TabPanel>
      <TabPanel class="overflow-auto">
        <TableNewsSimple :data="similarNews" :paginate="getSimilarNews" class="w-100 pb-10" />
      </TabPanel>
    </BaseTabs>
  </BaseDrawer>
</template>

<script>
import CellSentiment from '@/components/table/cell/CellSentiment.vue';
import ChartPriceMktSentiment from '@/components/chart/ChartPriceMktSentiment.vue';
import TableNewsSimple from '@/components/table/TableNewsSimple.vue';
import moment from 'moment';
import FilterMixin from '@/mixins/filters';
export default {
  mixins: [FilterMixin],
  components: {
    CellSentiment,
    ChartPriceMktSentiment,
    TableNewsSimple
  },
  mounted() {
    this.checkDataAvailable();
    this.checkToShowSimilarNews();
    this.loadSideBar();
  },
  props: {
    coin: {
      type: String,
      default: 'ethereum'
    },
    newsItem: {
      type: Object,
      default: () => {}
    },
    backOption: {
      type: Boolean,
      default: true
    },
    socialNews: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    coin: function (val) {
      if (val) {
        self.coinNews = [];
        self.similarNews = [];
        this.checkDataAvailable();
        this.loadSideBar();
      }

      this.checkToShowSimilarNews();
    },
    newsItem: function (val) {
      if (val) {
        this.selectedTimeframe = null;
        this.checkDataAvailable();
        this.loadSideBar();
      }
      this.checkToShowSimilarNews();
    },
    socialNews: function () {
      this.checkToShowSimilarNews();
    }
  },
  computed: {
    newsItemId() {
      return this.newsItem ? this.newsItem.id : null;
    },
    coinData() {
      const self = this;
      return self.$store.getters.coins.find(x => x.coin_uid === self.coin) || {};
    },
    tweetsChange() {
      return this.coinSinceData.tweet_volume / this.coinDataArticleTime.tweet_volume - 1;
    },
    volumeChange() {
      return this.coinSinceData.trading_volume / this.coinDataArticleTime.trading_volume - 1;
    },
    sentimentChange() {
      return this.coinSinceData.daily_sentiment / this.coinDataArticleTime.daily_sentiment - 1;
    },
    priceChange() {
      return this.coinSinceData.price / this.coinDataArticleTime.price - 1;
    },
    noSmaAlias() {
      return !this.coinData.tweet_volume || this.coinData.tweet_volume < 12;
    },
    filteredChartData() {
      return this.noSmaAlias ? { price: this.chartData.price, event: this.chartData.event } : this.chartData;
    }
  },
  methods: {
    setToolTip() {
      return !this.selectedTimeframe
        ? 'Since Article'
        : this.timeframes.find(x => x.id == this.selectedTimeframe).tooltip;
    },
    checkToShowSimilarNews() {
      if (!this.newsItem || this.socialNews) {
        this.tabs = this.tabValues.filter(r => {
          return r.id !== 'similar';
        });
        this.tab = 'recent';
      } else {
        this.tabs = this.tabValues;
      }
    },
    hide() {
      this.$store.dispatch('resetCoinSidebar');
    },
    loadSideBar() {
      this.getCoinNews();
      this.getSimilarNews();
      this.getChartData();
      this.getCoinDataAll();
    },
    async getCoinNews(pageParams = { page: 1, perPage: 20 }) {
      const self = this;
      if (self.coin != '') {
        let response = await this.$http.get(`/coin_news`, {
          params: { coin_uid: self.coin, page: pageParams.page, limit: pageParams.perPage }
        });
        if (pageParams.page == 1) {
          self.coinNews = response.data.data;
        } else {
          self.coinNews.push(...response.data.data);
        }
      }
      return pageParams;
    },
    getCoinData(timeframe) {
      const self = this;
      const timeStamp = this.newsItem ? this.newsItem.timestamp : '';
      this.coinSinceData = '';
      this.coinDataArticleTime = '';
      if (self.coin != '') {
        this.$http
          .get(`/coins/coin_info`, {
            params: {
              coin_uid: self.coin,
              since: timeStamp,
              to: timeframe
            }
          })
          .then(response => {
            // eslint-disable-next-line chai-friendly/no-unused-expressions
            timeframe ? (this.coinSinceData = response.data) : (this.coinDataArticleTime = response.data);
          });
      }
    },
    getCoinDataAll() {
      this.getCoinData(this.selectedTimeframe ? this.selectedTimeframe : '1D');
      this.getCoinData(null);
    },
    async getSimilarNews(pageParams = { page: 1, perPage: 20 }) {
      const self = this;
      if (self.newsItemId > 0 && !self.socialNews) {
        let response = await this.$http.get('/similar_news', {
          params: {
            news_item_id: self.newsItemId,
            page: pageParams.page,
            limit: pageParams.perPage
          }
        });
        if (pageParams.page == 1) {
          self.similarNews = response.data.data;
        } else {
          self.similarNews.push(...response.data.data);
        }
      }
      return pageParams;
    },
    async getChartData() {
      this.isPriceChartLoading = true;
      this.chartData = {};
      const timeStamp = this.newsItem ? this.newsItem.timestamp : '';
      try {
        const resp = await this.$http.get(`/price_sentiment_chart`, {
          params: {
            coin_uid: this.coin,
            newstime: timeStamp,
            to: this.selectedTimeframe ? this.selectedTimeframe : '1D'
          }
        });
        setTimeout(() => {
          this.chartData = resp.data;
          this.isPriceChartLoading = false;
        }, 1500);
      } catch (error) {
        this.chartData = {};
        this.isPriceChartLoading = false;
      }
    },
    handleAction(id, disable) {
      const timeStamp = this.newsItem ? this.newsItem.timestamp : '';
      if (timeStamp) {
        const date = moment(timeStamp, 'YYYY-MM-DD').toDate();
        const dif = parseInt(moment().diff(date, 'days'));
        if (id == '1D' && dif > 1 && !disable) {
          this.selectedTimeframe = id;
          this.loadSideBar();
        } else if (id == '3D' && dif > 3 && !disable) {
          this.selectedTimeframe = id;
          this.loadSideBar();
        } else if (id == '7D' && dif > 7 && !disable) {
          this.selectedTimeframe = id;
          this.loadSideBar();
        }
      }
    },
    checkDataAvailable() {
      this.timeframes.forEach(i => {
        i.disable = true;
      });
      const timeStamp = this.newsItem ? this.newsItem.timestamp : '';
      if (timeStamp) {
        const date = moment(timeStamp, 'YYYY-MM-DD').toDate();
        const dif = parseInt(moment().diff(date, 'days'));
        if (dif > 1) {
          this.selectedTimeframe = '1D';
          this.timeframes[0].disable = false;
        }
        if (dif > 3) {
          this.timeframes[1].disable = false;
        }
        if (dif > 7) {
          this.timeframes[2].disable = false;
        }
      }
    }
  },
  data() {
    return {
      coinNews: [],
      similarNews: [],
      tab: 'recent',
      chartData: {},
      isPriceChartLoading: true,
      coinSinceData: '',
      coinDataArticleTime: '',
      timeframes: [
        {
          id: '1D',
          text: '1d',
          disable: true,
          tooltip: '1 Day'
        },
        {
          id: '3D',
          text: '3d',
          disable: true,
          tooltip: '3 Days'
        },
        {
          id: '7D',
          text: '7d',
          disable: true,
          tooltip: '7 Days'
        }
      ],
      selectedTimeframe: null,
      tabValues: [
        { id: 'recent', label: 'Recent News' },
        { id: 'similar', label: 'Similar News' }
      ],
      tabs: []
    };
  }
};
</script>
