<template>
  <div class="flex space-x-3 text-sm text-white">
    <div v-for="(tag, index) in tags" :key="index" :class="colorMapping[tag]">
      {{ nameMapping[tag] }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CellDexTags',
  props: {
    tags: Array
  },
  data() {
    return {
      nameMapping: {
        early_holder: 'Early Holder',
        whale: 'Whale'
      },
      colorMapping: {
        early_holder: 'text-red-400',
        whale: 'text-purple-400'
      }
    };
  }
};
</script>
