<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :overrides="overrides"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      @expand="goToExpandLink"
    >
      <template #body>
        <template v-if="selectedToken">
          <div>
            <div :class="width > 350 ? 'flex justify-between' : ''" class="mt-2 pb-4">
              <div class="flex items-center space-x-4 bg-gray-900 px-2 pt-1">
                <div
                  v-for="tf in views"
                  :key="tf.id"
                  @click="selectedView = tf.id"
                  class="cursor-pointer rounded text-xs transition-all ease-linear"
                  :class="tf.id === selectedView ? 'font-semibold text-gray-100' : 'text-gray-400 hover:text-gray-100'"
                >
                  {{ tf.title }}
                </div>
              </div>
              <div class="flex items-center space-x-4 bg-gray-900 px-2 pt-1">
                <div
                  v-for="tf in tags"
                  :key="tf.id"
                  @click="selectedTag = tf.id"
                  class="cursor-pointer rounded text-xs transition-all ease-linear"
                  :class="tf.id === selectedTag ? 'font-semibold text-gray-100' : 'text-gray-400 hover:text-gray-100'"
                >
                  {{ tf.title }}
                </div>
              </div>
            </div>
            <TableBase
              v-if="selectedView == 'table'"
              :config="config"
              :data="filteredData"
              :loading="tableLoader"
              :paginate="getHolderData"
              :key="selectedTag"
              :reset-pagination-page="false"
            />
            <div v-if="selectedView == 'chart'" class="flex overflow-auto pt-4">
              <ChartPie v-if="filteredPieData.length" :series="filteredPieData" :truncate-length="25" />
              <NoDataFound v-else class="flex-1" />
            </div>
          </div>
        </template>
        <template v-else>
          <WidgetsErrorMessage>
            <span v-if="notSupported">Token does not support On-Chain Ownership</span>
            <span v-else>Select a token to load ownership data</span>
          </WidgetsErrorMessage>
        </template>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';
import { orderBy, uniqBy, sumBy } from 'lodash';
import ChartPie from '@/components/chart/ChartPie.vue';
export default {
  name: 'WidgetsOnChainOwnership',
  components: {
    ChartPie
  },
  mixins: [WidgetsMixin],
  mounted() {
    if (this.widget?.configuration) {
      this.topAddresses = this.widget.configuration?.top_addresses;
      this.addressOwnership = this.widget.configuration?.address_ownership;
      this.selectedView = this.widget.configuration?.selected_view
        ? this.widget.configuration.selected_view
        : this.selectedView;
      this.selectedTag = this.widget.configuration?.selected_tag
        ? this.widget.configuration.selected_tag
        : this.selectedTag;
    }
    this.getTokens();
    this.getTokenData();
    this.getHolderData();
  },
  computed: {
    tableData() {
      return uniqBy(
        this.holderData.map(x => {
          return {
            address: x.public_tag || x.address,
            quantity: x.amount,
            tag: this.tagsMap[x.tag[0]],
            price: this.tokenData.tokenPrice * x.amount,
            ownership: x.amount / this.tokenData.totalSupply,
            link: {
              link: 'https://etherscan.io/address/' + x.address,
              text: 'View Wallet'
            },
            linkAddress: x.address
          };
        }),
        'address'
      );
    },
    filteredData() {
      const self = this;
      if (this.selectedTag == 'top_holders') {
        return orderBy(this.tableData, 'quantity', 'desc');
      } else {
        return orderBy(
          this.tableData.filter(item => item.tag && item.tag.includes(self.tagsMap[this.selectedTag])),
          'quantity',
          'desc'
        );
      }
    },
    pieData() {
      const self = this;
      return self.holderData
        .map(x => {
          return {
            name: x.public_tag || x.address,
            y: (x.amount / self.tokenData.totalSupply) * 100,
            tag: x.tag[0],
            amount: x.amount,
            address: x.address
          };
        })
        .slice(0, 10);
    },
    filteredPieData() {
      const self = this;
      let data = this.pieData;
      data = this.topAddresses ? data.slice(0, this.topAddresses) : data;
      data = uniqBy(data, 'name');
      data = orderBy(
        data.filter(item => item.y >= self.addressOwnership),
        'y',
        'desc'
      );
      if (data.length > 5) {
        let top5 = data.splice(0, 5);
        top5.push({
          name: 'Others',
          y: sumBy(data, 'y')
        });
        return top5;
      } else {
        return data;
      }
    }
  },
  data: function () {
    return {
      notSupported: false,
      selectedToken: null,
      selectedView: 'table',
      views: [
        { id: 'table', title: 'Table' },
        { id: 'chart', title: 'Chart' }
      ],
      tags: [
        { id: 'top_holders', title: 'Top Holders' },
        { id: 'genesis', title: 'Genesis Wallets' },
        { id: 'earlyHolder', title: 'Early Holders' }
      ],
      selectedTag: 'top_holders',
      tagsMap: { genesis: 'Genesis', earlyHolder: 'Early Holder' },
      loading: false,
      pieLoader: true,
      tableLoader: true,
      config: {
        alternateBg: true,
        rowClass: 'py-2 hover:bg-gray-800 duration-100',
        horizontalScroll: false,
        noXOverflow: true,
        useParentContainer: true,
        hideLoadMore: true,
        headerClass: 'py-8 sticky top-0 bg-gray-900 capitalize',
        perPage: 100,
        page: 1,
        cols: [
          {
            type: 'index',
            size: 1,
            name: 'Rank',
            headerClass: 'border-b-0',
            cellClass: 'py-2 w-full'
          },
          {
            type: 'truncate_text',
            size: 5,
            name: 'Address',
            id: 'address',
            headerClass: 'border-b-0',
            truncateLength: 15
          },
          {
            type: 'dollar_format',
            size: 1,
            name: 'Quantity in USD',
            headerClass: 'border-b-0',
            id: 'price',
            cellClass: 'w-10'
          },
          {
            type: 'number',
            size: 4,
            name: 'Quantity',
            id: 'quantity',
            decimals: 2,
            headerClass: 'border-b-0'
          },
          {
            type: 'small_percent',
            size: 3,
            name: 'Ownership %',
            id: 'ownership',
            multiply: true,
            headerClass: 'border-b-0 text-right'
          },
          {
            type: 'text',
            size: 3,
            name: 'Tags',
            id: 'tag',
            headerClass: 'border-b-0 text-right',
            cellClass: 'text-right '
          },
          {
            type: 'link',
            size: 3,
            name: 'Etherscan',
            id: 'link',
            headerClass: 'border-b-0',
            cellClass: 'w-10'
          }
        ]
      },
      holderData: [],
      tokenData: {},
      topAddresses: '',
      addressOwnership: ''
    };
  },
  watch: {
    'widget.configuration'() {
      this.loading = true;
      this.setTokenFromConfig();
      this.setFiltersFromConfig();
      this.getTokenData();
      this.getHolderData();
    },
    connectedToChannel(newVal) {
      if (!newVal) {
        this.setTokenFromConfig();
      } else {
        this.setTokenFromChannel();
      }
    },
    widgetChannelOutput() {
      this.setTokenFromChannel();
    },
    selectedTag() {
      this.getHolderData();
      this.updateWidgetConfig();
    },
    selectedToken() {
      this.loading = true;
      this.getTokenData().then(() => {
        this.getHolderData();
      });
    },
    selectedView() {
      this.updateWidgetConfig();
    }
  },
  methods: {
    async getTokenData() {
      let response;
      const token = this.selectedToken?.token;
      try {
        if (token) {
          this.tableLoader = true;
          response = await this.$http.get('/token_data', {
            params: {
              address: token
            }
          });
          this.tokenData = response.data;
          this.tableLoader = false;
        }
      } catch (error) {
        this.tableLoader = false;
      }
    },
    async getTokens() {
      let response;
      const config = this.widget.configuration;
      if (config && config.token) {
        response = await this.$http.get('/coins_token');
        this.onChainTokens = response.data;
        this.$nextTick(() => {
          this.setToken();
        });
      }
    },
    async getHolderData(pageParams = { page: 1, perPage: 100 }) {
      const token = this.selectedToken?.token;
      let response;
      this.tableLoader = true;
      if (pageParams.page == 1) {
        this.pieLoader = true;
      }
      try {
        if (token) {
          response = await this.$http.get('/holder_data', {
            params: {
              page: pageParams.page,
              limit: pageParams.perPage,
              tag: this.selectedTag == 'top_holders' ? '' : this.selectedTag,
              address: token
            }
          });
        }
        if (pageParams.page == 1) {
          this.holderData = response.data.result;
        } else {
          this.holderData.push(...response.data.result);
        }
        if (!response.data.nextPage) {
          pageParams.stopCall = true;
        }
        this.loading = false;
        this.pieLoader = false;
        this.tableLoader = false;
      } catch {
        this.holderData = [];
        this.tableLoader = false;
        this.loading = false;
        this.pieLoader = false;
      }
      return pageParams;
    },
    setToken() {
      this.showError = false;
      if (this.connectedToChannel) {
        this.setTokenFromChannel();
      } else {
        this.setTokenFromConfig();
      }
    },
    setTokenFromChannel() {
      if (this.widgetChannelOutput) {
        const coin = this.widgetChannelOutput[0];
        const onChainToken = this.onChainTokens?.find(x => x.coin_uid == coin.coin_uid);
        if (onChainToken) {
          this.selectedToken = onChainToken;
        } else {
          this.selectedToken = null;
          this.showError = true;
          this.notSupported = true;
        }
      } else {
        if (this.connectedToChannel) {
          this.selectedToken = null;
        }
        this.showError = true;
      }
    },
    setTokenFromConfig() {
      if (this.widget.configuration && this.widget.configuration.token) {
        this.selectedToken = this.widget.configuration.token;
      } else {
        this.showError = true;
      }
    },
    setFiltersFromConfig() {
      if (this.widget.configuration && this.widget.configuration.top_addresses) {
        this.topAddresses = this.widget.configuration.top_addresses;
      }
      if (this.widget.configuration && this.widget.configuration.address_ownership) {
        this.addressOwnership = this.widget.configuration.address_ownership;
      }
    },
    async updateWidgetConfig() {
      if (!this.viewOnlyMode) {
        if (this.subscribeOnlyMode) {
          await await this.$http.post('/widget_overrides', {
            widget_override: {
              dashboard_widget_id: this.widget.id,
              overrides: {
                token: this.selectedToken
              }
            }
          });
        } else {
          let payload = {
            configuration: {
              ...this.widget.configuration,
              token: this.selectedToken,
              selected_view: this.selectedView,
              selected_tag: this.selectedTag
            }
          };
          if (this.selectedToken) {
            await this.$http.patch(`/dashboard_widgets/${this.widget.id}`, payload);
          }
        }
      }
    },
    goToExpandLink() {
      window.open(`/coin/${this.widget.configuration.coin_uid}`, '_blank');
    }
  }
};
</script>
