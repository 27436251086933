<template>
  <div class="relative flex w-full items-center justify-center" :style="`height: ${height}`">
    <div :id="randomId" class="w-full" :style="`height: ${height}`"></div>
    <!-- <NoDataFound v-else class="mt-4 pb-2" /> -->
  </div>
</template>
<script>
import Highcharts from 'highcharts';
import moment from 'moment';
import global from '@/mixins/global';
import colors from 'tailwindcss/colors';
import FilterMixin from '@/mixins/filters';
export default {
  mixins: [FilterMixin, global],
  components: {},
  mounted() {
    const self = this;
    if (this.series) {
      self.generateChart();
    }
    self.$eventHub.$on('reflow-highchart', function () {
      self.$nextTick(() => {
        self.chartObj.reflow();
      });
    });
  },
  beforeUnmount() {
    this.$eventHub.$off('reflow-highchart');
  },
  props: {
    series: {
      type: [Object, Array],
      default: () => []
    },
    config: {
      type: Object,
      default: () => {
        return { type: 'line', animation: true };
      }
    },
    height: {
      type: String,
      default: '100%'
    }
  },

  watch: {
    series() {
      this.generateChart();
    }
  },

  computed: {
    chartConfig() {
      const self = this;
      return {
        chart: {
          type: this.config.type || 'line',
          backgroundColor: 'transparent',
          zoomType: '',
          style: {
            position: 'inherit'
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          lineColor: colors.gray[600],
          visible: this.config.xAxis,
          labels: {
            enabled: true,
            style: {
              color: colors.gray[200],
              fontSize: '12px',
              fontWeight: 'bold'
            },
            formatter: function () {
              return Highcharts.dateFormat(self.config.xAxisFormat || '%b %e', this.value);
            }
          },
          lineWidth: this.config.xAxisLineWidth || 0,
          tickLength: 0
        },
        yAxis: {
          visible: this.config.yAxis,
          gridLineColor: colors.gray[800],
          lineColor: colors.gray[600],
          scaleShowLabels: true,
          title: {
            text: self.config.yAxisTitle || '',
            style: {
              color: colors.gray[300],
              fontSize: '14px'
            },
            margin: 20
          },
          labels: {
            enabled: this.config.yAxisLabels || false,
            style: {
              color: colors.gray[200],
              fontSize: '12px',
              fontWeight: 'bold'
            }
          }
        },
        title: {
          text: this.config.graphTitle || '',
          style: {
            fontWeight: 'bold',
            color: colors.gray[200],
            fontSize: '14px'
          }
        },
        time: {
          useUTC: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          shared: true,
          useHTML: true,
          backgroundColor: null,
          style: {
            color: 'white',
            pointerEvents: 'auto'
          },
          borderWidth: 0,
          formatter: function () {
            const date = moment(this.x).format(self.config.yAxisFormat || 'MMM DD');
            const tipHtml = `<div class='max-w-md break-words rounded border border-gray-700 bg-gray-800 px-4 py-2 text-left text-gray-200'><b>${date}</b></br>`;
            const fromattedPnts = this.points.map(point => {
              let roundTo = point.y > 1 ? 2 : 6;
              var value = self.formatNumber(point.y, roundTo);
              const pointSymb = this.points.length === 1 ? '' : `<span style="color:${point.color}">●</span>`;
              return (
                pointSymb +
                `<b> ${self.config.yAxisTitle}:</b> <span style="color:${point.color}; margin-left: 0.5rem;">${
                  self.config.dollarSignInTooltip ? '$' : ''
                }${value}</span> </div>`
              );
            });

            return tipHtml + fromattedPnts.join('<br/>');
          }
        },
        plotOptions: {
          series: {
            color: this.config.color || colors.blue[500],
            marker: {
              enabled: false
            },
            animation: this.config.animation
          },
          line: {
            animation: this.config.animation
          }
        },
        exporting: {
          enabled: false,
          fallbackToExportServer: false,
          filename: this.config.csvFilename,
          chartOptions: {
            chart: {
              backgroundColor: colors.gray[900]
            },
            title: {
              text: this.config.csvFilename + ' - ' + this.config.mentionPhrase,
              style: {
                color: colors.gray[300],
                fontWeight: 'bold',
                fontSize: '14px'
              }
            }
          },
          csv: {
            columnHeaderFormatter: function (item, key) {
              if (!key) {
                return 'Date Time';
              }
              return self.config.yAxisTitle + ' - ' + self.config.mentionPhrase;
            }
          }
        }
      };
    }
  },
  data() {
    return {
      chartObj: undefined,
      randomId: Math.random().toString()
    };
  },
  methods: {
    generateChart() {
      const self = this;
      let config = self.chartConfig;
      if (self.config.customPosition) {
        config.tooltip.positioner = function (labelWidth, labelHeight, point) {
          return {
            x: point.plotX - 20,
            y: point.plotY - 55
          };
        };
      }
      if (!Array.isArray(self.series)) {
        config.series = [self.series];
      } else {
        config.series = self.series;
      }
      self.chartObj = Highcharts.chart(self.randomId, config);
      self.$emit('chart', self.chartObj);
    }
  }
};
</script>
