<template>
  <BaseDialog :model-value="modal.status" size-class="!max-w-xl" @close="close">
    <template #header v-if="!hasNoWatchlists">
      <template v-if="entity == 'coins'">
        <div class="flex items-center">
          <span class="font-medium">Select watchlist(s) to {{ modal.payload.mode }}</span>
          <div class="ml-2 flex items-center">
            <BaseCoinIcon :coin="payload.uid" class="h-5 w-5" />
            <span class="font-medium">{{ payload.name }}</span>
          </div>
        </div>
      </template>
      <template v-if="entity == 'companies'">
        Select watchlist(s) to {{ modal.payload.mode }} {{ payload.name }}
      </template>
      <template v-if="entity == 'apefluencer_accounts'">
        Select watchlist(s) to {{ modal.payload.mode }} @{{ payload.screen_name }}
      </template>
    </template>
    <template #default>
      <template v-if="hasNoWatchlists && watchlistcall">
        <div class="items-center text-center">
          <p>Looks like you don't have any watchlists yet.</p>
          <BaseButton @press="createWatchlist" class="mx-auto my-4">Create a Watchlist</BaseButton>
        </div>
      </template>
      <template v-else-if="watchlistOptions.length == 0 && watchlistcall">
        <div class="my-4 flex items-center text-xs">
          <IconInfo class="mr-1 text-blue-300" />
          {{ infoMessage }}
        </div>
      </template>
      <template v-else>
        <div class="py-4">
          <BaseTags :tags="watchlistOptions" :selected="selectedWatchlists" @toggle="toggle" />
        </div>
      </template>
    </template>
    <template #footer v-if="!hasNoWatchlists">
      <div class="flex w-full justify-between">
        <div>
          <BaseButton type="secondary" @press="close">Cancel</BaseButton>
        </div>
        <div class="flex space-x-2">
          <BaseButton type="primary" @press="createWatchlist()" v-if="addMode"> Create Watchlist </BaseButton>
          <BaseButton
            class="ml-2 whitespace-nowrap"
            :busy="buttonBusy"
            :disabled="buttonDisabled"
            @press="addMode ? update() : remove()"
          >
            <template v-if="selectedWatchlists.length">
              {{ addMode ? 'Add to ' : 'Remove from ' }}
              {{ selectedWatchlists.length }} watchlists
            </template>
            <template v-else> No watchlist selected </template>
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { ref, watch, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { usePage } from '@inertiajs/inertia-vue3';
import { Inertia } from '@inertiajs/inertia';

const $store = useStore();
const $http = inject('http');
const $eventHub = inject('eventHub');

//MODAL
const modal = computed(() => {
  return $store.getters.addToWatchlistModal ? $store.state.modal : { status: false };
});
watch(modal, () => {
  if (modal.value.status) {
    loadWatchlists();
  }
});

const payload = computed(() => {
  return modal.value.payload?.payload || {};
});
const addMode = computed(() => {
  return modal.value.payload?.mode == 'add' || false;
});
const entity = computed(() => {
  return modal.value.payload ? modal.value.payload.entity : null;
});

const hasNoWatchlists = computed(() => {
  return allWatchlists.value.length == 0;
});
const infoMessage = computed(() => {
  let msg = 'Please create a watchlist first.';
  if (watchlistOptions.value.length == 0) {
    msg = addMode.value ? 'Already included in every watchlist.' : 'Not included in any watchlist yet.';
  }
  return msg;
});
function close() {
  allWatchlists.value.forEach(w => {
    w.enabled = false;
  });
  $store.commit('closeModal');
}

//BUTTON STATES
const buttonBusy = ref(false);
const buttonDisabled = computed(() => {
  return selectedWatchlists.value.length == 0;
});

//REQUEST PAYLOAD
function prepareRequestPayload() {
  return {
    watchlist_ids: selectedWatchlists.value,
    entity: modal.value.payload.entity,
    entity_id: modal.value.payload.entity == 'coins' ? payload.value.uid : payload.value.id,
    entity_info: modal.value.payload.payload
  };
}

//WATCHLISTS ACTIONS
const watchlistcall = ref(false);
const allWatchlists = ref([]);

const selectedWatchlists = computed(() => {
  return watchlistOptions.value
    .filter(w => {
      return w.enabled;
    })
    .map(m => m.id)
    .flat();
});
const watchlistOptions = computed(() => {
  if (addMode.value) {
    return allWatchlists.value
      .filter(x => !x.is_subscribed)
      .filter(x => !payload.value.watchlist_ids || !payload.value.watchlist_ids.includes(x.id));
  } else {
    return allWatchlists.value
      .filter(x => !x.is_subscribed)
      .filter(x => payload.value.watchlist_ids && payload.value.watchlist_ids.includes(x.id));
  }
});

function toggle(t) {
  allWatchlists.value = allWatchlists.value.map(i => {
    if (i.id === t) {
      i.enabled = !i.enabled;
    }
    return i;
  });
}
function createWatchlist() {
  window.localStorage.removeItem('prefillWatchlistId');
  window.localStorage.setItem('prefillWatchlistEntityIds', [modal.value.payload.payload.id]);
  window.localStorage.setItem('prefillWatchlistType', modal.value.payload.entity);
  close();
  Inertia.visit('/watchlists/create');
}
async function loadWatchlists() {
  let response = await $http.get(`/watchlists?type=${modal.value.payload.entity}`);
  allWatchlists.value = response.data.map(x => {
    return {
      ...x,
      title: x.name,
      is_subscribed: x.user_entity_permissions.length
        ? x.user_entity_permissions[0].can_read && !x.user_entity_permissions[0].can_write
        : false
    };
  });
  watchlistcall.value = true;
}
async function update() {
  const requestPayload = prepareRequestPayload();
  if (addMode.value) {
    buttonBusy.value = true;
    await $http.post('/watchlist_entity', requestPayload);
    buttonBusy.value = false;
    close();
    refreshPage();
  }
}
async function remove() {
  const requestPayload = prepareRequestPayload();
  await $http.delete(`/watchlist_entity/${requestPayload.entity_id}`, { params: requestPayload });

  close();
  refreshPage();
}

function refreshPage() {
  const url = usePage().url.value;
  if (url.includes('screener')) {
    window.location.href = '/screener';
  } else if (url.includes('coins')) {
    window.location.href = '/coins';
  } else if (url.includes('companies')) {
    window.location.href = '/companies';
  } else if (url.includes('tools')) {
    window.location.href = '/tools/governance';
  } else if (url.includes('news')) {
    window.location.href = '/news';
  }
}
</script>
