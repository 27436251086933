<template>
  <div class="h-full">
    <TableBase
      :config="config"
      :data="filteredNotableData"
      :loading="loading"
      :paginate="getTransactions"
      :key="filteredNotableData.length"
      :reset-pagination-page="false"
    />
  </div>
</template>

<script>
export default {
  name: 'TableDexTrades',
  mounted() {
    this.getTransactions();
  },
  props: {
    token: {
      type: String,
      default: null
    },
    filters: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: true,
      showFilterModal: false,
      config: {
        alternateBg: false,
        hideLoadMore: true,
        rowClass: 'py-2',
        headerClass: 'sticky top-0 z-20',
        perPage: 100,
        page: 1,
        horizontalScroll: true,
        cols: [
          {
            type: 'buysell',
            size: 4,
            name: 'Type',
            id: 'trxType',
            cellClass: 'text-center',
            headerClass: 'text-center w-24'
          },
          {
            type: 'text',
            size: 4,
            name: 'Block Number',
            id: 'blockNumber',
            cellClass: 'text-center',
            headerClass: 'text-center w-10'
          },
          {
            type: 'dollar_format',
            size: 4,
            name: 'Trade Size',
            id: 'estimatedDollarAmount',
            headerClass: 'w-28'
          },
          {
            type: 'truncate_text',
            name: 'From',
            id: 'from',
            cellClass: 'text-right w-40',
            headerClass: 'text-right',
            truncateLength: 25
          },
          {
            type: 'eth_type',
            name: 'In',
            id: 'in',
            cellClass: 'w-32'
          },
          {
            type: 'eth_type',
            name: 'Out',
            id: 'out',
            cellClass: 'w-40 text-center pr-5',
            headerClass: 'text-center pr-5'
          },
          {
            type: 'profile',
            name: 'Dex',
            nameCharacterLimit: 15,
            id: 'dexUsed',
            cellClass: 'text-right w-40',
            headerClass: 'text-center'
          },
          {
            type: 'url',
            size: 3,
            name: 'Tx',
            id: 'link',
            headerClass: 'w-10'
          }
        ]
      },
      notableData: [],
      tokens: {},
      transactionSize: this.filters.transactionSize,
      holderSize: this.filters.holderSize,
      coinSymbol: { 'SushiSwap-V2': 'sushi', 'UNI-V2': 'uniswap', 'UNI-V3': 'uniswap' }
    };
  },
  computed: {
    filteredNotableData() {
      let data = [];
      const self = this;
      this.notableData.forEach(i => {
        i.swapResults
          ? i.swapResults.forEach(key => {
              data.push({
                ...i,
                ...key,
                link: 'https://etherscan.io/tx/' + i.hash,
                dexUsed: {
                  imageUrl: `https://terminal.cloudfront.thetie.io/coin_images/${this.coinSymbol[key.dexUsed]}.png`,
                  name: key.dexUsed,
                  defaultImage: true
                },
                in: self.tokens[key['fromContract']]
                  ? key['fromTokenAmount'] * Math.pow(10, -self.tokens[key['fromContract']].decimal)
                  : 0,
                insymbol: self.tokens[key['fromContract']] ? self.tokens[key['fromContract']].symbol || '' : '',
                out: self.tokens[key['toContract']]
                  ? key['toTokenAmount'] * Math.pow(10, -self.tokens[key['toContract']].decimal)
                  : 0,

                outsymbol: self.tokens[key['toContract']] ? self.tokens[key['toContract']].symbol : ''
              });
            })
          : data.push({
              ...i,
              link: 'https://etherscan.io/tx/' + i.hash,
              tx: 'Transfer',
              tokenAmount: i.tokenAmount,
              tokenAmountsymbol: i.tokenSymbol,
              dexUsed: {}
            });
      });
      return data.filter(i => i.dexUsed.name != 'noneOnRecord');
    }
  },
  watch: {
    filters() {
      this.transactionSize = this.filters.transactionSize;
      this.holderSize = this.filters.holderSize;
      this.loading = true;
      this.getTransactions();
    },
    token() {
      this.getTransactions();
    }
  },
  methods: {
    async getTransactions(pageParams = { page: 1, perPage: 100 }) {
      const self = this;
      self.loading = true;
      try {
        let response = await this.$http.get('/notable_transactions', {
          params: {
            address: self.token,
            dex_trades: true,
            page: pageParams.page,
            limit: pageParams.perPage,
            transaction_size: self.transactionSize,
            holder_size: self.holderSize
          }
        });
        if (pageParams.page == 1) {
          self.config.page = 1;
          self.notableData = !response.data.transactions.includes('No transactions') ? response.data.transactions : [];
        } else {
          self.config.page++;
          !response.data.transactions.includes('No transactions')
            ? self.notableData.push(...response.data.transactions)
            : [];
        }
        self.tokens = { ...self.tokens, ...response.data.tokens };
        if (!response.data.nextPage || response.data.transactions.includes('No transactions')) {
          pageParams.stopCall = true;
        }
        self.loading = false;
      } catch {
        self.notableData = self.notableData.length ? self.notableData : [];
        self.loading = false;
      }
      return pageParams;
    }
  }
};
</script>
