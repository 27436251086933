<template>
  <div
    class="relative mb-1 flex items-center justify-center rounded-full"
    :class="[
      !imageUrl && !onlyPreview ? 'border-2 border-dashed border-gray-600 bg-gray-800 opacity-80' : '',
      settingsModal || onlyPreview ? (onlyPreview && !imageUrl ? 'h-36 w-36' : 'h-40 w-40') : 'h-32 w-32'
    ]"
    @drop.prevent="onDrop"
  >
    <div v-if="imageUrl && onlyPreview" class="absolute bottom-0 right-0 mr-3 mb-3">
      <div
        :class="user.online ? 'bg-green-600' : 'bg-gray-600'"
        class="h-6 w-6 rounded-full border-2 border-gray-900"
      ></div>
    </div>
    <img v-if="imageUrl" :src="imageUrl" class="h-full w-full rounded-full bg-gray-700 object-cover" />
    <div v-else-if="onlyPreview && !imageUrl">
      <ChatImage :item="user" size="xxl" :show-online="true"> </ChatImage>
    </div>
    <div v-else-if="!onlyPreview" class="flex-col space-y-2 text-xs">
      <input type="file" ref="file" accept="image/png, image/jpeg" style="display: none" @change="onFileChange" />
      <div class="relative cursor-pointer p-1 pt-1.5 text-gray-600" @click="triggerFileSelector()">
        <IconImage class="h-10 w-10" />
        <IconPlusCircle class="absolute top-0 right-0" />
      </div>
    </div>
  </div>
  <span
    v-if="imageError && imageError.length > 0"
    class="flex items-center justify-center text-center text-xs text-red-400"
  >
    {{ imageError }}
  </span>
  <div class="mt-3.5 flex w-40 items-center justify-center text-xs" v-if="!onlyPreview">
    <input type="file" accept="image/png, image/jpeg" ref="file" style="display: none" @change="onFileChange" />
    <BaseButton type="secondary" size="sm" @press="triggerFileSelector()"> Select File </BaseButton>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, onUnmounted, computed } from 'vue';
import { useStore } from 'vuex';
import ChatImage from './ChatImage.vue';
const store = useStore();
const props = defineProps({
  user: { type: Object, default: () => {} },
  settingsModal: { type: Boolean, default: false }
});
const $emit = defineEmits(['selected-image']);
const imageError = ref('');
const imageUrl = ref('');
const file = ref(null);
const events = reactive(['dragenter', 'dragover', 'dragleave', 'drop']);
const acceptabelImageTypes = reactive(['image/png', 'image/jpeg']);
onMounted(() => {
  imageUrl.value = props.user.image;
  events.forEach(eventName => {
    document.body.addEventListener(eventName, preventDefaults);
  });
});

onUnmounted(() => {
  events.forEach(eventName => {
    document.body.removeEventListener(eventName, preventDefaults);
  });
});

const onlyPreview = computed(() => {
  return store.getters.client.user.id !== props.user.id;
});

const editMode = computed(() => {
  return store.getters.client.user.id === props.user.id;
});

function onFileChange(e) {
  const file = e.target.files[0];
  setFile(file);
}

function setFile(file) {
  if (file.size > 5242880) {
    imageError.value = 'Image should be less than 5 MB.';
  } else if (!acceptabelImageTypes.includes(file.type)) {
    imageError.value = 'Invalid file format.';
  } else {
    imageError.value = '';
    imageUrl.value = URL.createObjectURL(file);
    $emit('selected-image', { imageUrl: imageUrl.value, file: file });
  }
}

function onDrop(e) {
  setFile([...e.dataTransfer.files][0]);
}

function preventDefaults(e) {
  e.preventDefault();
}

function triggerFileSelector() {
  file.value.click();
}
</script>
