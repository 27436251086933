<template>
  <div class="flex items-center">
    <component :is="item.icon"></component>
    <p class="max-w-sm line-clamp-3">{{ item.text }}</p>
  </div>
</template>
<script>
export default {
  name: 'CellSocialMediaPlatform',
  props: {
    item: Object
  }
};
</script>
