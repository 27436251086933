<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99991 9.29731C6.62722 9.29731 5.51343 8.18463 5.51343 6.81083C5.51343 5.43702 6.62722 4.32434 7.99991 4.32434C9.37261 4.32434 10.4864 5.43702 10.4864 6.81083C10.4864 8.18463 9.37261 9.29731 7.99991 9.29731ZM6.91883 6.81083C6.91883 7.40814 7.40309 7.89191 7.99991 7.89191C8.59674 7.89191 9.081 7.40814 9.081 6.81083C9.081 6.21352 8.59674 5.72975 7.99991 5.72975C7.40309 5.72975 6.91883 6.21352 6.91883 6.81083Z"
      fill="rgb(148 163 184)"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 6.81412L4.75676 2.05408H11.2432L16 6.81412L8 14.8108L0 6.81412ZM11.6757 5.08108C9.65456 3.05109 6.34544 3.0511 4.32432 5.08109L2.59459 6.81084L4.32432 8.54059C6.34544 10.5706 9.65456 10.5706 11.6757 8.54057L13.4054 6.81084L11.6757 5.08108Z"
      fill="rgb(148 163 184)"
    ></path>
  </svg>
</template>
