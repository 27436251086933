<template>
  <BaseDialog :model-value="show" size-class="!max-w-4xl" @update:model-value="close" z-index="z-100">
    <template #header> Reduce Coins </template>
    <template #default>
      <div class="mb-4 text-sm text-gray-400">
        {{ message }}
      </div>
      <div class="mb-2 flex space-x-2">
        <div
          v-for="op in options"
          @click="selectOption(op.id)"
          :key="op.id"
          class="cursor-pointer rounded bg-gray-800 px-2 py-0.5 text-xs font-medium text-gray-400 duration-100 hover:bg-blue-900 hover:text-blue-100"
        >
          {{ op.text }}
        </div>
      </div>
      <div class="max-h-96 overflow-y-scroll">
        <TableCoinSelect :filtered-coins="filteredCoinData" @select="selectCoin" @remove="removeCoin" />
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-end space-x-2">
        <BaseButton type="secondary" size="sm" @press="close">Cancel</BaseButton>
        <BaseButton size="sm" :disabled="!selectedCoins.length" @press="$emit('reduced', selectedCoins)">
          Use {{ selectedCoins.length }} Coins
        </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script setup>
import TableCoinSelect from '../table/TableCoinSelect.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
const emit = defineEmits(['close', 'reduced']);
const $store = useStore();
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  coins: {
    type: Array,
    default: () => []
  },
  message: String
});

const max = ref(15);
const selectedCoins = ref([]);
const options = ref([
  { id: 'market_cap', text: `Top ${max.value} by Market Cap` },
  { id: 'daily_sentiment', text: `Top ${max.value} by Daily Sentiment` },
  { id: 'trading_volume', text: `Top ${max.value} by Trading Volume` }
]);

const filteredCoinData = computed(() => {
  return $store.state.data.coins
    .filter(coin => {
      return props.coins.includes(coin.uid);
    })
    .map(coin => {
      return {
        ...coin,
        checked: selectedCoins.value.includes(coin.uid),
        checkDisabled: selectedCoins.value.includes(coin.uid) ? false : selectedCoins.value.length >= max.value
      };
    });
});

function selectOption(id) {
  const newSelectedCoins = [];

  const sortedByMetric = filteredCoinData.value.sort((a, b) => {
    return Number(b[id]) - Number(a[id]);
  });

  for (let i = 0; i < Math.min(sortedByMetric.length, max.value); i++) {
    newSelectedCoins.push(sortedByMetric[i].uid);
  }

  selectedCoins.value = newSelectedCoins;
}
function close() {
  emit('close');
}
function selectCoin(uid) {
  selectedCoins.value.push(uid);
}
function removeCoin(uid) {
  selectedCoins.value = selectedCoins.value.filter(coin => {
    return uid !== coin;
  });
}
</script>
