<template>
  <BaseDialog :model-value="show" size-class="!max-w-xl" @update:model-value="$emit('close')">
    <template #header> We'd love to hear your feedback </template>
    <template #default>
      <BaseTextarea v-model="feedbackBody" rows="5" placeholder="Ran into an issue or have product suggestions?" />
    </template>
    <template #footer>
      <div class="flex w-full flex-col">
        <div class="flex items-center justify-between text-sm">
          <div class="text-gray-100">Want to chat about an issue or idea?</div>
          <a
            :href="savvycalLink"
            data-savvycal-embed="data-savvycal-embed"
            @click="$emit('close')"
            class="ml-1 text-blue-300"
            >Schedule Feedback Call</a
          >
        </div>
        <div class="mt-5 flex w-full items-end justify-end space-x-2">
          <BaseButton type="secondary" @press="$emit('close')">Cancel</BaseButton>
          <BaseButton :busy="buttonBusy" :disabled="buttonBusy" @press="sendFeedback"> Send Feedback </BaseButton>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
export default {
  name: 'ModalFeedback',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    savvycalLink: {
      type: String
    }
  },
  data() {
    return {
      feedbackBody: '',
      buttonBusy: false
    };
  },
  methods: {
    sendFeedback() {
      if (this.feedbackBody.trim().length > 0) {
        this.buttonBusy = true;
        this.$http.post('/feedback', { feedback: this.feedbackBody });
        this.buttonBusy = false;
        this.feedbackBody = '';
        this.$emit('close');
      }
    }
  }
};
</script>
