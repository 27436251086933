<template>
  <div class="relative inline-block text-left">
    <div :id="dropdownId">
      <div
        class="flex w-full cursor-pointer select-none items-center justify-center space-x-0.5 text-xs"
        @click="toggleDropdown"
      >
        <div class="flex h-2 w-2 rounded-full bg-red-400"></div>
        <span class="whitespace-nowrap" v-if="Object.keys(gasFees).length > 0">
          <span class="text-gray-300">Gas:</span> {{ decimals(gweiFee.fast.gwei, 0, 0) }}
        </span>
        <span class="whitespace-nowrap" v-else><span class="text-gray-300">Gas:</span> n/a</span>&nbsp;<span
          class="font-mono text-xs text-gray-400"
        ></span>
        <IconChevronDown :class="{ 'rotate-180': showDropdown }" class="-ml-0.5 transform duration-100 ease-in-out" />
      </div>

      <div v-if="gasFees.length != 0 && showDropdown" class="relative">
        <div class="fixed inset-0 z-40" @click="closeGasFee()"></div>
        <div class="absolute right-0 z-50 mt-1">
          <div class="flex flex-col rounded border border-grid bg-gray-800 p-3">
            <table>
              <thead>
                <tr class="border-none">
                  <th>
                    <div class="items-center">
                      <BaseButton type="primary" size="xs" @press="goToAlertsPage">+ Create Alert </BaseButton>
                    </div>
                  </th>
                  <th class="px-3 py-0 text-right text-xs font-normal normal-case text-gray-300">Standard</th>
                  <th class="px-3 py-0 text-right text-xs font-normal normal-case text-gray-300">Fast</th>
                  <th class="px-3 py-0 text-right text-xs font-normal normal-case text-gray-300">Instant</th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-none">
                  <td class="py-0"></td>
                  <template v-for="(fee, idx) in gweiFee" :key="idx">
                    <td class="whitespace-nowrap px-3 text-right text-xs font-medium">
                      {{ decimals(fee.gwei, 0, 0) }} Gwei
                    </td>
                  </template>
                </tr>
                <tr v-for="(gf, idx) in gasFees" class="border-none" :key="idx">
                  <td class="whitespace-nowrap px-3 py-1 text-xs text-gray-300">{{ gf.description }}</td>
                  <td class="px-3 py-1 text-right">
                    <span class="text-xs text-white">${{ decimals(gf.fees.standard.usd, 2, 2) }}</span>
                  </td>
                  <td class="px-3 py-1 text-right">
                    <span class="text-xs text-white">${{ decimals(gf.fees.fast.usd, 2, 2) }}</span>
                  </td>
                  <td class="px-3 py-1 text-right">
                    <span class="text-xs text-white">${{ decimals(gf.fees.instant.usd, 2, 2) }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <ChartGasFee />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Inertia } from '@inertiajs/inertia';
import { decimals } from '@/composeables/filters';
import { ref, onMounted, inject, computed } from 'vue';
import ChartGasFee from '@/components/chart/ChartGasFee.vue';

const $http = inject('http');
const dropdownId = ref(`gas_fee_chart_${Math.random()}`);
const showDropdown = ref(false);
const gasFees = ref({});

onMounted(() => {
  getGasFees();
  setInterval(() => {
    getGasFees();
  }, 60000);
});

const gweiFee = computed(() => {
  return gasFees.value['swap_transaction'].fees || {};
});

function closeGasFee() {
  showDropdown.value = false;
}

function toggleDropdown() {
  showDropdown.value = !showDropdown.value;
}

function getGasFees() {
  $http.get('/gas_fees').then(response => (gasFees.value = response.data));
}

function goToAlertsPage() {
  Inertia.visit('/alerts/new?m=Gas');
}
</script>
