<template>
  <div class="max-w-xl text-sm">
    <a :href="item.link" target="_blank"><p class="line-clamp-2" v-html="item.headline"></p></a>
  </div>
</template>

<script>
export default {
  name: 'CellHeadlineNews',
  props: {
    item: Object
  },
  methods: {}
};
</script>
