<template>
  <BaseSelect
    dropdown-width-class="w-full"
    button-width-class="w-full"
    searchable
    show-selected
    :multiple="!onlyOne"
    v-model="selectedCoins"
    :options="coinOptions"
    :placeholder="placeholder"
    :display-count="displayCount"
    @update:model-value="emit('update', $event)"
  />
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const emit = defineEmits(['update']);
const props = defineProps({
  startingCoins: Array,
  onlyOne: { type: Boolean, default: false },
  displayCount: { type: Number, default: 3 },
  placeholder: { type: String, default: 'Select Coin(s)' },
  supportedCoins: Array
});

// setting available coins
const coinOptions = computed(() => {
  if (store.getters.coins.length && !props.supportedCoins) {
    return store.getters.coins.map(c => {
      return {
        id: c.uid,
        label: c.name
      };
    });
  } else if (store.getters.coins.length && props.supportedCoins.length) {
    const supportedCoinUids = props.supportedCoins.map(c => c.uid);
    return store.getters.coins
      .filter(c => supportedCoinUids.includes(c.uid))
      .map(c => {
        return {
          id: c.uid,
          label: c.name
        };
      });
  } else {
    return [];
  }
});

// setting selected coins
const selectedCoins = ref([]);
watch(coinOptions, () => {
  if (coinOptions.value && coinOptions.value.length > 0 && !selectedCoins.value) {
    selectedCoins.value = props.startingCoins.forEach(coin => coin.map(c => c.uid));
  }
});
onMounted(() => {
  if (props.startingCoins) {
    selectedCoins.value = props.startingCoins.map(c => c.uid);
  }
});

watch(
  () => props.startingCoins,
  (newVal, oldVal) => {
    if (props.startingCoins) {
      selectedCoins.value = props.startingCoins.map(c => c.uid);
    }
  }
);
</script>
