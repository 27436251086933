<template>
  <div class="py-2 px-4" :class="{ 'border-l-4 border-green-500 bg-green-900 bg-opacity-80': latestNewsItem }">
    <div class="mt-1 mb-1 flex items-center justify-between text-xs text-secondary">
      <span class="flex items-center">
        <IconTwitter />
        <a :href="`https://twitter.com/${source}`" class="hover:underline" target="_blank">@{{ source }}</a>
      </span>
    </div>
    <a class="line-clamp-2" target="_blank" :href="item.link" v-html="item.headline"></a>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'CellHeadlineTweet',
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    source() {
      //expecting only one source here
      return this.item.source_names.map(s => s.replace('@', '')).join(', ');
    },
    latestNewsItem() {
      var now = moment(new Date()); //todays date
      var duration = moment.duration(now.diff(this.item.timestamp));
      var minutes = duration.asMinutes();
      return minutes < 10 ? true : false;
    }
  }
};
</script>
