<template>
  <div
    :class="`${config.useParentContainer ? 'w-100' : 'relative h-full overflow-y-scroll'} ${
      config.horizontalScroll ? 'overflow-x-auto' : 'overflow-x-hidden'
    } ${config.disableVerticalScroll ? 'overflow-y-hidden' : ''} w-full`"
    @scroll="handleScroll"
    ref="tableDiv"
  >
    <slot />
    <table
      :class="`w-full border-separate text-white ${config.fixed ? 'table-fixed' : ''} ${
        data && data.length > 0 ? '' : 'h-full'
      }`"
      :id="config.tableId"
      :style="
        config.lines
          ? `border-collapse: collapse !important;`
          : `border-collapse: separate important; border-spacing: 0 !important`
      "
    >
      <thead class="top-0 h-8 py-3 px-5 font-medium text-secondary" :class="[config.headerClass]">
        <tr class="px-5 py-2 font-medium hover:bg-gray-800">
          <th
            v-for="(col, index) in activeCols"
            :key="index"
            class="top-0 h-8"
            :style="`${
              config.alignText || col.alignLeft
                ? 'text-align: left !important'
                : col.alignCenter
                ? 'text-align: center !important'
                : ''
            }`"
            :class="`${config.backgroundColor || 'bg-gray-900'} ${col.headerClass} ${
              sortable(col) && !(col.headerClass || '').includes('text-left') && 'text-right'
            } ${sortMethods[col.type] ? 'cursor-pointer' : ''}
            ${index === 0 && !(col.headerClass || '').includes('pl') ? 'pl-4' : ''}
             ${index === activeCols.length - 1 && !(col.headerClass || '').includes('pr') ? 'pr-4' : ''}
             ${col.customBorder}
             `"
          >
            <IconTwitter v-if="col.name == 'Twitter'" />
            <DropdownMetricTooltip :id="col.id" :class="col.dropdownMetricClass" placement="bottom">
              <span class="truncate">
                <div v-if="col.headerType == 'dropdown'">
                  <TablePartHeaderDropdownCell :column="col" @handle-action="handleAction($event)" />
                </div>
                <div v-else-if="col.headerType == 'dropdownCategory'">
                  <DropdownCategory :column="col" @handle-action="handleAction($event)" />
                </div>
                <span @click="toggleSort(col)" class="select-none px-1" v-else>
                  <span
                    v-if="sortOrder && sortCol && sortCol.id === col.id"
                    class="inline-block transform pr-px duration-100"
                    :class="{ 'rotate-180': sortOrder === 'DESC' }"
                  >
                    ▾
                  </span>
                  <span :class="`text-xs font-medium text-gray-400`">
                    <span :class="col.colTextClass">{{ truncate(col.name, 20) }}</span>
                  </span>
                </span>
              </span>
            </DropdownMetricTooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="paginatedRows.length > 0">
          <TablePartRow
            v-for="(row, index) in paginatedRows"
            :key="index"
            :class="[
              config.rowClass,
              config.lines ? 'border-b border-gray-800' : '',
              config.alternateBg ? (index % 2 === 1 ? 'bg-gray-900' : 'bg-gray-800/25') : 'bg-gray-900',
              config.highlightRow && config.highlightRow == row['highlight'] ? '!bg-blue-900/25' : ''
            ]"
            :id="`${index}_${config.tableId}`"
            class="font-medium"
            @click="$emit('apply-pair', row)"
          >
            <td
              @click="$emit('row-clicked', row)"
              v-for="(col, j) in activeCols"
              :style="`${col.alignLeft ? 'text-align: left !important' : ''}`"
              :key="j"
              :class="`
                  table-cell
                  text-xs
                  ${col.type === 'governance' || col.type === 'governance_widget' ? ' border-b border-gray-800' : ''}

                  ${col.cellClass}
                  ${col.type == 'news_action' ? 'py-0' : 'py-1'}
                  ${j === 0 && !excludeFromPadding.includes(col.type) ? 'pl-5' : ''}
                  ${j === activeCols.length - 1 && !excludeFromPadding.includes(col.type) ? 'pr-5' : ''}

                  ${config.backgroundColor}
                  px-1`"
            >
              <!-- index -->
              <div v-if="col.type === 'index'" class="max-w-md pr-2 tabular-nums text-gray-400">
                {{ (row._oIndex || index) + 1 }}
              </div>

              <!-- Governance Type -->
              <div v-else-if="col.type === 'governance'">
                <CellGovernance :snap="row" :key="row.id" />
              </div>

              <div v-else-if="col.type === 'governance_widget'">
                <CellGovernanceWidget :snap="row" :key="row.id" />
              </div>

              <!-- Chekbox Type -->
              <div v-else-if="col.type === 'checkbox_field'">
                <input type="checkbox" v-model="row[col.id]" @change="$emit('option-selected', row)" />
              </div>

              <div v-else-if="col.type === 'image_cell'">
                <CellImage :item="row[col.id]" />
              </div>

              <!-- admin coins blockchains/categories -->
              <div v-if="col.type === 'admin_coins'" class="max-w-xs pr-2 text-xs">
                <div v-if="row[col.id]" class="flex flex-wrap">
                  <span
                    class="ml-2 mt-1 cursor-pointer rounded-full bg-blue-500 py-1 px-2 text-white"
                    v-for="(list_item, idx) in row[col.id]"
                    :key="idx"
                  >
                    {{ list_item.name }}</span
                  >
                </div>
              </div>

              <!-- admin action -->
              <div v-else-if="col.type === 'admin_action'" class="p-2 text-xs">
                <button
                  class="pl-6 text-blue-400 underline outline-none hover:text-blue-600 focus:outline-none"
                  @click="$emit('actionClicked', { id: row.id })"
                >
                  Edit
                </button>
              </div>

              <!-- tvl zero handle -->
              <div v-else-if="col.category == 'tvl' && Number(row[col.id]) == 0" />

              <!-- dollar -->
              <div v-else-if="col.type === 'dollar'" class="text-right text-xs tabular-nums" :class="col.divClass">
                {{ usdCryptoPrice((row[col.id] || '').toLocaleString()) }}
              </div>

              <div
                v-else-if="col.type === 'price'"
                class="text-right text-xs tabular-nums"
                :class="row.ticker + '_price'"
              >
                {{ usdCryptoPrice((row[col.id] || '').toLocaleString()) }}
              </div>

              <!-- Dollar with number formatting -->
              <div
                v-else-if="col.type === 'dollar_format'"
                :class="[col.alignLeft ? 'text-left' : 'text-right', 'tabular-nums', col.divClass]"
              >
                {{ formatNumber(row[col.id], 2, '$') }}
              </div>

              <!-- number -->
              <div v-else-if="col.type === 'number'" class="text-right text-xs tabular-nums" :class="col.divClass">
                <div class="flex items-center justify-end">
                  <div v-if="col.alertIcon && row[col.id] == 0">
                    <IconAlert :class="'mr-0.5 text-red-500'" />
                  </div>
                  <div v-if="col.eth" class="">
                    <IconChain :class="'mr-1 text-gray-400'" v-if="row[col.id]" />
                  </div>
                  <span
                    v-if="
                      (col.hideNull && [null, undefined].includes(row[col.id])) ||
                      (col.category == 'sentiment' && noSmaAlias(row, col))
                    "
                    class="text-gray-500"
                  ></span>
                  <span v-if="col.dash && !row[col.id]" class="text-gray-600">-</span>
                  <span v-else-if="col.eth && !row[col.id]"> </span>
                  <span v-else-if="col.category == 'sentiment' && noSmaAlias(row, col)"> </span>
                  <span v-else>{{ rounded(Number(row[col.id] || ''), col.decimals || 0) }}</span>
                </div>
              </div>

              <!-- percent -->
              <div
                v-else-if="col.type === 'percent'"
                :class="[col.alignLeft ? 'text-left' : 'text-right', 'tabular-nums']"
              >
                <span v-if="col.dash && !row[col.id]" class="text-gray-600">-</span>
                <span v-if="col.category == 'sentiment' && noSmaAlias(row, col)" class="text-gray-500"></span>
                <BasePercentClassic :percent="row[col.id] || ''" :multiply="col.multiply" v-else />
              </div>

              <!-- btc format -->
              <div v-else-if="col.type === 'btc_format'" class="text-right text-xs tabular-nums">
                {{ roundedBtc(Number(row[col.id] || ''), col.decimals || 0) }}
              </div>

              <!-- coin -->
              <div v-else-if="col.type === 'coin'" class="min-w-fit max-w-md" :class="col.divClass">
                <CellCoin
                  :coin="{
                    id: row['id'],
                    ticker: row['ticker'],
                    name: row['name'],
                    uid: row['uid'],
                    watchlist_ids: row['watchlist_ids'],
                    noLink: row['noLink']
                  }"
                  @coin-clicked="$emit('row-clicked', row)"
                  :show-info="col.showInfo"
                />
              </div>

              <!-- widget coin -->
              <div v-else-if="col.type === 'widget_coin'" class="">
                <CellWidgetCoin
                  :coin="{
                    id: row['id'],
                    ticker: row['ticker'],
                    name: row['name'],
                    coin_uid: row['coin_uid'],
                    tag_id: row['tag_id'],
                    container_width: row['width']
                  }"
                  @coin-clicked="$emit('coin-clicked', $event)"
                />
              </div>
              <!-- text -->
              <div v-else-if="col.type === 'text'" :class="[col.divClass, col.lineClamp, 'space-x-1']" v-once>
                <!-- commenting out because this seems redundant -->
                <!-- <BaseTooltipClassic :text="`${col.title ? row[col.id] : ''}`" placement="bottom" v-if="!col.truncate">
                  <p
                    :class="`${col.lineClamp ? 'line-clamp-' + col.lineClamp : 'max-w-sm line-clamp-3'} ${
                      col.id != 'twitter_account' ? col.cellClass : ''
                    }`"
                  >
                    {{ row[col.id] }}
                  </p>
                </BaseTooltipClassic> -->
                <BaseTooltip v-if="row[col.id] && row[col.id].length > col.truncateLength">
                  <template #target>
                    <div :class="[col.lineClamp ? col.lineClamp : '', 'w-full', 'text-left']">
                      {{ truncate(row[col.id], col.truncateLength || 10) }}
                    </div>
                  </template>
                  <template #default>
                    <div :class="[col.breakAll ? 'break-all' : 'break-words', 'max-w-sm whitespace-normal']">
                      {{ row[col.id] }}
                    </div>
                  </template>
                </BaseTooltip>
                <span v-else :class="[col.lineClamp, col.maxWidth]">{{ row[col.id] }}</span>
                <CoinMetricTooltip
                  v-if="col.tooltip"
                  :title="row[col.id]"
                  :tooltip-key="row[col.tooltipKey]"
                  :placement="row[col.tooltipPlacement] || 'right'"
                />
              </div>
              <!-- comman-seperated-text -->
              <div v-else-if="col.type === 'comma_seperated_text'" class="w-fit text-xs" v-once>
                <BaseTooltip>
                  <template #target>
                    <p :class="`${col.lineClamp ? 'line-clamp-' + col.lineClamp : 'max-w-sm line-clamp-2'}`">
                      {{ row[col.id] ? row[col.id].map(x => x.text).join(', ') : '' }}
                    </p>
                  </template>
                  <template #default>
                    <div class="max-w-sm whitespace-normal">
                      {{ row[col.id] ? row[col.id].map(x => x.text).join(', ') : '' }}
                    </div>
                  </template>
                </BaseTooltip>
              </div>

              <!-- alert edit -->
              <div v-else-if="col.type === 'alertEdit'">
                <CellAlertEdit :alert="row" />
              </div>

              <!-- company -->
              <div v-else-if="col.type === 'company'">
                <CellCompany
                  :company="{
                    id: row['id'],
                    name: row['name'],
                    slug: row['slug'],
                    watchlist_ids: row['watchlist_ids'],
                    imageUrl: row['profile_image'] || row['imageUrl']
                  }"
                />
              </div>

              <!-- tags -->
              <div v-else-if="col.type === 'tags'" class="flex items-center whitespace-nowrap">
                <CellMultiTags :tags="row[col.id]" />
              </div>

              <!-- pair -->
              <div v-else-if="col.type === 'pair'" :class="col.divClass">
                <span class="">{{ row[col.id] }}</span>
              </div>

              <!-- timestamp -->
              <div v-else-if="col.type === 'timestamp'" class="px-1 text-xs" :class="col.divClass">
                <span class="" v-if="col.dateOnly">{{ readableDate(row[col.id]) }}</span>
                <span v-else>{{ minimalTimeWithinToday(row[col.id]) }}</span>
              </div>

              <!-- button -->
              <div
                v-else-if="col.type === 'button'"
                class="cursor-pointer text-xs font-bold text-gray-400 hover:text-white"
                v-once
              >
                {{ row[col.id].text }}
              </div>

              <!-- link -->
              <div v-else-if="col.type === 'link'" class="text-xs font-medium" :class="col.divClass" v-once>
                <a
                  :href="row[col.id].link"
                  :target="row[col.id].target || '_blank'"
                  :class="(row[col.id].textColor, [col.lineClamp, col.maxWidth])"
                  class="duration-100"
                  >{{ row[col.id].text }}</a
                >
              </div>

              <!-- news -->
              <div v-else-if="col.type === 'news'">
                <CellSmallNews :item="row[col.id]" :handle-coin-click="handleCoinClick" />
              </div>

              <!-- sentiment -->
              <div v-else-if="col.type === 'sentiment'" class="tabular-nums">
                <CellSentiment :sentiment="row[col.id]" v-if="row[col.id]" />
              </div>

              <!-- news_expanded -->
              <div v-else-if="col.type === 'news_expanded'">
                <CellNews :item="row" />
              </div>

              <!-- news_action -->
              <div v-else-if="col.type === 'news_action'">
                <CellActionNews :item="row" :is-focus-out="isFocusOut" />
              </div>

              <!-- action -->
              <div v-else-if="col.type === 'action'">
                <CellRowAction
                  :obj="row"
                  :label="col.label"
                  @action-clicked="actionClicked({ event: $event, id: index, highlight: true })"
                />
              </div>

              <!-- dropdown_actions -->
              <div v-else-if="col.type === 'dropdown_actions'">
                <DropdownTableCell
                  :items="row[col.id]"
                  :global-emit="true"
                  :cell-info="row"
                  class="-mt-3 h-3 w-4 text-gray-500"
                />
              </div>

              <!-- checkbox -->
              <div v-else-if="col.type === 'checkbox'">
                <div class="mr-4 flex select-none items-center py-1 text-xs font-medium text-secondary">
                  <input
                    class="cursor-pointer"
                    type="checkbox"
                    :checked="row.hasOwnProperty('checked') ? row.checked : row[col.name.toLowerCase()]"
                    :disabled="row.checkDisabled"
                    @click="
                      $emit('checkboxClicked', {
                        id: row.id,
                        value: row.hasOwnProperty('checked') ? !row.checked : !row[col.name.toLowerCase()],
                        name: col.name ? col.name.toLowerCase() : ''
                      })
                    "
                  />

                  <span class="whitespace-nowrap pl-1.5 text-gray-100" v-if="row[col.id]">{{ row[col.id] }}</span>
                </div>
              </div>

              <!-- Icons -->
              <div v-else-if="col.type === 'icons'">
                <CellIcon :icons="row[col.id]" :classes="col.cellClass" :size="row.color" :label="col.title" />
              </div>

              <!-- projects -->
              <div v-else-if="col.type === 'projects'" class="flex space-x-1 text-xs">
                <div
                  v-for="(proj, idx) in row[col.id]"
                  :key="idx"
                  class="cursor-pointer font-medium text-blue-300"
                  @click="handleCoinClick(proj.uid)"
                >
                  {{ row[col.id].length > 1 ? proj.shortName : `${proj.name} (${proj.shortName})` }}
                  {{ idx !== row[col.id].length - 1 ? ',' : '' }}
                </div>
              </div>

              <!-- tweet -->
              <div v-else-if="col.type === 'tweet'">
                <CellHeadlineTweet :item="row" />
              </div>

              <!-- youtube -->
              <div v-else-if="col.type === 'youtube'">
                <CellHeadlineYoutube :item="row" />
              </div>

              <!-- titkok -->
              <div v-else-if="col.type === 'tiktok'">
                <CellHeadlineTiktok :item="row" />
              </div>

              <!-- Relative Tweet/Trading Volume cell -->
              <div v-else-if="col.type === 'relative_change'" class="text-right text-xs tabular-nums">
                <CellRelativeVolume :volume="row[col.latest]" :average="row[col.average]" />
              </div>

              <!-- buy/ell -->
              <div
                v-else-if="col.type === 'buysell'"
                :class="`text-xs uppercase ${row[col.id] === 'Sell' ? 'text-red-400' : 'text-green-400'}`"
              >
                {{ row[col.id] }}
              </div>

              <div v-else-if="col.type === 'dex_transaction_tags'">
                <CellDexTags :tags="row[col.id]" />
              </div>

              <div v-else-if="col.type === 'sigdev_tags'">
                <CellSigDevTags :tags="row[col.id]" />
              </div>

              <div v-else-if="col.type === 'info_tags'">
                <CellInfoTags :tags="row[col.id]" />
              </div>

              <!-- Sigdev Explorer -->
              <div v-else-if="col.type === 'headline'" class="text-white">
                <CellHeadlineNews :item="{ headline: row.headline, link: row.link }" />
              </div>

              <!-- Simple Percent -->
              <div v-else-if="col.type === 'simple_percent'" class="text-right tabular-nums">
                <span v-if="col.category == 'sentiment' && noSmaAlias(row, col)" class="text-gray-500"></span>
                <span v-else-if="col.dash && !row[col.id]" class="text-gray-600">-</span>
                <span v-else>{{ getSimplePercent(row, col) }}</span>
              </div>

              <div v-else-if="col.type === 'small_percent'" class="text-right tabular-nums">
                <span>{{ roundSmallPercent(col.multiply ? row[col.id] * 100 : row[col.id], 2, 2) }}%</span>
              </div>

              <!-- Investor -->
              <div v-else-if="col.type === 'investor'" class="text-white">
                <CellInvestor :investor="row[col.id]" />
              </div>

              <!-- Invested In -->
              <div v-else-if="col.type === 'invested_id'" class="text-white">
                <CellInvestedIn :investable-type="row.investable_type" :invested-entity="row[col.id]" />
              </div>

              <!-- Currency Format -->
              <div v-else-if="col.type === 'currency'" class="text-white">
                <span>{{ getCurrencySymbol(row[col.currency_key]) }} {{ formatNumber(row[col.id]) }}</span>
              </div>

              <!-- Social Media Following Platform -->
              <div v-else-if="col.type === 'social_platform'">
                <CellSocialMediaPlatform :item="row[col.id]" />
              </div>

              <div v-else-if="col.type === 'correlated_tags'">
                <CellTags :item="row.tags" />
              </div>

              <!-- URL cell -->
              <div v-else-if="col.type === 'url'">
                <CellUrl :url="row[col.id]" :url-text="row.linkText" />
              </div>

              <!-- multipleUsers cell -->
              <div v-else-if="col.type === 'multipleUsers'">
                <CellMultipleUsers
                  :users="row[col.id]"
                  :total-user="row['num_follows']"
                  :key="row['num_follows']"
                  :max-user="4"
                />
              </div>

              <!-- Twitter Engagement -->
              <div v-else-if="col.type === 'twitter_engagement'">
                <div class="flex w-28 justify-between tabular-nums">
                  <p>{{ row[col.id]['title'] }}</p>
                  <p class="text-md font-bold">
                    {{ formatNumber(row[col.id]['count']) }}
                  </p>
                </div>
              </div>

              <!-- million format -->
              <div v-else-if="col.type === 'million_format'" class="text-right tabular-nums text-white">
                <span> {{ formatBigPricing(row[col.id]) }} </span>
              </div>
              <!-- Profile Cell -->
              <div v-else-if="col.type === 'profile'" class="text-right text-white">
                <CellProfile :profile="row[col.id]" :name-character-limit="col.nameCharacterLimit" />
              </div>
              <!-- Top Mints Cell -->
              <div v-else-if="col.type === 'top_mints'" class="text-right text-white">
                <CellTopMints :profile="row[col.id]" :address="row['address']" />
              </div>
              <!-- BlackHole ETH type  -->
              <div v-else-if="col.type === 'eth_type'" class="float-right text-right text-xs" :class="col.divClass">
                <CellEth :item="row[col.id]" :ticker="row[col.id + 'symbol'] || row['symbol']" />
              </div>

              <!-- Relative time  -->
              <div v-else-if="col.type === 'relative_time'">
                <span class="">{{ relativeTime(row[col.id]) }}</span>
              </div>

              <!-- Truncated Text -->
              <div v-else-if="col.type === 'truncate_text'" :class="col.divClass">
                <BaseTooltip class="w-full" v-if="row[col.id]">
                  <template #target>
                    <div
                      :class="col.cellClass"
                      v-if="row[col.id] && (col.truncateLength ? row[col.id].length > col.truncateLength : true)"
                    >
                      {{ truncate(row[col.id], 5) }}{{ row[col.id].slice(row[col.id].length - 3) }}
                    </div>
                    <span v-else-if="row[col.id]"> {{ row[col.id] }} </span>
                  </template>
                  <template #default>
                    <div class="max-w-sm whitespace-normal">{{ row[col.id] }}</div>
                  </template>
                </BaseTooltip>
              </div>

              <!-- Nft Collection -->
              <InertiaLink
                v-else-if="col.type === 'nft_collection'"
                class="flex items-center py-1"
                :href="row[col.id].link"
              >
                <img :src="row[col.id].img" class="h-5 w-5 rounded-full bg-gray-800" loading="lazy" />
                <div class="pl-2 pr-1">{{ row[col.id].name }}</div>
                <div
                  @click="
                    $event.preventDefault();
                    $emit('on-collection-saved', row);
                  "
                  class="cursor-pointer"
                >
                  <IconBookmarked class="h-4 w-4 text-white" v-if="row[col.id].saved" />
                  <IconBookmark class="h-4 w-4 text-gray-400" v-else />
                </div>
              </InertiaLink>

              <a
                v-else-if="col.type === 'address'"
                class="flex items-center py-1"
                :href="
                  `${row[col.id].base_url ? row[col.id].base_url : 'https://etherscan.io/address/'}` +
                  row[col.id].address
                "
                target="_blank"
              >
                <div>
                  {{
                    row[col.id].ens
                      ? row[col.id].ens.length > 18
                        ? row[col.id].ens.slice(row[col.id].ens.length - 6, row[col.id].ens.length)
                        : row[col.id].ens
                      : row[col.id].address.slice(0, 6) +
                        '...' +
                        row[col.id].address.slice(row[col.id].address.length - 6, row[col.id].address.length)
                  }}
                </div>
                <IconOpen class="h-3 text-xs text-gray-400" />
              </a>

              <a
                v-else-if="col.type === 'transaction'"
                class="flex items-center py-1"
                :href="
                  `${row[col.id].base_url ? row[col.id].base_url : 'https://etherscan.io/tx/'}` + row[col.id].trx_hash
                "
                target="_blank"
              >
                <div>
                  {{
                    row[col.id].trx_hash.slice(0, 6) +
                    '...' +
                    row[col.id].trx_hash.slice(row[col.id].trx_hash.length - 6, row[col.id].trx_hash.length)
                  }}
                </div>
                <IconOpen class="h-3 text-xs text-gray-400" />
              </a>

              <!-- Vue Router Link -->
              <div v-else-if="col.type === 'router_link'">
                <InertiaLink :href="`${col.link}${col.resource ? row[col.resourceItem] : ''}`">
                  <span class="">{{ row[col.id] }}</span>
                </InertiaLink>
              </div>

              <div
                v-else-if="col.type === 'chart'"
                :class="`${row.loading ? 'flex items-center' : ''} h-14 text-right text-xs`"
              >
                <BaseLoader size="h-5 w-5" class="m-auto" v-if="row.loading" />
                <ChartLine :series="row[col.id]" :config="col.config" v-else />
              </div>

              <div v-else-if="col.type === 'sentiment_changes'">
                <CellSentimentChanges :item="row[col.id]" :multiply="col.multiply" :monthly="col.monthly" />
              </div>

              <div v-else-if="col.type === 'onchain_changes'">
                <CellOnchainChanges :item="row[col.id]" />
              </div>

              <div v-else-if="col.type === 'token_amount'" class="text-right tabular-nums">
                <CellTokenAmount :item="row[col.id]" />
              </div>
            </td>
          </TablePartRow>
        </template>
        <tr class="table-row font-medium" v-else-if="!loading">
          <td :colspan="activeCols.length" style="text-align: center !important">
            <NoDataFound class="my-2" />
          </td>
        </tr>
        <tr class="table-row" v-else>
          <td :colspan="activeCols.length">
            <div class="mt-10 flex items-center justify-center">
              <BaseLoader />
            </div>
          </td>
        </tr>
        <template v-if="this.paginate && !loading && activeCols.length > 1">
          <tr class="table-row h-16 text-center" v-if="loadingPagination">
            <div class="pt-2">
              <div class="text-gray-300">Loading...</div>
            </div>
          </tr>
          <tr class="table-row h-16 text-center" v-if="!loadingPagination && !this.pagination.stopCall && loadMoreLink">
            <div class="pt-2">
              <div
                class="cursor-pointer font-medium text-gray-300 duration-100 hover:text-white"
                @click="handlePagination"
              >
                {{ activeCols.length }}
                Click Here to Load More
              </div>
            </div>
          </tr>
        </template>
        <template v-else-if="loading && paginatedRows.length > pagination.perPage * pagination.page">
          <tr class="table-row text-center">
            <td :colspan="activeCols.length">
              <div class="flex items-center justify-center">
                <BaseLoader />
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div
      v-if="showScrollToTop && goToTopButton"
      @click="goToTop($event)"
      class="group sticky left-full bottom-2 mr-2 flex h-10 w-10 cursor-pointer items-center justify-center rounded bg-blue-900 p-1 duration-100 hover:bg-blue-800"
    >
      <IconChevronDown class="mr-1 h-6 w-6 rotate-180 group-hover:text-white" />
    </div>
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/global';
import { formatMixin } from '@/util/format';
import CellDexTags from './cell/CellDexTags.vue';
import CellCoin from './cell/CellCoin.vue';
import CellNews from './cell/CellNews.vue';
import CellSmallNews from './cell/CellSmallNews.vue';
import CellAlertEdit from './cell/CellAlertEdit.vue';
import CellCompany from './cell/CellCompany.vue';
import CellRelativeVolume from './cell/CellRelativeVolume.vue';
import CellSigDevTags from './cell/CellSigDevTags.vue';
import CellHeadlineTweet from './cell/CellHeadlineTweet.vue';
import CellHeadlineYoutube from './cell/CellHeadlineYoutube.vue';
import CellHeadlineTiktok from './cell/CellHeadlineTiktok.vue';
import CellHeadlineNews from './cell/CellHeadlineNews.vue';
import CellInvestor from './cell/CellInvestor.vue';
import CellInvestedIn from './cell/CellInvestedIn.vue';
import CellSentiment from './cell/CellSentiment.vue';
import CellActionNews from './cell/CellActionNews.vue';
import CellRowAction from './cell/CellRowAction.vue';
import CellSocialMediaPlatform from './cell/CellSocialMediaPlatform.vue';
import CellTags from './cell/CellTags.vue';
import CellUrl from './cell/CellUrl.vue';
import CellMultipleUsers from './cell/CellMultipleUsers.vue';
import CellProfile from './cell/CellProfile.vue';
import CellTopMints from './cell/CellTopMints.vue';
import CellEth from './cell/CellEth.vue';
import CellWidgetCoin from './cell/CellWidgetCoin.vue';
import TablePartRow from './part/TablePartRow.vue';
import DropdownMetricTooltip from '@/components/dropdown/DropdownMetricTooltip.vue';
import CellIcon from './cell/CellIcon.vue';
import CellMultiTags from './cell/CellMultiTags.vue';
import CellInfoTags from './cell/CellInfoTags.vue';
import CellGovernance from './cell/CellGovernance.vue';
import CellImage from './cell/CellImage.vue';
import CellGovernanceWidget from './cell/CellGovernanceWidget.vue';
import DropdownTableCell from '@/components/dropdown/DropdownTableCell.vue';
import IconOpen from '../icon/IconOpen.vue';
import lodash from 'lodash';
import FilterMixin from '@/mixins/filters';
import IconChain from '@/components/icon/IconChain.vue';
import ChartLine from '@/components/chart/ChartLine.vue';
import CellSentimentChanges from './cell/CellSentimentChanges.vue';
import TablePartHeaderDropdownCell from './part/TablePartHeaderDropdownCell.vue';
import CellTokenAmount from './cell/CellTokenAmount.vue';
import CellOnchainChanges from './cell/CellOnchainChanges.vue';
import IconBookmarked from '@/components/icon/IconBookmarked.vue';
import IconBookmark from '@/components/icon/IconBookmark.vue';
import CoinMetricTooltip from '@/components/coin/CoinMetricTooltip.vue';
import DropdownCategory from '@/components/dropdown/DropdownCategory.vue';
import { Link as InertiaLink } from '@inertiajs/inertia-vue3';
export default {
  mixins: [GlobalMixin, formatMixin, FilterMixin],
  components: {
    CellDexTags,
    CellCoin,
    CellNews,
    CellSmallNews,
    CellAlertEdit,
    CellCompany,
    CellRelativeVolume,
    CellSigDevTags,
    CellHeadlineTweet,
    CellHeadlineYoutube,
    CellHeadlineTiktok,
    CellHeadlineNews,
    CellInvestor,
    CellInvestedIn,
    CellSentiment,
    CellActionNews,
    CellRowAction,
    CellSocialMediaPlatform,
    CellTags,
    CellUrl,
    CellMultipleUsers,
    CellProfile,
    CellTopMints,
    CellEth,
    CellIcon,
    CellWidgetCoin,
    TablePartRow,
    DropdownMetricTooltip,
    CellMultiTags,
    CellInfoTags,
    DropdownTableCell,
    ChartLine,
    CellSentimentChanges,
    TablePartHeaderDropdownCell,
    CellGovernance,
    CellGovernanceWidget,
    IconOpen,
    CellTokenAmount,
    CellOnchainChanges,
    IconBookmarked,
    IconBookmark,
    CoinMetricTooltip,
    CellImage,
    DropdownCategory,
    InertiaLink
  },
  props: {
    config: Object,
    isFocusOut: Boolean,
    loading: {
      type: Boolean,
      default: false
    },
    data: Array,
    searchPhrase: {
      type: String,
      default: ''
    },
    defaultSortOrder: {
      type: String,
      default: undefined
    },
    defaultSortColumn: {
      type: Object,
      default: undefined
    },
    goToTopButton: {
      type: Boolean,
      default: true
    },
    scrollbars: {
      type: Boolean,
      default: true
    },
    layout: {
      type: Object,
      default: () => {}
    },
    paginate: Function,
    resetPaginationPage: { type: Boolean, default: true },
    alignText: { type: String, default: '' }
  },
  computed: {
    loadMoreLink() {
      return !this.config.hideLoadMore;
    },
    sortedRows: function () {
      if (!this.sortCol || !this.sortOrder) {
        return this.searchedRows();
      }

      const sortMethod = this.sortMethods[this.sortCol.type];

      if (!sortMethod) {
        return this.searchedRows();
      }

      const dataCopy = this.searchedRows().map(r => {
        return {
          ...r
          // _oIndex: r.id || i   //disabling for now to remvove showing IDs of coin
        };
      });

      if (
        sortMethod === 'number' ||
        sortMethod === 'alphabetical' ||
        sortMethod === 'timestamp' ||
        sortMethod === 'total_count' ||
        sortMethod === 'token_amount'
      ) {
        // todo figure out way without copying
        const mutpl = this.sortOrder === 'ASC' ? -1 : 1;
        return dataCopy.sort((a, b) => {
          let aVal = '';
          let bVal = '';
          if (sortMethod === 'timestamp') {
            aVal = a[this.sortCol.id] ? new Date(a[this.sortCol.id]) : '';
            bVal = b[this.sortCol.id] ? new Date(b[this.sortCol.id]) : '';
          } else if (sortMethod === 'total_count') {
            aVal = lodash.sum(a[this.sortCol.id].map(x => x.count));
            bVal = lodash.sum(b[this.sortCol.id].map(x => x.count));
          } else if (sortMethod === 'token_amount') {
            aVal = a[this.sortCol.id] ? a[this.sortCol.id]?.amount : '';
            bVal = b[this.sortCol.id] ? b[this.sortCol.id]?.amount : '';
          } else {
            aVal = this.sortCol.type === 'coin' ? a[this.sortCol.id].name : a[this.sortCol.id];
            bVal = this.sortCol.type === 'coin' ? b[this.sortCol.id].name : b[this.sortCol.id];
          }

          const condition = sortMethod === 'number' ? parseFloat(aVal) > parseFloat(bVal) : aVal > bVal;

          if (condition) {
            return (aVal === null) - (bVal === null) || 1 * mutpl;
          }

          return (aVal === null) - (bVal === null) || (aVal === undefined) - (bVal === undefined) || -1 * mutpl;
        });
      }

      return this.searchedRows();
    },
    paginatedRows: function () {
      return this.sortedRows ? this.sortedRows.slice(0, this.pagination.page * this.pagination.perPage) : [];
    },
    activeCols: function () {
      if (!this.config.editable) {
        return this.config.cols;
      }

      return this.config.cols.filter(col => {
        return col.enabled;
      });
    },
    filterableCols() {
      return this.config.cols.filter(col => col.filterable);
    }
  },
  watch: {
    data(tableData) {
      if (tableData && tableData.length <= this.pagination.perPage) {
        if (this.resetPaginationPage) {
          this.pagination.page = 1;
        }
        // this.$refs.tableDiv.scrollTop = 0;
      }
    },
    layout() {
      this.sortCol = this.defaultSortColumn;
      this.sortOrder = this.defaultSortOrder;
    }
  },
  methods: {
    getSimplePercent(row, col) {
      let decimalValue = this.decimals(row[col.id] * 100, 2, col.decimals ? col.decimals : 2);
      return decimalValue == '' ? decimalValue : decimalValue + '%';
    },
    handleAction(action) {
      this.$emit('handleDropdownAction', action);
    },
    actionClicked(params) {
      this.$emit('actionClicked', params.event);
      if (params.event.action == 'IconEdit') {
        var table = document.getElementById('delivery_setting');
        for (var i = 0, row; (row = table.rows[i]); i++) {
          if (params.highlight) {
            if (row.id == params.id + '_' + this.config.tableId) {
              if (row.classList.contains('bg-gray-800/25')) {
                row.classList.remove('bg-gray-800/25');
                row.classList.add('bg-gray-600');
              } else {
                row.classList.add('bg-gray-600');
              }
            } else {
              row.classList.remove('bg-gray-600');
              if (!row.classList.contains('bg-gray-800/25') && i % 2 === 1) {
                row.classList.add('bg-gray-800/25');
              }
            }
          } else {
            row.classList.remove('bg-gray-600');
            if (!row.classList.contains('bg-gray-800/25')) {
              if (i % 2 === 1) {
                row.classList.add('bg-gray-800/25');
              }
            }
          }
        }
      }
    },
    searchedRows() {
      // Check empty or null
      if (this.searchPhrase !== '' && this.config.cols.filter(col => col.searchable).length !== 0) {
        return this.data.filter(row => {
          return this.config.cols
            .filter(col => col.searchable)
            .some(col => {
              if (col.type === 'coin') {
                return (
                  row['name'].toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
                  row['ticker'].toLowerCase().includes(this.searchPhrase.toLowerCase())
                );
              } else {
                if (row[col.id]) {
                  return row[col.id].toLowerCase().includes(this.searchPhrase.toLowerCase());
                }
              }
            });
        });
      }
      return this.data;
    },
    sortable(col) {
      const sortableCols = [
        'percent',
        'number',
        'dollar',
        'dollar_format',
        'price',
        'simple_percent',
        'sentiment',
        'small_percent',
        'btc_format',
        'eth_type',
        'buysell',
        'truncate_text',
        'timestamp',
        'token_amount'
      ];
      return (col.sortable && col.sortable === true) || sortableCols.includes(col.type);
    },
    handleCoinClick(id, newsItemId = null) {
      if (this.config.coinClick) {
        this.config.coinClick(id, newsItemId);
        return;
      }
    },
    noSmaAlias(row, col) {
      return !row['tweet_volume'] || row['tweet_volume'] < 12 || row[col.id] == null;
    },
    toggleSort(col) {
      if (!this.sortMethods[col.type] || col.sortable == false) {
        return;
      }
      if (this.sortCol && this.sortCol.id != col.id) {
        this.sortOrder = undefined;
      }
      this.sortCol = col;
      let path = this.$store.state.firstPath;
      let sortOrderPath = this.layout ? `${path}_sortOrder_${this.layout.id}` : `${path}_sortOrder`;
      let sortColPath = this.layout ? `${path}_sortCol_${this.layout.id}` : `${path}_sortCol`;
      if (this.sortOrder === undefined) {
        this.sortOrder = 'ASC';
        localStorage.setItem(sortOrderPath, 'ASC');
        localStorage.setItem(sortColPath, JSON.stringify(col));
        this.$emit('sort-screener', { col: this.sortCol, order: this.sortOrder, type: this.sortMethods[col.type] });
        return;
      } else if (this.sortOrder === 'ASC') {
        this.sortOrder = 'DESC';
        localStorage.setItem(sortOrderPath, 'DESC');
        localStorage.setItem(sortColPath, JSON.stringify(col));
        this.$emit('sort-screener', { col: this.sortCol, order: this.sortOrder, type: this.sortMethods[col.type] });
        return;
      } else {
        this.sortOrder = undefined;
        localStorage.setItem(sortOrderPath, '');
        this.$emit('sort-screener', { col: this.sortCol, order: this.sortOrder, type: this.sortMethods[col.type] });
        return;
      }
    },
    handlePagination: async function () {
      if (this.pagination.stopPagination) {
        this.loadingPagination = false;
        return;
      }
      this.loadingPagination = true;
      this.pagination.page++;
      if (this.pagination.stopCall) {
        this.loadingPagination = false;
        return;
      }
      try {
        this.$emit('paginationEvent');
        this.pagination = await this.paginate(this.pagination);
      } catch {
        // if paginate fails, still set loadingPagination back to false so we can try again
      }
      this.loadingPagination = false;
    },
    handleScroll: async function (el) {
      if (scrollbars) {
        if (el.target.offsetHeight + el.target.scrollTop >= el.target.scrollHeight - 200 && !this.loadingPagination) {
          this.handlePagination();
        }
        if (el.target.scrollTop > 150) {
          this.showScrollToTop = true;
        } else if (el.target.scrollTop < 150) {
          this.showScrollToTop = false;
        }
      }
    },
    goToTop() {
      this.$refs.tableDiv.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  data() {
    return {
      excludeFromPadding: ['news_expanded', 'tweet', 'news_action', 'youtube', 'tiktok', 'governance_widget'],
      sortCol: this.defaultSortColumn,
      sortedColId: '',
      sortOrder: this.defaultSortOrder,
      sortMethods: {
        price: 'number',
        index: 'number',
        coin: 'alphabetical',
        dollar: 'number',
        dollar_format: 'number',
        simple_percent: 'number',
        number: 'number',
        percent: 'number',
        sentiment: 'number',
        text: 'alphabetical',
        million_format: 'number',
        small_percent: 'number',
        btc_format: 'number',
        eth_type: 'number',
        buysell: 'alphabetical',
        truncate_text: 'alphabetical',
        timestamp: 'timestamp',
        company: 'alphabetical',
        router_link: 'alphabetical',
        info_tags: 'total_count',
        token_amount: 'token_amount'
      },
      pagination: {
        page: this.config.page || 1,
        perPage: this.config.perPage || 50
      },
      loadingPagination: false,
      showScrollToTop: false
    };
  }
};
</script>
<style>
/* in order to stick coins column for horizontal scrolling  */
.screener_coins_table th:nth-child(2) {
  position: sticky;
  left: 0;
  width: 14% !important;
  z-index: 5;
}
.screener_coins_table td:nth-child(2) {
  position: sticky;
  left: 0;
  width: 14% !important;
}
.screener_coins_table tr:nth-child(odd) td {
  background: rgb(18 27 46);
}
.screener_coins_table tr:nth-child(even) td {
  background: rgb(15 23 42);
}
.screener_coins_table tr:hover td {
  background-color: rgb(30 41 59);
}
.companies_table .company-th {
  position: sticky;
  left: 0;
  width: 10% !important;
  z-index: 5;
}
.companies_table .company-td {
  position: sticky;
  left: 0;
  width: 14% !important;
}
.companies_table tr:nth-child(odd) td {
  background: rgb(18 27 46);
}
.companies_table tr:nth-child(even) td {
  background: rgb(15 23 42);
}
.companies_table tr:hover td {
  background-color: rgb(30 41 59);
}
</style>
