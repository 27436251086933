<template>
  <BaseDialog :model-value="show" size-class="!max-w-sm" @update:model-value="$emit('close')">
    <template #header>
      <div class="flex w-full items-start justify-end">
        <div class="flex items-center space-x-1 text-gray-400">
          <div @click="$emit('close')">
            <IconCross></IconCross>
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div class="flex flex-col items-center text-base font-medium leading-6">
        <ChatImage size="xxl" :item="user" :show-online="true" />
        <div class="pt-5">
          <BaseUser
            :stream-companies="streamCompanies"
            :title="userName"
            :user="user"
            :user-profile-opened="true"
            :hide-cursor="true"
          >
          </BaseUser>
        </div>
      </div>
      <div class="p-4 text-center text-xs font-medium">
        <p v-if="user.about" class="text-gray-100">{{ user.about }}</p>
      </div>
      <div
        v-if="client.user.id !== user.id && user.id !== 'bot_user' && !user.deactivated_at"
        class="flex flex-row items-center justify-center gap-10 pt-4"
      >
        <BaseButton @press="emitter.$emit('send-dm', user)" class="px-[4.5rem]">Message</BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import BaseUser from './ChatBaseUser.vue';
import ChatImage from './ChatImage.vue';
import { useMapGetter } from '@/store/map-state';

import { useStore } from 'vuex';
const { client, streamUser } = useMapGetter();
const store = useStore();

const emitter = inject('eventHub');
const props = defineProps({
  user: Object
});
const $emits = defineEmits(['update-user', 'close']);
onMounted(() => {
  streamCompanies.value = store.getters.streamCompanies;
});
const buttonBusy = ref(false);
const profile = ref({
  about: props.user.about,
  image: ''
});
const streamCompanies = ref(null);
const show = ref(true);

const userName = computed(() => {
  return props.user.name;
});
</script>
