<template>
  <div class="flex-col items-center space-y-2">
    <div class="flex items-center space-x-2">
      <div class="flex w-full items-center space-x-2 rounded bg-gray-800 px-3">
        <IconSearch size="h-4 w-4 text-gray-500" />
        <input
          class="h-8 w-full rounded border-none bg-gray-800 text-xs outline-none focus:border-none"
          placeholder="Search people, companies and roles"
          v-model="searchText"
        />
      </div>
      <div class="flex space-x-0.5">
        <div
          class="flex h-8 w-24 cursor-pointer items-center justify-center rounded-l p-1 px-3 py-1.5 text-xs"
          :class="selectedTeamTab == 'externalteam' ? 'bg-blue-900 hover:bg-blue-800' : 'bg-gray-800 hover:bg-gray-700'"
          @click="$emit('team-tab-selected', 'externalteam')"
        >
          External
        </div>
        <div
          class="flex h-8 w-24 cursor-pointer items-center justify-center rounded-r p-1 px-3 py-1.5 text-xs"
          :class="selectedTeamTab == 'team' ? 'bg-blue-900 hover:bg-blue-800' : 'bg-gray-800 hover:bg-gray-700'"
          @click="$emit('team-tab-selected', 'team')"
        >
          Team
        </div>
      </div>
    </div>
    <div
      v-if="selectedTeamTab == 'externalteam'"
      class="flex flex-col"
      :class="teamMemberCount != undefined ? 'h-97' : 'h-96'"
    >
      <div v-if="selectedTeamTab == 'externalteam'" class="overflow-y-auto">
        <div v-for="company in filteredCompanies" :key="company.name">
          <div
            class="flex w-full items-center space-x-4"
            :class="company.opened ? 'pt-2' : 'border-b border-gray-800 py-2'"
            @click="company.opened = !company.opened"
          >
            <!-- <input
              type="checkbox"
              class="h-3.5 w-3.5 cursor-pointer"
              :checked="company.selected ? 'checked' : ''"
              @click="company.selected == false ? companyAdded(company) : companyRemoved(company)"
            /> -->
            <div class="flex w-full cursor-pointer items-center space-x-3 py-1">
              <ChatImage :item="company" size="md"></ChatImage>
              <div>
                <div class="text-sm font-semibold text-gray-100">{{ company.name }}</div>
                <div class="text-xs font-medium text-gray-400">{{ company.category }}</div>
              </div>
            </div>
            <div class="pr-5">
              <IconChevronDown
                size="h-5 w-5 cursor-pointer hover:text-white"
                :class="company.opened ? 'rotate-180' : ''"
              ></IconChevronDown>
            </div>
          </div>
          <!-- <div class="border-b border-gray-800"></div> -->
          <div v-if="company.opened || company.selected" class="pb-3">
            <div class="users_selection mb-3 flex flex-wrap items-center overflow-y-scroll" style="max-height: 5rem">
              <div
                v-for="user in company.users.filter(x => x.selected)"
                :key="user.id"
                class="mr-2 mt-2 flex h-8 flex-wrap items-center space-x-1 rounded bg-gray-800 p-1 px-3 hover:bg-gray-700"
              >
                <span class="text-xs font-medium leading-4">{{ user.name }}</span>
                <IconCross
                  size="h-3 w-3 text-gray-400"
                  @click="
                    selection = selection.filter(u => u.id != user.id);
                    user.selected = false;
                  "
                />
              </div>
            </div>
            <div class="flex-col rounded pr-1">
              <label
                v-for="(user, i) in filteredCompanyUsers(company.users)"
                :key="user.id"
                class="flex cursor-pointer p-4 py-3"
                :class="[
                  user.selected ? 'border-blue-900 bg-blue-900/25' : 'border-gray-700 bg-gray-800/50 hover:bg-gray-800',
                  i === filteredCompanyUsers(company.users).length - 1 ? '' : 'border-b'
                ]"
              >
                <div class="flex w-full items-center justify-between space-x-4">
                  <BaseUser
                    :title="user.name"
                    :user="user"
                    :sub-text="user.role_title"
                    :hide-profile="true"
                    :max-text-length="45"
                    :max-title-length="45"
                    base-class="space-x-4"
                    subtext-class="text-gray-500"
                  >
                    <template #image>
                      <ChatImage :item="user" :show-online="true" size="sm"></ChatImage>
                    </template>
                  </BaseUser>
                  <div class="mr-5">
                    <input
                      type="checkbox"
                      class="h-3.5 w-3.5 bg-gray-900 text-sm"
                      v-model="user.selected"
                      @click="setSelectedUser(user)"
                    />
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedTeamTab == 'team'">
      <div class="flex w-full items-center space-x-4 pt-3">
        <div class="flex w-full items-center">
          <div class="flex w-full items-center justify-between">
            <div class="flex items-center space-x-3">
              <ChatImage
                :item="{
                  name:
                    streamUser.companies && streamUser.companies.length > 0
                      ? streamUser.companies[0]
                      : streamUser.team_name,
                  image: streamUser.company_image
                }"
                size="md"
              />
              <div>
                <div class="text-sm font-semibold text-gray-100">
                  {{
                    streamUser.companies && streamUser.companies.length > 0
                      ? streamUser.companies.join(', ')
                      : streamUser.team_name
                  }}
                </div>
                <div class="text-xs font-medium text-gray-400">
                  {{ streamUser.company_categories ? streamUser.company_categories.join(', ') : '' }}
                </div>
              </div>
            </div>
            <div
              class="group mr-1.5 flex cursor-pointer items-center space-x-1"
              v-if="
                channel &&
                (hasAddPermissions || channel.data.add_member_access) &&
                !channel.data.direct_messaging &&
                teamMemberCount != undefined
              "
              @click="generateShareLink(channel.id)"
            >
              <IconLink size="w-4 h-4 text-gray-400 group-hover:text-white" />
              <span class="text-xs text-gray-300 group-hover:text-white">Invite to Chat via Link</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="pt-3 text-xs font-medium leading-4 text-gray-400">
          ({{ filteredUsers.length }} {{ filteredUsers === 1 ? 'Member' : 'Members' }})
        </div>
        <div class="users_selection mb-3 flex flex-wrap items-center overflow-y-scroll" style="max-height: 5rem">
          <div
            v-for="user in teamUsers.filter(x => x.selected)"
            :key="user.id"
            class="mr-2 mt-2 flex h-8 flex-wrap items-center space-x-1 rounded bg-gray-800 p-1 px-3 hover:bg-gray-700"
          >
            <span class="text-xs font-medium leading-4">{{ user.name }}</span>
            <IconCross
              size="h-3 w-3 text-gray-400"
              @click="
                selection = selection.filter(u => u.id != user.id);
                user.selected = false;
              "
            />
          </div>
        </div>

        <div class="overflow-y-auto rounded" :class="calculatedHeight">
          <div class="flex-col">
            <label
              v-for="(user, i) in filteredUsers"
              :key="user.id"
              class="flex cursor-pointer p-4 py-3"
              :class="[
                user.selected ? 'border-blue-900 bg-blue-900/25' : 'border-gray-700 bg-gray-800/50 hover:bg-gray-800',
                i === filteredUsers.length - 1 ? '' : 'border-b'
              ]"
            >
              <div class="flex w-full items-center justify-between space-x-4">
                <BaseUser
                  :title="user.name"
                  :user="user"
                  :sub-text="user.role_title"
                  :hide-profile="true"
                  :max-text-length="45"
                  :max-title-length="45"
                  base-class="space-x-4"
                  subtext-class="text-gray-500"
                >
                  <template #image>
                    <ChatImage :item="user" :show-online="true" size="sm"></ChatImage>
                  </template>
                </BaseUser>
                <div class="mr-5">
                  <input
                    type="checkbox"
                    class="h-3.5 w-3.5 bg-gray-900 text-sm"
                    v-model="user.selected"
                    @click="setSelectedUser(user)"
                  />
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import ChatImage from './ChatImage.vue';
import BaseUser from './ChatBaseUser.vue';
import DropdownBase from '@/components/dropdown/DropdownBase.vue';
import { useMapGetter } from '@/store/map-state';
import { getCompaniesByCategory } from '@/composeables/chat';
import { useStore } from 'vuex';
import { generateShareLink } from '@/composeables/chat';
const store = useStore();
const { streamUser, client } = useMapGetter();
const $emit = defineEmits(['user-selected', 'option-removed', 'team-tab-selected']);
const props = defineProps({
  users: Array,
  teamMemberCount: Number,
  selectedTeamTab: {
    type: String,
    default: 'team'
  },
  searchedTerm: String,
  channel: Object
});
const searchText = ref(props.searchedTerm || '');
const teamTabs = ref([
  { id: 'team', title: 'Team' },
  { id: 'externalteam', title: 'External' }
]);
const companies = ref([]);
const selectedExternalTeamTab = ref('All Companies');
const selectedCompanies = ref([]);
const teamOpened = ref(false);
const teamSelected = ref(false);
const showCategoriesDropdown = ref(false);
const selection = ref([]);
//computed
const filteredUsers = computed(() => {
  return searchText.value !== ''
    ? teamUsers.value.filter(
        x =>
          (x.name && x.name.toLowerCase().includes(searchText.value.toLowerCase())) ||
          (x.companies && x.companies.length
            ? x.companies.map(comp => comp.toLowerCase()).some(x => x.includes(searchText.value.toLowerCase()))
            : false) ||
          (x.role_title ? x.role_title.toLowerCase().includes(searchText.value.toLowerCase()) : false)
      )
    : teamUsers.value;
});
const filteredCompanies = computed(() => {
  return searchText.value !== ''
    ? companies.value.filter(
        comp =>
          comp.users.some(user => user.name.toLowerCase().includes(searchText.value.toLowerCase())) ||
          comp.users.some(user =>
            user.companies && user.companies.length
              ? user.companies.map(comp => comp.toLowerCase()).some(x => x.includes(searchText.value.toLowerCase()))
              : false
          ) ||
          comp.users.some(user =>
            user.role_title ? user.role_title.toLowerCase().includes(searchText.value.toLowerCase()) : false
          )
      )
    : companies.value;
});
const teamUsers = computed(() => {
  return props.users
    .filter(x => x.id != store.state.session.user_id && (x.teams ? x.team_id === streamUser.value.team_id : false))
    .sort((a, b) => a.name.localeCompare(b.name));
});
const externalUsers = computed(() => {
  return props.users.filter(
    x => x.id != store.state.session.user_id && x.teams && x.team_id !== streamUser.value.team_id
  );
});
const companyCategories = computed(() => {
  let companyCatogories = store.state.categories.company;
  let categories = [{ id: 'All Companies', text: 'All Companies' }];
  companyCatogories.forEach(category => {
    categories.push({ id: category.name, text: category.name });
  });
  return categories;
});
const selectedUsers = computed(() => {
  return props.users.filter(u => u.selected);
});
const selectedTeamUsers = computed(() => {
  return props.users.filter(u => u.selected && u.team_id === streamUser.value.team_id);
});
//methods
function companyAdded(company) {
  company.selected = true;
  selectedCompanies.value.push(company.name);
  company.users.map(x => (x.selected = true));
}
function companyRemoved(company) {
  company.selected = false;
  selectedCompanies.value.splice(company.name, 1);
  company.users.map(x => (x.selected = false));
}
function toggleTeamSelection() {
  teamSelected.value = !teamSelected.value;
  if (teamSelected.value) {
    filteredUsers.value.forEach(user => {
      $emit('user-selected', user);
    });
  } else {
    filteredUsers.value.forEach(user => {
      $emit('option-removed', user);
    });
  }
}
function setSelectedUser(user) {
  if (user.selected == false) {
    $emit('user-selected', user);
    selection.value.push(user);
  } else {
    $emit('option-removed', user);
    selection.value = selection.value.filter(u => u.id !== user.id);
  }
}
function filteredCompanyUsers(users) {
  return searchText.value.trim().length > 0
    ? users.filter(
        user =>
          user.name.toLowerCase().includes(searchText.value.toLowerCase()) ||
          (user.companies && user.companies.length
            ? user.companies.map(comp => comp.toLowerCase()).some(x => x.includes(searchText.value.toLowerCase()))
            : false) ||
          (user.role_title ? user.role_title.toLowerCase().includes(searchText.value.toLowerCase()) : false)
      )
    : users;
}

const compananiesLoaded = ref(false);
watch(
  () => props.users,
  () => {
    if (props.users.length && !compananiesLoaded.value) {
      companies.value = getCompaniesByCategory(
        selectedExternalTeamTab.value,
        externalUsers.value,
        selectedCompanies.value
      );
      companies.value.map(c => {
        if (searchText.value != '' && c.name == searchText.value) {
          c.opened = true;
        }
      });
      compananiesLoaded.value = true;
    }
  },
  { immediate: true, deep: true }
);

const calculatedHeight = computed(() => {
  return [
    selectedTeamUsers.value.length > 5
      ? props.teamMemberCount != undefined
        ? 'h-60'
        : 'h-56'
      : selectedTeamUsers.value.length === 0
      ? props.teamMemberCount != undefined
        ? 'h-80'
        : 'h-81'
      : props.teamMemberCount != undefined
      ? 'h-72'
      : 'h-80',
    selectedTeamUsers.value.length > 1 && selectedTeamUsers.value.length != 0 && selectedTeamUsers.value.length <= 5
      ? props.teamMemberCount != undefined
        ? 'h-72'
        : 'h-64'
      : ''
  ];
});

function hasAddPermissions() {
  const channelAdmins = props.channel.data.channel_admins;
  if (channelAdmins && channelAdmins.includes(client.value.user.id) && !props.channel.data.direct_messaging) {
    return true;
  } else {
    return false;
  }
}
</script>
