<template>
  <div class="text-right" @mouseover="hoverActive = true" @mouseleave="hoverActive = false">
    <template v-if="hoverActive">
      <BaseTooltip>
        <template #target>
          {{ ethValue }}<span v-if="item"> {{ ticker }}</span>
        </template>
        <template #default>
          {{ formatNumber(usdValue, 2, '$') }}
        </template>
      </BaseTooltip>
    </template>
    <template v-else>
      {{ ethValue }} <span v-if="item"> {{ ticker }}</span>
    </template>
  </div>
</template>
<script>
import FilterMixin from '@/mixins/filters';
export default {
  mixins: [FilterMixin],
  name: 'CellEth',
  props: {
    item: {
      type: Number,
      default: 0
    },
    ticker: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hoverActive: false
    };
  },
  computed: {
    ethCoin() {
      return this.$store.getters.coins.find(item => item.ticker == this.ticker);
    },
    usdValue() {
      return this.ethCoin && this.item ? this.ethCoin.price * this.item : '';
    },
    ethValue() {
      let formatted = this.item < 1 ? this.formatNumber(this.item, 4, '') : this.formatNumber(this.item, 2, '');
      return formatted == 0 && this.item == 0 ? '-' : formatted;
    }
  }
};
</script>
