<template>
  <div class="flex max-w-md items-center space-x-2 text-white">
    <div @click="handleClick" class="flex flex-1 items-center justify-between space-x-2">
      <div :class="`flex cursor-pointer items-center space-x-1 ${onNews ? 'mr-3' : ''}`" v-if="coin.name">
        <BaseCoinIcon class="h-4 w-4" :coin="coin.uid" loading="lazy" />
        <template v-if="coin.name.length > 12">
          <BaseTooltip position="right">
            <template #target>
              <span class="truncate" :class="onNews ? '' : 'whitespace-nowrap'" label="coin.name">
                {{ truncate(coin.name, 13) }}
              </span>
            </template>
            <template #default>
              {{ coin.name }}
            </template>
          </BaseTooltip>
        </template>
        <template v-else>
          {{ coin.name }}
        </template>
        <CoinVerifiedIcon v-if="isCoinVerified(coin.uid)" target-size="h-3 w-3" class="pl-1" position="top" />
      </div>
      <span :class="`-mb-0.5 pl-2 text-xs text-gray-400 ${onNews ? 'hidden xl:inline' : ''}`">
        {{ coin.ticker }}
      </span>
    </div>

    <div
      class="flex items-center space-x-0.5 pl-2"
      :class="page.url.includes('screener') ? '' : 'pr-5'"
      v-if="showInfo"
    >
      <DropdownTableCell
        :items="dropdownItems"
        :cell-info="coin"
        x-anchor="left"
        :global-emit="false"
        @add="addToWatchlist"
        :custom-style="page.url.includes('screener')"
        @remove="removeFromWatchlist"
        @goto="gotoCoin"
      />
    </div>
  </div>
</template>

<script>
import { isCoinVerified } from '@/composeables/helpers';
import { usePage } from '@inertiajs/inertia-vue3';
import DropdownTableCell from '@/components/dropdown/DropdownTableCell.vue';
import CoinVerifiedIcon from '@/components/coin/CoinVerifiedIcon.vue';
import FilterMixin from '@/mixins/filters';
import { Inertia } from '@inertiajs/inertia';
export default {
  mixins: [FilterMixin],
  name: 'CellCoin',
  components: {
    DropdownTableCell,
    CoinVerifiedIcon
  },
  props: {
    coin: Object,
    showInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      page: usePage()
    };
  },
  methods: {
    // coinClicked() {
    //   this.$store.dispatch('enableCoinSidebar', { coin: this.coin.id, newsItem: 0 });
    // },
    isCoinVerified,
    handleClick() {
      if (this.coin.noLink) {
        this.$emit('coinClicked');
      } else {
        Inertia.visit(this.coin.uid ? '/coin/' + this.coin.uid : 'javascript:');
      }
    },
    gotoCoin() {
      Inertia.visit('/coin/' + this.coin.uid);
    },
    addToWatchlist(coin) {
      this.$store.commit('openModal', {
        name: 'addToWatchlist',
        status: true,
        payload: {
          payload: { ...coin, watchlist_ids: this.watchlist_ids(coin) },
          mode: 'add',
          entity: 'coins'
        }
      });
    },
    removeFromWatchlist(coin) {
      this.$store.commit('openModal', {
        name: 'addToWatchlist',
        status: true,
        payload: {
          payload: { ...coin, watchlist_ids: this.watchlist_ids(coin) },
          mode: 'remove',
          entity: 'coins'
        }
      });
    },
    watchlist_ids(coin) {
      return this.$store.state.data.watchlists.coins
        .filter(x => x.entities.map(x => x.uid).includes(coin.uid))
        .map(i => i.id);
    }
  },
  computed: {
    dropdownItems() {
      return [
        { id: 'remove', text: 'Remove from Watchlist', event: 'remove' },
        { id: 'add', text: 'Add to Watchlist', event: 'add' },
        { id: 'goto', text: 'Go to coin', event: 'goto' }
      ];
    },
    onNews() {
      return this.page.url.includes('news');
    }
  }
};
</script>
