<template>
  <div class="flex select-none space-x-2">
    <div v-if="column.name">
      <span class="text-xs font-medium text-white">
        {{ column.name }}
      </span>
    </div>
    <Dropdown
      :dropdown-items="column.dropdown"
      @handle-action="handleDropdownAction(column, $event)"
      class="text-xs font-medium text-gray-400 duration-100 hover:text-gray-100"
      direction="left"
      max-height="max-h-36"
      :sort-options="column.sortOptions"
      v-if="column.dropdown && column.dropdown.length && column.dropdownType != 'coins'"
    >
      <div class="flex">
        <span><span v-if="column.vs">vs </span>{{ dropdownTitle(column.selectedOption, column.dropdown) }}</span>
        <IconChevronDown class="ml-0" />
      </div>
    </Dropdown>
    <DropdownSearch
      class="-mt-0.5"
      input-class="text-gray-400 hover:text-white text-xs !px-0"
      :hide-clear="true"
      :name="column.vs ? 'vs ' + dropdownSearchTitle.name : dropdownSearchTitle.name"
      @selected="handleAction(column, $event)"
      :items="column.dropdown"
      dropdown-width="7.2rem"
      :truncate-text="true"
      :selected-item="dropdownSearchTitle"
      :down-icon="true"
      :just-text="true"
      dropdown-class="max-h-32"
      dropdown-height="8rem"
      :sort-options="column.sortOptions"
      sort-key="text"
      :x-anchor="`${column.dropdownClass ? column.dropdownClass : 'none'} `"
      v-if="dropdownSearchTitle.name && column.dropdownType == 'coins'"
    />
  </div>
</template>

<script>
import Dropdown from '@/components/dropdown/Dropdown.vue';
import DropdownSearch from '@/components/dropdown/DropdownSearch.vue';
export default {
  name: 'TablePartHeader',
  props: {
    column: Object
  },
  components: { Dropdown, DropdownSearch },
  data: () => {
    return {
      selectedOption: null
    };
  },
  mounted() {
    if (this.column.dropdownType && this.column.selectedOption) {
      this.selectedOption = this.column.selectedOption;
    }
  },
  methods: {
    handleAction(col, option) {
      col.selectedOption = option.id;
      this.selectedOption = option.id;
      this.$emit('handleAction', { id: col.id, selectedOption: option.id });
    },
    handleDropdownAction(col, option) {
      col.selectedOption = option;
      this.$emit('handleAction', { id: col.id, selectedOption: option });
    },
    dropdownTitle(selectedOption, options) {
      return (options.find(metric => metric.id === selectedOption) || {}).text || 'Indicators';
    }
  },
  computed: {
    dropdownSearchTitle() {
      return this.column.dropdown.find(metric => metric.id === this.selectedOption) || {};
    }
  },
  watch: {
    selectedOption(val) {
      if (val) {
        this.$emit('handleAction', { id: this.column.id, selectedOption: val });
      }
    },
    column: {
      handler(val) {
        if (val.selectedOption && this.column.dropdownType) {
          this.selectedOption = this.column.selectedOption;
          this.$emit('handleAction', { id: this.column.id, selectedOption: this.selectedOption });
        }
      },
      deep: true
    }
  }
};
</script>
