<template>
  <div class="flex h-52 w-48 flex-col items-center justify-center space-y-4 rounded border border-gray-700 p-6">
    <ChatImage :item="object" size="lg" :show-online="object.users ? false : true" custom-class="font-medium">
    </ChatImage>
    <div class="w-36">
      <div class="flex items-center justify-center space-x-1 text-xs font-medium">
        <span
          class="cursor-pointer line-clamp-1"
          @click="showObjectInfo"
          style="max-width: 144px"
          :title="object.name"
          >{{ object.name }}</span
        >
      </div>
      <div
        v-if="isUser"
        class="cursor-pointer text-center text-xs font-medium text-gray-400 line-clamp-1"
        :title="getUserSubtitle(object)"
      >
        {{ getUserSubtitle(object) }}
      </div>
      <div
        v-else
        class="cursor-pointer text-center text-xs font-medium text-gray-400 line-clamp-1"
        :title="object.category"
      >
        {{ object.category }}
      </div>
    </div>
    <BaseButton type="secondary" size="sm" @press="clickAction" :busy="buttonBusy">Message</BaseButton>
  </div>
</template>
<script setup>
import { ref, inject } from 'vue';
import ChatImage from './ChatImage.vue';

import { getUserSubtitle } from '@/composeables/chat';
const emitter = inject('eventHub');
const props = defineProps({
  object: Object,
  isUser: Boolean
});

const buttonBusy = ref(false);

function clickAction() {
  if (props.isUser) {
    buttonBusy.value = true;
    emitter.$emit('send-dm', props.object);
  } else {
    emitter.$emit('show-create-channel-modal', props.object.name);
  }
}
function showObjectInfo() {
  // eslint-disable-next-line chai-friendly/no-unused-expressions
  props.isUser ? emitter.$emit('show-user-bio', props.object) : emitter.$emit('show-company-info', props.object);
}
</script>
