<template>
  <div
    :class="[
      item == activeItem
        ? 'border-blue-400 bg-blue-900/25 text-white'
        : 'border-gray-800 text-gray-300 hover:border-blue-400 hover:bg-blue-900/25 hover:text-white',
      customClass
    ]"
    class="w-100 my-1 flex cursor-pointer items-center border-l-2 px-3 font-medium duration-100 hover:bg-blue-900/25 hover:text-white"
  >
    <div class="flex flex-1 select-none flex-row gap-2 pr-2" @click="$emit('select', item)">
      <BaseTooltipClassic :text="title" placement="top" v-if="shouldTruncate">
        <div class="flex items-center space-x-1">
          <span>{{ truncate(title, 30) }}</span>
          <slot name="checkmark-icon" />
        </div>
      </BaseTooltipClassic>
      <div v-else>
        <div class="bg-lime-600 flex items-center space-x-1">
          <span>{{ title }}</span>
          <slot name="checkmark-icon" />
        </div>
      </div>
      <slot name="description" />
    </div>
    <slot></slot>
  </div>
</template>
<script>
import FilterMixin from '@/mixins/filters';
export default {
  mixins: [FilterMixin],
  name: 'BaseListItem',
  props: {
    title: {
      type: String,
      default: 'Unnamed'
    },
    activeItem: {
      type: [Number, String]
    },
    item: {
      type: [Number, String]
    },
    shouldTruncate: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: 'text-sm py-1'
    }
  }
};
</script>
