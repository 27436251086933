export default {
  methods: {
    getTagColor(subgroup) {
      let colors = this.$store.state.tagColors;
      if (subgroup && colors[subgroup]) {
        return colors[subgroup];
      } else {
        return 'text-blue-400';
      }
    },
    getTagBackground(subgroup) {
      let colors = this.$store.state.tagBackgroundColors;
      if (subgroup && colors[subgroup]) {
        return colors[subgroup];
      } else {
        return 'bg-blue-400';
      }
    },
    getSentimentClassAndText(sentiment) {
      let sentimentClasses = this.$store.state.sentimentClasses;
      let text = '';
      if (sentiment >= 80) {
        text = 'Very Bullish';
      } else if (sentiment >= 60) {
        text = 'Bullish';
      } else if (sentiment >= 40) {
        text = 'Neutral';
      } else if (sentiment >= 20) {
        text = 'Bearish';
      } else {
        text = 'Bearish';
      }
      return sentimentClasses[text];
    },
    getCoinInfo(coin_uid) {
      return this.$store.state.data.coins.filter(c => c.uid == coin_uid)[0];
    },
    getCurrencySymbol(cur) {
      return {
        USD: '$',
        GBP: '£',
        Chf: 'CHf'
      }[cur];
    },
    getRandomId() {
      return Math.random().toString(36).substr(0, 8);
    }
  },
  computed: {
    isTheTieTeamMember() {
      return this.$store.getters.roles.includes('tie_team');
    }
  }
};
