<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :overrides="overrides"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      @expand="goToExpandLink"
    >
      <template #body>
        <div class="flex justify-between">
          <div class="flex items-center py-2 pr-2">
            <div
              v-for="tag in tags"
              :key="tag.id"
              @click="setSelectedOption(tag.id)"
              class="mx-2 cursor-pointer rounded text-xs duration-200"
              :class="tag.id === selectedOption ? 'text-gray-100' : 'text-gray-400 hover:text-gray-100'"
            >
              {{ tag.title }}
            </div>
          </div>
          <div class="flex items-center">
            <input
              class="block w-44 rounded border border-gray-700 bg-transparent px-4 py-1 text-xs outline-none focus:border-gray-500 focus:outline-none"
              placeholder="Search Proposals"
              v-model="searchFilter"
            />
          </div>
        </div>
        <template v-if="resourceNotFound">
          <div class="mt-10">
            <WidgetsErrorMessage>{{ titleize(widget.configuration.source) }} is missing.</WidgetsErrorMessage>
          </div>
        </template>

        <template v-if="height >= 150">
          <TableBase
            style="height: calc(100% - 32px)"
            :config="config"
            :data="proposals"
            :loading="loading"
            :go-to-top-button="false"
            @pagination-event="pageProposals($event)"
          />
        </template>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';
import FilterMixin from '@/mixins/filters';
import { useGovernanceApi } from '@/components/tools/governance/GovernanceApi.js';
import { debounce } from 'lodash';

export default {
  name: 'WidgetsGovernanceProposals',
  mixins: [WidgetsMixin, FilterMixin],
  components: {},
  data() {
    return {
      governanceApi: useGovernanceApi(),
      proposals: [],
      watchlists: [],
      watchlistCoins: [],
      advanceLayout: {},
      currentActiveCoin: null,
      readyToDisplay: false,
      resourceNotFound: false,
      searchFilter: '',
      selectedOption: 'all',
      selectedCoins: [],
      selectedWatchlistCoin: [],
      page: 0,
      perPage: 10,
      activeCategory: {
        name: '',
        category_type: ''
      },
      config: {
        alternateBg: true,
        horizontalScroll: true,
        headerClass: 'hidden',
        cols: [
          {
            type: 'governance_widget'
          }
        ],
        perPage: this.perPage
      },
      tags: [
        {
          id: 'all',
          title: 'All',
          enabled: true
        },
        {
          id: 'active',
          title: 'Active'
        },
        {
          id: 'closed',
          title: 'Closed'
        }
      ],
      proposalState: [
        { id: 'all', title: 'All' },
        { id: 'active', title: 'Active' },
        { id: 'closed', title: 'Closed' }
      ]
    };
  },
  mounted() {
    const self = this;
    self.setSelectedCoins();
    self.getSnapshotProposalsData();
  },
  computed: {
    typeOptions() {
      return ['All Available', 'Watchlist', 'Coins'].map(x => {
        return {
          id: x.toLowerCase(),
          text: x
        };
      });
    },
    selectedResource() {
      let config = this.widget.configuration;
      if (config) {
        let id = 0;
        if (config.watchlist) {
          id = config.watchlist.id;
        }
        if (config.layout) {
          id = config.layout.id;
        }
        return { entity: config.source, id: id };
      }
      return null;
    },
    allCoins() {
      return this.$store.getters.coins;
    }
  },
  watch: {
    selectedOption() {
      this.page = 0;
      this.setSelectedCoins();
      this.getSnapshotProposalsData();
    },
    searchFilter() {
      this.page = 0;
      this.updateSearch();
    },
    'widget.configuration': {
      deep: true,
      handler: function () {
        this.page = 0;
        this.setSelectedCoins();
        this.getSnapshotProposalsData();
      }
    },
    widgetChannelOutput() {
      this.page = 0;
      this.setSelectedCoins();
      this.getSnapshotProposalsData();
    }
  },
  methods: {
    updateSearch: debounce(function () {
      this.setSelectedCoins();
      this.getSnapshotProposalsData();
    }, 250),
    pageProposals: debounce(function () {
      this.page++;
      this.setSelectedCoins();
      this.fetchProposals();
    }, 250),
    goToExpandLink() {
      window.open('/tools/governance', '_blank');
    },
    setSelectedCoins() {
      if (this.widgetChannelOutput && this.widgetChannelOutput.length == 1) {
        this.selectedCoins = this.widgetChannelOutput.map(coin => coin.coin_uid);
      } else if (this.widget.configuration && this.widget.configuration.coins) {
        const config = this.widget.configuration;
        this.selectedCoins = config.coins;
      }
    },
    setSelectedOption(tag) {
      this.selectedOption = tag;
      if (!this.viewOnlyMode) {
        this.$http.patch(`/dashboard_widgets/${this.widget.id}`, { configuration: { filter: this.selectedOption } });
      }
    },
    async fetchProposals() {
      const self = this;
      let response;
      try {
        response = await this.governanceApi.getProposalsData(
          'governance_snapshot_proposals',
          this.perPage,
          this.page,
          this.selectedOption,
          this.searchFilter,
          this.selectedCoins
        );
      } catch (error) {
        this.loading = false;
      }
      self.proposals = self.proposals.concat(response.proposals);
    },
    async getSnapshotProposalsData() {
      const self = this;
      let response;
      try {
        response = await this.governanceApi.getProposalsData(
          'governance_snapshot_proposals',
          this.perPage,
          this.page,
          this.selectedOption,
          this.searchFilter,
          this.selectedCoins
        );
      } catch (error) {
        this.loading = false;
      }
      self.proposals = response.proposals;
    },
    async updateWidgetConfig() {
      if (!this.viewOnlyMode) {
        if (this.subscribeOnlyMode) {
          await await this.$http.post('/widget_overrides', {
            widget_override: {
              dashboard_widget_id: this.widget.id,
              overrides: {
                active_coin: this.currentActiveCoin
              }
            }
          });
        } else {
          let payload = {
            configuration: {
              ...this.widget.configuration,
              active_coin: this.currentActiveCoin
            }
          };
          await this.$http.patch(`/dashboard_widgets/${this.widget.id}`, payload);
        }
      }
    },
    async getEntity() {
      if (this.selectedResource && this.selectedResource.id > 0) {
        try {
          this.loading = true;
          this.readyToDisplay = false;
          let response = await this.$http.post('/shared_resources', this.selectedResource);
          if (this.selectedResource.entity == 'watchlist') {
            this.watchlistCoins = response.data.extras;
          }
          if (this.selectedResource.entity == 'layout') {
            this.watchlists = response.data.extras;
            this.advanceLayout = response.data.resource;
          }
          this.readyToDisplay = true;
          this.loading = false;
        } catch {
          this.loading = false;
          this.resourceNotFound = true;
        }
      }
    }
  }
};
</script>
