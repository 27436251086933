<template>
  <div :id="dropdownId" class="w-48 cursor-pointer" @click="toggleDropdown = !toggleDropdown">
    <div class="flex items-center justify-between rounded bg-gray-800 py-2 px-2 text-sm font-medium">
      <div v-if="selectedChainItem" class="flex space-x-2">
        <img :src="selectedChainItem.image" class="inline h-4 w-4 rounded-full" loading="lazy" alt="..." />
        <div class="whitespace-nowrap text-xs font-medium leading-4">
          {{ selectedChainItem.text }}
        </div>
      </div>
      <IconChevronDown :class="[toggleDropdown ? 'rotate-180 duration-100' : '', 'h-5 w-5 transform']" />
    </div>
  </div>
  <div v-if="toggleDropdown">
    <DropdownBase
      :items="filteredChainOptions"
      custom-classes="ml-2 mt-1 w-48"
      custom-dropdown-item-class="cursor-pointer"
      x-anchor="left"
      @clicked="handleItemSelect($event)"
    >
    </DropdownBase>
  </div>
</template>

<script setup>
import DropdownBase from '@/components/dropdown/DropdownBase.vue';
import { ref, computed, watch, onBeforeMount } from 'vue';

const $emit = defineEmits(['select']);
const props = defineProps({
  supportedChains: { type: Array, default: () => [] }
});

//CHAIN OPTIONS
const allChains = [
  { id: 'ethereum', text: 'Ethereum', image: 'https://terminal.cloudfront.thetie.io/coin_images/ethereum.png' },
  {
    id: 'avalanche',
    text: 'Avalanche (C-Chain)',
    image: 'https://terminal.cloudfront.thetie.io/coin_images/avalanche.png'
  },
  {
    id: 'polygon',
    text: 'Polygon',
    image: 'https://terminal.cloudfront.thetie.io/coin_images/maticnetwork.png'
  }
];
const filteredChainOptions = computed(() => {
  let filteredItems = allChains;
  if (props.supportedChains.length > 0) {
    filteredItems = allChains.filter(x => props.supportedChains.includes(x.id));
  }
  let mappedItems = filteredItems.map(x => {
    return {
      ...x,
      html: `<div class="flex items-center space-x-2">
              <div class="flex flex space-x-2 items-center">
                <img src= ${x.image} class="inline rounded-full w-4 h-4" loading="lazy" alt="..." />
                <div class="break-words font-semibold text-gray-100 text-xs"> ${x.text}</div>
              </div>
            </div>`
    };
  });
  return mappedItems;
});

//DROPDOWN
const dropdownId = ref(`profile_dropdown_${Math.random()}`);
const toggleDropdown = ref(false);

//SELECTED ITEM
const selectedChain = ref('');
watch(selectedChain, () => {
  if (selectedChain.value != '') {
    localStorage.setItem('selected_chain', selectedChain.value);
  }
});

const selectedChainItem = computed(() => {
  return filteredChainOptions.value.find(x => x.id == selectedChain.value);
});

onBeforeMount(() => {
  let activeChain = '';
  if (localStorage.selected_chain) {
    activeChain = localStorage.selected_chain;
  }
  if (filteredChainOptions.value.length > 0) {
    //override if chain is not supported
    if (!filteredChainOptions.value.map(x => x.id).includes(activeChain)) {
      activeChain = filteredChainOptions.value[0].id;
    }
  }
  selectedChain.value = activeChain;
  $emit('select', selectedChain.value);
});

//ITEM SELECT
function handleItemSelect(event) {
  selectedChain.value = event;
  toggleDropdown.value = false;
  $emit('select', event);
}
</script>
