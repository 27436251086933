<template>
  <ModalVideoPlayer
    :show="showVideo"
    @close="showVideo = false"
    :iframe-video="!videoTagLink"
    :video-link="videoLink"
  />
  <div
    v-if="validVideoLink"
    class="absolute flex h-full w-full items-center justify-center"
    @click="if (validVideoLink) showVideo = true;"
  >
    <IconPlay :size="'h-16 w-16'" v-if="videoTagLink"></IconPlay>
  </div>
  <div class="absolute inset-0 z-10 animate-pulse rounded-lg bg-gray-800" v-if="loading"></div>
  <video
    class="aspect-video h-full w-full rounded-lg object-cover"
    playsinline
    preload="metadata"
    :src="videoLink + '#t=5'"
    @error="
      validVideoLink = false;
      loading = false;
    "
    @loadeddata="loading = false"
    @click="if (validVideoLink) showVideo = true;"
    v-if="validVideoLink && videoTagLink"
  ></video>
  <iframe
    v-else-if="validVideoLink"
    :src="videoLink"
    frameborder="0"
    @load="loading = false"
    class="aspect-video h-full w-full rounded-lg object-cover"
  ></iframe>
  <div
    v-else
    class="flex h-full w-full flex-col items-center justify-center rounded-lg border border-gray-800 text-gray-400"
  >
    <IconPlaySlash />
    <div>Sorry! video can't be played</div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import ModalVideoPlayer from '../modal/ModalVideoPlayer.vue';

const validVideoLink = ref(true);
const showVideo = ref(false);
const loading = ref(true);

const props = defineProps({
  videoLink: {
    type: Object,
    required: true
  }
});

const videoFormats = ref(['ogg', 'mp4', 'webm']);

const videoTagLink = computed(() => {
  for (const ext of videoFormats.value) {
    if (props.videoLink.endsWith(ext)) {
      return true;
    }
  }
  return false;
});
</script>
