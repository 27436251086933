<template>
  <DropdownMetricTooltip :placement="tooltipPlacement ? tooltipPlacement : `right`" :id="tooltipKey">
    <span class="align-tooltip">
      <IconInfo size="h-3 w-3" class="cursor-pointer text-gray-400 hover:text-gray-100" />
    </span>
  </DropdownMetricTooltip>
</template>

<script>
import DropdownMetricTooltip from '@/components/dropdown/DropdownMetricTooltip.vue';
export default {
  props: {
    title: String,
    tooltipKey: String,
    tooltipPlacement: String
  },
  components: {
    DropdownMetricTooltip
  }
};
</script>
<style>
.align-tooltip {
  position: relative;
  top: 2px;
}
</style>
