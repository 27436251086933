<template>
  <div
    v-if="broadcastChannelAndAdmin(channel)"
    class="relative h-full border-l border-gray-600"
    style="width: 400px; min-width: 300px"
  >
    <div class="flex items-center justify-between px-4 py-2 pl-3" v-if="!onlyShowMembers">
      <div
        v-if="!editMode"
        @click="$emit('toggle-chat-settings')"
        class="flex cursor-pointer items-center space-x-1 text-gray-400 hover:text-gray-300"
      >
        <IconChevronRight class="h-4 w-4"></IconChevronRight>
        <span class="text-xs font-medium">Hide</span>
      </div>
      <div
        v-else
        @click="cancelSettingsUpdate()"
        class="flex items-center space-x-1 text-gray-400"
        :class="disabled ? 'opacity-50' : 'cursor-pointer hover:text-gray-300'"
      >
        <span class="text-xs font-medium">Cancel</span>
      </div>
      <div class="flex items-center space-x-1 text-gray-400 hover:text-gray-300">
        <BaseLoader v-if="disabled" size="w-3 h-3" />
        <span
          v-if="hasPermissions && !editMode"
          @click="editMode = true"
          class="cursor-pointer text-xs font-medium hover:underline"
        >
          Edit
        </span>
        <span
          v-else-if="hasPermissions && editMode"
          @click="saveSettings()"
          class="text-xs font-medium text-blue-400"
          :class="disabled ? 'opacity-50' : 'cursor-pointer hover:underline'"
        >
          Save
        </span>
      </div>
    </div>
    <div class="flex flex-col space-y-4 overflow-y-auto rounded p-4" style="max-height: 84%">
      <div class="relative flex justify-center">
        <ImageSelector
          v-if="imageObject"
          :channel="imageObject"
          :edit-mode="editMode"
          :channel-name="channelName"
          @selected-image="setImage"
        >
        </ImageSelector>
      </div>
      <div>
        <input
          v-if="editMode"
          class="mt-2 h-8 w-full rounded border-none bg-gray-800 px-3 text-base font-medium text-gray-100 outline-none focus:border-none"
          v-model="editableChannelName"
        />
        <BaseTooltip v-else>
          <template #target>
            <span class="cursor-pointer truncate whitespace-nowrap text-base font-medium text-gray-100">
              {{ truncate(channelName, 30) }}
            </span>
          </template>
          <template #default v-if="channelName.length > 30">{{ channelName }}</template>
        </BaseTooltip>
        <span v-show="channelNameError.trim().length" class="text-xxs font-medium text-red-400">{{
          channelNameError
        }}</span>
        <div class="border-b border-gray-700 pb-4 text-xs font-medium text-gray-400" :class="editMode ? 'mt-2' : ''">
          Created on {{ creationDate }}.
        </div>
      </div>
      <div class="flex flex-col space-y-2">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-1">
            <!-- <IconChevronDown v-if="showMembers" class="ml-0 h-4 w-4 text-gray-400 hover:text-white" />
            <IconChevronRight v-else class="ml-0 h-3.5 w-3.5 text-gray-300 hover:text-white" /> -->
            <div class="text-xs font-semibold uppercase tracking-wide text-gray-400">
              {{ channelMemberCount }}
            </div>
          </div>
          <div class="flex items-center">
            <div
              v-if="
                (hasPermissions || channel.data.add_member_access) &&
                !channel.data.direct_messaging &&
                !showRequestView &&
                !onlyShowMembers
              "
            >
              <IconLink
                size="mr-1 h-3.5 w-3.5 cursor-pointer text-gray-400 hover:text-white"
                @click="showCopyLinkModal = true"
              />
            </div>
            <div
              v-if="
                (hasPermissions || channel.data.add_member_access || channel.data.direct_messaging) &&
                !showRequestView &&
                !onlyShowMembers
              "
              class="cursor-pointer"
              title="Add Members"
              @click="emitter.$emit('add-members', channel.cid)"
            >
              <IconUserAdd class="h-4 w-4 text-gray-400 hover:text-gray-300"></IconUserAdd>
            </div>
          </div>
        </div>
        <div class="space-y-4 pl-1" v-if="showMembers">
          <div class="space-y-2" v-if="filteredChannelUsers && filteredChannelUsers.length > 0">
            <ChannelMember
              v-for="user in filteredChannelUsers"
              :key="user.user_id"
              :user="user.user"
              :has-permissions="hasPermissions"
              :channel="channel"
              @leave-chat="leaveChannel($event)"
              @toggle-admin="toggleAdmin($event)"
            ></ChannelMember>
          </div>
        </div>
        <div v-if="invitedUsers.length > 0 && !onlyShowMembers">
          <div class="pb-2 text-xs font-semibold uppercase tracking-wide text-gray-400">Pending Members</div>
          <div class="space-y-2">
            <ChannelMember
              v-for="user in invitedUsers"
              :key="user.user_id"
              :user="user.user"
              :has-permissions="hasPermissions"
              :channel="channel"
              @leave-chat="leaveChannel($event)"
            ></ChannelMember>
          </div>
        </div>
      </div>
      <div v-if="editMode">
        <div class="pb-2 text-xs font-medium uppercase tracking-wider text-gray-400">User Settings</div>
        <ChannelPermissions
          class="p-1 text-left text-sm font-medium text-gray-400"
          custom-style="items-start"
          :user-settings="settings"
          @settings-updated="settings = $event"
        ></ChannelPermissions>
      </div>
    </div>
    <div class="absolute inset-x-0 bottom-0 mb-4 flex justify-between rounded bg-gray-900 p-4 pl-3 text-xs font-medium">
      <div
        v-if="!channel.data.direct_messaging && !showRequestView && !onlyShowMembers"
        @click="leaveChannel(client.user)"
        class="flex items-center space-x-2"
        :class="editMode ? 'text-gray-700' : 'cursor-pointer text-gray-400 hover:text-gray-300'"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
            clip-rule="evenodd"
          />
        </svg>
        <div>Leave Chat</div>
      </div>
      <div
        v-if="editMode"
        :class="disabled ? 'opacity-50' : 'cursor-pointer'"
        class="flex items-center space-x-2 text-red-400"
        @click="deleteChannel"
      >
        <icon-cross></icon-cross>
        <div>Delete this Chat</div>
      </div>
    </div>
  </div>
  <ChatInviteLinkDialog :channel-id="channel.id" @close="showCopyLinkModal = false" v-if="showCopyLinkModal" />
</template>

<script setup>
import { ref, watch, computed, onMounted, inject } from 'vue';
import ordinal from 'ordinal';
import ChannelPermissions from './ChatChannelPermissions.vue';
import ChannelMember from './ChatChannelSettingsMember.vue';
import ImageSelector from './ChannelImageSelector.vue';
import IconChevronDown from '@/components/icon/IconChevronDown.vue';
import { debounce } from 'lodash';

import { useMapGetter } from '@/store/map-state';
import { getChannelName, getImageObject, broadcastChannelAndAdmin } from '@/composeables/chat';
import { truncate } from '@/composeables/filters';
import { useStore } from 'vuex';
import ChatInviteLinkDialog from './ChatInviteLinkDialog.vue';
const { client, streamUser } = useMapGetter();
const store = useStore();
const emitter = inject('eventHub');
const $emit = defineEmits(['toggle-chat-settings']);
const props = defineProps({
  channel: Object,
  channelName: String,
  chosenChannel: Object,
  showRequestView: Boolean,
  userId: String
});
const showMembers = ref(true);
const channelAdmins = ref(props.channel.data.channel_admins);
const channelMembers = ref(Object.values(props.channel.state.members));
const editableChannelName = ref('');
const selectedImage = ref(null);
const editMode = ref(false);
const imageObject = ref(null);
const channelNameError = ref('');
const disabled = ref(false);
const showCopyLinkModal = ref(false);
const settings = ref({
  message_history_access: props.channel.data.message_history_access,
  add_member_access: props.channel.data.add_member_access
});
const onlyShowMembers = ref(props.channel.data.broadcast_channel);
onMounted(() => {
  imageObject.value = getImageObject(props.channel);
  emitter.$on('removeFromChat', data => {
    leaveChannel(data);
  });
  props.channel.on('channel.updated', () => {
    imageObject.value = getImageObject(props.channel);
    channelMembers.value = Object.values(props.channel.state.members);
    channelAdmins.value = props.channel.data.channel_admins;
  });
  client.value.on('user.updated', async event => {
    let members = Object.values(props.channel.state.members).map(x => x.user_id);
    if (members.includes(event.user.id)) {
      imageObject.value = getImageObject(props.channel);
      channelMembers.value = Object.values(props.channel.state.members);
      channelAdmins.value = props.channel.data.channel_admins;
    }
  });

  editableChannelName.value = props.channelName;
});
//methods
function setImage(data) {
  selectedImage.value = data;
}
async function deleteChannel() {
  if (!disabled.value) {
    await store.dispatch('confirm', 'Are you sure you want to delete this Chat?');
    await props.channel.delete();
  }
}
async function leaveChannel(user) {
  if (!editMode.value) {
    let channelMember = Object.values(props.channel.state.members).find(x => x.user_id == user.id);
    let isInvited = channelMember ? channelMember.invited : false;
    emitter.$emit('leave-channel', {
      channel: props.channel,
      id: user.id,
      name: user.name,
      invited: isInvited
    });
    if (user.id == props.userId) {
      $emit('toggle-chat-settings');
    }
  }
}
async function toggleAdmin(user) {
  let message = '';
  let admins = channelAdmins.value;
  if (!admins) {
    admins = [user.id];
    message = 'added';
  } else if (admins.includes(user.id)) {
    admins = admins.filter(x => x != user.id);
    message = 'removed';
  } else {
    admins.push(user.id);
    message = 'added';
  }
  props.channel.updatePartial({
    set: {
      channel_admins: admins
    }
  });
  let msgText =
    message === 'added'
      ? `{{user:${user.id}}} was promoted to admin in the chat.`
      : `{{user:${user.id}}} was demoted to member in the chat.`;
  props.channel.sendMessage({
    text: msgText,
    activity_status: message === 'added' ? 'promoted' : 'demoted',
    activity_members: [{ id: user.id, name: user.name }]
  });
}
async function saveSettings() {
  if (editableChannelName.value.trim().length == 0) {
    channelNameError.value = 'Channel name can not be empty';
    return;
  } else if (!disabled.value) {
    disabled.value = true;
    await savePermissions();
    await updateChannelName();
    await updateImage();
    editMode.value = false;
    disabled.value = false;
  }
}
function cancelSettingsUpdate() {
  if (!disabled.value) {
    editMode.value = false;
    selectedImage.value = null;
    editableChannelName.value = props.channelName;
    settings.value = {
      message_history_access: props.channel.data.message_history_access,
      add_member_access: props.channel.data.add_member_access
    };
  }
}
async function savePermissions() {
  await props.channel.updatePartial({
    set: {
      message_history_access: settings.value.message_history_access,
      add_member_access: settings.value.add_member_access
    }
  });
}
async function updateChannelName() {
  if (editableChannelName.value != '' && editableChannelName.value != props.channelName) {
    await props.channel.updatePartial({
      set: {
        name: editableChannelName.value,
        nameUpdated: true
      }
    });
  }
}
async function updateImage() {
  if (selectedImage.value) {
    const response = await props.channel.sendImage(selectedImage.value.file);
    await props.channel.updatePartial({
      set: {
        image: response.file
      }
    });
  }
}
//computed
const creationDate = computed(() => {
  var date = new Date(props.channel.data.created_at);
  const month = date.toLocaleString('default', { month: 'long', timezone: 'UTC' });
  return month + ' ' + ordinal(date.getUTCDate()) + ', ' + date.getUTCFullYear();
});
const channelMemberCount = computed(() => {
  let rejectedUsers = channelMembers.value ? channelMembers.value.filter(x => x.invited && x.invite_rejected_at) : [];
  let count = props.channel.data.member_count - rejectedUsers.length;
  return count > 1 ? count + ' Members' : count + ' Member';
});
const filteredChannelUsers = computed(() => {
  let users = channelMembers.value ? channelMembers.value.filter(x => !(x.invited && !x.invite_accepted_at)) : [];
  users.sort((a, b) => Number(b.user.online) - Number(a.user.online) || a.user.name.localeCompare(b.user.name));
  return users;
});
const invitedUsers = computed(() => {
  let users = channelMembers.value
    ? channelMembers.value.filter(x => x.invited && !x.invite_accepted_at && !x.invite_rejected_at)
    : [];
  users.sort((a, b) => Number(b.user.online) - Number(a.user.online) || a.user.name.localeCompare(b.user.name));
  return users;
});
const currentUser = computed(() => {
  return channelMembers.value ? channelMembers.value.find(x => x.user_id == props.userId) : [];
});
const hasPermissions = computed(() => {
  if (
    channelAdmins.value &&
    channelAdmins.value.includes(client.value.user.id) &&
    !props.channel.data.direct_messaging
  ) {
    return true;
  } else {
    return false;
  }
});
watch(
  () => editableChannelName.value,
  () => {
    if (editableChannelName.value.trim().length) {
      channelNameError.value = '';
    }
  }
);
watch(
  () => props.channelName,
  () => {
    editableChannelName.value = getChannelName(props.channel);
  }
);
</script>
