<template>
  <div class="mt-3">
    <div class="flex">
      <div class="text-sm capitalize">
        {{ title }}
      </div>
      <div class="ml-auto mt-auto flex text-xs">
        <div class="mr-2" :class="'text-' + color + '-400'">{{ comment }}</div>
      </div>
    </div>
    <BaseSentimentSlider :value="value" v-if="value" />
  </div>
</template>

<script>
export default {
  props: {
    property: String,
    value: Number
  },
  data() {
    return {
      comments: [
        {
          value: 80,
          color: 'green',
          comment: 'Very Bullish'
        },
        {
          value: 60,
          color: 'green',
          comment: 'Bullish'
        },
        {
          value: 40,
          color: 'yellow',
          comment: 'Neutral'
        },
        {
          value: 20,
          color: 'red',
          comment: 'Bearish'
        },
        {
          value: 0,
          color: 'red',
          comment: 'Very Bearish'
        }
      ]
    };
  },
  computed: {
    title() {
      return this.property.split('_').slice(0, -1).join(' ');
    },
    color: function () {
      return this.value ? this.comments.find(c => c.value <= this.value).color : '';
    },
    comment: function () {
      return this.value ? this.comments.find(c => c.value <= this.value).comment : '';
    }
  }
};
</script>
