<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :overrides="overrides"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="coinsLoader"
      @expand="goToExpandLink"
    >
      <template #body>
        <template v-if="coin">
          <a :href="`/coin/${coin.coin_uid}`" target="_blank">
            <div class="flex items-center pt-2">
              <BaseCoinIcon :coin="coin.coin_uid" class="inline h-4 w-4" />
              <div class="block text-sm font-semibold text-gray-100">
                {{ coin.coin.name }}
                <!-- <span class="capitalize text-gray-400 ml-1">{{ coin.coin.ticker }}</span> -->
              </div>
            </div>
          </a>
          <div class="space-y-2" v-show="width <= 300">
            <div class="flex justify-between">
              <div>
                <span class="block text-lg font-semibold text-gray-100">{{ usdCryptoPrice(coin.price) }}</span>
                <span class="block text-sm"><BasePercentClassic :percent="coin.price_return_24_hours" /></span>
              </div>
              <div class="text-center">
                <span class="block text-xs text-gray-400">Market Cap</span>
                <span class="block font-semibold text-gray-100">
                  {{ formatNumber(coin.market_cap, 2, '$') }}
                </span>
              </div>
            </div>
            <div class="h-32 w-full">
              <ChartLine
                :series="chartSeries"
                :config="{ yAxisTitle: 'Price', yAxisFormat: 'MMM DD, YYYY H:mm', animation: false }"
              />
            </div>
          </div>
          <div class="space-y-2" v-show="width > 300">
            <div class="flex items-center justify-between">
              <div class="w-1/2">
                <span class="block text-xl font-semibold text-gray-100">{{ usdCryptoPrice(coin.price) }}</span>
                <span class="block"><BasePercentClassic :percent="coin.price_return_24_hours" /></span>
              </div>
              <div class="w-1/2">
                <div class="h-24 w-full">
                  <ChartLine
                    :series="chartSeries"
                    :config="{ yAxisTitle: 'Price', yAxisFormat: 'MMM DD, YYYY H:mm', animation: false }"
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-around">
              <div class="flex flex-col items-center">
                <span class="block text-xs text-gray-400">Market Cap</span>
                <span class="block font-semibold text-gray-100">
                  {{ formatNumber(coin.market_cap, 2, '$') }}
                </span>
              </div>
              <div class="flex flex-col items-center">
                <span class="block text-xs text-gray-400">Tweets</span>
                <span class="block font-semibold text-gray-100">
                  {{ rounded(Number(coin.tweet_volume), 0) }}
                </span>
              </div>
              <div class="flex flex-col items-center">
                <span class="block text-xs text-gray-400">Daily Sentiment</span>
                <span class="block font-semibold text-gray-100">
                  <CellSentiment :sentiment="coin.daily_sentiment" />
                </span>
              </div>
            </div>
          </div>
          <div v-show="height >= 200" class="mt-4">
            <table class="w-full">
              <thead class="font-semibold text-gray-100">
                <th class="text-left">Return</th>
                <th class="text-right">vs USD</th>
                <th class="text-right">vs BTC</th>
              </thead>
              <tbody>
                <tr class="text-xs">
                  <td class="text-gray-400">1hr Change</td>
                  <td class="text-right"><BasePercentClassic :percent="coin.price_return_1_hour" /></td>
                  <td class="text-right"><BasePercentClassic :percent="coin.price_return_btc_1_hour" /></td>
                </tr>
                <tr class="text-xs">
                  <td class="text-gray-400">24hr Change</td>
                  <td class="text-right"><BasePercentClassic :percent="coin.price_return_24_hours" /></td>
                  <td class="text-right"><BasePercentClassic :percent="coin.price_return_btc_24_hours" /></td>
                </tr>
                <tr class="text-xs">
                  <td class="text-gray-400">7d Change</td>
                  <td class="text-right"><BasePercentClassic :percent="coin.price_return_7_days" /></td>
                  <td class="text-right"><BasePercentClassic :percent="coin.price_return_btc_7_days" /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-show="height >= 200" class="mt-4">
            <table class="w-full">
              <thead class="font-semibold text-gray-100">
                <th class="text-left" colspan="2">Key Stats</th>
              </thead>
              <tbody>
                <tr class="text-xs">
                  <td class="text-gray-400">Tweets</td>
                  <td class="text-right">
                    {{ rounded(Number(coin.tweet_volume), 0) }}
                    <span v-if="coin.relative_tweet_volume">
                      (<BasePercentClassic :percent="coin.relative_tweet_volume" />)
                    </span>
                  </td>
                </tr>
                <tr class="text-xs">
                  <td class="text-gray-400">Daily Sentiment</td>
                  <td class="text-right"><CellSentiment :sentiment="coin.daily_sentiment" /></td>
                </tr>
                <tr class="text-xs">
                  <td class="text-gray-400">Volume</td>
                  <td class="text-right">
                    {{ formatNumber(coin.trading_volume, 2, '$') }}
                    <span v-if="coin.relative_trade_volume">
                      (<BasePercentClassic :percent="coin.relative_trade_volume" />)
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <template v-else>
          <WidgetsErrorMessage>Select a coin to load profile</WidgetsErrorMessage>
        </template>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import ChartLine from '@/components/chart/ChartLine.vue';
import WidgetsMixin from '@/mixins/widgets';
import CellSentiment from '@/components/table/cell/CellSentiment.vue';
import FilterMixin from '@/mixins/filters';
export default {
  name: 'WidgetsCoinProfile',
  mixins: [WidgetsMixin, FilterMixin],
  components: {
    ChartLine,
    CellSentiment
  },
  mounted() {
    if (this.coinSelected) {
      this.getCoinHistory();
      this.coinUid = this.widget.configuration?.coin[0];
    }
    setInterval(() => {
      this.getCoinHistory();
    }, 60 * 1000);
  },
  data() {
    return {
      chartData: [],
      coinUid: null
    };
  },
  computed: {
    coinSelected() {
      return this.widget.configuration && this.widget.configuration.valid;
    },
    payloadForChart() {
      let start = new Date(new Date() - 1000 * 60 * 60);
      let end = new Date();
      return {
        coin_uids: this.coin ? [this.coin.coin_uid] : [],
        frequency: '5min',
        items: 'price,daily_sentiment',
        start_datetime: new Date(start.getTime()),
        end_datetime: new Date(end),
        timeframe: '1H'
      };
    },
    chartSeries() {
      let series = this.chartData.map(x => {
        return [new Date(x.datetime).getTime(), parseFloat(x.price)];
      });
      return {
        data: series,
        name: 'Price',
        type: 'line'
      };
    },
    coinsLoader() {
      return this.$store.getters.coinsLoader;
    },
    coin() {
      //assumes coin is an array
      if (!this.coinsLoader && this.coinUid) {
        return this.$store.getters.coins.find(x => x.coin_uid == this.coinUid);
      }
      return null;
    },
    payloadForOutput() {
      if (!this.coin) {
        return null;
      }
      return {
        id: this.coin.id,
        ticker: this.coin.ticker,
        coin_uid: this.coin.coin_uid,
        tag_id: this.coin.tag_id
      };
    }
  },
  watch: {
    'widget.configuration.coin'() {
      this.coinUid = this.widget.configuration?.coin[0] || null;
    },
    widgetChannelOutput() {
      if (this.widgetChannelOutput && this.widgetChannelOutput.length) {
        this.coinUid = this.widgetChannelOutput[0]['coin_uid'];
      } else {
        this.coinUid = this.widget.configuration?.coin[0];
      }
    },
    'coin.ticker'() {
      this.$nextTick(() => {
        this.getCoinHistory();
      });
    }
  },
  methods: {
    goToExpandLink() {
      window.open(`/coin/${this.coin.coin_uid}`, '_blank');
    },
    handleChannelUpdate(payload) {
      this.$store.commit('setWidgetOutputChannel', { channel: payload.to, value: [this.payloadForOutput] });
    },
    async getCoinHistory() {
      if (this.payloadForChart.coin_uids.length > 0) {
        try {
          let response = await this.$http.post('/coins/historical_grouped', this.payloadForChart);
          this.chartData = response.data.data;
        } catch {
          //do nothing
        }
      }
    }
  }
};
</script>
