<template>
  <div class="space-y-2">
    <div class="mt-4 text-sm">
      <div class="pt-1.5 pl-2 text-sm font-medium">{{ title }}</div>
      <BaseDatepicker
        @change="
          (date, type) => {
            selectedDate = date;
          }
        "
        :default-date="dateRange"
        :disabled-future-date="date => date >= new Date()"
        format="MM/D/YY"
        font-size="text-xs"
        :custom-slots="true"
        @close="updateFilters"
        :key="selectedDate"
      >
      </BaseDatepicker>
    </div>
    <div class="flex h-40 justify-center pt-2">
      <BaseLoader :background="800" v-if="loading" />
      <ChartMultiline
        v-else-if="timeSeries"
        :series="timeSeries"
        :config="lineChartConfig"
        :key="graphId"
        gas-fee-date-format="true"
        :water-mark-font-size="12"
      />
    </div>
    <div class="flex justify-end">
      <div>
        <a class="" target="_blank" href="https://zapper.fi/">
          <img src="../../images/powered_by_zap_black.svg" class="h-8" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import ChartMultiline from '@/components/chart/ChartMultiline.vue';
export default {
  name: 'ChartGasFee',
  props: {
    title: {
      type: String,
      default: 'Historical Gas Fee Chart'
    }
  },
  mounted() {
    this.getChartData(this.dateRange);
  },
  components: {
    ChartMultiline
  },
  data() {
    return {
      selectedDate: [],
      graphId: `gas_fee_chart_${Math.random()}`,
      loading: true,
      interval: 0,
      timeSeries: [],
      dropdownValues: [
        { text: '1m', id: '1min' },
        { text: '5m', id: '5min' },
        { text: '1h', id: '1hour' }
      ],
      labelConfig: [{ enabled: true, style: { color: '#ffff' } }, { enabled: false }, { enabled: false }],
      graphColorsScheme: ['#BFDBFE', '#3B82F6', '#1E40AF'],
      graphLineWidth: 1,
      selectedGasFeeValue: localStorage.getItem('gasFeeSelectedFreq') || '1min'
    };
  },
  computed: {
    lineChartConfig() {
      return {
        yAxis: true,
        xAxis: true,
        yAxisLabels: true,
        customTooltip: false
      };
    },
    dateRange() {
      if (this.selectedDate.length) {
        return this.selectedDate.map(date => {
          return moment(date).format('YYYY-MM-DD HH:mm:ss');
        });
      } else {
        let defaultDateRange = [];
        let startOfDay = moment().subtract(0, 'day').format('YYYY-MM-DD HH:mm:ss');
        let endOfDay = moment().format('YYYY-MM-DD HH:mm:ss');
        defaultDateRange.push(startOfDay, endOfDay);
        return defaultDateRange;
      }
    }
  },
  methods: {
    async getChartData(selected_dates) {
      const self = this;
      self.loading = true;
      self.timeSeries = [];
      const response = await this.$http.post('/gas_fee_chart_data', { selected_dates: selected_dates });
      self.timeSeries = response.data.data.map((x, i) => {
        return {
          ...x,
          label: self.labelConfig[i],
          color: self.graphColorsScheme[i],
          lineWidth: 1
        };
      });
      self.loading = false;
    },
    updateFilters() {
      let default_date = this.dateRange;
      this.getChartData(default_date);
    }
  }
};
</script>
