<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :loading="false"
      :connectable="false"
      :horizontal-padding="false"
      @expand="goToExpandLink"
      @paginate="loadMoreArticles"
    >
      <template #body>
        <div class="px-2">
          <BaseTags :tags="tags" :selected="[filter]" @toggle="filter = $event" class="mt-2 pb-1" />
        </div>
        <div class="grid grid-cols-1 gap-4">
          <div v-for="article in articles" :key="article.slug" class="group w-full">
            <div class="flex space-x-2 rounded px-4 py-2 group-hover:bg-gray-800">
              <div v-if="width >= 300" class="relative w-1/3">
                <InertiaLink :href="`/research/article/${article.slug}`">
                  <div class="relative aspect-video">
                    <img class="h-full w-full rounded object-cover" :src="article.company_cover_image" />
                    <div
                      v-if="articleCoins(article).length > 0"
                      class="absolute right-0 bottom-0 mb-1 flex items-center space-x-2 rounded-l-full bg-black bg-opacity-50 px-2 py-1"
                    >
                      <BaseCoinIcon
                        v-for="coin in articleCoins(article)"
                        :key="coin.id"
                        :coin="coin.uid"
                        class="h-4 w-4"
                      />
                    </div>
                  </div>
                </InertiaLink>
              </div>
              <div :class="width < 300 ? 'w-full' : 'w-2/3'" class="space-y-2 px-1">
                <div :class="width >= 400 ? 'text-sm' : 'text-xs'" class="font-semibold">
                  <InertiaLink :href="`/research/article/${article.slug}`">
                    {{ article.title }}
                  </InertiaLink>
                </div>
                <div class="flex items-center justify-between text-xs text-gray-400">
                  <span class="cursor-pointer truncate">
                    <InertiaLink :href="`/company/${article.company_slug}`">
                      {{ article.company_name }}
                    </InertiaLink>
                  </span>
                  <span class="whitespace-nowrap">{{ relativeTime(article.created_at) }}</span>
                </div>
                <div :class="['text-gray-100 line-clamp-3', width >= 400 ? 'text-sm' : 'text-xs']">
                  <p>{{ article.short_description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loading" class="grid grid-cols-1 px-4">
            <LoadTemplatesResearchCard v-for="i in 4" :key="i" loader-style="list" />
          </div>
        </div>
        <WidgetsErrorMessage v-if="articles.length === 0">No research found</WidgetsErrorMessage>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import { Link as InertiaLink } from '@inertiajs/inertia-vue3';
import WidgetsMixin from '@/mixins/widgets';
import FiltersMixin from '@/mixins/filters';
import LoadTemplatesResearchCard from '@/components/load_templates/LoadTemplatesResearchCard.vue';

export default {
  name: 'WidgetsResearch',
  components: {
    LoadTemplatesResearchCard,
    InertiaLink
  },
  mixins: [WidgetsMixin, FiltersMixin],
  data() {
    return {
      loggedInUserId: this.$store.getters.userId,
      articles: [],
      filter: 'all',
      page: 0,
      stopPagination: false,
      tags: [
        { title: 'Latest Updates', id: 'all' },
        { title: 'Favorite Publishers', id: 'favorites' }
      ]
    };
  },
  mounted() {
    if (this.widget.configuration && this.widget.configuration.filter) {
      this.filter = this.widget.configuration.filter;
    }
    this.loadArticles();
  },
  computed: {
    requestPayload() {
      if (this.filter === 'favorites') {
        return {
          only_favorites: true,
          page: this.page,
          limit: 40
        };
      } else {
        return {
          page: this.page,
          limit: 40
        };
      }
    },
    coins() {
      return this.$store.getters.coins;
    }
  },
  watch: {
    filter() {
      this.updateWidgetConfig();
      this.page = 0;
      this.loadArticles();
    }
  },
  methods: {
    articleCoins(article) {
      let maxCoins = this.width >= 350 ? 5 : 3;
      return article.tags
        ? this.coins
            .filter(coin => article.tags.find(tag => tag.toLowerCase() == coin.name.toLowerCase()) !== undefined)
            .slice(0, maxCoins)
            .map(coin => {
              return {
                id: coin.id,
                name: coin.name.toLowerCase(),
                uid: coin.uid
              };
            })
        : [];
    },
    goToExpandLink() {
      window.open('/research', '_blank');
    },
    loadMoreArticles() {
      if (!this.loading && !this.stopPagination) {
        this.page++;
        this.loadArticles();
      }
    },
    async loadArticles() {
      const self = this;
      self.loading = true;
      try {
        let response = await self.$http.get('/research', { params: self.requestPayload });
        const newArticles = response.data.articles;
        if (newArticles.length == 0) {
          self.stopPagination = true;
        }
        if (self.page > 0) {
          newArticles.forEach(a => {
            self.articles.push(a);
          });
        } else {
          self.articles = newArticles;
        }
      } finally {
        self.loading = false;
      }
    },
    async updateWidgetConfig() {
      if (!this.viewOnlyMode) {
        let payload = {
          configuration: {
            ...this.widget.configuration,
            filter: this.filter
          }
        };

        await this.$http.patch(`/dashboard_widgets/${this.widget.id}`, payload);
      }
    }
  }
};
</script>
