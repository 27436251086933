<template>
  <Teleport to="body">
    <div
      v-if="show"
      :class="['fixed inset-0 overflow-y-auto', containerClass, zIndex]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 sm:block sm:p-0">
        <div v-if="show" class="fixed inset-0 bg-gray-800 opacity-75" @click="closeable && $emit('close')"></div>
        <div
          v-if="show"
          class="fixed left-0 right-0 top-1/2 z-50 m-auto -translate-y-1/2 transform rounded bg-gray-900 shadow sm:w-full"
          role="dialog"
          :class="[maxWidthClass, padding ? 'p-8' : '']"
          :style="customStyle"
        >
          <div class="flex text-gray-100" :class="padding ? 'mb-3' : ''">
            <slot name="header"></slot>
          </div>
          <div
            :class="['default-h relative text-gray-100', overflow ? 'overflow-y-auto' : 'overflow-y-none']"
            :style="`max-height: ${maxHeight}px; min-height: ${minHeight ? minHeight + 'px;' : ''}`"
          >
            <slot name="body"></slot>
          </div>
          <div class="flex" :class="padding ? 'mt-5' : ''">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  emits: ['close'],
  props: {
    bodyHeight: { type: Number, default: null },
    show: { type: Boolean, default: false },
    maxWidth: { type: String, default: 'md' },
    closeable: { type: Boolean, default: true },
    overflow: { type: Boolean, default: true },
    minHeight: { type: Number, default: null },
    containerClass: { type: String, default: '' },
    zIndex: { type: String, default: 'z-100' },
    padding: { type: Boolean, default: true },
    customStyle: { type: String, default: '' }
  },
  watch: {
    show: {
      immediate: true,
      handler(show) {
        if (show) {
          // document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = null;
        }
      }
    }
  },
  computed: {
    maxWidthClass() {
      return {
        sm: 'sm:max-w-sm',
        md: 'sm:max-w-md',
        lg: 'sm:max-w-lg',
        xl: 'sm:max-w-xl',
        '2xl': 'sm:max-w-2xl',
        '3xl': 'sm:max-w-3xl',
        '4xl': 'sm:max-w-4xl',
        '6xl': 'sm:max-w-6xl'
      }[this.maxWidth];
    },
    maxHeight() {
      return Number(this.bodyHeight ? window.innerHeight - 200 : 500);
    }
  }
};
</script>
<style scoped>
.z-100 {
  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.default-h {
  max-height: 500px;
}
.max-h-700 {
  max-height: 700px;
}
</style>
