<template>
  <div
    :class="[
      `col-span-8 mb-1 ${altStyle ? 'flex space-x-4' : 'grid'} items-center grid-cols-${
        cols || 7
      } w-full gap-1 text-white`,
      background
    ]"
  >
    <div
      v-show="altStyle"
      v-for="item in tabs"
      @click="$emit('tabChange', item.id)"
      :key="item.id"
      class="py-1.text-xs mt-1 flex cursor-pointer border-b-2 text-sm font-medium"
      :class="
        item.id == selected
          ? 'border-blue-500 text-white'
          : 'border-transparent text-gray-400 duration-100 hover:border-gray-500 hover:text-white'
      "
    >
      <div class="whitespace-nowrap">
        {{ item.title }}
      </div>
    </div>
    <div
      v-show="!altStyle"
      v-for="item in tabs"
      @click="$emit('tabChange', item.id)"
      :key="item.id"
      class="mt-1 flex cursor-pointer border-b py-1.5 px-3 text-xs font-medium"
      :class="
        item.id == selected
          ? 'border-gray-500 bg-gray-800 text-white'
          : 'border-gray-800 bg-gray-800/25 text-gray-400 duration-100 hover:border-gray-500 hover:bg-gray-800 hover:text-white'
      "
    >
      <div class="whitespace-nowrap">
        {{ item.title }}
      </div>
    </div>
    <div class="col-start-7 flex items-center justify-end">
      <slot name="right-side-content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tabs: Array,
    selected: String,
    altStyle: Boolean,
    cols: Number,
    background: {
      type: String,
      default: 'bg-gray-900'
    },
    disableHotKeys: Boolean
  },
  data() {
    // required so these don't get purged
    // grid-col-1 grid-col-2 grid-col-3 grid-col-4 grid-col-5 grid-col-6 grid-col-7
    return {
      holdingShift: false
    };
  },
  methods: {
    keydown: function (e) {
      if (this.disableHotKeys) {
        return;
      }

      const keyToNumber = {
        '!': 1,
        '@': 2,
        '#': 3,
        $: 4,
        '%': 5,
        '^': 6,
        '&': 7,
        '*': 8,
        '(': 9
      };

      if (e.key === 'Shift') {
        this.holdingShift = true;
      }

      if (
        this.holdingShift &&
        keyToNumber[e.key] > 0 &&
        keyToNumber[e.key] < 10 &&
        keyToNumber[e.key] <= this.tabs.length &&
        !['input', 'textarea'].includes(e.target.localName)
      ) {
        this.$emit('tabChange', this.tabs[keyToNumber[e.key] - 1].id);
      }
    },
    keyup: function (e) {
      if (e.key === 'Shift') {
        this.holdingShift = false;
      }
    }
  },
  mounted: function () {
    document.addEventListener('keyup', this.keyup);
    document.addEventListener('keydown', this.keydown);
  },
  beforeUnmount: function () {
    document.removeEventListener('keyup', this.keyup);
    document.removeEventListener('keydown', this.keydown);
  }
};
</script>
