<template>
  <div class="flex justify-end space-x-0.5">
    <component
      v-if="typeof icons == 'string'"
      :is="'icon-' + iconComponentName(icons)"
      :size="`${size} w-4 h-4`"
      :class="classes"
    ></component>
    <span v-else v-for="item in parsedIcons" :key="`${item.id}`">
      <BaseTooltip v-if="label">
        <template #target>
          <component :is="getComponentName(item.channel)"></component>
        </template>
        <template #default>
          {{ item.name }}
        </template>
      </BaseTooltip>
    </span>
    <BaseTooltip v-if="more > 0">
      <template #target>
        <span class="inline-flex text-sm font-semibold" v-if="more > 0">{{ `+${more}` }}</span>
      </template>
      <template #default>
        <div class="flex space-x-0.5">
          <component class="flex-inline" v-for="item in moreIcons" :key="item.id" :is="`icon-${item.channel}`" />
        </div>
      </template>
    </BaseTooltip>
  </div>
</template>
<script>
export default {
  name: 'CellIcon',
  props: {
    icons: {
      type: [String, Array]
    },
    label: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      maxIcon: 5
    };
  },
  computed: {
    parsedIcons() {
      if (typeof icons != 'string') {
        return this.icons.slice(0, this.maxIcon);
      } else {
        return this.icons;
      }
    },
    more() {
      return typeof this.icons != 'string' ? this.icons.length - this.maxIcon : 0;
    },
    moreIcons() {
      return typeof this.icons != 'string' ? this.icons.slice(this.maxIcon) : '';
    }
  },
  methods: {
    iconComponentName(icons) {
      return icons == 'sigdev_messenger' ? 'message' : icons;
    },
    getComponentName(c) {
      return c == 'sigdev_messenger' ? 'IconMessage' : 'Icon' + c.charAt(0).toUpperCase() + c.slice(1);
    }
  }
};
</script>
