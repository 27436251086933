<template>
  <div class="h-full w-full overflow-x-auto overflow-y-hidden pb-8">
    <div class="relative z-10 flex h-10 items-center justify-between border-b border-grid px-2">
      <div>
        <div class="flex items-center">
          <div :class="viewOnlyMode ? 'cursor-not-allowed' : 'cursor-pointer'">
            <Dropdown
              :class="viewOnlyMode ? 'pointer-events-none' : ''"
              :dropdown-items="layouts"
              @handle-action="changeLayout($event)"
              class="mr-auto text-xs font-medium text-gray-400 duration-100 hover:text-gray-100"
              direction="right"
              max-height="max-h-32"
              :sort-options="true"
            >
              <div class="flex items-center">
                <span class="whitespace-nowrap text-xs font-medium capitalize">{{ layoutName }}</span>
                <IconChevronDown size="h-4 w-4" class="ml-0 mt-0 mr-4" />
              </div>
            </Dropdown>
          </div>
          <div class="flex items-center" v-if="width > 800">
            <div
              v-for="tf in timeframes"
              :key="tf.id"
              @click="emit('update:timeframe', tf), selectedTime(tf.id)"
              class="cursor-pointer items-center rounded pr-3 text-xs font-medium transition-all ease-linear"
              :class="tf.id === selectedTimeframe ? 'font-semibold text-gray-100' : 'text-gray-400 hover:text-gray-100'"
            >
              {{ tf.text }}
            </div>
          </div>
          <div class="flex items-center pr-4" v-else>
            <Dropdown
              :dropdown-items="timeframes"
              @handle-action="emit('update:timeframe', selectedTime($event))"
              class="text-xs font-medium text-gray-400 duration-100 hover:text-gray-100"
              direction="right"
              max-height="max-h-32"
              :sort-options="false"
            >
              <div class="flex">
                <span class="text-xs font-medium capitalize">{{ currentTimeframe.text }}</span>
                <IconChevronDown size="h-4 w-4" class="-ml-[0.5px]" />
              </div>
            </Dropdown>
          </div>
          <div class="flex">
            <IconCalendar
              v-if="!showDatePicker"
              class="cursor-pointer text-gray-400"
              size="h-[15px] w-[15px]"
              @click="showDatePicker == false ? (showDatePicker = true) : (showDatePicker = false)"
            />
            <BaseDatepicker
              v-if="showDatePicker"
              :key="time[0]"
              :range="true"
              :default-date="time"
              format="MMMM DD, YYYY"
              font-size="text-xxs"
              @change="emit('update:date', $event)"
              @clear="emit('clear:time')"
            />
          </div>
          <BaseLoader v-if="loading" style="transform: scale(0.45)" class="ml-2" />
        </div>
      </div>
      <div class="flex space-x-4 whitespace-nowrap pl-4">
        <div class="flex space-x-4 font-medium">
          <label v-if="tieTeam" class="flex items-center space-x-1 text-xs text-gray-400">
            <BaseCheckbox v-model="showWatermark" @update:model-value="toggleWatermark(showWatermark)">
              Show Watermark
            </BaseCheckbox>
          </label>
          <label class="flex items-center space-x-1 text-xs text-gray-400">
            <BaseCheckbox v-model="sameAxis" @update:model-value="toggleAxis(sameAxis)"> Same Axis </BaseCheckbox>
          </label>
          <label class="flex items-center space-x-1 text-xs text-gray-400">
            <BaseCheckbox v-model="log" @update:model-value="toggleLog(log)"> Log </BaseCheckbox>
          </label>
        </div>
        <div
          class="flex items-center rounded bg-gray-800 px-4 py-1 text-gray-200 hover:bg-gray-700 hover:text-gray-100"
        >
          <Dropdown :dropdown-items="exportOptions" @handle-action="emit($event)">
            <div class="flex items-center space-x-1">
              <span class="text-xs font-medium">Export</span>
              <IconDownload size="h-3 w-3" />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>

    <div
      v-if="settings && settings.sources.length === 0"
      class="flex h-full flex-row items-center justify-center text-sm"
    >
      <div class="pb-36 text-gray-400">Your chart is empty, please add data from the sidebar to get started</div>
    </div>

    <div class="flex h-full flex-row text-sm" v-show="settings && settings.sources.length > 0">
      <div class="w-full" :id="randomId" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, inject, nextTick } from 'vue';
import DatePicker from 'vue-datepicker-next';
import Dropdown from '@/components/dropdown/Dropdown.vue';
import { DateTime } from 'luxon';
import 'vue-datepicker-next/index.css';
import { useStore } from 'vuex';

const emitter = inject('eventHub');
const emit = defineEmits(['export:csv', 'export:chart', 'update:date', 'update:timeframe', 'clear:time']);
const $store = useStore();

const props = defineProps({
  time: Array,
  loading: Boolean,
  selectedTimeframe: String,
  settings: Object,
  randomId: String,
  viewOnlyMode: Boolean,
  id: String,
  layouts: Object,
  layout: Object,
  width: Number
});

const showWatermark = ref(false);
const log = ref(false);
const sameAxis = ref(false);
const showDatePicker = ref(false);
const currentTimeframe = ref({
  time: 1000 * 60 * 60 * 24 * 90,
  id: '90D',
  text: '3M'
});
const exportOptions = ref([
  { id: 'export:csv', text: 'Export as CSV' },
  { id: 'export:chart', text: 'Export as PNG' }
]);
const timeframes = ref([
  {
    time: 1000 * 60 * 60 * 24,
    id: '1D',
    text: '1d'
  },
  {
    time: 1000 * 60 * 60 * 24 * 7,
    id: '7D',
    text: '7d'
  },
  {
    time: 1000 * 60 * 60 * 24 * 30,
    id: '30D',
    text: '30d'
  },
  {
    time: 1000 * 60 * 60 * 24 * 90,
    id: '90D',
    text: '3M'
  },
  {
    time: 1000 * 60 * 60 * 24 * 180,
    id: '6M',
    text: '6M'
  },
  {
    time: 1000 * 60 * 60 * 24 * 365,
    id: '1Y',
    text: '1Y'
  },
  {
    time: new Date().getTime() - new Date(new Date().getFullYear(), 0, 1).getTime(),
    id: 'YTD',
    text: 'YTD'
  },
  {
    time: 1000 * 60 * 60 * 24 * 365 * 10,
    id: 'All',
    text: 'All'
  }
]);

onMounted(() => {
  sameAxis.value = props.settings.sameAxis;
  log.value = props.settings.log;
});

const layoutName = computed(() => {
  return props.layout?.name ? props.layout?.name : 'None';
});

const tieTeam = computed(() => {
  var team_roles = $store.state.team_roles;
  return team_roles?.some(e => e.name == 'tie_team') || false;
});

watch(
  () => props.layout,
  () => {
    if (props.layout) {
      const state = JSON.parse(props.layout?.state);
      sameAxis.value = state?.sameAxis;
      log.value = state?.log;
      return;
    }
    sameAxis.value = props.settings?.sameAxis;
    log.value = props.settings?.log;
  }
);

function selectedTime(id) {
  const time = timeframes.value.find(t => t.id == id);
  currentTimeframe.value = time;
  return time;
}

watch(
  () => props.selectedTimeframe,
  () => {
    let tf = timeframes.value.find(t => t.id == props.selectedTimeframe);
    currentTimeframe.value = tf ? tf : { id: '', time: '', text: 'Custom' };
  }
);

function toggleLog(log) {
  emitter.$emit('toggle:log', { id: props.id, log: log });
}
function toggleAxis(axis) {
  emitter.$emit('toggle:axis', { id: props.id, axis: axis });
}
function toggleWatermark(watermark) {
  emitter.$emit('toggle:watermark', { id: props.id, watermark: watermark });
}
function changeLayout(layout) {
  let start = new Date(new Date().getTime() - currentTimeframe.value.time);
  let end = new Date();
  emitter.$emit('change:layout', { id: props.id, layout: layout, timeframe: [start, end] });
}
</script>
