<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :overrides="overrides"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      :connectable="false"
      :hidden-actions="['expand']"
      @expand="goToExpandLink"
    >
      <template #body>
        <template v-if="!settings.sources">
          <div class="mt-10">
            <WidgetsErrorMessage>Please add sources from configuration.</WidgetsErrorMessage>
          </div>
        </template>
        <template v-else>
          <div class="h-full w-full overflow-hidden">
            <Chart
              v-if="settings"
              :show-watermark="showWatermark"
              :settings="baseSettings"
              :ready-to-plot="true"
              :widget="true"
              :metric-options="chartMetricOptions"
              :dropdown-layouts="dropdownLayouts"
              :layout="layout"
              :view-only="viewOnlyMode"
              :widget-width="width"
              :chart-id="id"
              @options-change="settings = { ...settings, ...$event }"
            />
          </div>
        </template>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';
import FilterMixin from '@/mixins/filters';
import { PersistChartRemoteStorage } from '../tradingview/persist/remote';
import Chart from '@/components/chart/Chart.vue';

export default {
  name: 'WidgetsResearchChart',
  mixins: [WidgetsMixin, FilterMixin],
  components: {
    Chart
  },
  data() {
    return {
      showWatermark: false,
      layout: null,
      layouts: [],
      notFound: false,
      loading: false,
      dropdownLayouts: [{ id: 'none', text: '-' }],
      settings: { sources: [], sameAxis: true, log: false },
      startTime: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 90).getTime(),
      endTime: new Date().getTime(),
      id: `chart_${Math.random()}`,
      chartMetricOptions: [
        { id: 'price', name: 'Price' },
        { id: 'daily_sentiment', name: 'Daily Sentiment' },
        { id: 'tweet_volume', name: 'Tweet Volume' },
        { id: 'trading_volume', name: 'Trading Volume' },
        { id: 'price_return_24_hours', name: 'Price Change 24 Hours' },
        { id: 'price_return_1_hour', name: 'Price Change 1 Hour' },
        { id: 'daily_sentiment_avg_30_days', name: 'Daily Sentiment Avg 30 Days' },
        { id: 'daily_sentiment_change_7_days', name: 'Daily Sentiment Avg 7 Days' },
        { id: 'daily_sentiment_momentum', name: 'Daily Sentiment Momentum' },
        { id: 'hourly_sentiment', name: 'Hourly Sentiment' },
        { id: 'long_term_sentiment', name: 'Long Term Sentiment' },
        { id: 'market_cap', name: 'Market Cap' },
        { id: 'price_btc', name: 'Price BTC' },
        { id: 'price_return_30_days', name: 'Price Change 30 Days' },
        { id: 'price_return_btc_1_hour', name: 'Price Change BTC 1 hour' },
        { id: 'trading_volume_30_day_avg', name: 'Trading Volume 30 Days Avg' },
        { id: 'tweet_volume_30_day_avg', name: 'Tweet Volume 30 Days Avg' },
        { id: 'tweet_volume_dominance', name: 'Tweet Volume Dominance' },
        { id: 'trading_volume_dominance', name: 'Trading Volume Dominance' },
        { id: 'price_return_btc_24_hours', name: 'Price Change BTC 24 Hours' },
        { id: 'price_return_btc_30_days', name: 'Price Change BTC (30D)' },
        { id: 'relative_trade_volume', name: 'Relative Trading Volume' },
        { id: 'simple_moving_average_50_days', name: '50D SMA' },
        { id: 'simple_moving_average_change_50_days', name: '50D SMA Change' },
        { id: 'hype_to_activity_ratio', name: 'Hype To Activity Ratio' },
        { id: 'nvtweet_ratio', name: 'NVTweet Ratio' },
        { id: 'relative_tweet_volume', name: 'Relative Tweet Volume' },
        { id: 'tweet_unique_sources_percent', name: 'Tweet Unique Sources %' },
        { id: 'tweet_unique_sources_percent_30_day_avg', name: '30D Avg Unique Tweet Sources' },
        { id: 'twitter_accounts', name: 'Twitter Accounts' },
        { id: 'twitter_accounts_30_day_avg', name: 'Twitter Accounts 30D AVG' },
        { id: 'tweet_volume_1_minute', name: 'Tweet Volume 1 Minute' },
        { id: 'tweet_unique_sources_percent_1_minute', name: 'Tweet Unique Sources % 1 Min' },
        { id: 'news_stories_v2_1_day', name: 'News Stories 1 Day' },
        { id: 'news_stories_v2_30_day_avg', name: 'News Stories 30 Day' },
        { id: 'relative_news_v2', name: 'Relative News' },
        { id: 'market_cap_dominance', name: 'Market Cap Dominance' },
        { id: 'tvl', name: 'Total Value Locked', onlyCoin: true },
        { id: 'mcap_/_tvl', name: 'MCAP/TVL', onlyCoin: true },
        { id: 'youtube_view_count', name: 'YouTube Views' },
        { id: 'tiktok_play_count', name: 'TikTok Views' },
        { id: 'fourchan_post_count', name: '4chan Mentions' },
        { id: 'youtube_view_count_30d_avg', name: 'YouTube Views (30d Avg)' },
        { id: 'tiktok_play_count_30d_avg', name: 'TikTok Views (30d Avg)' },
        { id: 'fourchan_post_count_30d_avg', name: '4chan Mentions (30d Avg)' },
        { id: 'num_inc_24', name: 'Addresses with Increased Balance' },
        { id: 'num_dec_24', name: 'Addresses with Decreased Balance' },
        { id: 'num_txs_24h', name: 'Transactions' },
        { id: 'num_active_addrs_24h', name: 'Active Addresses' },
        { id: 'num_inc_30d_avg', name: 'Addresses with Increased Balance (30d Avg)' },
        { id: 'num_dec_30d_avg', name: 'Addresses with Decreased Balance (30d Avg)' },
        { id: 'num_tx_30d_avg', name: 'Transactions (30d Avg)' },
        { id: 'num_active_addrs_30d_avg', name: 'Active Addresses (30d Avg)' },
        { id: 'supply_in_circulation', name: '% of Supply in Circulation' },
        { id: 'commit_count', name: 'Monthly Commits' },
        { id: 'active_users_30d', name: 'Monthly Active Developers' }
      ]
    };
  },
  mounted() {
    this.refreshLayouts().then(() => {
      this.currentuserLayouts();
      const config = this.widget.configuration;
      if (this.overrides?.layout) {
        this.layout = this.overrides.layout;
      } else if (config?.selectedLayout) {
        this.selectLayout(config?.selectedLayout);
      } else if (config?.settings) {
        this.settings = config?.settings;
      }
    });
    this.$eventHub.$on('toggle:axis', data => {
      if (this.settings && data.id == this.id) {
        this.settings.sameAxis = data.axis;
      }
    });
    this.$eventHub.$on('toggle:log', data => {
      if (this.settings && data.id == this.id) {
        this.settings.log = data.log;
      }
    });
    this.$eventHub.$on('toggle:watermark', data => {
      if (data.id == this.id) {
        this.showWatermark = data.watermark;
      }
    });
    this.$eventHub.$on('change:layout', data => {
      if (data.id == this.id) {
        this.selectLayout(data.layout);
        this.startTime = data.timeframe[0];
        this.endTime = data.timeframe[1];
      }
    });
  },
  computed: {
    baseSettings() {
      if (this.settings.start && this.settings.end) {
        return { ...this.settings };
      }

      return {
        ...this.settings,
        start: this.startTime,
        end: this.endTime,
        showWatermark: this.showWatermark
      };
    },
    selectedResource() {
      const config = this.widget.configuration;
      return {
        entity: 'chart',
        shared_user_id: config?.user_id,
        shared_layout_id: config?.selectedLayout
      };
    }
  },
  watch: {
    'widget.configuration'() {
      const config = this.widget.configuration;
      if (config?.settings) {
        this.settings = config.settings;
      }
      this.selectLayout(config?.selectedLayout);
    },
    closed() {
      this.$eventHub.$emit('checkForChartResize');
    },
    layout(newVal, oldVal) {
      const config = this.widget.configuration;
      if (!newVal && !config?.selectedLayout) {
        this.settings = this.widget.configuration?.settings;
      }
      if (newVal) {
        const setting = JSON.parse(newVal?.state);
        this.settings = setting;
      }

      this.updateWidgetConfig();
    }
  },
  methods: {
    async refreshLayouts() {
      try {
        this.loading = true;
        let response = await this.$http.post('/shared_resources', this.selectedResource);
        this.loading = false;
        let userLayouts = response.data.resource;
        let sharedLayout = response.data.extras;
        this.layouts = userLayouts;
        if (sharedLayout) {
          this.layouts.push(sharedLayout);
        }
      } catch {
        this.loading = false;
      }
    },
    currentuserLayouts() {
      let userLayouts = this.layouts.filter(l => l.user_id == this.widget.user_id);
      this.dropdownLayouts = userLayouts.map(({ id, name }) => ({ id: id, text: name }));
      this.dropdownLayouts.push({ id: 'none', text: '-' });
    },
    goToExpandLink() {
      window.open('/charts/research', '_blank');
    },
    async updateWidgetConfig() {
      if (!this.viewOnlyMode) {
        await await this.$http.post('/widget_overrides', {
          widget_override: {
            dashboard_widget_id: this.widget.id,
            overrides: {
              layout: this.layout ? this.layout : null
            }
          }
        });
        let payload = {
          configuration: {
            ...this.widget.configuration
          }
        };
        await this.$http.patch(`/dashboard_widgets/${this.widget.id}`, payload);
      }
    },
    changeCollapse() {
      this.$eventHub.$emit('reflow-highchart');
    },
    selectLayout(id) {
      const config = this.widget.configuration;
      const layout = this.layouts.find(l => l.id == id);
      const user_id = this.widget.user_id;

      if ((id == 'none' && !config?.selectedLayout) || !id) {
        this.settings = config?.settings;
        this.layout = null;
      } else if ((id == 'none' && config?.selectedLayout) || (user_id && user_id != layout?.user_id)) {
        const layout = this.layouts.find(l => l.id == config?.selectedLayout);
        this.settings = JSON.parse(layout?.state);
        this.layout = null;
      } else {
        this.layout = layout;
      }
    }
  }
};
</script>
