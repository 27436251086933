<template>
  <div class="relative mb-1 flex items-center justify-center rounded-full" @drop.prevent="onDrop">
    <div class="flex-col space-y-2 text-xs">
      <input type="file" ref="file" accept="image/png, image/jpeg" style="display: none" @change="onFileChange" />
      <ChatImage
        size="xl"
        :item="channel.cid ? (imageUrl ? { image: imageUrl } : channel) : channel"
        :channel-name="channelName"
        class="my-4"
        :show-online="channel.cid ? false : true"
        :class="editMode ? 'opacity-30' : ''"
      >
      </ChatImage>
      <div
        v-if="editMode"
        class="absolute top-0 flex h-full w-20 cursor-pointer items-center justify-center text-sm font-medium hover:underline"
        @click="editMode ? triggerFileSelector() : ''"
      >
        Edit
      </div>
    </div>
  </div>
  <span
    v-if="imageError && imageError.length > 0"
    class="flex items-center justify-center text-center text-xs text-red-400"
  >
    {{ imageError }}
  </span>
</template>
<script setup>
import { ref, reactive, onMounted, onUnmounted, watch, inject } from 'vue';
import { useStore } from 'vuex';
import ChatImage from './ChatImage.vue';
const store = useStore();
const emitter = inject('eventHub');
const props = defineProps({
  channel: { type: Object, default: () => {} },
  channelName: { type: String, default: null },
  editMode: { type: Boolean, default: false }
});
const $emit = defineEmits(['selected-image']);
const imageError = ref('');
const imageUrl = ref('');
const file = ref(null);
const events = reactive(['dragenter', 'dragover', 'dragleave', 'drop']);
const acceptabelImageTypes = reactive(['image/png', 'image/jpeg']);
onMounted(() => {
  imageUrl.value = props.channel.image;
  events.forEach(eventName => {
    document.body.addEventListener(eventName, preventDefaults);
  });
});

onUnmounted(() => {
  events.forEach(eventName => {
    document.body.removeEventListener(eventName, preventDefaults);
  });
});

function onFileChange(e) {
  const file = e.target.files[0];
  setFile(file);
}

function setFile(file) {
  if (file.size > 5242880) {
    imageError.value = 'Image should be less than 5 MB.';
  } else if (!acceptabelImageTypes.includes(file.type)) {
    imageError.value = 'Invalid file format.';
  } else {
    imageError.value = '';
    imageUrl.value = URL.createObjectURL(file);
    $emit('selected-image', { imageUrl: imageUrl.value, file: file });
  }
}

function onDrop(e) {
  setFile([...e.dataTransfer.files][0]);
}

function preventDefaults(e) {
  e.preventDefault();
}

function triggerFileSelector() {
  if (file.value) {
    file.value.click();
  }
}

watch(
  () => props.editMode,
  (newVal, oldVal) => {
    if (newVal == false) {
      imageUrl.value = '';
      imageError.value = '';
      file.value = null;
    }
  }
);
</script>
