<template>
  <div v-if="!loading" class="flex h-full w-full">
    <SidebarNft @select-chain="setSelectedChain($event)" />
    <div class="relative h-full w-full overflow-y-auto text-white">
      <slot />
    </div>
  </div>
  <div v-else class="flex h-full w-full items-center justify-center">
    <BaseLoader class="h-10 w-10" />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, provide, onMounted } from 'vue';
import SidebarNft from '@/components/sidebar/SidebarNft.vue';
import useHttp from '@/composeables/http';

const store = useStore();
const $http = useHttp();

onMounted(async () => {
  await fetchData();

  if (localStorage.getItem('nftChain')) {
    store.dispatch('setNftChain', localStorage.getItem('nftChain'));
  }
});

const userCollections = ref([]);
const userTokens = ref([]);
const loading = ref(false);

const fetchData = async () => {
  loading.value = true;

  const collectionsResponse = await $http.get('/nft_collections');
  userCollections.value = collectionsResponse.data;

  const tokensResponse = await $http.get('/nft_tokens');
  userTokens.value = tokensResponse.data;

  loading.value = false;
};

const addUserCollection = async e => {
  const index = userCollections.value.findIndex(c => c.contract === e.contract);
  if (index != -1) {
    let recordId = userCollections.value[index].id;
    // remove the collectiom
    userCollections.value.splice(index, 1);
    await $http.delete(`/nft_collections/${recordId}`, e);
  } else {
    // add the collection
    let response = await $http.post('/nft_collections', e);
    userCollections.value.push(response.data);
  }
};

const addUserToken = async e => {
  const index = userTokens.value.findIndex(c => c.contract === e.contract && e.token === c.token);

  if (index != -1) {
    let recordId = userTokens.value[index].id;
    userTokens.value.splice(index, 1);
    await $http.delete(`/nft_tokens/${recordId}`, e);
  } else {
    let response = await $http.post('/nft_tokens', e);
    userTokens.value.push(response.data);
  }
};

provide('addUserCollection', addUserCollection);
provide('addUserToken', addUserToken);
provide('userCollections', userCollections);
provide('userTokens', userTokens);

const setSelectedChain = chain => {
  localStorage.setItem('nftChain', chain);
  store.dispatch('setNftChain', chain);
};
</script>
