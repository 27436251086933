<template>
  <div>
    <input
      class="custom-color-input"
      v-model="selectedColor"
      type="color"
      list="presetColors"
      @change="$emit('update', selectedColor)"
    />
    <datalist id="presetColors">
      <option v-for="(color, idx) in suggestedColors" :key="idx">{{ color }}</option>
    </datalist>
  </div>
</template>
<script>
export default {
  name: 'BaseColorInput',
  data() {
    return {
      selectedColor: '#60A5FA',
      suggestedColors: [
        '#60A5FA',
        '#3B82F6',
        '#2563EB',
        '#1D4ED8',
        '#1E40AF',
        '#F87171',
        '#EF4444',
        '#DC2626',
        '#B91C1C',
        '#991B1B',
        '#34D399',
        '#10B981',
        '#059669',
        '#047857',
        '#065F46',
        '#FBBF24',
        '#F59E0B',
        '#D97706',
        '#845309',
        '#92400E',
        '#E879F9',
        '#D946EF',
        '#C026D3',
        '#A21CAF',
        '#86198F'
      ]
    };
  }
};
</script>
<style scoped>
.custom-color-input {
  color: #fff;
  border-radius: 9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 60px !important;
  height: 30px !important;
  cursor: default;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  border-width: 0;
  padding: 0 !important;
}
.custom-color-input::-webkit-color-swatch {
  border: none;
  border-radius: 5px;
}
.custom-color-input::-moz-color-swatch {
  border: none;
  border-radius: 5px;
}
#presetColors {
  background: #1e283b;
}
.colorpick-eyedropper-input-trigger {
  display: none;
}
</style>
