<template>
  <div>
    <div class="h-full w-8" @click="$emit('open')">
      <div :class="[`bg-${color}-800/50`, 'flex h-full cursor-pointer']">
        <div class="absolute top-1/2 left-4 text-center text-xs" :class="[textDirection]">{{ title }}</div>
      </div>
    </div>
    <aside
      class="fixed top-10 left-0 z-30 h-full transform overflow-auto border-r border-grid bg-gray-900 shadow-lg transition-all duration-300 ease-in-out"
      :class="[open ? 'translate-x-0' : '-translate-x-full', width]"
    >
      <div :class="`relative ${headerClass}`" v-show="open">
        <div
          v-if="title"
          :class="`h-8 bg-${color}-800 flex select-none items-center border-b border-grid px-4`"
          @mouseover="hover = true"
          @mouseleave="hover = false"
          @click="$emit('clicked-title')"
        >
          <div :class="`tie-card-header truncate text-${color}-100 flex-1 py-2 pr-3 text-xs font-medium`">
            {{ title }}
          </div>
          <BaseButton type="secondary" size="xs" @press="$emit('close')">
            <IconChevronRight v-if="direction == 'left'" class="mr-2 h-3.5 w-3.5 rotate-180 transform" />
            Hide
            <IconChevronRight v-if="direction == 'right'" class="h-3.5" />
          </BaseButton>
        </div>
      </div>
      <div style="height: calc(100% - 32px)" class="relative flex flex-1" v-show="!noContainer && open">
        <div
          :class="`overflow-y-${scroll ? 'auto' : 'hidden'} absolute top-0 left-0 right-0 bottom-0 overflow-x-hidden`"
        >
          <slot></slot>
        </div>
      </div>
    </aside>
    <div
      v-if="open"
      :class="showBackground ? 'bg-gray-900/50' : ''"
      class="absolute inset-0 z-10 cursor-pointer"
      @click="$emit('close')"
    ></div>
  </div>
</template>
<script>
export default {
  name: 'BaseOverlaySidebar',
  props: {
    title: String,
    scroll: Boolean,
    noContainer: Boolean,
    dropdown: Array,
    headerClass: String,
    direction: {
      type: String,
      default: 'left'
    },
    color: {
      type: String,
      default: 'gray'
    },
    open: {
      type: Boolean,
      default: false
    },
    showBackground: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'w-72'
    }
  },
  computed: {
    cardTitle() {
      return this.title.toLowerCase().replace(/\s+/g, '_');
    },
    textDirection() {
      return {
        left: 'top-1/2 left-4 rotate-left',
        right: 'top-1/2 left-4 rotate-right',
        top: '',
        bottom: ''
      }[this.direction];
    }
  }
};
</script>
<style scoped>
.rotate-right {
  -moz-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}
.rotate-left {
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}
</style>
