<template>
  <div class="relative inline" @keydown="keydown" :id="dropdownId">
    <VueTagsInput
      :id="inputId"
      :class="selectClass ? selectClass : 'w-96 pl-1 text-gray-400'"
      class="rounded border border-gray-700 text-xs"
      v-model="search"
      :add-on-key="allowKeywords ? undefined : []"
      :tags="vueTagsInputTags"
      :add-on-blur="false"
      :placeholder="showPlaceholder"
      @tags-changed="filterTagsChanged"
      @focus="focusChange"
      @blur="focusChange"
      :style="`max-height: ${maxHeight || 80}px; overflow-y: scroll`"
    />

    <div v-if="search || isFocus" class="relative">
      <div
        :class="[`max-h-96 w-${w || 96} absolute top-0 z-50 overflow-y-scroll bg-gray-800/75 pt-2 backdrop-blur-md`]"
        class="rounded border border-gray-700"
        v-if="isFocus"
      >
        <slot />

        <div
          v-for="(filter, index) in filteredTags"
          :key="index"
          @click="toggleFilter(filter, $event)"
          :class="`flex cursor-pointer select-none items-start py-1 pl-2 pr-4 ${
            selectedIndex === index ? 'element-hover bg-gray-700' : ''
          } ${holdingShift ? 'hover:bg-red-900/50' : 'hover:bg-green-900/50'}`"
        >
          <div class="mr-2 flex pt-1">
            <input
              :type="optionsType"
              class="cursor-pointer"
              :checked="selectedTags[filter.type][filter.id]"
              v-if="selectedTags[filter.type][filter.id] !== 'exclude'"
            />

            <div
              v-if="selectedTags[filter.type][filter.id] === 'exclude'"
              class="flex h-3.5 w-3.5 items-center justify-center rounded-sm border border-red-700 bg-red-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3 text-red-900"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div>
            <div class="text-xs">
              {{ filter.label || filter.name }}
            </div>
            <div class="text-xs text-gray-400">
              {{ filter.typeName }}
            </div>
          </div>
        </div>
        <div v-if="filteredTags.length === 0" class="p-5 text-center text-sm text-gray-400">
          Sorry, no results found for matching the search term "{{ search }}".
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@sipec/vue3-tags-input';
import { fixScrolling } from '@/composeables/helpers';

export default {
  components: {
    VueTagsInput
  },
  emits: ['change'],
  props: {
    placeholder: String,
    possibleTags: Array,
    selectClass: String,
    setItem: Object,
    shift: Boolean,
    maxHeight: Number,
    allowKeywords: Boolean,
    w: Number,
    setFocus: Boolean,
    disableShift: { type: Boolean, default: false },
    onlyOne: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFocus: false,
      selectedIndex: undefined,
      dropdownId: `dropdown_${Math.random()}`,

      search: '',
      vueTagsInputTags: [],

      selectedTags: {},
      holdingShift: false,
      inputId: `${Math.random()}`,
      searchPhrase: ''
    };
  },
  methods: {
    setFocused() {
      if (this.vueTagsInputTags.length > 0) {
        return;
      }

      try {
        // this is somewhat hacky but the vue-tags-input does not support auto-focus, so we can our selfs like this:
        const input = document.getElementById(this.inputId).children[0].children[0].children[0].children[0];
        input.focus();
      } catch {
        // do nothing: see above
      }
    },
    isHoldingShift(e) {
      if (this.shift) {
        return true;
      }
      this.holdingShift = this.disableShift ? false : e.shiftKey;
    },
    focusChange: function (e) {
      const filterSearch = document.getElementById(this.dropdownId);
      const isFocus = e.type === 'focus';

      if (isFocus) {
        this.isFocus = true;
        return;
      }

      if (filterSearch.contains(e.target)) {
        return;
      }

      this.isFocus = isFocus;
    },
    keydown: function (e) {
      // escape
      if (e.keyCode === 27) {
        this.search = '';
        this.searchPhrase = '';
        return;
      }

      //backspace
      if (e.keyCode === 8) {
        this.searchPhrase = '';
      }

      // down arrow
      if (e.keyCode === 40) {
        e.preventDefault();
        this.selectedIndex = this.selectedIndex === undefined ? 0 : this.selectedIndex + 1;
        if (this.selectedIndex >= this.filteredTags.length - 1) {
          this.selectedIndex = this.filteredTags.length - 1;
        }
        fixScrolling('down');
        return;
      }

      // up arrow
      if (e.keyCode === 38) {
        e.preventDefault();
        this.selectedIndex = (this.selectedIndex || 0) - 1;

        if (this.selectedIndex < 0) {
          this.selectedIndex = 0;
        }
        fixScrolling('up');
        return;
      }

      // enter key
      if (e.keyCode === 13 && this.selectedIndex !== undefined) {
        const filters = this.filteredTags;
        const item = filters[this.selectedIndex];
        this.toggleFilter(item, e);
      }
    },
    toggleFilter(s, e, blockEmit) {
      this.searchPhrase = '';
      const newSelectedTags = JSON.parse(JSON.stringify(this.selectedTags));
      let includeOrExclude = e.shiftKey || this.shift ? 'exclude' : 'include';
      if (this.disableShift) {
        includeOrExclude = 'include';
      }

      // eslint-disable-next-line chai-friendly/no-unused-expressions
      newSelectedTags[s.type][s.id]
        ? (newSelectedTags[s.type][s.id] = undefined)
        : (newSelectedTags[s.type][s.id] = includeOrExclude);

      if (newSelectedTags[s.type][s.id]) {
        this.vueTagsInputTags.push({
          text: s.label || s.name,
          id: s.id,
          type: s.type,
          exclude: includeOrExclude === 'exclude',
          classes: includeOrExclude === 'include' ? 'bg-green-900' : 'bg-red-900'
        });
        this.search = '';
      } else {
        this.vueTagsInputTags = this.vueTagsInputTags.filter(t => {
          return t.id !== s.id;
        });
      }

      this.selectedTags = newSelectedTags;

      if (blockEmit) {
        return;
      }

      this.$emit('change', this.selectedTags);
    },
    filterTagsChanged(newTags) {
      newTags.forEach(t => {
        this.searchPhrase = t.text[0] === '!' ? t.text.slice(1) : t.text;
      });

      // if they add a tag
      if (this.allowKeywords && newTags.length === this.vueTagsInputTags.length + 1) {
        this.vueTagsInputTags = newTags
          .map(t => {
            const keyword = t.text[0] === '!' ? t.text.slice(1) : t.text;
            const newSelectedTags = JSON.parse(JSON.stringify(this.selectedTags));

            if (this.selectedTags['keywords'][keyword]) {
              return undefined;
            }

            if (!t.type) {
              let includeOrExclude = t.text[0] === '!' ? 'exclude' : 'include';

              if (includeOrExclude === 'include' && this.holdingShift) {
                includeOrExclude = 'exclude';
              }

              t.type = 'keywords';
              // eslint-disable-next-line chai-friendly/no-unused-expressions
              (t.id = keyword),
                (t.exclude = includeOrExclude === 'exclude'),
                (t.classes = includeOrExclude === 'include' ? 'bg-green-900' : 'bg-red-900');
              t.text = `Keyword: ${keyword}`;
              t.keyword = keyword;

              newSelectedTags['keywords'][keyword] = includeOrExclude;
              this.selectedTags = newSelectedTags;
              this.$emit('change', this.selectedTags);
            }
            return t;
          })
          .filter(r => {
            return r !== undefined;
          });
        return;
      }

      // if they remove a tag
      let missing;

      for (let i = 0; i < this.vueTagsInputTags.length; i++) {
        const existing = this.vueTagsInputTags[i];
        let found = false;

        for (let j = 0; j < newTags.length; j++) {
          if (existing.id === newTags[j].id) {
            found = true;
            break;
          }
        }

        if (!found) {
          missing = existing;
          break;
        }
      }

      const newSelectedTags = JSON.parse(JSON.stringify(this.selectedTags));
      newSelectedTags[missing.type][missing.id] = undefined;

      this.selectedTags = newSelectedTags;
      this.vueTagsInputTags = newTags;
      if (missing) {
        this.searchPhrase = '';
      }
      this.$emit('change', this.selectedTags);
    }
  },
  computed: {
    optionsType() {
      return this.onlyOne ? 'radio' : 'checkbox';
    },
    showPlaceholder() {
      return this.onlyOne && this.vueTagsInputTags.length > 0 ? '' : this.placeholder;
    },
    filteredTags() {
      let searchString = this.searchPhrase ? this.searchPhrase.toLowerCase() : this.search.toLowerCase();

      return this.possibleTags.filter(tag => {
        return tag.name.toLowerCase().includes(searchString);
      });
    }
  },
  watch: {
    setFocus() {
      if (!this.setFocus) {
        return;
      }

      setTimeout(() => {
        this.setFocused();
      }, 0);
    },
    possibleTags() {
      const newSelectTags = JSON.parse(JSON.stringify(this.selectedTags));

      this.possibleTags.forEach(tag => {
        newSelectTags[tag.type] = {};
      });

      if (this.allowKeywords) {
        newSelectTags['keywords'] = {};
      }

      this.selectedTags = newSelectTags;

      let triggerRefresh = false;
      this.possibleTags.forEach(tag => {
        if (tag.startingState) {
          this.toggleFilter(tag, { shiftKey: tag.startingState === 'exclude' }, true);

          if (tag.triggerRefresh) {
            triggerRefresh = true;
          }
        }
      });

      if (triggerRefresh) {
        this.$emit('change', this.selectedTags);
      }
    },
    setItem() {
      if (!this.setItem) {
        return;
      }

      const tag = this.possibleTags.find(tag => {
        return tag.id === this.setItem.id && tag.type === this.setItem.type;
      });

      this.toggleFilter(
        {
          id: this.setItem.id,
          type: this.setItem.type,
          name: tag.name
        },
        {
          shiftKey: !this.setItem.include
        }
      );
    }
  },
  mounted() {
    document.addEventListener('click', this.focusChange);
    document.addEventListener('keydown', this.isHoldingShift);
    document.addEventListener('keyup', this.isHoldingShift);

    if (this.shift) {
      this.holdingShift = true;
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.focusChange);
    document.removeEventListener('keydown', this.isHoldingShift);
    document.removeEventListener('keyup', this.isHoldingShift);
  }
};
</script>
