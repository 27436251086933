<template>
  <div class="flex flex-col overflow-hidden" :class="external ? 'h-[360px]' : 'h-full'" @click="markRead">
    <ChannelHeader
      v-if="!external"
      :channel="channel"
      :channel-name="channelName"
      :is-channel="true"
      @toggle-chat-settings="showSettings = !showSettings"
      @show-notification-modal="showNotificationSettings = true"
    />
    <div class="flex h-full w-full" :style="external ? '' : 'height: 90%'" @paste="onPaste">
      <div class="flex h-full w-full flex-col overflow-hidden" @drop.prevent="onDrop">
        <div class="relative flex h-screen flex-col justify-end overflow-y-auto">
          <div
            class="flex h-full flex-col space-y-4 overflow-y-auto overflow-x-hidden py-4"
            :class="external ? 'px-1' : 'px-4'"
            :id="`channel_container_${channel.cid}`"
            @scroll="handleScroll"
          >
            <ChatNewState
              :channel="channel"
              :channel-name="channelName"
              :is-channel="true"
              :external="external"
              :show-request-view="showRequestView"
              @toggle-chat-settings="showSettings = true"
            ></ChatNewState>
            <div v-if="messages.length > 0 || showRequestView" class="flex w-full items-center">
              <div class="flex w-full flex-1 border-b border-gray-800"></div>
              <div class="px-4 text-xxs font-medium">
                {{
                  showRequestView && filteredMessages.length <= 1
                    ? toChatTime(channelUser.updated_at, 'chat_start')
                    : toChatTime(channel.data.created_at, 'chat_start')
                }}
              </div>
              <div class="flex w-full flex-1 border-b border-gray-800"></div>
            </div>

            <Message
              v-for="msg in filteredMessages"
              :key="msg.id"
              :message="msg"
              :user-bio="true"
              :hide-reply="showRequestView"
              :external="external"
              @reply-message="
                quotedMessage = $event;
                nextTick(() => {
                  scrollToBottom();
                });
              "
            />
            <div
              v-if="uploading"
              class="flex items-center space-x-2 rounded border border-gray-800 bg-gray-800 py-2"
              :class="external ? 'px-2' : 'px-4'"
            >
              <span class="text-sm font-medium">Uploading</span>
              <span class="text-xs text-gray-400">{{ completedFiles }} out of {{ totalFiles }}</span>
              <BaseLoader size="w-4 h-4" :background="800" />
            </div>
          </div>
          <GIFSelection
            v-if="showGIFs"
            :channel-id="channel.id"
            class="absolute bottom-0 left-4 mb-1"
            @send-message="
              sendMessage($event);
              showGIFs = false;
            "
            @close="showGIFs = false"
          ></GIFSelection>
        </div>
        <MessageComposer
          v-if="!showRequestView && broadcastChannelAndAdmin(channel)"
          :class="external ? '' : 'mb-1 px-4'"
          :quoted-message="quotedMessage"
          :channel-id="channel.id"
          :external="external"
          @remove-quoted-message="quotedMessage = null"
          @show-gifs="external ? $emit('show-gifs', channel.id) : (showGIFs = !showGIFs)"
          @scroll-to-bottom="
            nextTick(() => {
              scrollToBottom();
            })
          "
          @send-message="sendMessage"
        />
      </div>
      <ChatSettings
        v-if="showSettings"
        :channel="channel"
        :show-request-view="showRequestView"
        :channel-name="channelName"
        :user-id="userId"
        @toggle-chat-settings="showSettings = false"
      ></ChatSettings>
      <NotificationSettingsModal
        v-if="showNotificationSettings"
        :channel="channel"
        :user="client.user"
        @close="showNotificationSettings = false"
      ></NotificationSettingsModal>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick, inject } from 'vue';
import Message from './ChatMessage.vue';
import ChatSettings from './ChatSettings.vue';
import MessageComposer from './ChatMessageComposer.vue';
import ChannelHeader from './ChatChannelHeader.vue';
import NotificationSettingsModal from './ChatNotificationSettingsModal.vue';
import GIFSelection from './ChatGIFSelection.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import ChatNewState from './ChatNewState.vue';

import { useMapGetter } from '@/store/map-state';
import { getChannelName, getUserName, broadcastChannelAndAdmin } from '@/composeables/chat';
import { toChatTime } from '@/composeables/filters';
const { client, streamUser, streamUsers } = useMapGetter();
const emitter = inject('eventHub');
const $emit = defineEmits(['show-gifs']);
const props = defineProps({
  channel: Object,
  userId: String,
  external: {
    type: Boolean,
    default: false
  }
});
const messages = ref([]);
const messageListener = ref(null);
const showSettings = ref(false);
const showNotificationSettings = ref(false);
const loading = ref(false);
const programmaticScroll = ref(false);
const userScrolledUp = ref(false);
const firstPage = ref(false);
const lastPage = ref(false);
const messageLimit = ref(25);
const quotedMessage = ref(null);
const showGIFs = ref(false);
const channelName = ref(getChannelName(props.channel));
const events = ref(['dragenter', 'dragover', 'dragleave', 'drop']);
const uploading = ref(false);
const completedFiles = ref(0);
const totalFiles = ref(null);
const showRequestView = ref(false);
const channelUser = ref(null);

onMounted(() => {
  let currentUser = Object.values(props.channel.state.members).find(x => x.user_id == streamUser.value.id);
  if (currentUser && currentUser.invited && !currentUser.invite_accepted_at && !currentUser.invite_rejected_at) {
    channelUser.value = currentUser;
    showRequestView.value = true;
  }
  messages.value = props.channel.state.messages;
  if (props.channel.state.messages < messageLimit.value) {
    firstPage.value = true;
    lastPage.value = true;
  }
  messageListener.value = props.channel.on('message.new', () => {
    messages.value = props.channel.state.messages;
    if (
      !props.external ||
      (localStorage.getItem('closedChats') && !localStorage.getItem('closedChats').includes(props.channel.cid)) ||
      !localStorage.getItem('closedChats')
    ) {
      markRead();
    }
    // TODO: Check if user is not at bottom already, and therefore shouldn't jump them down if they're browsing history
    nextTick(() => {
      if (!userScrolledUp.value) {
        scrollToBottom();
      }
    });
  });

  client.value.on('user.updated', async event => {
    let members = Object.values(props.channel.state.members).map(x => x.user_id);
    if (members.includes(event.user.id)) {
      let filter = { cid: { $eq: props.channel.cid } };
      let channel = await client.value.queryChannels(filter);
      if (channel.length) {
        messages.value = channel[0].state.messages;
        channelName.value = getChannelName(props.channel);
      }
    }
  });

  props.channel.on('channel.updated', () => {
    channelName.value = getChannelName(props.channel);
  });

  props.channel.on('member.added', e => {
    if (e.member.user_id == streamUser.value.id) {
      let currentUser = Object.values(props.channel.state.members).find(x => x.user_id == streamUser.value.id);
      if (currentUser && currentUser.invited && !currentUser.invite_accepted_at && !currentUser.invite_rejected_at) {
        channelUser.value = currentUser;
        showRequestView.value = true;
      } else {
        showRequestView.value = false;
      }
    }
  });

  setTimeout(() => {
    scrollToBottom();
  }, 100);

  emitter.$on('focusMessage', id => {
    scrollTodiv(`message_${id}`);
  });

  emitter.$on('scroll-to-bottom', function () {
    if (!userScrolledUp.value) {
      scrollToBottom();
    }
  });

  events.value.forEach(eventName => {
    document.body.addEventListener(eventName, preventDefaults);
  });
});
onBeforeUnmount(() => {
  // Need to unsubscribe to channel events or else it'll stack every time channel is switched
  emitter.$off('focusMessage');
  events.value.forEach(eventName => {
    document.body.removeEventListener(eventName, preventDefaults);
  });
  if (messageListener.value) {
    messageListener.value.unsubscribe();
  }
});
//computed
const firstMessageId = computed(() => {
  return filteredMessages.value[0].id;
});
const lastMessageId = computed(() => {
  return filteredMessages.value[filteredMessages.value.length - 1].id;
});
const filteredMessages = computed(() => {
  if (showRequestView.value) {
    return getRequestMessages();
  }
  let temp = [...messages.value];
  let sortedMessages = _.orderBy(
    temp,
    function (o) {
      return new moment(o.created_at);
    },
    ['asc']
  );
  let uniqMessages = _.uniqBy(sortedMessages, function (o) {
    return o.id;
  });
  if (!props.channel.data.message_history_access) {
    let member = Object.values(props.channel.state.members).find(x => x.user_id == streamUser.value.id);
    return uniqMessages.filter(x => new Date(x.created_at) >= new Date(member.created_at));
  }
  return uniqMessages;
});
//methods
function getInvitedBy(channel) {
  if (Array.isArray(channel.data.invited_users)) {
    let invited = channel.data.invited_users.find(x => x.invited_users.includes(streamUser.value.id));
    return invited ? getUserName(invited.invited_by) : channel.data.created_by.name;
  }
  return channel.data.created_by.name;
}

async function sendMessage(payload) {
  let messageObject = {};
  if (quotedMessage.value) {
    messageObject = {
      quoted_message_id: quotedMessage.value ? quotedMessage.value.id : null
    };
  }
  if (payload.text && payload.text.trim().length > 0) {
    messageObject.text = payload.text;
  } else if (payload.gif) {
    messageObject = {
      attachments: [
        {
          type: 'giphy',
          thumb_url: `https://media.giphy.com/media/${payload.gif.id}/giphy.gif`,
          title: payload.gif.title,
          title_link: payload.gif.url,
          giphy: {
            original: {
              height: String(payload.gif.images.original.height),
              width: String(payload.gif.images.original.width)
            }
          }
        }
      ],
      quoted_message_id: quotedMessage.value ? quotedMessage.value.id : null,
      command: 'giphy',
      command_info: { name: 'Giphy' }
    };
  }
  if (payload.files && payload.files.length > 0) {
    uploading.value = true;
    completedFiles.value = 0;
    totalFiles.value = payload.files.length;
    scrollToBottom();
    messageObject.attachments = [];
    for (var i = 0; i < payload.files.length; i++) {
      completedFiles.value += 1;
      if (
        payload.files[i].type.startsWith('image/') &&
        !payload.files[i].type.endsWith('.photoshop') // photoshop files begin with 'image/'
      ) {
        let response = await props.channel.sendImage(payload.files[i]);
        messageObject.attachments.push({ type: 'image', image_url: response.file, title: payload.files[i].name });
      } else {
        let response = await props.channel.sendFile(payload.files[i]);
        messageObject.attachments.push({
          type: payload.files[i].type.startsWith('video/') ? 'video' : 'file',
          asset_url: response.file,
          title: payload.files[i].name,
          mime_type: payload.files[i].type,
          size: payload.files[i].size
        });
      }
    }
    await props.channel.sendMessage(messageObject);
    uploading.value = false;
  } else {
    await props.channel.sendMessage(messageObject);
  }
  scrollToBottom();
  quotedMessage.value = null;
}

function scrollToBottom() {
  var chatWindow = document.getElementById(`channel_container_${props.channel.cid}`);
  programmaticScroll.value = true;
  if (chatWindow) {
    chatWindow.scrollTop = chatWindow.scrollHeight;
  }
}

function scrollTodiv(elem) {
  programmaticScroll.value = true;
  var element = document.getElementById(elem);
  var chatWindow = document.getElementById(`channel_container_${props.channel.cid}`);
  if (element && chatWindow && element.parentElement.id === chatWindow.id) {
    var top = element.offsetTop;
    chatWindow.scrollTo(0, top - 40);
    element.classList.add('bg-blue-900/25');
    setTimeout(function () {
      element.classList.remove('bg-blue-900/25');
    }, 1000);
  }
}

const handleScroll = async function (el) {
  if (!programmaticScroll.value) {
    var chatWindow = document.getElementById(`channel_container_${props.channel.cid}`);
    if (chatWindow) {
      userScrolledUp.value = chatWindow.scrollTop != chatWindow.scrollHeight - chatWindow.offsetHeight;
    }
  } else {
    programmaticScroll.value = false;
  }
  if (el.target.scrollTop <= 270 && !loading.value && !firstPage.value) {
    loading.value = true;
    let message_id = firstMessageId.value;
    const result = await props.channel.query({
      messages: { limit: messageLimit.value, id_lt: message_id }
    });
    if (result.messages.length > 0) {
      let formatMessages = result.messages.map(x => {
        return { ...x, created_at: new Date(x.created_at) };
      });
      messages.value.unshift(...formatMessages);
      if (result.messages.length < messageLimit.value) {
        firstPage.value = true;
      }
      scrollTodiv(`message_${message_id}`);
    }
    loading.value = false;
  } else if (
    el.target.offsetHeight + el.target.scrollTop >= el.target.scrollHeight - 200 &&
    !loading.value &&
    !lastPage.value
  ) {
    loading.value = true;
    let message_id = lastMessageId.value;
    const result = await props.channel.query({
      messages: { limit: messageLimit.value, id_gt: message_id }
    });
    if (result.messages.length > 0) {
      let formatMessages = result.messages.map(x => {
        return { ...x, created_at: new Date(x.created_at) };
      });
      messages.value.push(...formatMessages);
      if (result.messages.length < messageLimit.value) {
        lastPage.value = true;
      }
      scrollTodiv(`message_${message_id}`);
    }
    loading.value = false;
  }
};

function markRead() {
  props.channel.markRead();
}

function onDrop(e) {
  emitter.$emit('files-added', e.dataTransfer.files);
}

function preventDefaults(e) {
  e.preventDefault();
}

function getRequestMessages() {
  let invitedBy = getInvitedBy(props.channel);
  let messages = [];
  if (props.channel.data.note) {
    messages.push({
      id: Math.random(),
      created_at: props.channel.data.created_at,
      text: props.channel.data.note,
      user: props.channel.data.created_by
    });
  }
  messages.push({
    id: Math.random(),
    created_at: channelUser.value.updated_at,
    text: !props.channel.data.direct_messaging
      ? '<div class="text-xs text-gray-400"><span class="font-medium text-gray-200">' +
        invitedBy +
        '</span> has <span class="font-medium text-gray-200">invited</span> you to ' +
        channelName.value +
        '.</div>'
      : '<div class="text-xs text-gray-400"><span class="font-medium text-gray-200">' +
        invitedBy +
        '</span> wants to connect.</div>',
    activity_status: 'invited'
  });
  return messages;
}
function onPaste(event) {
  var items = (event.clipboardData || event.originalEvent.clipboardData).items;
  let files = [];
  for (var index in items) {
    var item = items[index];
    if (item.kind === 'file' && item.type.startsWith('image/')) {
      var file = item.getAsFile();
      files.push(file);
    }
  }
  if (files.length > 0) {
    emitter.$emit('files-added', files);
  }
}
watch(
  () => props.channel,
  () => {
    messages.value = props.channel.state.messages;
  },
  { deep: true }
);
</script>
