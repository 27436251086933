<template>
  <div class="relative flex h-full w-full items-center justify-center">
    <div :id="randomId" class="h-full w-full" v-if="series.length"></div>
    <NoDataFound v-else />
  </div>
</template>
<script>
import Highcharts from 'highcharts/highcharts';
//this is work in progress
window.Highcharts = Highcharts;
import moment from 'moment';
import global from '@/mixins/global';
import colors from 'tailwindcss/colors';
import FilterMixin from '@/mixins/filters';

export default {
  mixins: [global, FilterMixin],
  mounted() {
    const self = this;
    if (this.series.length) {
      self.generateChart();
    }
    self.$eventHub.$on('reflow-highchart', function () {
      self.$nextTick(() => {
        self.chartObj.reflow();
      });
    });
  },
  beforeUnmount() {
    this.$eventHub.$off('reflow-highchart');
  },
  props: {
    waterMarkFontSize: {
      type: Number,
      default: () => 16
    },
    series: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object,
      default: () => {
        return { type: 'line' };
      }
    },
    gasFeeDateFormat: {
      type: String,
      default: null
    }
  },

  watch: {
    series() {
      const self = this;
      self.$nextTick(() => {
        self.generateChart();
      });
    }
  },

  computed: {
    chartConfig() {
      const self = this;
      const GasFeeFormat = this.gasFeeDateFormat;
      return {
        chart: {
          type: this.config.type || 'line',
          backgroundColor: 'transparent',
          zoomType: '',
          events: {
            load: function () {
              self.addWatermark(this);
            }
          }
        },
        credits: {
          enabled: false
        },
        colors: [
          colors.blue[500],
          colors.yellow[500],
          colors.purple[500],
          colors.orange[500],
          colors.teal[500],
          colors.gray[500],
          colors.pink[500]
        ],
        xAxis: {
          type: 'datetime',
          lineColor: colors.gray[600],
          visible: this.config.xAxis,
          labels: {
            enabled: true,
            style: {
              color: colors.gray[200],
              fontSize: '12px',
              fontWeight: 'bold'
            },
            formatter: self.config.dateformater
              ? function () {
                  return window.Highcharts.dateFormat('%b %e', this.value);
                }
              : null
          },
          lineWidth: this.config.xAxisLineWidth || 0,
          tickLength: 0
        },
        yAxis: [],
        title: {
          text: this.config.graphTitle || '',
          style: {
            fontWeight: 'bold',
            color: colors.gray[200],
            fontSize: '14px'
          }
        },
        time: {
          useUTC: GasFeeFormat ? true : false,
          timezoneOffset: GasFeeFormat ? 300 : 0
        },
        legend: {
          enabled: this.config.legend || false,
          itemHoverStyle: {
            color: colors.gray[200]
          },
          itemStyle: {
            color: colors.gray[400],
            fontWeight: '400',
            fontSize: '14px'
          }
        },
        tooltip: {
          shared: true,
          useHTML: true,
          backgroundColor: null,
          style: {
            color: 'white',
            pointerEvents: 'auto'
          },
          borderWidth: 0,
          formatter: function () {
            const date = GasFeeFormat
              ? moment(this.x).utcOffset(0, false).format('MMM DD, YY HH:mm:ss')
              : moment(this.x).format('MMM DD, YYYY hh:mm:ss');
            const tipHtml = `<b>${date}</b><br/>`;
            const fromattedPnts = this.points.map(point => {
              const pointSymb = this.points.length === 1 ? '' : `<span style="color:${point.color}">●</span>`;
              let roundTo = point.y > 1 ? 2 : 6;
              return (
                pointSymb +
                `<b> ${point.series.name}:</b>
                <span style="color:${point.color}; margin-left: 0.5rem;">${self.formatNumber(point.y, roundTo)}</span>`
              );
            });
            return (
              "<div class='max-w-md break-words rounded border border-gray-700 bg-gray-800 px-4 py-2 text-left text-gray-200'>" +
              tipHtml +
              fromattedPnts.join('<br/>') +
              '</div>'
            );
          }
        },
        series: [],
        plotOptions: {
          series: {
            // color: colors.blue[500],
            animation: false,
            marker: {
              enabled: false
            }
          }
        },
        exporting: {
          enabled: false,
          fallbackToExportServer: false,
          filename: this.config.csvFilename,
          chartOptions: {
            chart: {
              backgroundColor: colors.gray[900]
            },
            title: {
              text: this.config.pngFileTitle,
              style: {
                color: colors.gray[300],
                fontWeight: 'bold',
                fontSize: '14px'
              }
            }
          },
          sourceWidth: 1200,
          sourceHeight: 400
        }
      };
    }
  },
  data() {
    return {
      chartObj: undefined,
      randomId: Math.random().toString()
    };
  },
  methods: {
    generateChart() {
      const self = this;
      let config = self.chartConfig;
      let yAxisConfig = {
        visible: this.config.yAxis,
        gridLineColor: colors.gray[800],
        lineColor: colors.gray[600],
        scaleShowLabels: true,
        opposite: true,
        title: {
          text: '',
          style: {
            color: colors.gray[300],
            fontSize: '14px'
          }
        },
        labels: {
          enabled: this.config.yAxisLabels || true,
          style: {
            color: colors.gray[200],
            fontSize: '12px',
            fontWeight: 'bold'
          }
        }
      };
      for (var i = 0; i < self.series.length; i++) {
        let temp = JSON.parse(JSON.stringify(yAxisConfig));
        temp.opposite = i % 2 != 0;
        if (self.config.yAxisTitle) {
          temp.title.text = self.series[i].name;
        }
        if (self.series[i].lable) {
          temp.labels = self.series[i].lable;
        }
        config.yAxis.push(temp);
      }
      config.series = self.series;
      if (config.series && config.series.length > 0) {
        self.chartObj = window.Highcharts.chart(self.randomId, config);
        self.$emit('chart', self.chartObj);
      }
    },
    addWatermark(chart) {
      var xCords = chart.plotWidth / 2 + chart.plotLeft - 75,
        yCords = chart.plotHeight / 2 + chart.plotTop + 5;
      chart.renderer
        .text('The Tie Terminal', xCords, yCords)
        .css({
          fontSize: this.waterMarkFontSize + 'px',
          color: colors.gray[700]
        })
        .add();
    }
  }
};
</script>
