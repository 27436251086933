<template>
  <div class="relative inline">
    <div class="flex items-center justify-center py-2">
      <div class="flex w-36 cursor-pointer" @click="focusInput()">
        <BaseSearchbox
          placeholder="Type / to Search"
          class="w-36"
          :id="inputId"
          @keyup="updateSearchPhrase()"
          v-model="searchPhrase"
          @focus="searchFocused = true"
          data-id="search-input"
        />
      </div>
    </div>
    <div v-if="searchMode" class="relative">
      <div class="fixed inset-0 z-40" @click="closeSearch()"></div>
      <div
        class="absolute right-0 z-51 h-auto w-132 rounded border border-grid bg-gray-800/75 py-1 shadow-lg backdrop-blur-md"
      >
        <div v-if="!searchPhrase">
          <div class="space-y-1 px-4 py-1">
            <div class="justify-left flex items-center space-x-2 px-2">
              <div class="mt-4 mb-2 flex w-48 items-center space-x-1 text-xs font-medium text-gray-400">
                Top Gainers <IconArrowUpDown class="ml-1" />
              </div>
              <div class="mt-4 mb-2 w-24 items-center space-x-1 whitespace-nowrap text-xs font-medium text-gray-400">
                Price
              </div>
              <div class="mt-4 mb-2 w-24 items-center space-x-1 whitespace-nowrap text-xs font-medium text-gray-400">
                Price Change 24hr
              </div>
            </div>

            <div
              id="trending-coins"
              :data-dropdown-item="idx"
              :data-link="`/coin/${coin.uid}`"
              class="flex cursor-pointer items-center justify-between space-x-1 rounded px-2 py-1.5 text-white duration-100 hover:bg-gray-700/50"
              :class="`${focusedItem == idx ? 'element-hover bg-gray-700/50' : ''}`"
              v-for="(coin, idx) in trendingCoins"
              :key="idx"
              :limit="trendingCoins.length"
              @focus="focusedItem = idx"
              @click="selectItem(`/coin/${coin.uid}`, coin)"
            >
              <div class="flex w-full items-center space-x-2">
                <div class="flex w-48 cursor-pointer items-center">
                  <BaseCoinIcon :coin="coin.uid" class="mr-1 h-4 w-4" />
                  <span v-if="coin.name" class="flex items-center text-sm font-medium">
                    {{ truncate(coin.name, 20) }}
                    <span class="ml-1 mt-0.5 text-xs font-medium text-gray-400">{{ coin.ticker }}</span>
                    <CoinVerifiedIcon v-if="isCoinVerified(coin.uid)" :target-size="'h-3 w-3'" class="pl-1" />
                  </span>
                </div>
                <div class="mt-0.5 w-24 text-xs font-medium text-white">
                  {{ usdCryptoPrice((coin.price || '').toLocaleString()) }}
                </div>
                <div class="mt-0.5 w-24 text-xs font-medium text-white">
                  <BasePercentClassic :percent="`${coin.price_return_24_hours}`" />
                </div>
                <div v-if="focusedItem == idx" class="flex items-center justify-center space-x-1">
                  <span class="text-xs font-medium text-gray-300">Select</span>
                  <div class="flex items-center justify-around rounded bg-gray-700 py-px px-1">
                    <IconEnter />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-6 px-4" v-if="recentSearch.length">
            <span class="text-xs font-medium text-gray-400">Recent Searches</span>
            <div class="flex h-full w-full flex-row items-center space-x-2 overflow-x-auto py-2">
              <div
                v-for="(item, idx) in recentSearch"
                :key="idx"
                class="flex cursor-pointer items-center justify-between rounded bg-gray-700 px-1 py-1 shadow duration-100 hover:bg-gray-700/50"
              >
                <InertiaLink class="h-16 w-20 justify-center" :href="item.link">
                  <div class="flex cursor-pointer flex-col items-center justify-center">
                    <div class="mb-1">
                      <BaseCoinIcon v-if="item.uid" :coin="item.uid" class="ml-2 h-4 w-4" />
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        class="inline h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <BaseTooltip position="top">
                      <template #target>
                        <div class="flex flex-row items-center">
                          <div v-if="item.name" class="whitespace-nowrap text-sm font-medium">
                            {{ truncate(item.name, 8) }}
                          </div>
                          <CoinVerifiedIcon v-if="isCoinVerified(item.uid)" :target-size="'h-3 w-3'" class="pl-1" />
                        </div>
                      </template>
                      <template #default>{{ item.name }}</template>
                    </BaseTooltip>
                    <span v-if="item.ticker" class="text-xs font-medium text-gray-400">{{ item.ticker }}</span>
                  </div>
                </InertiaLink>
              </div>
            </div>
          </div>
        </div>

        <div v-if="searchPhrase && (this.computedCoins.length || this.computedCompanies.length)">
          <div class="flex items-center">
            <div class="flex items-center justify-center py-1 px-6">
              <BaseTags
                :tags="filteredTags"
                :selected="[selectedTag]"
                @toggle="updateSelectedTag($event)"
                :tag-bg-class="'bg-gray-700'"
              />
            </div>
          </div>
          <div :class="`${displayAllCoins || displayAllCompanies ? 'max-h-80 overflow-y-auto' : 'max-h-104'}`">
            <div class="flex items-center px-4 py-1" v-if="computedCoins.length && !displayAllCompanies">
              <div class="w-full">
                <div v-if="!displayAllCoins" class="justify-left flex items-center space-x-1 px-2">
                  <div class="w-48 text-xs font-medium text-gray-400">Coins</div>
                  <div
                    class="mt-4 mb-2 w-24 items-center space-x-1 whitespace-nowrap text-xs font-medium text-gray-400"
                  >
                    Price
                  </div>
                  <div
                    class="mt-4 mb-2 w-24 items-center space-x-1 whitespace-nowrap text-xs font-medium text-gray-400"
                  >
                    Price Change 24hr
                  </div>
                </div>
                <div class="space-y-1">
                  <div
                    id="coins"
                    :data-dropdown-item="idx"
                    :data-link="`/coin/${coin.uid}`"
                    class="flex cursor-pointer items-center justify-between space-x-1 rounded px-1 py-1.5 text-white duration-100 hover:bg-gray-700/50"
                    :class="`${focusedItem == idx ? 'element-hover bg-gray-700/50' : ''}`"
                    v-for="(coin, idx) in computedCoins"
                    :key="idx"
                    :limit="computedCoins.length + computedCompanies.length"
                    @focus="focusedItem = idx"
                    @click="selectItem(`/coin/${coin.uid}`, coin)"
                  >
                    <div class="flex w-full items-center space-x-2">
                      <div class="flex w-48 cursor-pointer items-center">
                        <BaseCoinIcon :coin="coin.uid" class="mr-1 h-4 w-4" />
                        <span v-if="coin.name" class="text-sm font-medium">{{ truncate(coin.name, 20) }}</span>
                        <span class="ml-1 text-xs font-medium text-gray-400">{{ coin.ticker }}</span>
                        <CoinVerifiedIcon v-if="isCoinVerified(coin.uid)" :target-size="'h-3 w-3'" class="pl-1" />
                      </div>
                      <div class="mt-0.5 w-24 text-xs font-medium text-white">
                        {{ usdCryptoPrice((coin.price || '').toLocaleString()) }}
                      </div>
                      <div class="mt-0.5 w-24 text-xs font-medium text-white">
                        <BasePercentClassic :percent="`${coin.price_return_24_hours}`" />
                      </div>
                      <div v-if="focusedItem == idx" class="flex items-center space-x-1">
                        <span class="text-xs font-medium text-gray-300">Select</span>
                        <div class="flex items-center justify-around rounded bg-gray-700 py-px px-1">
                          <IconEnter />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 cursor-pointer" v-if="!displayAllCoins && computedCoins.length > 4">
                    <span
                      class="text-xs text-blue-400"
                      @click="
                        displayAllCoins = true;
                        selectedTag = 'coins';
                        focusedItem = 0;
                      "
                      >See all results ({{ filteredCoins.length }})</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center px-4 py-1" v-if="computedCompanies.length && !displayAllCoins">
              <div class="w-full">
                <span class="text-xs font-medium text-gray-400">Companies</span>
                <div class="space-y-1">
                  <div
                    id="companies"
                    :data-dropdown-item="!displayAllCompanies ? computedCoins.length + idx : idx"
                    :data-link="`/company/${company.slug}`"
                    class="flex cursor-pointer items-center justify-between space-x-1 rounded px-1 py-1.5 text-white duration-100 hover:bg-gray-700/50"
                    :class="`${
                      (!displayAllCompanies ? focusedItem == computedCoins.length + idx : focusedItem == idx)
                        ? 'bg-gray-700/50'
                        : ''
                    }`"
                    v-for="(company, idx) in computedCompanies"
                    :key="company.id"
                    :limit="computedCoins.length + computedCompanies.length"
                    @focus="focusedItem = !displayAllCompanies ? computedCoins.length + idx : idx"
                    @click="selectItem(`/company/${company.slug}`, company)"
                  >
                    <div class="flex cursor-pointer items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-2 inline h-3 w-3"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span v-if="company.name" class="text-sm font-medium">{{ truncate(company.name, 20) }}</span>
                    </div>
                    <div
                      v-if="!displayAllCompanies ? focusedItem == computedCoins.length + idx : focusedItem == idx"
                      class="flex items-center space-x-1"
                    >
                      <span class="text-xs font-medium text-gray-300">Select</span>
                      <div class="flex items-center justify-around rounded bg-gray-700 py-px px-1">
                        <IconEnter />
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 cursor-pointer" v-if="!displayAllCompanies && computedCompanies.length > 4">
                    <span
                      class="mt-4 cursor-pointer text-xs text-blue-400"
                      @click="
                        displayAllCompanies = true;
                        selectedTag = 'companies';
                        focusedItem = 0;
                      "
                      >See all results ({{ filteredCompanies.length }})</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="searchPhrase && !this.computedCoins.length && !this.computedCompanies.length"
          class="flex flex-col items-center justify-center py-8 px-4"
        >
          <span class="mb-2 text-sm font-medium">No results for '{{ searchPhrase }}'</span>
          <span class="text-center text-xs text-gray-400">
            If we don't support this coin or company, please let us know - we appreciate requests.
          </span>
        </div>
        <div
          class="mt-8 flex flex-row items-center justify-between px-4 pb-2"
          :class="searchPhrase && !this.computedCoins.length && !this.computedCompanies.length ? 'hidden' : ''"
        >
          <div class="flex flex-row items-center space-x-1">
            <div class="flex h-4 w-4 items-center justify-around rounded bg-gray-700">
              <IconArrowUp />
            </div>
            <div class="flex h-4 w-4 items-center justify-around rounded bg-gray-700">
              <IconArrowDown />
            </div>
            <span class="text-xs font-medium text-gray-300">To Navigate</span>
          </div>
          <div class="flex flex-row items-center space-x-1">
            <div class="flex h-4 w-8 items-center justify-around rounded bg-gray-700">
              <span class="text-xs"> esc </span>
            </div>
            <span class="text-xs font-medium text-gray-300">To Cancel</span>
          </div>
          <div class="flex flex-row items-center space-x-1">
            <div class="flex h-4 w-6 items-center justify-around rounded bg-gray-700">
              <IconEnter />
            </div>
            <span class="text-xs font-medium text-gray-300">To Select</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import FilterMixin from '@/mixins/filters';
import CoinVerifiedIcon from '@/components/coin/CoinVerifiedIcon.vue';
import { isCoinVerified } from '@/composeables/helpers';
import { Link as InertiaLink } from '@inertiajs/inertia-vue3';
import { createEvent, fixScrolling } from '@/composeables/helpers';
import { Inertia } from '@inertiajs/inertia';

export default {
  mixins: [FilterMixin],
  components: {
    CoinVerifiedIcon,
    InertiaLink
  },
  mounted() {
    window.addEventListener('keydown', this.keydownEventBeforeOpen);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.keydownEventBeforeOpen);
  },
  data() {
    return {
      inputId: `${Math.random()}`,
      searchPhrase: '',
      searchMode: false,
      tags: [
        {
          id: 'all',
          title: 'All'
        },
        {
          id: 'coins',
          title: 'Coins'
        },
        {
          id: 'companies',
          title: 'Companies'
        }
      ],
      selectedTag: 'all',
      displayAllCoins: false,
      displayAllCompanies: false,
      companies: [],
      news: [],
      focusedItem: 0,
      searchFocused: false
    };
  },
  computed: {
    smallCaseSearchPhrase() {
      return this.searchPhrase.toLowerCase();
    },
    coins() {
      return this.$store.getters.coins;
    },
    trendingCoins() {
      var tcoins = JSON.parse(JSON.stringify(this.coins)).sort(function (a, b) {
        let val1 = a['price_return_24_hours'];
        let val2 = b['price_return_24_hours'];
        return val2 - val1;
      });
      if (this.recentSearch.length) {
        return tcoins.slice(0, 5);
      } else {
        return tcoins.slice(0, 9);
      }
    },
    recentSearch() {
      let recentSearch = JSON.parse(localStorage.getItem('recentSearch')) || [];
      return recentSearch
        ? recentSearch
            .filter(
              item =>
                Object.prototype.hasOwnProperty.call(item, 'name') && Object.prototype.hasOwnProperty.call(item, 'link')
            )
            .reverse()
            .slice(0, 5)
        : recentSearch;
    },
    filteredCoins() {
      let filteredCoins = this.coins.filter(coin => {
        return (
          coin.name.toLowerCase().includes(this.smallCaseSearchPhrase) ||
          coin.ticker.toLowerCase().includes(this.smallCaseSearchPhrase)
        );
      });
      return filteredCoins;
    },
    computedCoins() {
      let computedCoins = !this.displayAllCoins ? this.filteredCoins.slice(0, 5) : this.filteredCoins;
      this.updateFocusedItem(computedCoins);
      return computedCoins;
    },
    filteredCompanies() {
      let filteredCompanies = this.companies.filter(x => {
        return (x.name || '').toLowerCase().includes(this.smallCaseSearchPhrase);
      });
      return filteredCompanies;
    },
    computedCompanies() {
      let computedCompanies = !this.displayAllCompanies ? this.filteredCompanies.slice(0, 5) : this.filteredCompanies;
      this.updateFocusedItem(computedCompanies);
      return computedCompanies;
    },
    newsItem() {
      return this.news.length ? this.news[0] : null;
    },
    sourceName() {
      return this.newsItem.sources.length > 1
        ? `${this.newsItem.sources[0]?.name} and ${this.newsItem.sources.length - 1} more...`
        : this.newsItem.sources[0]?.name;
    },
    filteredTags() {
      if (this.smallCaseSearchPhrase) {
        if (!this.filteredCoins.length && !this.filteredCompanies.length) {
          return [];
        }
        if (!this.filteredCoins.length) {
          return this.tags.filter(t => t.id != 'coins');
        }
        if (!this.filteredCompanies.length) {
          return this.tags.filter(t => t.id != 'companies');
        } else {
          return this.tags;
        }
      } else return this.tags;
    }
  },
  watch: {
    searchPhrase() {
      this.updateSearch();
    }
  },
  methods: {
    isCoinVerified,
    async getCompanies() {
      await this.$http.get('/companies').then(response => {
        this.companies = response.data;
      });
    },
    updateSearchPhrase() {
      if (this.searchPhrase == '') {
        this.displayAllCoins = false;
        this.displayAllCompanies = false;
      }
    },
    updateSelectedTag(tag) {
      this.selectedTag = tag;
      if (tag == 'coins') {
        this.displayAllCoins = true;
        this.displayAllCompanies = false;
      }
      if (tag == 'companies') {
        this.displayAllCompanies = true;
        this.displayAllCoins = false;
      }
      if (tag == 'all') {
        this.displayAllCompanies = false;
        this.displayAllCoins = false;
      }
      this.focusedItem = 0;
    },
    saveSearch(redirectLink, item) {
      let recentSearch = JSON.parse(localStorage.getItem('recentSearch')) || [];
      let data = {
        uid: item.uid,
        name: item.name,
        ticker: item.ticker,
        link: redirectLink
      };
      if (!recentSearch.find(item => item['name'] === data.name)) {
        recentSearch.push(data);
        localStorage.setItem('recentSearch', JSON.stringify(recentSearch));
      }
    },
    keydownEventsAfterOpen(e) {
      if (e.target.nodeName.toLowerCase() === 'textarea') {
        return;
      }
      if (e.key === 'Escape') {
        this.closeSearch();
      }
      if (e.key == 'Backspace') {
        this.focusedItem = 0;
      }
      if (e.key === 'Enter') {
        let redirectLink = document
          .querySelector('[data-dropdown-item="' + this.focusedItem + '"]')
          ?.getAttribute('data-link');

        let item = '';
        let activeDivId = document.querySelector('[data-dropdown-item="' + this.focusedItem + '"]')?.getAttribute('id');

        if (activeDivId == 'trending-coins') {
          item = this.trendingCoins ? this.trendingCoins[this.focusedItem] : null;
        }
        if (activeDivId == 'coins') {
          item = this.computedCoins ? this.computedCoins[this.focusedItem] : null;
        }
        if (activeDivId == 'companies') {
          let focusedItem = Math.abs(this.computedCoins.length - this.focusedItem);
          item = this.computedCompanies ? this.computedCompanies[focusedItem] : null;
        }
        this.selectItem(redirectLink, item);
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.focusedItem--;
        if (this.focusedItem >= 0) {
          document.querySelector('[data-dropdown-item="' + this.focusedItem + '"]').focus();
        } else {
          this.focusedItem = 0;
          document.querySelector('input[data-id="search-input"]').focus();
        }
        fixScrolling('up');
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.focusedItem++;
        let limit = document.querySelector('[data-dropdown-item="' + this.focusedItem + '"]')?.getAttribute('limit');

        if (limit && this.focusedItem < limit) {
          document.querySelector('[data-dropdown-item="' + this.focusedItem + '"]').focus();
        } else {
          this.focusedItem = 0;
        }
        fixScrolling('down');
      }
    },
    keydownEventBeforeOpen(e) {
      if (!['input', 'textarea'].includes(e.target.nodeName.toLowerCase()) && e.key === '/') {
        this.focusInput();
      }
    },
    focusInput() {
      this.searchMode = true;
      this.searchFocused = true;
      this.getCompanies();
      setTimeout(() => document.querySelector('input[data-id="search-input"]').focus(), 10);
      window.addEventListener('keydown', this.keydownEventsAfterOpen);
    },
    selectItem(redirectLink, data) {
      if (redirectLink && data) {
        this.saveSearch(redirectLink, data);
        Inertia.visit(redirectLink);
      }
    },
    updateFocusedItem(item) {
      if (item.length) {
        this.focusedItem = 0;
      }
    },
    updateSearch: debounce(function () {
      createEvent({ title: 'Global Search', properties: { search_text: this.searchPhrase } });
    }, 1500),
    closeSearch() {
      this.searchMode = false;
      this.searchPhrase = '';
      window.removeEventListener('keydown', this.keydownEventsAfterOpen);
    }
  }
};
</script>
