<template>
  <div class="grid grid-cols-4 py-4">
    <div class="col-span-4 col-start-1 space-y-2 overflow-y-auto pb-2">
      <div class="flex items-center space-x-2">
        <div
          :class="[
            'rounded px-2 py-1 text-xs capitalize',
            snap.state == 'active' ? 'bg-green-800 text-white' : 'bg-gray-800 text-gray-400'
          ]"
        >
          {{ snap.state }}
        </div>
        <div v-if="snap.name || snap.ticker">
          <InertiaLink
            :href="`/coin/${snap.coin_uid}/governance`"
            class="flex cursor-pointer space-x-1 pr-2 text-xs font-semibold"
          >
            <BaseCoinIcon :coin="snap.coin_uid" class="mr-1 h-4 w-4" :style-class="''" />
            <span class="text-gray-200">{{ snap.name || '' }}</span>
            <span class="text-gray-400">{{ snap.ticker || '' }}</span>
          </InertiaLink>
        </div>
      </div>
    </div>
    <div class="col-span-3 col-start-1 space-y-2 overflow-y-auto">
      <div>
        <div class="flex text-gray-200">
          <a :href="snap.link" class="flex items-center" target="_blank">
            <div class="font-bold hover:text-white">{{ snap.title }}</div>
          </a>
        </div>
        <div class="flex pt-2 text-xs">
          <span class="text-gray-400"> Proposed by </span>
          <div class="ml-1 flex">
            <a
              :href="`http://www.etherscan.io/address/${snap.author}`"
              target="_blank"
              class="flex text-gray-200 hover:text-white"
            >
              <span>{{ snap.space_id }}</span>
              <IconOpen size="w-4 h-4" class="ml-2 text-gray-400 hover:text-gray-200"
            /></a>
          </div>
        </div>
        <div class="pt-2 text-xs font-semibold text-gray-200">
          <span>{{ endsOn(snap.end_at) }}</span>
        </div>
      </div>
    </div>
    <div class="ml-auto flex w-full flex-col">
      <div v-for="(choice, i) in snap.choices.slice(0, entries)" :key="i">
        <div>
          <div class="grid grid-cols-2 items-end">
            <div class="items-end pr-2 text-right text-xs text-gray-400">
              <div v-if="mapChoices(choice, i)">
                <BaseTooltip position="bottom">
                  <template #target>
                    <p class="text-xs text-gray-400">{{ truncate(choices[i], 8) }}</p>
                  </template>
                  <template #default v-if="choices[i].length > 8">
                    {{ choices[i] }}
                  </template>
                </BaseTooltip>
              </div>
              <div v-else>
                <BaseTooltip position="bottom">
                  <template #target>
                    <p class="text-right text-xs text-gray-400">{{ truncate(choice, 8) }}</p>
                  </template>
                  <template #default v-if="choice.length > 8">
                    {{ choice }}
                  </template>
                </BaseTooltip>
              </div>
            </div>
            <!-- <div class="mx-auto pr-4 text-gray-400">{{ formatNumber(snap.scores[i]) }}</div> -->
            <div class="ml-auto w-full text-gray-200">
              {{ roundedPercent((snap.scores[i] / snap.scores_total) * 100) || 'N/A' }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="grid grid-cols-2 items-end">
          <div></div>
          <div
            v-if="snap.choices && snap.choices.length > 2"
            class="flex cursor-pointer pt-2 pb-2 text-xs font-normal text-gray-300"
            @click="
              expandChart == false //currently using icon, can also switch to text to show more or less
                ? ((expandChart = true), (currentChartState = 'View less'), (entries = snap.choices.length))
                : ((expandChart = false), (currentChartState = 'View more'), (entries = 2))
            "
          >
            {{ currentChartState }}
            <iconChevronDown
              class="text-gray-300 hover:text-white"
              :class="expandChart ? 'rotate-180' : ''"
            ></iconChevronDown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import FilterMixin from '@/mixins/filters';
import { relativeTime } from '@/composeables/filters';
import { Link as InertiaLink } from '@inertiajs/inertia-vue3';
export default {
  mixins: [FilterMixin],
  name: 'CellGovernance',
  components: {
    InertiaLink
  },
  props: {
    snap: {
      type: Object,
      default: () => {}
    }
  },
  data: () => {
    return {
      choices: ['For', 'Against', 'Abstain'],
      currentChartState: 'View more',
      currentBodyState: 'Read more',
      expandBody: false,
      expandChart: false,
      entries: 2
    };
  },
  methods: {
    goToCoinPage(coin_uid) {
      window.open(`/coin/${coin_uid}/governance`);
    },
    endsOn(date) {
      var dateString = this.formatDate(date);
      var endTime = date;
      var currentTime = Date.now();
      let diff = endTime - currentTime;
      return diff > 0 ? `Ends ${relativeTime(date)} (${dateString})` : `Ended ${relativeTime(date)} (${dateString})`;
    },
    formatDate(date) {
      return moment(date).format('MMM D, h:mma');
    },
    mapChoices(choice, choiceNumber) {
      let forTerms = ['Yes', 'yes', 'YAE', 'Yae', 'For', 'FOR', 'Approve', 'Accept', 'YES', 'Adopt'];
      let againstTerms = [
        'No',
        'NAY',
        'NAE',
        'nae',
        'nay',
        'Against',
        'AGAINST',
        'Disapprove',
        'Reject',
        'NO',
        'no',
        'Nothing',
        'Not'
      ];
      let abstainTerms = ['Abstain', 'abstain', 'ABSTAIN'];

      if (forTerms.some(term => term == choice) && choiceNumber == 0) {
        this.choices[0] = 'For';
        return true;
      }
      if (againstTerms.some(term => term == choice) && choiceNumber == 1) {
        this.choices[1] = 'Against';
        return true;
      }
      if (abstainTerms.some(term => term == choice) && choiceNumber == 2) {
        this.choices[2] = 'Abstain';
        return true;
      }
    }
  }
};
</script>
