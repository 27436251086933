import useHttp from '@/composeables/http';
import moment from 'moment';

export function useGovernanceApi() {
  const $http = useHttp();
  const getProposalsData = async (
    tableValue,
    limit,
    pagevalue,
    stateValue,
    searchValue,
    selectedCoins,
    selectedTimeframe,
    closeVoteValue
  ) => {
    const governanceResponse = await $http.get('/governance_proposals', {
      params: {
        table: tableValue,
        per_page: limit,
        page: pagevalue,
        state: stateValue,
        search: searchValue,
        coins: selectedCoins,
        timeframe: selectedTimeframe,
        close_score: closeVoteValue
      }
    });

    const mappedProposals = governanceResponse.data.proposals.map(row => {
      let ret = {
        ...row,
        index: row.index || 0,
        type: row.type || '',
        body: row.body || '',
        choices: JSON.parse(row.choices),
        scores: JSON.parse(row.scores),
        scores_by_strategy: JSON.parse(row.scores_by_strategy),
        scores_total: parseFloat(row.scores_total),
        end_at: new Date(row.end_at),
        start_at: new Date(row.start_at),
        scores_updated_at: new Date(row.scores_updated_at)
      };
      return ret;
    });
    return { proposals: mappedProposals, count: governanceResponse.data.count.total_count };
  };

  const getSpacesData = async table => {
    const governanceResponse = await $http.get('/governance_proposals');

    return governanceResponse.data.spaces.map(row => {
      let ret = {
        ...row,
        index: row.index || 0,
        type: row.type || ''
      };

      return ret;
    });
  };

  return { getProposalsData, getSpacesData };
}
