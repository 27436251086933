<template>
  <div class="float-right flex items-center text-white">
    <span
      class="inline-flex cursor-pointer"
      v-for="action in obj.action"
      :key="`${action}_${obj.id}`"
      @click="clicked(action)"
      @mouseenter="mouseenter(action)"
      @mouseleave="mouseleave(action)"
    >
      <component :is="action" :only-pencil="true" class="" :size="`${textClass(action)} w-4 h-4 ml-1`"> </component>
      <span :class="`${textClass(action)} inline text-sm capitalize `">{{ label ? action : '' }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'CellRowAction',
  props: {
    obj: {
      type: Object,
      default: () => {}
    },
    label: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hover: {
        IconDelete: 'text-red-500',
        IconEdit: 'text-blue-500',
        IconRestart: 'text-gray-400',
        IconPlay: 'text-gray-400',
        IconPause: 'text-gray-400'
      },
      IconDeleteClass: 'text-gray-100',
      IconEditClass: 'text-gray-100',
      IconRestartClass: 'text-gray-100',
      IconPlayClass: 'text-gray-100',
      IconPauseClass: 'text-gray-100'
    };
  },
  methods: {
    clicked(action) {
      this.$emit('actionClicked', { action: action, obj: this.obj });
    },
    mouseenter(action) {
      this[action + 'Class'] = this.hover[action];
    },
    mouseleave(action) {
      this[action + 'Class'] = 'text-gray-100';
    },
    textClass(action) {
      return this[action + 'Class'];
    }
  }
};
</script>
