<template>
  <Multiselect
    :class="['text-sm', classes]"
    :mode="mode"
    :close-on-select="closeOnselect"
    :can-clear="clearable"
    :searchable="searchable"
    :placeholder="placeholder"
    :hide-selected="hideSelected"
    :open-direction="openDirection"
    :options="sortedOptions"
    :model-value="modelValue"
    @change="val => updateValue(val)"
    :value-prop="valueProp"
    :label="label"
    :required="required"
    :disabled="disabled"
  >
    <template #option="{ option }" v-if="mode != 'single'">
      <div class="flex items-center space-x-2">
        <input type="checkbox" class="block" :checked="modelValue.indexOf(getID(option)) !== -1" />
        <span class="block pb-px">{{ getLabel(option) }}</span>
      </div>
    </template>
  </Multiselect>
</template>

<script setup>
import { ref, computed } from 'vue';
import { orderBy } from 'lodash';
import { useStore } from 'vuex';
import Multiselect from '@vueform/multiselect';
// import 'css/multiselect.css';

const emits = defineEmits(['update:modelValue', 'input']);
const store = useStore();

const sortedOptions = computed(() => {
  if (props.sortOptions) {
    return orderBy(props.options, props.label, props.sortOrder);
  } else {
    return props.options;
  }
});

const props = defineProps({
  modelValue: { type: [String, Array], default: undefined },
  mode: { type: String, default: 'single' },
  clearable: { type: Boolean, default: true },
  searchable: { type: Boolean, default: false },
  openDirection: { type: String, default: 'bottom' },
  required: { type: Boolean, default: false },
  closeOnselect: { type: Boolean, default: true },
  placeholder: { type: String, default: 'Select' },
  hideSelected: { type: Boolean, default: true },
  options: { type: Array, default: () => [] },
  valueProp: { type: String, default: 'value' },
  label: { type: String, default: 'label' },
  classes: { type: String, default: 'w-full' },
  disabled: { type: Boolean, default: false },
  sortOptions: { type: Boolean, default: false },
  sortOrder: { type: String, default: 'asc' }
});

function updateValue(val) {
  emits('update:modelValue', val);
  emits('input', val);
}

function getLabel(option) {
  if (props.label === 'name') return option.name;
  else return option.label;
}

function getID(option) {
  if (props.valueProp === 'id') return option.id;
  else return option.value;
}
</script>
<style>
.multiselect-options > li {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.multiselect-dropdown:hover > li {
  background-color: #1e293b !important;
}
</style>
