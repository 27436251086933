<template>
  <div class="w-full" v-if="loaderStyle == 'grid'">
    <div class="rounded">
      <div class="aspect-video animate-pulse rounded bg-gray-800"></div>
      <div class="space-y-2 py-2">
        <div class="h-4 w-40 animate-pulse rounded bg-gray-800"></div>
        <div class="h-4 w-full animate-pulse rounded bg-gray-800"></div>
      </div>
    </div>
  </div>
  <div v-else class="w-full">
    <div class="flex">
      <div class="w-3/12">
        <div class="aspect-video animate-pulse rounded bg-gray-800"></div>
      </div>
      <div class="w-9/12">
        <div class="px-4">
          <div class="mb-2 h-4 w-1/2 animate-pulse rounded-sm bg-gray-800"></div>
          <div class="h-4 w-1/4 animate-pulse rounded-sm bg-gray-800"></div>
          <div class="mt-6 space-y-2">
            <div class="h-4 animate-pulse rounded-sm bg-gray-800"></div>
            <div class="h-4 animate-pulse rounded-sm bg-gray-800"></div>
            <div class="h-4 w-3/4 animate-pulse rounded-sm bg-gray-800"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadTemplatesResearchCard',
  props: {
    loaderStyle: {
      type: String,
      default: 'grid'
    }
  }
};
</script>
