<template>
  <div class="items-left min-w-xl flex py-1 text-left font-normal text-white">
    <div class="flex items-center space-x-2">
      <InertiaLink :href="profile.link">
        <div class="flex items-center">
          <img :src="profile.imageUrl" class="h-5 w-5 rounded-full bg-gray-800" loading="lazy" />
          <div class="ml-2">
            <p class="whitespace-nowrap">{{ profile.name }}</p>
          </div>
        </div>
      </InertiaLink>
      <div class="w-full pl-4">
        <div
          @click="
            $event.preventDefault();
            onCollectionSaved(profile, address);
          "
        >
          <IconBookmarked class="h-4 w-4 cursor-pointer text-white" v-if="profile.saved" />
          <IconBookmark class="h-4 w-4 cursor-pointer text-gray-400" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Link as InertiaLink } from '@inertiajs/inertia-vue3';
import useEmitter from '@/composeables/emitter';
import { inject } from 'vue';

const props = defineProps({
  profile: {
    type: Object,
    default: () => {}
  },
  address: {
    type: String,
    default: ''
  }
});

const addUserCollection = inject('addUserCollection');

const onCollectionSaved = (col, add) => {
  addUserCollection({
    name: col.name,
    contract: add
  });
};
</script>
