<template>
  <div
    :class="`flex flex-1 flex-col bg-gray-900 text-white ${noContainer ? '' : 'relative'} ${rounded ? 'rounded' : ''}`"
  >
    <div :class="`relative ${headerClass}`">
      <div
        v-if="title"
        :class="`w-100 flex h-8 select-none items-center border-b border-grid bg-gray-800 ${
          rounded ? 'rounded-t' : ''
        }`"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        @click="$emit('clicked-title')"
      >
        <div class="tie-card-header flex w-full items-center justify-between py-2 px-5">
          <div :class="`tie-card-title w-11/12 truncate text-xs font-medium text-gray-100`">
            {{ title }}
            <span v-if="iconTooltip">
              <CoinMetricTooltip :title="title" :tooltip-key="tooltipKey" :placement="placement" />
            </span>
          </div>
          <div>
            <slot name="right-content"></slot>
          </div>
        </div>
        <div
          v-if="viewMore"
          class="group mr-5 flex cursor-pointer items-center whitespace-nowrap text-xs font-semibold"
          @click="$emit('viewMore')"
        >
          <span class="block cursor-pointer text-xs text-gray-400 group-hover:text-white">See More </span>
          <IconArrowRight class="ml-1 block h-4 w-4 text-gray-400 group-hover:text-white" />
        </div>

        <div v-if="closeable" class="ml-auto" @click="$emit('close')">
          <IconChevronLeft class="mr-1 h-4 w-4 cursor-pointer text-gray-300 hover:text-white" />
        </div>
        <div
          class="ml-auto inline cursor-pointer pr-3 hover:text-white"
          v-if="dropdown"
          @click="$emit('actionClicked')"
        >
          <DropdownTableCell :items="dropdown" :cell-info="cellInfo" class="z-50" />
        </div>
        <div v-if="csvDownload && !exportLoading" class="ml-auto pr-2" @click="$emit('download:csv')">
          <BaseTooltip>
            <template #target>
              <IconDownload class="mr-2 h-4 w-4 cursor-pointer text-gray-300 hover:text-white" />
            </template>
            <template #default> Download CSV </template>
          </BaseTooltip>
        </div>
        <div v-else-if="csvDownload && exportLoading" class="pr-4">
          <BaseLoader size="w-4 h-4" />
        </div>
      </div>
    </div>
    <div class="tie-card-container relative flex flex-1" v-if="!noContainer">
      <div
        :class="`overflow-y-${scroll ? 'scroll' : 'hidden'} absolute top-0 left-0 right-0 bottom-0 overflow-x-hidden`"
      >
        <slot></slot>
      </div>
    </div>

    <div v-if="noContainer" class="tie-card-container" :class="noContainerClass">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import DropdownTableCell from '@/components/dropdown/DropdownTableCell.vue';
import CoinMetricTooltip from '@/components/coin/CoinMetricTooltip.vue';
export default {
  name: 'BaseCardClassic',
  components: {
    DropdownTableCell,
    CoinMetricTooltip
  },
  props: {
    title: String,
    color: String,
    scroll: Boolean,
    closeable: Boolean,
    csvDownload: Boolean,
    noContainer: Boolean,
    dropdown: Array,
    rounded: Boolean,
    headerClass: String,
    cellInfo: Object,
    iconTooltip: Boolean,
    tooltipKey: String,
    placement: String,
    noContainerClass: {
      type: String,
      default: ''
    },
    showBellIcon: {
      type: Boolean,
      default: false
    },
    viewMore: {
      type: Boolean,
      default: false
    },
    exportLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hover: false,
      hoverOverride: false
    };
  },
  methods: {
    dropdownChange: function (dropdownState) {
      this.hoverOverride = dropdownState;
      this.hover = dropdownState;
    }
  }
};
</script>
