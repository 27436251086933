<template>
  <span :class="percent > 0 ? 'text-green-400' : 'text-red-400'">
    <template v-if="hideSign">
      {{ showPositiveNegativeSign ? (percent > 0 ? '+' : '-') : '' }}
      {{ rounded(Math.abs(percentageValue)) }}%
    </template>
    <template v-else>
      {{ showPositiveNegativeSign ? (percent > 0 ? '+' : '-') : '' }}
      {{ percentage(rounded(percentageValue)) }}
    </template>
  </span>
</template>

<script>
import FilterMixin from '@/mixins/filters';

export default {
  mixins: [FilterMixin],
  props: {
    percent: { type: [Number, String], required: false },
    hideSign: { type: Boolean, required: false },
    multiply: { type: Boolean, default: true },
    parentheses: { type: Boolean },
    showPositiveNegativeSign: { type: Boolean, default: false }
  },
  computed: {
    percentageValue() {
      return this.multiply && this.percent ? this.percent * 100 : this.percent;
    }
  }
};
</script>
