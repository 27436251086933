<template>
  <div class="flex h-full flex-col justify-between px-8 pr-12">
    <div class="space-y-2 px-2 pt-2 pr-5">
      <div class="flex flex-col items-center space-y-2">
        <div class="relative w-full rounded border border-gray-700">
          <div class="flex h-8 items-center space-x-2 rounded bg-gray-800 px-3 py-2">
            <IconRequest size="h-4 w-4 text-gray-500 flex-shrink-0" />
            <input
              v-if="!selectedUser"
              class="h-full w-full rounded border-none bg-gray-800 text-xs outline-none focus:border-none"
              placeholder="Search people"
              v-model="searchPeopleText"
              @click="searchPeopleDropdown = true"
            />
            <div v-if="selectedUser" class="w-full cursor-text" @click="searchPeopleDropdown = true">
              <div class="flex w-fit items-center space-x-2 rounded bg-blue-900 py-1.5 px-1.5 pb-1">
                <span class="text-xs font-medium leading-4">
                  {{ truncate(selectedUserData.name, 25) }}
                </span>
                <IconCross
                  v-if="selectedUser"
                  size="w-3.5 h-3.5 text-gray-100 justify-end"
                  @click="
                    selectedUser = null;
                    channel_data = [];
                    searchPeopleText = '';
                  "
                />
              </div>
            </div>
          </div>
          <div class="absolute w-full">
            <DropdownMessenger
              v-if="searchPeopleDropdown"
              :items="userOptions"
              :open="searchPeopleDropdown"
              :search-text="searchPeopleText"
              :search-dropdown="true"
              :active-chat-option="selectedUser ? { id: selectedUserData, name: selectedUserData.name } : null"
              @select="
                searchPeopleDropdown = false;
                setSelectedUser($event);
              "
              @close="searchPeopleDropdown = false"
            />
          </div>
        </div>
        <div class="flex w-full items-center space-x-2 rounded border border-gray-700 bg-gray-800 px-3">
          <BaseDatepicker
            @change="
              (date, type) => {
                selectedRange = date;
              }
            "
            format="MM/DD/YY"
            placeholder="Select Date Range"
            :default-date="selectedRange"
            font-size="text-xs"
            custom-style="width: 17rem; max-width: 17rem"
            :show-clear-button="false"
            :custom-icon="true"
          ></BaseDatepicker>
        </div>
        <div class="relative w-full rounded border border-gray-700 bg-gray-800">
          <div class="flex h-8 items-center space-x-2 rounded bg-gray-800 px-3 py-2">
            <IconChatAlt size="h-3.5 w-3.5 text-gray-500 flex-shrink-0" />
            <input
              v-if="!channel_data.length"
              class="h-8 w-full rounded border-none bg-gray-800 text-xs outline-none focus:border-none"
              placeholder="Search chat"
              v-model="searchChatText"
              @click="searchChatDropdown = true"
            />
            <div v-if="channel_data.length" class="w-full cursor-text" @click="searchChatDropdown = true">
              <div class="flex w-fit cursor-text items-center space-x-2 rounded bg-blue-900 px-1.5 py-1.5 pb-1">
                <span class="text-xs font-medium leading-4">
                  {{ truncate(selectedChatsData, 70) }}
                </span>
                <IconCross
                  v-if="channel_data.length"
                  size="w-3.5 h-3.5 text-gray-100 justify-end"
                  @click="channel_data = []"
                />
              </div>
            </div>
          </div>
          <div class="absolute w-full">
            <ChatSearchFilter
              v-if="searchChatDropdown"
              :key="`chat_rooms_filter`"
              :items="channelOptions"
              dropdown-class="left-0"
              :multiple="true"
              :selected="channel_data"
              :search-text="searchChatText"
              @removed="channel_data = channel_data.filter(x => x !== $event)"
              @selected="channel_data.push($event)"
              @close="searchChatDropdown = false"
            />
          </div>
        </div>
        <div class="flex h-12 w-full items-center space-x-2 border-b border-gray-700 py-4 pt-10">
          <div class="py-3 text-base font-semibold">Export Details</div>
          <div class="text-xs font-medium text-red-400">{{ errorMessage }}</div>
        </div>
      </div>
      <div
        class="flex w-full flex-col items-center justify-center space-y-2 pt-10 text-center"
        v-if="!selectedUser || !channel_data"
      >
        <IconExclamationCircleNew size="text-gray-400 w-9 h-9" />
        <span class="text-base font-semibold leading-6">No Messages Selected Yet</span>
        <span class="text-sm font-medium leading-5 text-gray-400">
          Fill out the input form above to export messages
        </span>
      </div>
      <div v-if="selectedUser && channel_data" class="flex flex-col space-y-3">
        <div class="flex flex-col space-y-0.5">
          <span class="text-xs font-medium leading-4 text-gray-400">User</span>
          <span class="text-xs font-medium leading-4 text-gray-100">{{ selectedUserData.name }}</span>
        </div>
        <div class="flex flex-col space-y-0.5">
          <span class="text-xs font-medium leading-4 text-gray-400">Companies</span>
          <span class="text-xs font-medium leading-4 text-gray-100">
            {{ selectedUserData.companies ? selectedUserData.companies.join(', ') : '-' }}
          </span>
        </div>
        <div class="flex flex-col space-y-0.5">
          <span class="text-xs font-medium leading-4 text-gray-400">Last Active</span>
          <span class="text-xs font-medium leading-4 text-gray-100">
            {{ moment(selectedUserData.last_active).format('h:mm A MMMM Do, YYYY') }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex w-full justify-end space-x-2 pb-3">
      <div class="flex items-center space-x-2">
        <BaseButton :disabled="buttonBusy" type="secondary" @press="$emit('close')"> Cancel </BaseButton>
        <BaseButton :busy="buttonBusy" @press="exportData()">Export</BaseButton>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, inject, onMounted } from 'vue';
import DropdownMessenger from '@/components/dropdown/DropdownMessenger.vue';
import { useMapGetter } from '@/store/map-state';
import { getChannelName } from '@/composeables/chat';
import { orderBy, uniqBy } from 'lodash';
import { truncate } from '@/composeables/filters';
import moment from 'moment';
import ChatSearchFilter from '@/components/chat/ChatSearchFilter.vue';
const props = defineProps({ exportedUser: { type: String } });
const $emit = defineEmits('close');
const $http = inject('http');

const { client, requestChannels, channels, rejectedChannels, streamUsers, session } = useMapGetter();

const searchPeopleDropdown = ref(false);
const searchChatDropdown = ref(false);
const searchChatText = ref('');
const searchPeopleText = ref('');
const selectedUser = ref(null);
const selectedRange = ref(null);
const emailText = ref(null);
const buttonBusy = ref(false);
const channelsData = ref([]);
const errorMessage = ref('');
const channelsLoading = ref(false);

onMounted(() => {
  if (props.exportedUser) {
    let user = userOptions.value.find(x => x.id == props.exportedUser);
    if (user) {
      setSelectedUser(props.exportedUser);
    }
  }
  emailText.value = session.value.user_email;
});
const userOptions = computed(() => {
  let options = [];
  const filterUsers = streamUsers.value.filter(x => x.team_id == client.value.user.team_id);
  let allUsers = orderBy(
    filterUsers,
    function (o) {
      return o.name.toLowerCase();
    },
    'asc'
  );
  allUsers.forEach(user => {
    let imageObj = '';
    if (user.image) {
      imageObj = `<img class="w-7 h-7 rounded-full object-cover" src="${user.image}"/>`;
    } else {
      imageObj = `<div class="w-7 h-7 rounded-full text-gray-900 uupercase flex flex-col items-center font-semibold justify-center ${
        user.background_color ? '' : 'bg-gray-500'
      }" style="background-color:${user.background_color}">${user.name ? user.name[0] : '<>'}</div>`;
    }
    options.push({
      id: user.id,
      text: user.name,
      html: `<div class="flex items-center space-x-2 pl-1">
              ${imageObj}
              <div class="flex flex-col items-start">
                <div class="break-words font-semibold text-gray-100 text-xs">${truncate(user.name, 50)}</div>
                <div class="text-xxs font-medium text-gray-400">${truncate(
                  user.role_title ? user.role_title : user.team_name,
                  25
                )}</div>
              </div>
            </div>`,
      selected: selectedUser.value ? selectedUser.value == user.id : false
    });
  });
  return options;
});

const channelOptions = computed(() => {
  let options = [];
  let allChannels = orderBy(
    channelsData.value,
    function (o) {
      return getChannelName(o, selectedUserData.value).toLowerCase();
    },
    'asc'
  );
  allChannels.forEach(c => {
    let imageObj = '';
    if (getImageObject(c)) {
      imageObj = `<img class="w-7 h-7 rounded-full object-cover" src="${getImageObject(c)}"/>`;
    } else {
      imageObj = `<div class="w-7 h-7 rounded-full text-gray-900 uupercase flex flex-col items-center font-semibold justify-center ${
        c.data.background_color ? '' : 'bg-gray-500'
      }" style="background-color:${c.data.background_color}">${
        getChannelName(c, selectedUserData.value) ? getChannelName(c, selectedUserData.value)[0] : '<>'
      }</div>`;
    }
    options.push({
      id: c.id,
      name: getChannelName(c, selectedUserData.value),
      html: `<div class="flex space-x-2 items-center w-full">${imageObj}<div class="break-words mx-2 text-gray-100 font-semibold text-xs" >${truncate(
        getChannelName(c, selectedUserData.value),
        50
      )}</div>  </div>`,
      channel: c
    });
  });
  return options;
});

const selectedUserData = computed(() => {
  if (selectedUser.value) {
    return streamUsers.value.find(x => x.id == selectedUser.value);
  } else {
    return null;
  }
});

const selectedChatsData = computed(() => {
  if (channel_data.value) {
    return channelOptions.value
      .filter(x => channel_data.value.includes(x.channel.id))
      ?.map(x => x.name)
      ?.join(', ');
  } else {
    return null;
  }
});

// const otherChannelMembers = computed(() => {
//   return selectedChatsData.value && selectedChatsData.value.channel
//     ? Object.values(selectedChatsData.value.channel.state.members)
//         .filter(c => c.user_id != selectedUser.value)
//         .map(x => x.user.name)
//         .join(', ')
//     : '-';
// });

function getImageObject(channel) {
  if (channel.data.direct_messaging) {
    let channelMembers = Object.values(channel.state.members);
    let member = channelMembers.filter(x => x.user_id !== selectedUser.value);
    if (member.length > 0) {
      return member[0].user.image;
    }
  } else {
    return channel.data.image;
  }
}

function setSelectedUser(data) {
  selectedUser.value = data;
}

const channel_data = ref([]);
function setSelectedChat(data) {
  channel_data.value = data;
}

async function setChannelData() {
  channelsLoading.value = true;
  const filter = { members: { $in: [selectedUser.value] } };
  const sort = { updated_at: -1 };
  let all_channels = [];
  let limit = 30;
  all_channels = await client.value.queryChannels(filter, sort, {
    limit: 30,
    state: true
  });
  let tempChannels = all_channels;
  while (channels.length >= limit) {
    all_channels = await client.value.queryChannels(filter, sort, {
      limit: 30,
      offset: limit,
      state: true
    });
    tempChannels.push(...all_channels);
    limit += 30;
  }

  let leftUsersChannels = await getLeftUsers();
  if (leftUsersChannels.length) {
    tempChannels.push(...leftUsersChannels);
  }
  channelsData.value = tempChannels;
  channelsLoading.value = false;
}

async function getLeftUsers() {
  if (!channels.value.length) {
    return [];
  }
  let messagesData = [];
  let pagination = { sort: [{ created_at: -1 }], limit: 100 };
  let channelFilters = [{ joined: { $eq: true } }];
  let messageFilters = [
    {
      text: {
        $in: [
          `{{user:${selectedUser.value}}} has left the chat.`,
          `{{user:${selectedUser.value}}} was removed from the chat.`
        ]
      }
    }
  ];
  messagesData = await client.value.search(
    {
      $and: channelFilters
    },
    {
      $and: messageFilters
    },
    pagination
  );
  if (messagesData.results.length > 0) {
    const channelIds = messagesData.results.map(c => c.message.channel.id);
    const filter = { id: { $in: channelIds } };
    const sort = { updated_at: -1 };
    let limit = 30;
    let all_channels = await client.value.queryChannels(filter, sort, {
      limit: 30,
      state: true
    });
    return all_channels;
  } else {
    return [];
  }
}

async function exportData() {
  if (!selectedUser.value || !channel_data.value || !selectedRange.value || !emailText.value) {
    errorMessage.value = 'Fill out the input form.';
    return;
  } else {
    buttonBusy.value = true;
    try {
      let channelsPayload = channelOptions.value
        ?.filter(x => channel_data.value.includes(x.id))
        ?.map(c => {
          return { id: c.channel.id, name: c.text };
        });
      await $http.post('/export_chat', {
        channel_data: channelsPayload,
        user_data: { id: selectedUserData.value.id, name: selectedUserData.value.name },
        email: emailText.value,
        start_time: selectedRange.value[0],
        end_time: selectedRange.value[1]
      });
      buttonBusy.value = false;
      $emit('close');
    } catch (e) {
      buttonBusy.value = false;
      $emit('close');
    }
  }
}

watch(
  () => searchChatDropdown.value,
  () => {
    if (searchChatDropdown.value) {
      searchPeopleDropdown.value = false;
    }
  }
);
watch(
  () => searchPeopleDropdown.value,
  () => {
    if (searchPeopleDropdown.value) {
      searchChatDropdown.value = false;
    }
  }
);
watch(
  () => selectedUser.value,
  () => {
    if (selectedUser.value) {
      setChannelData();
    } else {
      channel_data.value = [];
      channelsData.value = [];
    }
  }
);
</script>
