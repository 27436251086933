<template>
  <svg viewBox="0 0 151 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8838_32124)">
      <path
        d="M51 28.1972C51.1563 28.0518 51.3167 27.9148 51.4647 27.761C57.6447 21.3264 63.8234 14.8911 70.0006 8.45513C70.1261 8.3245 70.2227 8.16649 70.3584 7.99162L77.5556 15.6461L89.869 3.56283L86.3444 0H95.5219V9.28106L92.2605 5.90996L77.4672 20.4247C75.1127 17.9195 72.7705 15.4249 70.3831 12.8881L53.3586 30.618L51.0062 28.3004L51 28.1972Z"
        fill="#475569"
      />
      <path d="M89.9775 13.4668H101V44.9761H89.9775V13.4668Z" fill="#475569" />
      <path d="M85.6243 44.9999H74.5879V24H85.6223L85.6243 44.9999Z" fill="#475569" />
      <path d="M59.166 29.0254H70.2086V44.977H59.166V29.0254Z" fill="#475569" />
    </g>
    <defs>
      <clipPath id="clip0_8838_32124">
        <rect width="50" height="45" fill="white" transform="translate(51)" />
      </clipPath>
    </defs>
  </svg>
</template>
