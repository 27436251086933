<template>
  <div class="mt-4">
    <div class="flex">
      <BaseTags :tags="sourceTags" :selected="[editableConfiguration.source]" @toggle="toggleSource" />
    </div>
    <div v-if="editableConfiguration.source == 'watchlist' || editableConfiguration.source == 'coins'">
      <label class="space-y-1 text-xs capitalize text-gray-400"> Select {{ editableConfiguration.source }} </label>
      <DropdownSelectCoin
        v-if="editableConfiguration.source == 'coins'"
        :starting-coins="preSelectedCoins"
        :display-count="10"
        @update="editableConfiguration.coins = $event"
      />
      <DropdownSimple
        v-else-if="editableConfiguration.source == 'watchlist'"
        class="text-xs"
        :active-item="activeSourceOption"
        :items="sourceOptions"
        :truncate-value="50"
        @selected="setSourceOption"
      />
    </div>
  </div>
</template>
<script>
import DropdownSimple from '@/components/dropdown/DropdownSimple.vue';
import DropdownSelectCoin from '@/components/dropdown/DropdownSelectCoin.vue';
import FilterMixin from '@/mixins/filters';
export default {
  name: 'WidgetConfigsGovernance',
  emits: ['update'],
  mixins: [FilterMixin],
  props: {
    config: {
      type: Object
    }
  },
  components: {
    DropdownSimple,
    DropdownSelectCoin
  },
  mounted() {
    if (this.config) {
      this.editableConfiguration = JSON.parse(JSON.stringify(this.config));
      if (this.editableConfiguration.source == 'coins') {
        this.preSelectedCoins = this.coins.filter(x => this.editableConfiguration.coins.includes(x.coin_uid));
      }
    }
  },
  data() {
    return {
      preSelectedCoins: [],
      sourceTags: [
        { id: 'all', title: 'All' },
        { id: 'coins', title: 'Coins' },
        { id: 'watchlist', title: 'Watchlists' }
      ],
      editableConfiguration: {
        source: 'coins',
        coins: [],
        watchlist: null
      }
    };
  },
  computed: {
    coins() {
      return this.$store.getters.coins;
    },
    coinDatapoints() {
      return this.$store.getters.coinDatapoints.filter(x => !x.default);
    },
    activeSourceOption() {
      let config = this.editableConfiguration;
      let activeOption = 0;
      if (config.source == 'watchlist' && config.watchlist) {
        activeOption = config.watchlist.id;
      }
      if (this.sourceOptions.some(x => x.id == activeOption)) {
        return activeOption;
      }
      return 0;
    },
    sourceOptions() {
      if (this.editableConfiguration.source == 'watchlist') {
        return this.$store.getters.watchlists.coins.map(x => {
          return {
            id: x.id,
            text: x.name,
            coins: x.entities
          };
        });
      }
      return [];
    }
  },
  watch: {
    editableConfiguration: {
      deep: true,
      handler: function () {
        let status = true;
        let source = this.editableConfiguration.source;
        if (source == 'watchlist' && !this.editableConfiguration.watchlist) {
          status = false;
        }
        this.$emit('update', { valid: status, ...this.editableConfiguration });
      }
    }
  },
  methods: {
    toggleSource(payload) {
      if (payload != this.editableConfiguration.source) {
        this.resetConfiguration();
      }
      this.editableConfiguration.source = payload;
    },
    resetConfiguration() {
      this.editableConfiguration = {
        coins: [],
        watchlist: null
      };
    },
    setSourceOption(selectedOption) {
      if (this.editableConfiguration.source == 'watchlist') {
        this.editableConfiguration.watchlist = selectedOption;
        this.editableConfiguration.coins = selectedOption.coins.map(x => x.uid);
      }
      // else if (this.editableConfiguration.source == 'all') {

      // }
    }
  }
};
</script>
