<template>
  <BaseDialog :model-value="true" size-class="!max-w-lg" @update:model-value="$emit('close')">
    <template #header><span class="px-2 text-base">Enable Messenger</span></template>
    <template #default>
      <div class="flex flex-col space-y-3 px-2 py-3 text-gray-300">
        <span>The Tie Terminal will launch a platform wide Messaging service on {{ readableDate(launchDate) }}.</span>
        <span>If you would like to enable Messenger for your team, click on Enable below.</span>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-end space-x-2">
        <BaseButton type="secondary" size="md" @press="$emit('close')">Cancel</BaseButton>
        <BaseButton size="md" @press="enableMessenger">Enable</BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { readableDate } from '@/composeables/filters';
import { inject, computed } from 'vue';
import { usePage } from '@inertiajs/inertia-vue3';
import moment from 'moment';
const auth = computed(() => usePage().props.value.auth);
const $emit = defineEmits(['close']);
const $http = inject('http');
async function enableMessenger() {
  await $http.patch('/chat/enable_messaging');
  $emit('close');
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}

const launchDate = computed(() => {
  return auth.value['messenger-launch-date'];
});
</script>
