<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      :connectable="false"
      @expand="goToExpandLink"
      :id="Math.random().toString()"
      @paginate="handleScroll"
    >
      <template #body>
        <table class="w-full text-xs font-medium text-gray-200">
          <thead>
            <tr class="sticky top-0 border-0 bg-gray-900">
              <th class="py-2">
                <div class="flex items-center">
                  <div
                    v-for="tf in timeframes"
                    :key="tf.id"
                    @click="setSelectedTimeframe(tf.id)"
                    class="mx-2 cursor-pointer rounded text-xs transition-all ease-linear"
                    :class="
                      tf.id === selectedTimeframe ? 'font-semibold text-gray-100' : 'text-gray-400 hover:text-gray-100'
                    "
                  >
                    {{ tf.title }}
                  </div>
                </div>
              </th>
              <th v-if="width >= 400" class="text-right text-gray-400">Floor</th>
              <th v-if="width >= 400" class="text-right text-gray-400">Volume</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="border-none"
              v-for="(collection, idx) in filteredCollections"
              :key="idx"
              :class="idx % 2 == 0 ? 'bg-gray-800/25' : ''"
            >
              <td>
                <div class="flex items-center">
                  <a
                    :href="collection.openSeaCollectionData.externalLink"
                    target="_blank"
                    class="flex items-center hover:text-white"
                  >
                    <img
                      :src="collection.openSeaCollectionData.imageUrl"
                      class="inline h-5 w-5 rounded-full"
                      loading="lazy"
                    />
                    <div class="ml-2">
                      <span class="whitespace-nowrap text-xs font-semibold">
                        {{ truncate(collection.openSeaCollectionData.name, 20) }}
                        <IconOpen
                          class="mb-px inline h-3 w-3 text-gray-400"
                          v-if="collection.openSeaCollectionData.externalLink"
                        />
                      </span>
                      <div v-if="width <= 250">
                        <span class="text-xs" v-if="collection.floorPrice"
                          >Fl: {{ decimals(collection.floorPrice, 0, 3) }} ETH</span
                        >
                        <span class="text-xs text-gray-400">Vol: {{ rounded(Number(collection.volume), 0) }} ETH</span>
                      </div>
                    </div>
                  </a>
                </div>
              </td>
              <td v-if="width >= 250" class="text-right text-xs">
                <span class="block text-xs" v-if="collection.floorPrice"
                  >{{ decimals(collection.floorPrice, 0, 3) }} ETH</span
                >
                <span v-if="width <= 400" class="block whitespace-nowrap text-xs text-gray-400">
                  {{ rounded(Number(collection.volume), 0) }} ETH
                </span>
              </td>
              <td v-if="width > 400" class="whitespace-nowrap text-xs text-gray-400">
                {{ rounded(Number(collection.volume), 0) }} ETH
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import { orderBy } from 'lodash';
import WidgetsMixin from '@/mixins/widgets';
import FilterMixin from '@/mixins/filters';
export default {
  name: 'WidgetsTrendingCollections',
  mixins: [WidgetsMixin, FilterMixin],
  mounted() {
    if (this.widget?.configuration && this.widget.configuration['filter']) {
      this.selectedTimeframe = this.widget.configuration['filter'];
    }
    this.loading = true;
    this.getCollections();
  },
  data() {
    return {
      hoverActive: false,
      hoverItem: -1,
      timeframes: [
        {
          id: '1 day',
          title: '1d',
          enabled: true
        },
        {
          id: '7 day',
          title: '7d'
        },
        {
          id: '30 day',
          title: '30d'
        }
      ],
      selectedTimeframe: '1 day',
      transactions: { '1 day': 'one_day_sales', '7 day': 'seven_day_sales', '30 day': 'thirty_day_sales' },
      volumeData: { '1 day': 'one_day_volume', '7 day': 'seven_day_volume', '30 day': 'thirty_day_volume' },
      trendingData: [],
      trendingDataAllData: [],
      pagination: {
        page: 0,
        perPage: 50
      }
    };
  },
  computed: {
    filteredCollections() {
      const self = this;
      return this.trendingData
        ? orderBy(
            this.trendingData.map(x => {
              return {
                floorPrice: x.floor_price,
                totalSupply: x.count,
                transactions: x[self.transactions[self.selectedTimeframe]],
                volume: x[self.volumeData[self.selectedTimeframe]],
                numOwners: x.num_owners,
                openSeaCollectionData: {
                  externalLink: x.external_link,
                  imageUrl: x.image_url,
                  name: x.collection_name
                },
                link: `https://opensea.io/collection/${x.os_slug}`,
                linkText: 'OpenSea',
                symbol: 'ETH'
              };
            }),
            'volume',
            'desc'
          )
        : [];
    }
  },
  methods: {
    handleScroll: async function () {
      this.pagination.page++;
      this.trendingData = this.trendingAllData.slice(0, this.pagination.perPage * this.pagination.page);
    },
    setSelectedTimeframe(filter) {
      this.selectedTimeframe = filter;
      this.$http.patch(`/dasboard_widgets/${this.widget.id}`, { configuration: { filter: this.selectedTimeframe } });
    },
    showTooltip(idx) {
      this.hoverActive = true;
      this.hoverItem = idx;
    },
    hideTooltip() {
      this.hoverActive = false;
      this.hoverItem = -1;
    },
    goToExpandLink() {
      window.open('/nft/market/trends', '_blank');
    },
    async getCollections() {
      const self = this;
      if (this.pagination.stopCall) return;
      try {
        let response = await this.$http.get('/trending_collections', {
          params: {
            page: this.pagination.page,
            limit: this.pagination.perPage
          }
        });
        self.trendingAllData = response.data.result;
        self.trendingData = self.trendingAllData.slice(0, this.pagination.perPage);
        // if (this.pagination.page == 0) {
        //   self.trendingData = response.data.result;
        // } else {
        //   self.trendingData.push(...response.data.result);
        // }
        // if (!response.data.nextPage) {
        //   this.pagination.stopCall = true;
        // }
        self.loading = false;
      } catch {
        self.trendingData = [];
        self.loading = false;
      }
    }
  }
};
</script>
