<template>
  <div class="relative h-full w-full border-r border-gray-700">
    <div class="">
      <div class="flex justify-between space-x-2 border-b border-gray-700 px-3 py-2.5">
        <div class="h-4 w-28 animate-pulse rounded-full bg-gray-800"></div>
        <div class="h-4 w-4 animate-pulse rounded-full bg-gray-800"></div>
      </div>
      <div class="flex justify-between space-x-2 border-b border-gray-700 px-3 py-2.5">
        <div class="h-4 w-28 animate-pulse rounded-full bg-gray-800"></div>
        <div class="h-4 w-4 animate-pulse rounded-full bg-gray-800"></div>
      </div>
    </div>
    <div class="my-4 space-y-4 px-2">
      <div v-for="i in 5" :key="i">
        <div class="flex space-x-2">
          <div class="h-7 w-7">
            <div class="h-7 w-7 animate-pulse rounded-full bg-gray-800"></div>
          </div>
          <div class="w-full space-y-2">
            <div class="h-4 w-full animate-pulse rounded-full bg-gray-800"></div>
            <div class="h-3 w-1/2 animate-pulse rounded-full bg-gray-800"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadTemplatesMiniMessenger'
};
</script>
