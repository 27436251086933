<template>
  <div class="inline" @keydown="keydown" :id="dropdownId">
    <VueTagsInput
      :tags="tokenInputTags"
      :add-on-key="[]"
      v-model="filterSearch"
      :max-tags="onlyOne ? 1 : 0"
      :add-on-blur="false"
      :placeholder="placeholder"
      class="w-100 max-h-32 overflow-y-auto pl-1 text-xs text-gray-400"
      @tags-changed="filterTagsChanged"
      @focus="focusChange"
      @blur="focusChange"
      :style="`${maxHeight ? 'max-height: 5rem' : ''}`"
    />
    <div class="relative">
      <div
        class="absolute left-0 right-0 top-0 z-50 max-h-72 overflow-y-scroll rounded rounded-tl-none rounded-tr-none border border-t-0 border-grid bg-gray-900 pt-2"
        v-if="isFocus"
      >
        <div
          v-for="(coin, index) in filteredCoins"
          :key="index"
          @click="toggleToken(coin)"
          :class="`flex cursor-pointer select-none py-1 pl-2 pr-4 hover:bg-gray-700 ${
            selectedIndex === index ? 'bg-gray-800' : ''
          }`"
        >
          <div class="mr-2 flex pt-2">
            <input :type="optionsType" class="cursor-pointer" :checked="selectedTokens[coin.id] !== undefined" />
          </div>
          <div>
            <div class="flex py-1 text-sm">
              <!-- <img class="w-5 h-5 mr-2" :src="`https://terminal.cloudfront.thetie.io/coin_images/${coin.id}.png`" /> -->
              {{ coin.name }}
            </div>
            <div class="text-xs text-gray-400">
              {{ coin.ticker }}
            </div>
          </div>
        </div>
        <div v-if="filteredCoins.length === 0" class="p-5 text-center text-sm text-gray-400">
          Sorry, no results found for matching the search term "{{ filterSearch }}".
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@sipec/vue3-tags-input';

export default {
  name: 'DropdownSelectOnChainToken',
  components: {
    VueTagsInput
  },
  mounted() {
    this.getTokens();
    document.addEventListener('click', this.focusChange);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.focusChange);
  },
  props: {
    showOnly: Array,
    placeholder: String,
    startingTokens: Array,
    startingCoins: {
      type: Boolean,
      default: false
    },
    onlyOne: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tokens: [],

      isFocus: false,

      selectedTokens: {},
      filterSearch: '',

      tokenInputTags: [],

      selectedIndex: undefined,
      dropdownId: `dropdown_${Math.random()}`
    };
  },

  computed: {
    optionsType() {
      return this.onlyOne ? 'radio' : 'checkbox';
    },
    coins() {
      return this.tokens.map(t => {
        let coin = this.$store.getters.coins.find(x => x.uid == t.coin_uid);
        return {
          ...coin,
          id: t.token
        };
      });
    },
    filteredCoins() {
      const coins = this.coins
        .filter(coin => {
          return (coin.name + '__' + coin.ticker).toLowerCase().includes(this.filterSearch.toLowerCase());
        })
        .filter(coin => {
          return coin;
        });

      return coins;
    }
  },
  watch: {
    selectedTokens() {
      let tokens = Object.keys(this.selectedTokens).map(token => {
        return {
          token: token,
          coin_uid: this.selectedTokens[token].uid
        };
      });
      this.$emit('tokenChanged', tokens);
    },
    startingTokens() {
      this.setStartingTokens();
    }
  },
  methods: {
    async getTokens() {
      let response = await this.$http.get('/coins_token');
      this.tokens = response.data;
      this.setStartingTokens();
    },

    focusChange: function (e) {
      const filterSearch = document.getElementById(this.dropdownId);
      const isFocus = e.type === 'focus';

      if (isFocus) {
        this.isFocus = true;
        return;
      }

      if (filterSearch.contains(e.target)) {
        return;
      }

      this.isFocus = isFocus;
    },
    keydown: function (e) {
      // escape
      if (e.keyCode === 27) {
        this.filterSearch = '';
        return;
      }

      // down arrow
      if (e.keyCode === 40) {
        e.preventDefault();
        this.selectedIndex = this.selectedIndex === undefined ? 0 : this.selectedIndex + 1;
        return;
      }

      // up arrow
      if (e.keyCode === 38) {
        e.preventDefault();
        this.selectedIndex = (this.selectedIndex || 0) - 1;

        if (this.selectedIndex < 0) {
          this.selectedIndex = 0;
        }

        return;
      }

      // enter key
      if (e.keyCode === 13 && this.selectedIndex !== undefined) {
        const filters = this.filteredCoins;
        const item = filters[this.selectedIndex];
        this.toggleToken(item, e);
      }
    },
    toggleToken(s) {
      if (this.onlyOne) {
        this.tokenInputTags = [
          {
            text: s.name,
            id: s.id,
            classes: 'bg-green-900'
          }
        ];
        this.filterSearch = '';
        this.selectedTokens = { [s.id]: s };
        this.isFocus = false;
      } else {
        const newSelectedTags = JSON.parse(JSON.stringify(this.selectedTokens));
        if (!this.selectedTokens[s.id]) {
          this.tokenInputTags.push({
            text: s.name,
            id: s.id,
            classes: 'bg-green-900'
          });

          this.filterSearch = '';
          newSelectedTags[s.id] = s;
        } else {
          delete newSelectedTags[s.id];
          this.tokenInputTags = this.tokenInputTags.filter(t => {
            return t.id !== s.id;
          });
        }

        this.selectedTokens = newSelectedTags;
      }
    },
    filterTagsChanged(newTags) {
      // if they remove a tag
      let missing;

      for (let i = 0; i < this.tokenInputTags.length; i++) {
        const existing = this.tokenInputTags[i];
        let found = false;

        for (let j = 0; j < newTags.length; j++) {
          if (existing.id === newTags[j].id) {
            found = true;
            break;
          }
        }

        if (!found) {
          missing = existing;
          break;
        }
      }

      const newSelectedTags = JSON.parse(JSON.stringify(this.selectedTokens));
      delete newSelectedTags[missing.id];

      this.selectedTokens = newSelectedTags;
      this.tokenInputTags = newTags;
    },
    close: function (e) {
      const drop = document.getElementById(this.dropdownId);

      if (!drop.contains(e.target) && e.target.id !== this.dropdownId) {
        this.filterSearch = '';
      }
    },
    setStartingTokens() {
      const self = this;
      self.selectedTokens = {};
      self.tokenInputTags = [];

      (self.startingTokens || []).forEach(t => {
        let token = self.coins.find(x => {
          return self.startingCoins ? x.uid == t : x.id == t;
        });
        if (token) {
          if (self.selectedTokens[t]) {
            return;
          }
          self.toggleToken({
            ...token
          });
        }
      });
    }
  }
};
</script>
