<template>
  <BaseDialog :model-value="show" size-class="!max-w-xl" @update:model-value="$emit('close')">
    <template #header>Edit Component </template>
    <template #default>
      <div class="space-y-4">
        <WidgetConfigsIndex :widget="widget" @update="editableWidget = $event" />
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-between">
        <div>
          <BaseButton v-if="sourceIsEditable" size="sm" type="success" @press="goToEditPage">
            Edit in Component Builder
          </BaseButton>
        </div>
        <div class="flex space-x-2">
          <BaseButton size="sm" type="secondary" @press="$emit('close')">Cancel</BaseButton>
          <BaseButton size="sm" :disabled="updating" @press="update">Update</BaseButton>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>
<script>
import { Inertia } from '@inertiajs/inertia';
import { flashMessage } from '@/composeables/helpers';
import WidgetConfigsIndex from '@/components/widget_configs/WidgetConfigsIndex.vue';
export default {
  name: 'ModalEditWidget',
  components: {
    WidgetConfigsIndex
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    widget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      updating: false,
      editableWidget: {}
    };
  },
  computed: {
    currentUserId() {
      return this.$store.getters.userId;
    },
    sourceIsEditable() {
      return (
        this.widget.component == 'WidgetsCustomComponent' && this.widget.widget_template.user_id == this.currentUserId
      );
    }
  },
  methods: {
    closeModal(payload = null) {
      this.$emit('close', payload);
    },
    goToEditPage() {
      Inertia.visit(`/dashboard/component/edit/${this.widget?.widget_template?.uuid}`);
    },
    updateWidget() {
      if (this.currentUserId != this.widget.user_id) {
        this.addWidgetOverride();
      } else {
        this.update();
      }
    },
    async update() {
      try {
        this.updating = true;
        let response = await this.$http.patch(`/dashboard_widgets/${this.editableWidget.id}`, this.editableWidget);
        this.updating = false;
        this.closeModal({ data: response.data, event: 'widget-update' });
        flashMessage({
          type: 'success',
          message: 'The selected component has been updated successfully'
        });
      } catch {
        this.updating = false;
      }
    }
  }
};
</script>
