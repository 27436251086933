<template>
  <div class="flex items-center space-x-0.5 rounded-full bg-gray-700 px-2 text-xs text-gray-200" v-if="name">
    <img src="../../../../images/logo-sm.svg" height="12px" width="12px" class="mr-1 inline-block align-middle" />
    <div>
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableCellPartTieStarredTag',
  props: {
    name: String
  }
};
</script>
