<template>
  <div
    v-if="droppedDown"
    class="z-50 select-none overflow-hidden overflow-y-auto rounded-md border border-gray-700 bg-gray-800 text-xs text-white shadow"
    :id="dropdownId"
    :style="
      customStyles ? customStyles : dropdownWidth ? `min-width:${dropdownWidth} !important; ${bottomStyle()}` : ''
    "
    :class="[customClasses, maxHeight, useParentContainer ? '' : 'absolute']"
  >
    <div v-for="(group, idx) in groupedItems" :key="`group_${idx}`">
      <div
        v-for="(row, idy) in groupedItems[idx]"
        :key="idy"
        @click="clicked(row)"
        :style="row.style"
        :class="`
          ${row.class}
          ${backgroundColor}
          ${row.id == selected ? 'bg-gray-700 text-white' : 'bg-gray-800 text-gray-300'}
          text-md flex select-none items-center py-2 px-3 text-left capitalize duration-100 hover:bg-gray-700 hover:text-white
        `"
        @mouseover="row.childs ? (childHovered = true) : ''"
        @mouseleave="row.childs ? (childHovered = false) : ''"
      >
        <input type="checkbox" v-if="row.checked !== undefined" :checked="row.checked" />
        <component v-if="row.icon" :is="row.icon" size="h-3.5 w-3.5 flex-no-shrink" />
        <div class="flex w-full items-center justify-between">
          <BaseTooltip class="ml-2" v-if="row.text.length > maxTextLength">
            <template #target>
              <p class="truncate whitespace-nowrap">
                {{ truncate(row.text, maxTextLength) }}
              </p>
            </template>
            <template #default>
              {{ row.text }}
            </template>
          </BaseTooltip>
          <div v-else class="ml-2 break-words">{{ row.text }}</div>

          <IconChevronRight v-if="row.childs" size="h-3 w-3 text-gray-400" />
        </div>
        <div v-if="row.childs" class="text-xs font-normal leading-4">
          <DropdownAdvance
            v-if="row.childs && childHovered"
            :items="row.childs"
            dropdown-width="8rem"
            :custom-classes="`left-full ${groupedItems[0].length == 3 ? 'top-4 mt-20' : 'top-10 mt-6'}`"
            max-height="max-h-32"
            :max-text-length="10"
            @close="droppedDown = false"
            @clicked="emitter.$emit($event.event, $event)"
          />
        </div>
      </div>
      <div v-if="idx != Object.keys(groupedItems).length - 1" class="px-3">
        <div class="border-b border-gray-700"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, onMounted, onUnmounted, inject } from 'vue';
import { groupBy } from 'lodash';
import { truncate } from '@/composeables/filters';

const emitter = inject('eventHub');

const props = defineProps({
  items: { type: Array, default: () => [] },
  backgroundColor: {
    type: String,
    default: 'bg-gray-800'
  },
  selected: {
    type: String
  },
  maxTextLength: {
    type: Number,
    default: 10
  },
  dropdownWidth: {
    type: String
  },
  customClasses: { type: String },
  useParentContainer: Boolean,
  maxHeight: { type: String, default: 'max-h-64' },
  customStyles: {
    type: String
  }
});

const emit = defineEmits(['close', 'clicked']);

const droppedDown = ref(true);
const dropdownId = ref(`dropdown_advance_${Math.random()}`);
const initialRender = ref(true);
const childHovered = ref(false);

onMounted(() => {
  document.addEventListener('click', close);
});

onUnmounted(() => {
  document.removeEventListener('click', close);
});

const groupedItems = computed(() => {
  return groupBy(props.items, item => item.group);
});

function close(e) {
  const drop = document.getElementById(dropdownId.value);

  if (initialRender.value) {
    initialRender.value = false;
  } else if (drop && !drop.contains(e.target) && droppedDown.value && e.target.id !== dropdownId.value) {
    droppedDown.value = false;
    emit('close');
  }
}

function clicked(item) {
  emit('clicked', item);
  emit('close');
}

function bottomStyle() {
  setTimeout(() => {
    var elem = document.getElementById(dropdownId.value);
    if (elem) {
      var bounding = elem.getBoundingClientRect();
      if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
        elem.style.bottom = 0;
      } else {
        return '';
      }
    }
  });
}
</script>
