<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      :connectable="false"
      :hidden-actions="['expand']"
    >
      <template #body>
        <div class="relative h-full">
          <Markdown
            :source="markdown"
            :html="true"
            :linkify="true"
            class="prose prose-sm prose-invert pt-2 text-[12px]"
          />
        </div>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>
<script>
import Markdown from 'vue3-markdown-it';
import 'highlight.js/styles/monokai.css';
import WidgetsMixin from '@/mixins/widgets';
export default {
  name: 'WidgetsMarkdown',
  mixins: [WidgetsMixin],
  components: {
    Markdown
  },
  mounted() {
    if (this.widget.configuration && this.widget.configuration.markdown) {
      this.markdown = this.widget.configuration.markdown;
    }
  },
  data() {
    return {
      markdown:
        '## Headline 2\n### Headline 3\nThis is useful for describing and organizing your dashboard layout. You can make things *italic* or **bold** or use any standard markdown syntax.'
    };
  },
  watch: {
    'widget.configuration'() {
      if (this.widget.configuration && this.widget.configuration.markdown) {
        this.markdown = this.widget.configuration.markdown;
      }
    }
  }
};
</script>
