export const formatPricing = n => {
  if (typeof n === 'undefined' || n === null) return '';
  return n.toLocaleString();
};

export const formatBigPricing = n => {
  if (typeof n === 'undefined' || n === null) return '';
  n = parseFloat(n || 0);
  let isPositive = n && n >= 0;
  if (!isPositive) {
    n = -n;
  }
  if (n < 1e3) return (isPositive ? n : -n).toFixed(1);
  if (n >= 1e3 && n < 1e6) return +((isPositive ? n : -n) / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +((isPositive ? n : -n) / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +((isPositive ? n : -n) / 1e9).toFixed(1) + 'B';
  if (n >= 1e12 && n < 1e15) return +((isPositive ? n : -n) / 1e12).toFixed(1) + 'T';
  if (n >= 1e15 && n < 1e18) return +((isPositive ? n : -n) / 1e15).toFixed(1) + 'q';
  if (n >= 1e18 && n < 1e21) return +((isPositive ? n : -n) / 1e18).toFixed(1) + 'Q';
  if (n >= 1e21 && n < 1e24) return +((isPositive ? n : -n) / 1e21).toFixed(1) + 's';
  if (n >= 1e24 && n < 1e27) return +((isPositive ? n : -n) / 1e24).toFixed(1) + 'S';
  if (n >= 1e27 && n < 1e30) return +((isPositive ? n : -n) / 1e27).toFixed(1) + 'o';
  if (n >= 1e30 && n < 1e33) return +((isPositive ? n : -n) / 1e30).toFixed(1) + 'n';
};

export const formatPercent = n => {
  if (typeof n === 'undefined' || n === null) return '';
  n = parseFloat(n);
  return (
    (n < 0
      ? n.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : '+' + n.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) + '%'
  );
};

export const humanReadableToNumber = n => {
  if (typeof n === 'undefined' || n === null) return '';
  n = n.replace('$', '').toLowerCase();
  if (n.includes('k')) return parseFloat(n) * 1e3;
  if (n.includes('m')) return parseFloat(n) * 1e6;
  if (n.includes('b')) return parseFloat(n) * 1e9;
  if (n.includes('t')) return parseFloat(n) * 1e12;
  if (n.includes('p')) return parseFloat(n) * 1e15;
  if (n.includes('e')) return parseFloat(n) * 1e18;
  else return n;
};

export const formatMixin = {
  methods: {
    formatPricing,
    formatPercent,
    formatBigPricing,
    humanReadableToNumber
  }
};
