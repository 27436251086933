<template>
  <div class="relative flex h-full w-full items-center justify-center p-2">
    <BaseLoader v-if="loading" />
    <NoDataFound v-else-if="noChart" />
    <div v-else :id="randomGraphId" :class="loading ? 'hidden' : ''" class="h-full w-full"></div>
  </div>
</template>
<script>
import Highcharts from 'highcharts/highcharts';
import colors from 'tailwindcss/colors';
import moment from 'moment';
import global from '@/mixins/global';
import _ from 'lodash';
import FilterMixin from '@/mixins/filters';
window.Highcharts = Highcharts;

export default {
  mixins: [FilterMixin],
  mounted() {
    const self = this;
    self.$eventHub.$on('reflow-highchart', function () {
      self.$nextTick(() => {
        self.generateChart();
      });
    });
  },
  props: {
    series: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false }
  },

  watch: {
    series() {
      const self = this;
      self.$nextTick(() => {
        self.generateChart();
      });
    }
  },

  computed: {
    noChart() {
      return !this.loading && _.isEmpty(this.series);
    },
    randomId() {
      return this.getRandomId();
    },
    randomSeries() {
      var firstDay = moment().subtract(15, 'days');
      var lastDay = moment();
      var series = [];
      for (var i = firstDay; i <= lastDay; i = i.add(1, 'day')) {
        series.push([i.unix() * 1000, Math.random()]);
      }
      return series;
    },
    chartThreshold() {
      return 50;
    },
    price() {
      return this.series.price ? this.series.price : [];
    },
    sentiment() {
      return this.series.sentiment ? this.series.sentiment : [];
    },
    event() {
      return this.series.event ? this.series.event : [];
    },
    tweet_volume() {
      return this.series.tweet_volume ? this.series.tweet_volume : [];
    },
    config() {
      const self = this;
      return {
        chart: {
          type: 'line',
          backgroundColor: colors.gray['900'],
          zoomType: '',
          animation: false
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          plotLines: [
            {
              color: colors['blue']['700'],
              width: 2,
              value: self.event,
              zIndex: 5
            }
          ],
          lineWidth: 0,
          tickWidth: 0,
          labels: {
            style: {
              color: colors['gray']['400']
            }
          }
        },
        title: {
          text: ''
        },
        yAxis: [
          // Primary yAxis
          {
            title: {
              text: (self.series.tweet_volume || []).length ? 'Tweet Volume' : '',
              style: {
                color: colors['gray']['400']
              }
            },
            gridLineWidth: 0,
            labels: {
              style: {
                color: colors['gray']['400']
              },
              formatter() {
                let formattedVal = this.value;
                if (this.axis.series[0].name == 'Market Cap') {
                  formattedVal = self.formatNumber(this.value, 5);
                }
                return formattedVal;
              }
            }
          },
          // Secondary yAxis
          {
            gridLineWidth: 0,
            title: {
              text: 'Price',
              style: {
                color: colors['blue']['400']
              }
            },
            labels: {
              style: {
                color: colors['blue']['400']
              }
            },
            opposite: true
          },
          // Tertiary yAxis
          ...((self.series.sentiment || []).length
            ? [
                {
                  gridLineWidth: 0,
                  title: {
                    text: 'Sentiment',
                    style: {
                      color: colors['red']['400']
                    }
                  },
                  labels: {
                    style: {
                      color: colors['red']['400']
                    }
                    //define formatter based on metric
                  },
                  opposite: true
                }
              ]
            : [])
        ],
        time: {
          useUTC: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          shared: true,
          useHTML: true,
          backgroundColor: colors.gray[800],
          style: {
            color: 'white',
            pointerEvents: 'auto'
          },
          borderWidth: 0,
          formatter: function () {
            const date = moment(this.x).format('MMM DD, YYYY HH:mm UTCZ');
            const tipHtml = `<b>${date}</b><br/>`;
            const fromattedPnts = this.points.map(point => {
              var value = '';
              if (['Market Cap', 'Price'].includes(point.series.name)) {
                value = self.usdCryptoPrice(point.y);
              } else {
                value = Math.round(point.y);
              }
              const pointSymb = this.points.length === 1 ? '' : `<span style="color:${point.color}">●</span>`;
              return (
                pointSymb +
                `<b> ${point.series.name}:</b> <span style="color:${point.color}; margin-left: 0.5rem;">${value}</span>`
              );
            });

            return tipHtml + fromattedPnts.join('<br/>');
          }
        },
        plotOptions: {
          series: {
            fillOpacity: 0.3,
            animation: false
          },
          area: {
            marker: false,
            lineWidth: 3
          },
          spline: {
            marker: false
          }
        },
        exporting: {
          enabled: false
        }
      };
    }
  },
  data() {
    return {
      chartObj: undefined,
      randomGraphId: `price_sentimant_chart_${Math.random()}`
    };
  },
  methods: {
    generateChart() {
      const self = this;
      const config = self.config;
      config.series = [
        {
          data: self.tweet_volume.length ? self.tweet_volume : [],
          name: 'Tweet Volume',
          yAxis: 0,
          color: colors.gray[400],
          opacity: 0.4,
          borderColor: colors.gray[400],
          type: 'column',
          zIndex: 0
        },
        {
          data: self.price.length ? self.price : self.series.market_cap,
          name: self.price.length ? 'Price' : 'Market Cap',
          color: colors.blue[400],
          type: 'spline',
          lineWidth: 3,
          yAxis: 1,
          zIndex: 2
        },
        ...((self.sentiment || []).length
          ? [
              {
                type: 'spline',
                data: self.sentiment,
                name: 'Sentiment',
                yAxis: 2,
                lineWidth: 2,
                color: colors.green[400],
                negativeColor: colors.red[400],
                threshold: self.chartThreshold,
                zIndex: 1
              }
            ]
          : [])
      ];
      if (!self.noChart) {
        if (document.getElementById(self.randomGraphId)) {
          self.chartObj = Highcharts.chart(self.randomGraphId, config);
        }
      }
      //hack to avoid auto reloading of chart
      // if (self.chartObj === undefined) {
      //   self.chartObj.reflow();
      // } else {
      //   self.chartObj.series[0].setData(config.series[0], false);
      //   if ((self.sentiment || []).length) {
      //     self.chartObj.series[1].setDatas(config.series[1], false);
      //   }
      // }
    }
  },
  beforeUnmount() {
    this.$eventHub.$off('reflow-highchart');
  }
};
</script>
