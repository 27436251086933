<template>
  <template v-if="loading">
    <div class="h-full w-full animate-pulse bg-gray-800"></div>
  </template>
  <BaseCard v-else class="h-full w-full select-none">
    <template #header>
      <span class="flex items-center truncate">
        <span class="mr-2 cursor-default">{{ widget.title }}</span>
        <BaseTooltip placement="right" v-if="widget.description">
          <template #target> <IconInfo size="w-3 h-3 text-gray-400 cursor-pointer" /> </template>
          <template #default>
            <div class="max-w-sm whitespace-normal">{{ widget.description }}</div>
          </template>
        </BaseTooltip>
      </span>
    </template>
    <template #controls v-if="!viewOnlyMode">
      <div class="ignore-drag flex items-center justify-center space-x-1">
        <Dropdown
          v-if="connectable && selectedLinkOption"
          :dropdown-items="connectionChannelOptions"
          :dropdown-width="'40px'"
          :key="key"
          @handle-action="updateLinkedChannel"
          class="z-40"
        >
          <div v-html="selectedLinkOption.html"></div>
        </Dropdown>
        <BaseMenu :items="widgetOptions" @action="handleAction" position="right" width-class="w-28"> </BaseMenu>
      </div>
    </template>
    <template #default>
      <div
        :class="[horizontalPadding ? 'px-2' : '', 'h-full overflow-auto bg-gray-900']"
        @scroll="handleScroll"
        :id="id"
      >
        <slot name="body"></slot>
      </div>
    </template>
  </BaseCard>
  <ModalEditWidget
    v-if="showEditModal"
    :show="showEditModal"
    :widget="widget"
    @close="widgetModalCloseHandler($event)"
  />
</template>

<script>
import Dropdown from '@/components/dropdown/Dropdown.vue';
import ModalEditWidget from '@/components/modal/ModalEditWidget.vue';
import DropdownTableCell from '@/components/dropdown/DropdownTableCell.vue';
import { flashMessage, copyToClipboard } from '@/composeables/helpers';

export default {
  name: 'WidgetsBaseComponent',
  emits: ['paginate', 'channel-update', 'expand'],
  components: {
    Dropdown,
    ModalEditWidget
  },
  mounted() {
    if (this.connectedToChannel) {
      this.linkedChannel = this.connectedToChannel;
      this.$emit('channel-update', this.connectedToChannel);
    }
  },
  props: {
    widget: { type: Object, required: true },
    overrides: { type: Object },
    loading: { type: Boolean, default: true },
    viewOnlyMode: { type: Boolean, default: false },
    subscribeOnlyMode: { type: Boolean, default: false },
    connectable: { type: Boolean, default: true },
    hiddenActions: { type: Array, default: () => [] },
    horizontalPadding: { type: Boolean, default: true },
    additionalActions: { type: Array, default: () => [] }
  },
  data() {
    return {
      showEditModal: false,
      key: `color_dropdown_${Math.random()}`,
      linkedChannel: null,
      editableWidget: this.widget,
      id: Math.random().toString()
    };
  },
  computed: {
    connectedToChannel() {
      let channel = this.widget.connected_channel;
      if (this.overrides != null && 'connected_channel' in this.overrides) {
        channel = this.overrides.connected_channel;
      }
      return channel;
    },
    selectedLinkOption() {
      return this.connectionChannelOptions.find(x => x.id == this.linkedChannel);
    },
    connectionChannelOptions() {
      let options = [];
      let defaultOption = {
        id: null,
        html: '<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 text-gray-100" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd"/></svg>'
      };
      options.push(defaultOption);
      let channels = [
        { id: 'channel_a', text: 'A', color: '#c22d2d' },
        { id: 'channel_b', text: 'B', color: '#353ad4' },
        { id: 'channel_c', text: 'C', color: '#6e4a03' },
        { id: 'channel_d', text: 'D', color: '#48075c' },
        { id: 'channel_e', text: 'E', color: '#033280' },
        { id: 'channel_f', text: 'F', color: '#026473' },
        { id: 'channel_g', text: 'G', color: '#705d08' },
        { id: 'channel_h', text: 'H', color: '#065232' }
      ];
      channels.forEach(x => {
        options.push({
          id: x.id,
          html: `<div class="flex flex-col items-center justify-center w-4 h-4 rounded-full text-[10px]" style="background-color: ${x.color}">${x.text}</div>`
        });
      });
      return options;
    },
    widgetOptions() {
      if (!this.viewOnlyMode) {
        let defaultOptions = [
          { id: 'edit', label: 'Edit', action: 'edit', order: 0 },
          { id: 'expand', label: 'Expand', action: 'expand', order: 1 },
          { id: 'duplicate', label: 'Duplicate', action: 'duplicate', order: 2 },
          { id: 'share', label: 'Share', action: 'share', order: 3 },
          { id: 'remove', label: 'Remove', action: 'remove', order: 999 }
        ];

        let additionalOptions = [{ id: 'download', label: 'Download CSV', action: 'download', order: 4 }];

        if (this.hiddenActions.length > 0) {
          defaultOptions = defaultOptions.filter(x => !this.hiddenActions.includes(x.id));
        }
        if (this.additionalActions.length > 0) {
          defaultOptions.push(additionalOptions.filter(x => this.additionalActions.includes(x.id)));
          defaultOptions = defaultOptions.flat();
        }
        if (this.subscribeOnlyMode) {
          defaultOptions = defaultOptions.filter(x => ['expand', 'share', 'download'].includes(x.id));
        }
        return defaultOptions.sort((a, b) => b.order < a.order);
      }
      return [];
    }
  },
  methods: {
    handleAction(action) {
      switch (action) {
        case 'edit':
          this.showEditModal = true;
          break;
        case 'remove':
          this.removeWidget();
          break;
        case 'duplicate':
          this.duplicateWidget();
          break;
        case 'share':
          this.shareWidget();
          break;
        case 'download':
          this.$eventHub.$emit(`download_${this.widget.id}`);
          break;
        case 'expand':
          this.$emit('expand');
          break;
      }
    },
    handleScroll: async function () {
      if (this.id) {
        let elem = document.getElementById(this.id);
        let elemScrolPosition = elem.scrollHeight - elem.scrollTop - elem.clientHeight;
        if (elemScrolPosition < 1) {
          this.$emit('paginate');
        }
      }
    },
    removeWidget() {
      this.$store.dispatch('confirm', 'Please confirm you want to remove this widget from dashboard?').then(() => {
        this.$http.delete(`/dashboard_widgets/${this.editableWidget.id}`).then(() => {
          this.widgetModalCloseHandler({ data: this.editableWidget.id, event: 'widget-delete' });
          flashMessage({
            type: 'success',
            message: 'The selected component has been deleted successfully'
          });
        });
      });
    },
    duplicateWidget() {
      this.$store.dispatch('confirm', 'Please confirm you want to duplicate this widget?').then(async () => {
        this.$http.post('/duplicate_widget', { id: this.editableWidget.uuid }).then(response => {
          this.$eventHub.$emit('widget-create', response.data);
          flashMessage({
            type: 'success',
            message: 'The selected component has been duplicated successfully'
          });
        });
      });
    },
    shareWidget() {
      if (this.widget && this.widget.uuid) {
        const link = `${window.location.origin}/dashboard/component/shared?uuid=${this.widget.uuid}`;
        copyToClipboard(link, 'Component link copied to clipboard!');
      }
    },
    widgetModalCloseHandler(payload) {
      if (payload) {
        this.$eventHub.$emit(payload.event, payload.data);
      }
      this.showEditModal = false;
    },
    updateLinkedChannel(channel) {
      this.linkedChannel = channel;
      //clear the existing widget ouput
      // if (this.widget.connected_channel) {
      //   this.$store.commit('clearWidgetOutputChannel', this.widget.connected_channel);
      // }
      this.$emit('channel-update', { to: channel, from: this.connectedToChannel });
      if (this.subscribeOnlyMode) {
        this.addWidgetOverride({ connected_channel: this.linkedChannel });
      } else {
        this.updateWidget({ connected_channel: this.linkedChannel });
      }
    },
    async updateWidget(payload) {
      try {
        let response = await this.$http.patch(`/dashboard_widgets/${this.widget.id}`, payload);
        this.$eventHub.$emit('widget-update', response.data);
      } catch {
        //do nothing
      }
    },
    async addWidgetOverride(channelOverride) {
      try {
        const payload = {
          dashboard_widget_id: this.widget.id,
          overrides: channelOverride
        };
        let response = await this.$http.post('/widget_overrides', { widget_override: payload });
        this.$eventHub.$emit('widget-overridden', response.data);
      } catch {
        //do nothing
      }
    }
  }
};
</script>
<style>
.widget-container::after {
  content: '';
  display: block;
  clear: both;
}
</style>
