<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      :connectable="false"
      :hidden-actions="['expand']"
    >
      <template #body>
        <div class="py-4">
          <div class="space-y-8">
            <div class="justify-around" :class="width > 150 ? 'flex' : 'space-y-4'">
              <div :class="width > 200 ? 'space-y-2' : 'space-y-1'" class="flex flex-col items-center text-center">
                <span class="block whitespace-nowrap text-xs text-gray-400">Market Cap</span>
                <div>
                  <span class="block text-xl font-semibold text-gray-100">
                    {{ formatNumber(universalData.market_cap, 2, '$') }}
                  </span>
                  <span class="block text-sm text-gray-100">
                    <BasePercentClassic :percent="universalData.market_cap_return_24_hours" />
                    <span class="text-xs text-gray-400"> 24hr </span>
                  </span>
                </div>
              </div>
              <div :class="width > 200 ? 'space-y-2' : 'space-y-1'" class="flex flex-col items-center text-center">
                <span class="block whitespace-nowrap text-xs text-gray-400">Trading Volume</span>
                <div>
                  <span class="block text-xl font-semibold text-gray-100">
                    {{ formatNumber(universalData.trading_volume, 2, '$') }}
                  </span>
                  <span class="block text-sm text-gray-100">
                    <BasePercentClassic :percent="universalData.relative_trading_volume" />
                    <span class="text-xs text-gray-400"> vs. avg </span>
                  </span>
                </div>
              </div>
              <div :class="width > 200 ? 'space-y-2' : 'space-y-1'" class="flex flex-col items-center text-center">
                <span class="block whitespace-nowrap text-xs text-gray-400">Tweet Volume</span>
                <div>
                  <span class="block text-xl font-semibold text-gray-100">
                    {{ formatNumber(universalData.tweet_volume, 0) }}
                  </span>
                  <span class="block text-sm text-gray-100">
                    <BasePercentClassic :percent="universalData.relative_tweet_volume" />
                    <span class="text-xs text-gray-400"> 24hr </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="justify-around" :class="width > 150 ? 'flex' : 'space-y-4'">
              <div :class="width > 200 ? 'space-y-2' : 'space-y-1'" class="flex flex-col items-center text-center">
                <span class="block whitespace-nowrap text-xs text-gray-400">Avg Tweet Volume</span>
                <span class="block text-xl font-semibold text-gray-100">
                  {{ formatNumber(universalData.tweet_volume_30_day_avg, 0) }}
                </span>
              </div>
              <div :class="width > 200 ? 'space-y-2' : 'space-y-1'" class="flex flex-col items-center text-center">
                <span class="block whitespace-nowrap text-xs text-gray-400">Twitter Accounts</span>
                <span class="block text-xl font-semibold text-gray-100">
                  {{ formatNumber(universalData.twitter_accounts, 0) }}
                </span>
              </div>
              <div :class="width > 200 ? 'space-y-2' : 'space-y-1'" class="flex flex-col items-center text-center">
                <span class="block whitespace-nowrap text-xs text-gray-400">Daily Sentiment</span>
                <span class="block text-xl font-semibold text-gray-100">
                  <CellSentiment :sentiment="universalData.daily_sentiment" />
                </span>
              </div>
            </div>
            <!-- <div v-show="height > 6 && width >= 3">
              <div class="flex h-24 w-full items-center">
                <ChartLine :series="randomSeries" />
              </div>
            </div> -->
          </div>
        </div>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
// import ChartLine from '@/components/chart/ChartLine.vue';
import WidgetsMixin from '@/mixins/widgets';
import CellSentiment from '@/components/table/cell/CellSentiment.vue';
import moment from 'moment';
import FilterMixin from '@/mixins/filters';
export default {
  name: 'WidgetsMarketOverview',
  mixins: [FilterMixin, WidgetsMixin],
  components: {
    // ChartLine,
    CellSentiment
  },
  mounted() {},
  data() {
    return {
      labelClasses: 'block text-gray-400 text-xs whitespace-nowrap'
    };
  },
  computed: {
    randomSeries() {
      var firstDay = moment().subtract(2, 'days');
      var lastDay = moment();
      var series = [];
      for (var i = firstDay; i <= lastDay; i = i.add(1, 'hour')) {
        series.push([i.unix() * 1000, Math.floor(Math.random() * 100 + 1)]);
      }
      return [
        {
          data: series,
          name: 'Random',
          title: 'Random',
          type: 'line'
        }
      ];
    },
    universalData() {
      return this.$store.state.data.universal;
    },
    lineChartConfig() {
      return {
        yAxis: false,
        xAxis: false,
        yAxisLabels: false
      };
    }
  }
};
</script>
