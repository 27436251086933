<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="false"
      :connectable="false"
      @expand="goToExpandLink"
    >
      <template #body>
        <div class="h-full w-full" :style="`background: ${bgColor}`">
          <iframe :src="iFrameSource" class="h-full w-full" :id="Math.random()"></iframe>
        </div>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';

const iFramesList = [
  {
    title: 'Open Interest by Strike Price',
    src: 'https://thetie.gvol.io/global-open-interest-by-strike',
    id: 'open-interest',
    width: '85%',
    openWidth: '88%'
  },
  {
    title: 'Open Interest by Expiry',
    src: 'https://thetie.gvol.io/open-interest-by-expiration',
    id: 'open-interest-expiry',
    width: '65%',
    openWidth: '80%'
  },
  {
    title: 'Open Interest Over Time',
    src: 'https://thetie.gvol.io/global-open-interest-over-time',
    id: 'open-interest-time',
    width: '85%',
    openWidth: '95%'
  },
  {
    title: 'Put/Call Ratio by Open Interest',
    src: 'https://thetie.gvol.io/open-interest-put-call-ratio',
    id: 'put-call-ratio',
    width: '70%',
    openWidth: '85%'
  },
  {
    title: 'Volume by Type',
    src: 'https://thetie.gvol.io/global-volume-by-type',
    id: 'volume-by-type',
    width: '69%',
    openWidth: '80%'
  },
  {
    title: 'Open Interest by Exchange',
    src: 'https://thetie.gvol.io/global-open-interest-by-exchange',
    id: 'open-interest-exchange',
    width: '65%',
    openWidth: '80%'
  },
  {
    title: 'Put/Call Ratio in Premium',
    src: 'https://thetie.gvol.io/put-call-ratio',
    id: 'call-ratio-premium',
    width: '90%',
    openWidth: '100%'
  },
  {
    title: 'Liquidations',
    src: 'https://thetie.gvol.io/options-liquidations',
    id: 'options-liquidations',
    width: '70%',
    openWidth: '80%'
  },
  {
    title: 'Block Trades',
    src: 'https://thetie.gvol.io/block-trades',
    id: 'block-trades'
  },
  { title: 'Term Structure', src: 'https://thetie.gvol.io/term-structure', id: 'term-structure' },
  { title: 'Skew', src: 'https://thetie.gvol.io/skews', id: 'skew' },
  {
    title: 'Futures Basis',
    src: 'https://thetie.gvol.io/futures-basis',
    id: 'futures-basis',
    width: '70%',
    openWidth: '80%'
  },
  {
    title: 'Futures Liquidations',
    src: 'https://thetie.gvol.io/futures-liquidations',
    id: 'futures-Liquidations',
    width: '70%',
    openWidth: '80%'
  },
  {
    title: 'Futures Open Interest',
    src: 'https://thetie.gvol.io/futures-oi',
    id: 'futures-open-interest',
    width: '70%',
    openWidth: '80%'
  },
  {
    title: 'Futures Volume',
    src: 'https://thetie.gvol.io/futures-volume',
    id: 'futures-volume',
    width: '70%',
    openWidth: '80%'
  }
];

export default {
  name: 'WidgetsDerivatives',
  mixins: [WidgetsMixin],
  data() {
    return {};
  },
  computed: {
    widgetConfig() {
      return this.widget.widget_template?.default_configuration || undefined;
    },
    iFrameSource() {
      return `${this.selectedIFrame.src}/?symbol=${this.coin}`;
    },
    coin() {
      return this.widget?.configuration?.coin || 'BTC';
    },
    bgColor() {
      return this.widgetConfig ? this.widgetConfig.backgroundColor : '#0F172B';
    },
    content() {
      return this.widgetConfig ? this.widgetConfig.content : '';
    },
    selectedIFrame() {
      return iFramesList.find(frame => {
        return frame.id === this.widgetConfig.derivatives_id;
      });
    }
  },
  methods: {
    goToExpandLink() {
      window.open('/tools/derivatives', '_blank');
    }
  }
};
</script>
