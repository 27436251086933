<template>
  <DropdownSelectMulti
    class="text-sm"
    :select-class="selectClass"
    :placeholder="placeholder"
    :possible-tags="tags"
    :set-item="setItemSource"
    :only-one="onlyOne"
    @change="change"
    :shift="false"
    :key="JSON.stringify(override)"
    :disable-shift="disableShift"
    :allow-keywords="showOnly.includes('keywords')"
    :w="w"
  >
    <slot />
  </DropdownSelectMulti>
</template>

<script>
import DropdownSelectMulti from '@/components/dropdown/DropdownSelectMulti.vue';
export default {
  components: {
    DropdownSelectMulti
  },
  emits: ['tagIdsChanged', 'tagsChanged'],
  props: {
    selectClass: String,
    showOnly: Array,
    placeholder: String,
    onlyOne: Boolean,
    startingFeed: Object,
    newsFeedProps: Object,
    setItem: Object,
    tagIds: Array,
    disableExcludeSource: Boolean,
    w: Number,
    disableShift: { type: Boolean, default: false }
  },
  data() {
    return {
      tags: [],
      override: {},
      setItemSource: undefined
    };
  },
  methods: {
    makeTagArray(arr, type, typeName, triggerRefresh) {
      const correctedType = type === 'sigdevs' ? 'tags' : type;

      return arr
        .map(item => {
          item.id = Number(item.id);
          let state = undefined;
          const included = ((this.startingFeedWithOverride || {})[correctedType] || [])
            .map(z => {
              return Number(z);
            })
            .includes(item.id);
          const excluded = ((this.startingFeedWithOverride || {})[`unwanted_${correctedType}`] || [])
            .map(z => {
              return Number(z);
            })
            .includes(item.id);

          const isPartOfTagIds =
            this.tagIds &&
            (this.tagIds.includes(item.tag_id) ||
              ((type === 'sigdevs' || type === 'tags') && this.tagIds.includes(item.id)));

          if (included || isPartOfTagIds) {
            state = 'included';
          }

          if (excluded) {
            state = 'exclude';
          }

          return {
            ...item,
            startingState: state,
            type: correctedType,
            typeName,
            triggerRefresh
          };
        })
        .filter(item => {
          if (!this.showOnly) {
            return true;
          }

          return this.showOnly.includes(item.type);
        });
    },
    async refreshTags(triggerRefresh = false) {
      const response = this.newsFeedProps;
      const tags = this.makeTagArray(response.tags, 'tags', 'Tag', triggerRefresh);
      const sigdevs = this.makeTagArray(response.sigdevs, 'sigdevs', 'SigDev', triggerRefresh);
      const coins = this.makeTagArray(response.coins, 'coins', 'Coin', triggerRefresh);
      const companies = this.makeTagArray(response.companies, 'companies', 'Company', triggerRefresh);
      const watchlists = this.makeTagArray(response.watchlists, 'watchlists', 'Watchlist', triggerRefresh);
      const categories = this.makeTagArray(response.categories, 'categories', 'Category', triggerRefresh);
      const subCategories = this.makeTagArray(
        response.sub_categories,
        'sub_categories',
        'Sub Category',
        triggerRefresh
      );
      const sources = this.makeTagArray(response.sources, 'sources', 'Source', triggerRefresh);
      const collections = this.makeTagArray(response.collections, 'collections', 'Group', triggerRefresh);

      const tagGroups = this.makeTagArray(
        [response.sigdevs_tag_subgroup, response.coins_tag_subgroup, response.companies_tag_subgroup].filter(row => {
          return row;
        }),
        'tag_subgroups',
        'Group',
        triggerRefresh
      );

      const existingKeywords =
        this.showOnly.includes('keywords') && this.startingFeedWithOverride.keywords
          ? this.startingFeedWithOverride.keywords.map(keyword => {
              return {
                id: keyword,
                name: `Keyword: ${keyword}`,
                type: 'keywords',
                startingState: 'included',
                typeName: 'Keywords'
              };
            })
          : [];

      const existingUnwantdKeywords =
        this.showOnly.includes('keywords') && this.startingFeedWithOverride.unwanted_keywords
          ? this.startingFeedWithOverride.unwanted_keywords.map(keyword => {
              return {
                id: keyword,
                name: `Keyword: ${keyword}`,
                type: 'keywords',
                startingState: 'exclude',
                typeName: 'Keywords'
              };
            })
          : [];

      this.tags = [
        ...tagGroups,
        ...tags,
        ...sigdevs,
        ...coins,
        ...companies,
        ...watchlists,
        ...categories,
        ...subCategories,
        ...collections,
        ...sources,
        ...existingKeywords,
        ...existingUnwantdKeywords
      ];
    },
    change(t) {
      const tags = JSON.parse(JSON.stringify(t));
      const feedObj = {};

      Object.keys(tags).forEach(type => {
        // if a new item is added
        Object.keys(tags[type]).forEach(item => {
          const include = tags[type][item] === 'include';

          if (include) {
            feedObj[type] = [...(feedObj[type] || []), item];
          } else {
            if (!this.disableShift) {
              feedObj[`unwanted_${type}`] = [...(feedObj[`unwanted_${type}`] || []), item];
            }
          }
        });

        // if an an item is removed
        if (
          Object.keys(tags[type]).filter(k => {
            return tags[type][k] === 'include';
          }).length === 0
        ) {
          feedObj[type] = [];
        }

        if (
          Object.keys(tags[type]).filter(k => {
            return tags[type][k] === 'exclude';
          }).length === 0
        ) {
          if (!this.disableShift) {
            feedObj[`unwanted_${type}`] = [];
          }
        }
      });

      function getTagIdsForInclude(root, mapping) {
        if (!mapping || !root) {
          return [];
        }

        const tagIds = [];

        Object.keys(mapping).forEach(key => {
          if (mapping[key] !== 'include') {
            return;
          }

          root.forEach(item => {
            if (String(item.id) === String(key)) {
              tagIds.push(item.tag_id || item.id);
            }
          });
        });

        return tagIds;
      }

      const tagIds = [
        ...getTagIdsForInclude(this.tags, tags.coins),
        ...getTagIdsForInclude(this.tags, tags.companies),
        ...getTagIdsForInclude(this.tags, tags.sigdevs),
        ...getTagIdsForInclude(this.tags, tags.tags),
        ...getTagIdsForInclude(this.tags, tags.keywords)
      ];
      this.$emit('tagIdsChanged', tagIds);
      this.$emit('tagsChanged', feedObj);
    }
  },
  mounted() {
    this.refreshTags();

    this.$eventHub.$on('excludeSource', ({ id }) => {
      if (this.disableExcludeSource) {
        return;
      }

      if ((this.showOnly && this.showOnly.includes('sources')) || !this.showOnly) {
        const past = [...(this.startingFeed.unwanted_sources || []), ...(this.override.unwanted_sources || [])];

        if (past.includes(id)) {
          return;
        }

        this.setItemSource = {
          id,
          type: 'sources'
        };
      }
    });
  },
  computed: {
    startingFeedWithOverride() {
      const j = {};

      if (this.startingFeed) {
        Object.keys(this.startingFeed).forEach(key => {
          if (Array.isArray(this.startingFeed[key])) {
            j[key] = (this.startingFeed[key] || []).map(item => {
              return isNaN(Number(item)) ? item : Number(item);
            });
            return;
          }

          j[key] = this.startingFeed[key];
        });
      }

      return {
        ...j,
        ...this.override
      };
    }
  },
  watch: {
    override() {
      this.refreshTags(true);
    },
    setItem() {
      this.setItemSource = this.setItem;
    }
  }
};
</script>
