<template>
  <img :src="imageURL" class="inline rounded-full" :class="styleClass" loading="lazy" alt="..." />
</template>
<script>
export default {
  props: {
    coin: {
      type: String
    },
    styleClass: {
      type: String,
      default: 'mr-2'
    }
  },
  computed: {
    imageURL() {
      return `https://terminal.cloudfront.thetie.io/coin_images/${this.coin}.png`;
    }
  }
};
</script>
