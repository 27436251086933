<template>
  <ModalTokenDetails
    v-if="showSidebar"
    :coin="coin"
    :news-item="newsItem"
    :back-option="true"
    :social-news="socialNews"
  />
</template>

<script>
import ModalTokenDetails from './ModalTokenDetails.vue';
export default {
  components: {
    ModalTokenDetails
  },
  computed: {
    showSidebar() {
      return this.$store.getters.coinSidebar;
    },
    coin() {
      return this.$store.state.sidebarData.coin;
    },
    newsItem() {
      return this.$store.state.sidebarData.newsItem;
    },
    socialNews() {
      return this.$store.state.sidebarData.socialNews;
    }
  }
};
</script>
<style>
.slide-enter-active {
  transition: 0.5s ease-in-out;
}
.slide-leave-active {
  transition: 1s ease-out;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(200%, 0);
}
</style>
