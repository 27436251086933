<template>
  <div class="float-right cursor-pointer text-blue-100 hover:text-blue-400">
    <DropdownTableCell :items="alert.type === 'Metric' ? metricDropdownItems : defDropdownItems" :cell-info="alert" />
  </div>
</template>

<script>
import DropdownTableCell from '@/components/dropdown/DropdownTableCell.vue';
export default {
  name: 'CellAlertEdit',
  components: {
    DropdownTableCell
  },
  props: {
    notifications: Object,
    alert: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      metricDropdownItems: [
        { id: 'edit', text: 'Edit', event: 'editCustomAlert' },
        { id: 'remove', text: 'Remove', event: 'removeCustomAlert' }
      ],
      defDropdownItems: [{ id: 'remove', text: 'Remove', event: 'removeCustomAlert' }]
    };
  },

  methods: {},

  computed: {}
};
</script>
