<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :overrides="overrides"
      :horizontal-padding="false"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="false"
      :connectable="false"
      :hidden-actions="['expand']"
    >
      <template #body>
        <div class="h-full" :id="chartId"></div>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
const SCRIPT_ID = 'tradingview-widget-script';
import WidgetsMixin from '@/mixins/widgets';
import { DateTime } from 'luxon';
export default {
  mixins: [WidgetsMixin],
  data() {
    return {
      chartId: Math.random()
    };
  },
  mounted() {
    this.appendScript(this.initWidget);
  },
  watch: {
    widget() {
      this.initWidget();
    }
  },
  computed: {
    symbol() {
      return this.widget.configuration?.symbol || 'COINBASE:BTCUSD';
    },
    interval() {
      return this.widget.configuration?.interval || '5';
    }
  },
  methods: {
    canUseDOM() {
      return typeof window !== 'undefined' && window.document && window.document.createElement;
    },
    getScriptElement() {
      return document.getElementById(SCRIPT_ID);
    },
    updateOnloadListener(onload) {
      const script = this.getScriptElement();
      const oldOnload = script.onload;
      return (script.onload = () => {
        oldOnload();
        onload();
      });
    },
    scriptExists() {
      return this.getScriptElement() !== null;
    },
    appendScript(onload) {
      if (!this.canUseDOM()) {
        onload();
        return;
      }
      if (this.scriptExists()) {
        if (typeof TradingView === 'undefined') {
          this.updateOnloadListener(onload);
          return;
        }
        onload();
        return;
      }
      const script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://s3.tradingview.com/tv.js';
      script.onload = onload;
      document.getElementsByTagName('head')[0].appendChild(script);
    },
    initWidget() {
      if (typeof TradingView === 'undefined') {
        return;
      }
      new window.TradingView.widget(
        Object.assign(
          { container_id: this.container_id },
          {
            autosize: true,
            symbol: this.widget.configuration?.symbol || 'COINBASE:BTCUSD',
            interval: this.widget.configuration?.interval || '5',
            timezone: DateTime.local().zoneName,
            theme: 'dark',
            style: '1',
            locale: 'en',
            toolbar_bg: '#f1f3f6',
            enable_publishing: false,
            hide_top_toolbar: !this.widget.configuration?.showTopBar,
            withdateranges: this.widget.configuration?.showBottomBar,
            hide_legend: true,
            allow_symbol_change: true,
            show_popup_button: true,
            popup_width: '1000',
            popup_height: '650',
            container_id: this.chartId
          }
        )
      );
    }
  }
};
</script>
