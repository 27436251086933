<template>
  <div class="grid grid-cols-3 gap-4" :class="simpleMode ? 'grid-cols-1' : 'grid-cols-3'">
    <div class="space-y-1">
      <label for="title" class="text-xs text-gray-400">Title (required)</label>
      <BaseTextbox
        placeholder="Title of Your Component"
        class="mt-1 mb-2 text-xs"
        name="title"
        v-model="editableWidget.title"
      />
    </div>
    <div :class="simpleMode ? '' : 'col-span-2'">
      <label for="description" class="text-xs text-gray-400">Description (optional)</label>
      <BaseTextbox
        placeholder="Describe Your Component"
        class="mt-1 mb-2 text-xs"
        name="description"
        v-model="editableWidget.description"
      />
    </div>
  </div>
  <div
    :style="simpleMode ? 'height: 300px' : 'height: calc(100% - 86px)'"
    :class="['mt-4 grid grid-cols-3 gap-x-4', simpleMode ? 'grid-cols-1' : 'grid-cols-3']"
  >
    <div v-if="componentToDisplay" :class="['overflow-y-auto', simpleMode ? '' : componentWidth]">
      <component
        :key="editableWidget.component"
        :is="componentToDisplay"
        :config="widgetConfigurations"
        :created-by="editableWidget.widget_template.user_id"
        :template-id="editableWidget.uuid || editableWidget.widget_template_id"
        @update="handleConfigUpdate"
      />
    </div>
    <div :class="widgetPreviewWidth">
      <slot name="preview"></slot>
    </div>
  </div>
</template>
<script setup>
import WidgetConfigsIframe from '@/components/widget_configs/WidgetConfigsIframe.vue';
import WidgetConfigsCalendar from '@/components/widget_configs/WidgetConfigsCalendar.vue';
import WidgetConfigsCoinProfile from '@/components/widget_configs/WidgetConfigsCoinProfile.vue';
import WidgetConfigsDexFilters from '@/components/widget_configs/WidgetConfigsDexFilters.vue';
import WidgetConfigsNews from '@/components/widget_configs/WidgetConfigsNews.vue';
import WidgetConfigsCustomComponent from '@/components/widget_configs/WidgetConfigsCustomComponent.vue';
import WidgetConfigsScreener from '@/components/widget_configs/WidgetConfigsScreener.vue';
import WidgetsConfigsResearchChart from '@/components/widget_configs/WidgetsConfigsResearchChart.vue';
import WidgetConfigsMarkdown from '@/components/widget_configs/WidgetConfigsMarkdown.vue';
import WidgetConfigsDerivatives from '@/components/widget_configs/WidgetConfigsDerivatives.vue';
import WidgetConfigsTradingviewChart from '@/components/widget_configs/WidgetConfigsTradingviewChart.vue';
import WidgetConfigsGovernance from '@/components/widget_configs/WidgetConfigsGovernance.vue';
import { ref, computed, watch, onBeforeUnmount, onBeforeUpdate } from 'vue';
import { isEmpty, isEqual } from 'lodash';

const emits = defineEmits(['update']);
const props = defineProps({
  widget: { type: Object, required: false },
  simpleMode: { type: Boolean, default: true }
});

//CONFIG COMPONENTS
const widgetConfigComponents = {
  WidgetsSimpleIframe: { component: WidgetConfigsIframe, orientation: 'portrait' },
  WidgetsCalendar: { component: WidgetConfigsCalendar, orientation: 'portrait' },
  WidgetsCoinProfile: { component: WidgetConfigsCoinProfile, orientation: 'portrait' },
  WidgetsDexTrades: { component: WidgetConfigsDexFilters, orientation: 'landscape' },
  WidgetsDexTransactions: { component: WidgetConfigsDexFilters, orientation: 'landscape' },
  WidgetsNews: { component: WidgetConfigsNews, orientation: 'portrait' },
  WidgetsCustomComponent: { component: WidgetConfigsCustomComponent, orientation: 'landscape' },
  WidgetsScreener: { component: WidgetConfigsScreener, orientation: 'portrait' },
  WidgetsMarkdown: { component: WidgetConfigsMarkdown, orientation: 'portrait' },
  WidgetsDerivatives: { component: WidgetConfigsDerivatives, orientation: 'landscape' },
  WidgetsTradingviewChart: { component: WidgetConfigsTradingviewChart, orientation: 'landscape' },
  WidgetsResearchChart: { component: WidgetsConfigsResearchChart, orientation: 'landscape' },
  WidgetsGovernanceProposals: { component: WidgetConfigsGovernance, orientation: 'landscape' }
};
const componentToDisplay = computed(() => {
  return widgetConfigComponents[editableWidget.value.component]?.component;
});

const componentOrientation = computed(() => {
  return widgetConfigComponents[editableWidget.value.component]?.orientation || 'landscape';
});

const componentWidth = computed(() => {
  if (widgetPreviewWidth.value == 'col-span-1') return 'col-span-2';
  return 'col-span-1';
});
const widgetPreviewWidth = computed(() => {
  let span = 'col-span-3';
  if (componentToDisplay.value) {
    span = componentOrientation.value == 'portrait' ? 'col-span-1' : 'col-span-2';
  }
  return span;
});

//EDITABLE WIDGET
const editableWidget = ref(JSON.parse(JSON.stringify(props.widget)));

const widgetConfigurations = computed(() => {
  let defaultConfig = { ...editableWidget.value.widget_template.default_configuration };
  let userConfig = { ...editableWidget.value.configuration };
  let updatedConfig = !isEmpty(defaultConfig) ? defaultConfig : userConfig;

  if (editableWidget.value.component == 'WidgetsDerivatives' && userConfig) {
    updatedConfig = userConfig;
  }
  if (editableWidget.value.component == 'WidgetsCustomComponent' && editableWidget.value.id && userConfig) {
    //editing in ModalEditWidget component
    updatedConfig.source = userConfig.source;
  }
  return updatedConfig;
});

watch(
  () => editableWidget.value.title,
  () => {
    emits('update', editableWidget.value);
  }
);
watch(
  () => editableWidget.value.description,
  () => {
    emits('update', editableWidget.value);
  }
);
// watch(
//   () => editableWidget.value.configuration,
//   (oldVal, newVal) => {
//     if (!isEqual(oldVal, newVal)) {
//       console.log('WATCH UPDATE');
//       emits('update', editableWidget.value);
//     }
//   },
//   { deep: true, immediate: true }
// );

function handleConfigUpdate(payload) {
  editableWidget.value.configuration = payload;
  emits('update', editableWidget.value);
}

onBeforeUnmount(() => {
  Object.assign(editableWidget.value, {});
});
onBeforeUpdate(() => {
  if (!componentToDisplay.value) {
    //force valid config if no custom configs are available
    editableWidget.value.configuration = {
      valid: true
    };
  }
});
</script>
