<template>
  <div :class="['relative  flex px-2 py-px', dropdowns ? 'justify-between' : 'justify-end']">
    <div v-if="dropdowns" class="flex space-x-1">
      <Dropdown
        v-for="(dropdown, idx) in dropdowns"
        :base-dropdown-parent-div-class="''"
        :key="dropdown.param"
        :dropdown-items="getDropdownOptions(dropdown)"
        @handle-action="updateDropdown($event, idx)"
        class="z-100 py-1 text-xs font-medium text-gray-400 duration-100 hover:text-gray-100"
      >
        <div class="flex whitespace-nowrap">
          <span>{{ dropdown.selectedOption ? dropdown.selectedOption : dropdown.label }}</span>
          <IconChevronDown class="ml-0" />
        </div>
      </Dropdown>
    </div>
    <div v-if="search" class="flex w-1/4 max-w-sm items-center py-1">
      <IconSearch class="h-3.5 w-3.5"></IconSearch>
      <input
        v-model="searchTerm"
        :placeholder="search ? search.placeholder : 'Search'"
        @keyup="updateSearch"
        class="w-full truncate rounded bg-transparent text-xs outline-none focus:border-none"
      />
    </div>
  </div>
</template>
<script setup>
import Dropdown from '@/components/dropdown/Dropdown.vue';
import { ref, onMounted, watch } from 'vue';
import { debounce } from 'lodash';
const emit = defineEmits(['update-dropdown', 'update-search']);
const props = defineProps({
  advancedSettings: Object,
  preview: { type: Object, default: null },
  tab: { type: String, default: '' },
  params: { type: Object, default: null }
});
const dropdowns = ref({});
const search = ref('');
const searchTerm = ref('');

onMounted(() => {
  updateHeader();
});

function updateHeader() {
  if (props.advancedSettings) {
    if (props.advancedSettings.searchDropdowns) {
      let dropdownValues = JSON.parse(JSON.stringify(props.advancedSettings.searchDropdowns));
      dropdowns.value = dropdownValues
        .filter(x => x.param && x.param != '')
        .map(x => {
          if (!x.options.find(opt => opt.title == x.label && opt.value == x.default_value)) {
            x.options.unshift({ id: Math.random(), title: x.label, value: x.default_value });
          }
          if (props.params) {
            let idx = props.params.findIndex(i => i.id == x.param);
            if (idx != -1) {
              let selected = x.options.find(option => option.value == props.params[idx].value);
              return { ...x, selectedOption: selected ? selected.title : '' };
            }
          }
          return { ...x, selectedOption: x.label };
        });
      dropdowns.value = dropdowns.value.length ? dropdowns.value : null;
    }
    if (
      props.advancedSettings.searchParam &&
      props.advancedSettings.searchParam.placeholder != null &&
      props.advancedSettings.searchParam.param != null
    ) {
      search.value = props.advancedSettings.searchParam;
      searchTerm.value = '';
    }
  }
}
function updateDropdown(event, index) {
  let selectedOption = dropdowns.value[index].options.filter(x => x.id == event)[0];
  dropdowns.value[index].selectedOption = selectedOption.title;
  emit('update-dropdown', { param: dropdowns.value[index].param, option: selectedOption });
}

const updateSearch = debounce(() => {
  emit('update-search', { param: search.value.param, searchTerm: searchTerm.value });
}, 1000);

function getDropdownOptions(dropdown) {
  let options = [];
  dropdown.options
    ?.filter(x => x.title != '')
    .forEach(x => {
      options.push({ id: x.id, text: x.title, value: x.value });
    });
  return options;
}
watch(
  props.advancedSettings,
  () => {
    updateHeader();
  },
  { deep: true }
);
</script>
