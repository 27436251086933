<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="false"
      :connectable="false"
      @expand="goToExpandLink"
    >
      <template #body>
        <div>
          <div class="flex justify-between">
            <div class="flex items-center">
              <div
                v-for="tag in tags"
                :key="tag.id"
                @click="setSelectedTag(tag.id)"
                class="mx-2 cursor-pointer rounded text-xs transition-all ease-linear"
                :class="tag.id === selectedTag ? 'font-semibold text-gray-100' : 'text-gray-400 hover:text-gray-100'"
              >
                {{ tag.title }}
              </div>
            </div>
            <div class="flex items-center py-2">
              <input
                class="block w-44 rounded border border-gray-700 bg-transparent px-4 py-1 text-xs outline-none focus:border-gray-500 focus:outline-none"
                placeholder="Search Profile"
                v-model="searchQuery"
                ref="search"
                @click="$refs.search.focus()"
                @input="applySearch"
              />
            </div>
          </div>
          <div>
            <table class="w-full text-xs font-medium text-gray-200">
              <thead>
                <tr class="sticky top-0 border-0 bg-gray-900 text-gray-400">
                  <th class="whitespace-nowrap py-2 text-left">Followed Profile</th>
                  <th class="whitespace-nowrap py-2 text-left">Latest Key Followers</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td colspan="3">
                      <div class="flex w-full justify-center">
                        <BaseLoader />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <template v-if="transformedTrendingFollows.length">
                    <tr
                      class="border-none"
                      v-for="(follow, idx) in transformedTrendingFollows"
                      :key="idx"
                      :class="idx % 2 == 0 ? 'bg-gray-800/25' : ''"
                    >
                      <td class="pr-2">
                        <CellProfile
                          class="whitespace-nowrap text-xs"
                          :profile="{ ...follow.user, subtext: follow.description }"
                          image-size="w-5 h-5"
                          :name-character-limit="15"
                        />
                      </td>
                      <td>
                        <CellMultipleUsers
                          class="text-xs"
                          image-size="w-5 h-5"
                          image-spacing="space-x-0.5"
                          :users="follow.recent_follows"
                          :total-user="follow.num_follows"
                          :key="follow.num_follows"
                          :max-user="4"
                        />
                      </td>
                      <td></td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="3">
                        <NoDataFound class="text-xs" />
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import moment from 'moment';
import { debounce } from 'lodash';
import { getTrendingFollows } from '@/api/apefluencer';
import WidgetsMixin from '@/mixins/widgets';
import CellProfile from '@/components/table/cell/CellProfile.vue';
import CellMultipleUsers from '@/components/table/cell/CellMultipleUsers.vue';
export default {
  name: 'WidgetsApefluencerTrendingFollows',
  mixins: [WidgetsMixin],
  mounted() {
    if (this.widget?.configuration && this.widget.configuration.filter) {
      this.selectedTag = this.widget.configuration.filter;
    }
    this.getData();
  },
  components: {
    CellProfile,
    CellMultipleUsers
  },
  data() {
    return {
      searchQuery: '',
      loading: false,
      tags: [
        {
          id: '24hr',
          title: '24hr',
          startTime: moment().utc().subtract(1, 'day').format('YYYY-MM-DD'),
          enabled: true
        },
        {
          id: '7d',
          title: '7d',
          startTime: moment().utc().subtract(7, 'days').format('YYYY-MM-DD')
        },
        {
          id: '30d',
          title: '30d',
          startTime: moment().utc().subtract(30, 'days').format('YYYY-MM-DD')
        }
      ],
      selectedTag: '24hr',
      trendingFollows: [],
      perPage: 100
    };
  },
  computed: {
    parameters() {
      const startTime = this.tags.find(x => x.id == this.selectedTag).startTime;
      return {
        page: 0,
        search: this.searchQuery,
        start_datetime: startTime
      };
    },
    transformedTrendingFollows() {
      return this.trendingFollows.map(x => {
        return {
          user: {
            name: x.user_followed_metadata?.screen_name,
            externalLink: x.user_followed_metadata?.profile_url_https,
            imageUrl: x.user_followed_metadata?.profile_image_url_https,
            subtext: x.user_followed_metadata?.description
          },
          num_follows: x.num_follows,
          description: x.user_followed_metadata?.description,
          recent_follows: x.indicators,
          created_at: x.user_followed_metadata?.created_at,
          user_followers_count: x.user_followed_metadata?.followers_count
        };
      });
    }
  },
  watch: {
    selectedTag() {
      this.getData();
    }
  },
  methods: {
    applySearch: debounce(function () {
      this.getData();
    }, 500),
    async getData() {
      try {
        this.loading = true;
        const response = await getTrendingFollows(this.parameters);
        if (response && response.data) {
          this.trendingFollows = response.data;
        } else {
          this.trendingFollows = [];
        }
      } finally {
        this.loading = false;
      }
    },
    goToExpandLink() {
      window.open('/tools/apefluencer', '_blank');
    },
    setSelectedTag(tag) {
      this.selectedTag = tag;
      this.$http.patch(`/dashboard_widgets/${this.widget.id}`, { configuration: { filter: this.selectedTag } });
    }
  }
};
</script>
