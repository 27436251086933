<template>
  <div class="ml-2 flex h-full flex-col justify-center text-right">
    <BasePercentClassic
      :percent="item.relative_change"
      class="text-sm font-medium"
      :multiply="multiply"
      v-if="item.relative_change"
    />
    <span v-else class="text-gray-400">0%</span>
    <span class="text-xs"
      ><span class="text-gray-400">{{ monthly ? '(30d) ' : '(24hr) ' }} </span
      >{{ rounded(Number(item.total_volume || ''), 0) }}</span
    >
    <span class="text-xs"
      ><span class="text-gray-400">{{ monthly ? '(1y avg) ' : '(30d avg) ' }}</span
      >{{ rounded(Number(item.avg_30_day || ''), 0) }}</span
    >
  </div>
</template>
<script>
import FilterMixin from '@/mixins/filters';
export default {
  mixins: [FilterMixin],
  name: 'CellSentimentChanges',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    multiply: {
      type: Boolean,
      default: true
    },
    monthly: Boolean
  },
  data() {
    return {};
  }
};
</script>
