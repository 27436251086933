<template>
  <div class="h-full">
    <TableBase
      :config="config"
      :data="filteredNotableData"
      :loading="loading"
      :paginate="getTransactions"
      :key="filteredNotableData.length"
      :reset-pagination-page="false"
    />
  </div>
</template>

<script>
export default {
  name: 'TableDexTransactions',
  mounted() {
    this.getTransactions();
  },
  props: {
    token: {
      type: String,
      default: null
    },
    filters: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: true,
      config: {
        alternateBg: false,
        rowClass: 'py-2',
        headerClass: 'sticky top-0 z-20',
        hideLoadMore: true,
        perPage: 100,
        page: 1,
        horizontalScroll: true,
        cols: [
          {
            type: 'buysell',
            size: 4,
            name: 'Type',
            id: 'tx',
            cellClass: 'text-center',
            headerClass: 'text-center w-12'
          },
          {
            type: 'text',
            size: 4,
            name: 'Block Number',
            id: 'blockNumber',
            cellClass: 'text-center',
            headerClass: 'text-center w-10'
          },
          {
            type: 'dollar_format',
            size: 4,
            name: 'Transfer Size',
            id: 'estimatedDollarAmount',
            headerClass: 'w-10'
          },
          {
            type: 'truncate_text',
            name: 'From',
            id: 'from',
            cellClass: 'text-right w-10',
            headerClass: 'text-right',
            truncateLength: 25
          },
          {
            type: 'eth_type',
            name: 'Token Amount',
            id: 'tokenAmount',
            cellClass: 'w-10'
          },
          {
            type: 'truncate_text',
            name: 'To',
            id: 'to',
            cellClass: 'text-right w-10',
            headerClass: 'text-right',
            truncateLength: 25
          },
          {
            type: 'url',
            size: 3,
            name: 'Tx',
            id: 'link',
            headerClass: 'w-10'
          }
        ]
      },
      notableData: [],
      tokens: {},
      transactionSize: '',
      holderSize: ''
    };
  },
  computed: {
    filteredNotableData() {
      let data = [];
      this.notableData.forEach(i => {
        data.push({
          ...i,
          link: 'https://etherscan.io/tx/' + i.hash,
          tx: 'Transfer',
          tokenAmount: i.tokenAmount,
          tokenAmountsymbol: i.tokenSymbol,
          dexUsed: {}
        });
      });
      return data.filter(i => i.dexUsed.name != 'noneOnRecord');
    }
  },
  watch: {
    filters() {
      this.transactionSize = this.filters.transactionSize;
      this.holderSize = this.filters.holderSize;
      this.loading = true;
      this.getTransactions();
    },
    token() {
      this.getTransactions();
    }
  },
  methods: {
    async getTransactions(pageParams = { page: 1, perPage: 100 }) {
      const self = this;
      self.loading = true;
      try {
        let response = await this.$http.get('/notable_transactions', {
          params: {
            address: self.token,
            dex_trades: false,
            page: pageParams.page,
            limit: pageParams.perPage,
            transaction_size: self.transactionSize,
            holder_size: self.holderSize
          }
        });
        response.data.transactions = response.data.transactions.map(x => {
          return { ...x, from: x.from_public_tag || x.from, to: x.to_public_tag || x.to };
        });
        if (pageParams.page == 1) {
          self.config.page = 1;
          self.notableData = !response.data.transactions.includes('No transactions') ? response.data.transactions : [];
        } else {
          self.config.page++;
          !response.data.transactions.includes('No transactions')
            ? self.notableData.push(...response.data.transactions)
            : [];
        }
        self.tokens = { ...self.tokens, ...response.data.tokens };
        if (!response.data.nextPage || response.data.transactions.includes('No transactions')) {
          pageParams.stopCall = true;
        }
        self.loading = false;
      } catch {
        self.notableData = self.notableData.length ? self.notableData : [];
        self.loading = false;
      }
      return pageParams;
    }
  }
};
</script>
