<template>
  <tr class="table-row px-5 font-medium">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: 'TablePartRow',
  data() {
    return {};
  }
};
</script>
