<template>
  <div>
    <DropdownAlign :on="active" :placement="placement ? placement : 'top'">
      <template #trigger>
        <span @mouseover="active = true" @mouseleave="active = false">
          <slot />
        </span>
      </template>

      <template #dropdown>
        <div
          v-show="active && text"
          class="max-w-md break-words rounded border border-gray-700 bg-gray-800 text-left text-gray-200"
          :class="addPadding ? 'px-4 py-2' : ''"
          @mouseover="active = true"
          @mouseleave="active = false"
        >
          <div v-if="title" class="mb-0.5 text-xs font-bold normal-case text-white">
            {{ title }}
          </div>
          <div v-if="isSlot" class="whitespace-normal text-xs font-semibold normal-case text-gray-300">
            <slot name="components"></slot>
          </div>
          <div
            v-else
            :class="[
              breakAll ? 'break-all' : 'break-words',
              wrapText ? 'whitespace-normal' : 'whitespace-nowrap',
              text.length > 50 ? 'w-72' : 'w-auto'
            ]"
            class="text-xs font-semibold normal-case text-gray-300"
          >
            <div v-html="text"></div>
          </div>
        </div>
      </template>
    </DropdownAlign>
  </div>
</template>

<script>
import DropdownAlign from '@/components/dropdown/DropdownAlign.vue';
export default {
  components: {
    DropdownAlign
  },
  props: {
    text: String,
    title: String,
    placement: String,
    breakAll: { type: Boolean, default: false },
    wrapText: { type: Boolean, default: true },
    isSlot: { type: Boolean, default: false },
    addPadding: { type: Boolean, default: true }
  },
  data() {
    return {
      active: false
    };
  }
};
</script>
