<template>
  <div class="h-full w-full pb-24">
    <div class="relative z-10 flex h-10 items-center border-b border-grid px-5">
      <BaseDatepicker
        :key="time[0]"
        :range="true"
        :default-date="time"
        format="MMMM DD, YYYY"
        font-size="text-xs"
        @change="emit('update:date', $event)"
        @clear="emit('clear:time')"
      />

      <BaseLoader v-if="settings.sources.length > 0 && loading" class="ml-2" />

      <div class="ml-auto flex items-center space-x-2">
        <div class="pl-4"></div>
        <div
          v-for="tf in timeframes"
          :key="tf.id"
          @click="emit('update:timeframe', tf), emitter.$emit('update:controls', tf)"
          class="cursor-pointer rounded px-2 py-0.5 text-xs font-medium"
          :class="
            tf.id == currentTimeframe
              ? 'bg-blue-900 text-blue-100'
              : 'bg-gray-800 text-gray-400 duration-100 hover:bg-blue-900 hover:text-blue-100'
          "
        >
          {{ tf.text }}
        </div>
        <div class="w-2" />
        <div
          @click="toggleLog(!settings.log)"
          class="cursor-pointer rounded px-2 py-0.5 text-xs font-medium"
          :class="
            settings.log
              ? 'bg-blue-900 text-blue-100'
              : 'bg-gray-800 text-gray-400 duration-100 hover:bg-blue-900 hover:text-blue-100'
          "
        >
          LOG
        </div>
        <div
          @click="toggleAxis(!settings.sameAxis)"
          class="cursor-pointer rounded px-2 py-0.5 text-xs font-medium"
          :class="
            settings.sameAxis
              ? 'bg-blue-900 text-blue-100'
              : 'bg-gray-800 text-gray-400 duration-100 hover:bg-blue-900 hover:text-blue-100'
          "
        >
          Same Axis
        </div>
        <div
          @click="emit('export:csv')"
          class="
            flex
            cursor-pointer
            items-center
            space-x-1
            rounded
            bg-gray-800
            px-2
            py-0.5
            text-xs
            font-medium
            text-gray-400
            duration-100
            hover:bg-blue-900 hover:text-blue-100
          "
        >
          <div>CSV</div>
          <IconDownload />
        </div>

        <div
          @click="emit('export:chart')"
          class="
            flex
            cursor-pointer
            items-center
            space-x-1
            rounded
            bg-gray-800
            px-2
            py-0.5
            text-xs
            font-medium
            text-gray-400
            duration-100
            hover:bg-blue-900 hover:text-blue-100
          "
        >
          <div>PNG</div>
          <IconDownload />
        </div>
      </div>
    </div>

    <div v-if="settings.sources.length === 0" class="flex h-full flex-row items-center justify-center text-sm">
      <div class="pb-36 text-gray-400">Your chart is empty, please add data from the sidebar to get started</div>
    </div>

    <div class="flex h-full flex-row text-sm" v-show="settings.sources.length > 0">
      <div class="w-full" :id="randomId" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, inject, nextTick } from 'vue';
import DatePicker from 'vue-datepicker-next';
import { DateTime } from 'luxon';
import 'vue-datepicker-next/index.css';

const emitter = inject('eventHub');
const emit = defineEmits(['export:csv', 'export:chart', 'update:date', 'update:timeframe', 'clear:time']);

const props = defineProps({
  time: Array,
  loading: Boolean,
  selectedTimeframe: String,
  settings: Object,
  randomId: String
});
const currentTimeframe = ref(props.selectedTimeframe);
const timeframes = ref([
  // {
  //   time: 1000 * 60 * 60,
  //   id: '1H',
  //   text: '1h'
  // },
  // {
  //   time: 1000 * 60 * 60 * 3,
  //   id: '3H',
  //   text: '3h'
  // },
  // {
  //   time: 1000 * 60 * 60 * 6,
  //   id: '6H',
  //   text: '6h'
  // },
  {
    time: 1000 * 60 * 60 * 24,
    id: '1D',
    text: '1d'
  },
  {
    time: 1000 * 60 * 60 * 24 * 7,
    id: '7D',
    text: '7d'
  },
  {
    time: 1000 * 60 * 60 * 24 * 30,
    id: '30D',
    text: '30d'
  },
  {
    time: 1000 * 60 * 60 * 24 * 90,
    id: '90D',
    text: '3M'
  },
  {
    time: 1000 * 60 * 60 * 24 * 180,
    id: '6M',
    text: '6M'
  },
  {
    time: 1000 * 60 * 60 * 24 * 365,
    id: '1Y',
    text: '1Y'
  },
  {
    time: 1000 * 60 * 60 * 24 * 365 * 10,
    id: 'All',
    text: 'All'
  },
  {
    time: new Date().getTime() - new Date(new Date().getFullYear(), 0, 1).getTime(),
    id: 'YTD',
    text: 'YTD'
  }
]);

watch(
  () => props.selectedTimeframe,
  () => {
    currentTimeframe.value = props.selectedTimeframe;
  }
);

function toggleLog(log) {
  emitter.$emit('toggle:log', log);
}
function toggleAxis(axis) {
  emitter.$emit('toggle:axis', axis);
}
</script>
