<template>
  <div class="relative h-full" :class="externalChat ? '' : 'w-80 border-r border-gray-600'">
    <div class="flex items-center justify-between bg-gray-800 px-3 py-2" v-if="!externalChat">
      <span class="text-xs font-medium leading-4 tracking-wide">Chats</span>
      <div class="flex items-center space-x-2.5">
        <IconSearch @click="showSearchModal = true" size="w-4 h-4 cursor-pointer text-gray-400 hover:text-white" />
        <IconEditFilled @click="showCreateModal = true" size="w-4 h-4 cursor-pointer text-gray-400 hover:text-white" />
      </div>
    </div>
    <div id="chat-sidebar" class="flex h-full flex-col overflow-y-hidden rounded">
      <ChatSidebarChannelGroup
        type="requests"
        :channels="requestChannels"
        :chosen-channel="chosenChannel"
        :external="externalChat"
        :request-opened="requestOpened"
        @show-search-modal="
          searchTab = 'people';
          showSearchModal = true;
        "
        @set-requests-state="requestOpened = !requestOpened"
      />
      <ChatSidebarChannelGroup
        :type="selectedFolder"
        :selected-folder="selectedFolder"
        :channels="filteredChannels"
        :all-channels="channels"
        :chosen-channel="chosenChannel"
        :external="externalChat"
        :request-opened="requestOpened"
        @set-folder="selectedFolder = $event"
      />
    </div>
    <div
      class="fixed inset-x-0 bottom-0 flex h-14 w-80 items-center justify-between space-x-1 bg-gray-800 px-3"
      v-if="!externalChat"
    >
      <BaseUser
        v-if="streamUser"
        :user="streamUser"
        :title="streamUser.name"
        :max-text-length="30"
        :sub-text="getUserSubtitle(streamUser)"
        :hide-cursor="true"
      >
        <template #image>
          <ChatImage size="sm" :show-online="true" :item="streamUser"> </ChatImage>
        </template>
      </BaseUser>
      <div class="flex items-center space-x-2.5">
        <div class="cursor-pointer" @click="emitter.$emit('change-channel', null)">
          <IconHome class="h-4 w-4 text-gray-400 hover:text-white" />
        </div>
        <div class="cursor-pointer" @click="showSettingsModal = true">
          <IconGearFilled size="w-4 h-4 text-gray-400 hover:text-white" />
        </div>
      </div>
    </div>
    <ChatCreateChannelModal
      v-if="showCreateModal"
      :channels="channels"
      :searched-term="searchedTerm"
      :searched-user="searchedUser"
      @close="
        showCreateModal = false;
        searchedTerm = null;
      "
    ></ChatCreateChannelModal>
    <ChatSearchModal
      v-if="showSearchModal"
      :channels="channels"
      :pinned-channels="pinnedChannels"
      :temporary-channels="temporaryChannels"
      :archived-channels="archivedChannels"
      :search-tab="searchTab"
      @close="showSearchModal = false"
      @find-message-in-channel="searchMessage($event)"
      @find-channel="
        showSearchModal = false;
        emitter.$emit('change-channel', $event);
      "
    ></ChatSearchModal>
    <ChatSettingsModal v-if="showSettingsModal" @close="showSettingsModal = false"></ChatSettingsModal>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, inject } from 'vue';
import ChatSettingsModal from './ChatSettingsModal.vue';
import ChatSidebarChannelGroup from './ChatSidebarChannelGroup.vue';
import BaseUser from './ChatBaseUser.vue';
import ChatImage from './ChatImage.vue';
import ChatCreateChannelModal from './ChatCreateChannelModal.vue';
import ChatSearchModal from './ChatSearchModal.vue';
import { getUserSubtitle } from '@/composeables/chat';
import { useMapGetter } from '@/store/map-state';

import { useStore } from 'vuex';
import moment from 'moment';
const { client, streamUser, folders, rejectedChannels } = useMapGetter();
const store = useStore();
const $http = inject('http');

const emitter = inject('eventHub');
const $emit = defineEmits(['search-message']);
const props = defineProps({
  channels: {
    type: Array,
    default: () => []
  },
  chosenChannel: Object,
  showRequests: Boolean,
  externalChat: {
    type: Boolean,
    default: false
  }
});

const showCreateModal = ref(false);
const showSearchModal = ref(false);
const showSettingsModal = ref(false);
const scrollVisible = ref(false);
const searchedTerm = ref(null);
const searchedUser = ref(null);
const searchTab = ref(null);
const selectedFolder = ref('all_chats');
const requestOpened = ref(false);

onMounted(() => {
  if (props.chosenChannel && requestChannels.value.map(x => x.cid).includes(props.chosenChannel.cid)) {
    requestOpened.value = true;
  } else if (props.chosenChannel && archivedChannels.value.map(x => x.cid).includes(props.chosenChannel.cid)) {
    selectedFolder.value = 'archived';
  }
  emitter.$on('show-user-bio', user => {
    showSearchModal.value = false;
  });
  emitter.$on('show-settings-modal', () => {
    showSettingsModal.value = true;
  });
  emitter.$on('channel-added', async channel => {
    let selectedFolderItem = folders.value.find(x => x.id == selectedFolder.value);
    if (selectedFolderItem) {
      selectedFolderItem.channels.push(channel.cid);
      await $http.put(`/messaging_folders/${selectedFolderItem.folder_id}`, {
        messaging_folder: {
          name: selectedFolderItem.text,
          channels: selectedFolderItem.channels
        }
      });
      const res = await $http.get('/messaging_folders');
      store.dispatch('setFolders', res.data.folders);
    }
  });
  emitter.$on('show-create-channel-modal', event => {
    if (event != undefined && typeof event === 'object') {
      if (streamUser.value.companies && streamUser.value.companies.includes(event.companies[0])) {
        searchedUser.value = event;
      } else {
        searchedTerm.value = event.companies[0];
        searchedUser.value = event;
      }
    } else if (event != undefined && (!streamUser.value.companies || !streamUser.value.companies.includes(event))) {
      searchedTerm.value = event;
    }
    showCreateModal.value = true;
  });
  emitter.$on('add-to-folder', data => {
    addToFolder(data);
  });
  const elm = document.getElementById('chat-sidebar');
  if (ResizeObserver) {
    new ResizeObserver(() => {
      scrollVisible.value = elm.scrollHeight > elm.clientHeight ? true : false;
    }).observe(elm);
  }
});

onBeforeUnmount(() => {
  emitter.$off('show-user-bio');
  emitter.$off('show-create-channel-modal');
  emitter.$off('show-settings-modal');
  emitter.$off('add-to-folder');
});
//computed
const pinnedChannels = computed(() => {
  return props.channels.filter(c =>
    streamUser.value && Array.isArray(streamUser.value.pinned_channels)
      ? streamUser.value.pinned_channels.includes(c.cid)
      : false
  );
});
const unreadChannels = computed(() => {
  let unreadChannels = props.channels.filter(
    c =>
      !requestChannels.value.map(x => x.cid).includes(c.cid) &&
      !rejectedChannels.value.includes(c.cid) &&
      !archivedChannels.value.map(x => x.cid).includes(c.cid) &&
      c.state.unreadCount > 0
  );

  return unreadChannels;
});
const allChatChannels = computed(() => {
  let allChats = props.channels.filter(
    c =>
      !requestChannels.value.map(x => x.cid).includes(c.cid) &&
      !rejectedChannels.value.includes(c.cid) &&
      !archivedChannels.value.map(x => x.cid).includes(c.cid)
  );

  return allChats;
});
const archivedChannels = computed(() => {
  let archivedChats = props.channels.filter(c =>
    streamUser.value && Array.isArray(streamUser.value.archive_channels)
      ? streamUser.value.archive_channels.includes(c.cid)
      : false
  );

  return archivedChats;
});
const requestChannels = computed(() => {
  var requests = props.channels.filter(c =>
    Object.values(c.state.members).length > 0
      ? Object.values(c.state.members).some(
          u =>
            u.user_id === client.value.user.id && u.invited === true && !u.invite_accepted_at && !u.invite_rejected_at
        )
      : false
  );
  store.dispatch(
    'setRequestChannels',
    requests.map(x => x.cid)
  );
  return requests;
});
const filteredChannels = computed(() => {
  let channelsData = [];
  if (selectedFolder.value === 'all_chats') {
    channelsData = allChatChannels.value;
  } else if (selectedFolder.value === 'unread') {
    channelsData = unreadChannels.value;
  } else if (selectedFolder.value === 'requests') {
    channelsData = requestChannels.value;
  } else if (selectedFolder.value === 'archived') {
    channelsData = archivedChannels.value;
  } else {
    let selectedFolderData = folders.value.find(x => x.id == selectedFolder.value);
    if (selectedFolderData) {
      channelsData = props.channels.filter(
        c => selectedFolderData.channels.includes(c.cid) && !archivedChannels.value.map(x => x.cid).includes(c.cid)
      );
    }
  }
  return channelsData;
});

//methods
function searchMessage(payload) {
  $emit('search-message', payload);
  showSearchModal.value = false;
}
function channelsBeforeDay(date) {
  let hour = moment().diff(moment(date), 'hours');
  return hour > 24;
}
async function addToFolder(data) {
  let folder = folders.value.find(f => f.id === data.id);
  const response = await $http.put(`/messaging_folders/${data.folder_id}`, {
    messaging_folder: {
      channels: [...folder.channels, data.channelCid]
    }
  });
  store.dispatch('updateFolder', response.data.folder);
}
watch(
  () => folders.value,
  (newVal, oldVal) => {
    if (oldVal.length > 0) {
      let newFolder = newVal.filter(x => !oldVal.map(o => o.id).includes(x.id));
      if (newFolder.length) {
        selectedFolder.value = newFolder[0].id;
      }
    }
  }
);
watch(
  () => showSearchModal.value,
  () => {
    if (showSearchModal.value == false) {
      searchTab.value = null;
    }
  }
);
watch(
  () => props.chosenChannel,
  () => {
    if (props.chosenChannel && requestChannels.value.map(x => x.cid).includes(props.chosenChannel.cid)) {
      requestOpened.value = true;
    } else if (props.chosenChannel) {
      requestOpened.value = false;
    }
  }
);
</script>
