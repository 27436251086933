<template>
  <div class="flex items-center">
    <div class="flex flex-shrink-0 items-center justify-end space-x-2 rounded text-gray-400">
      <BaseTooltipClassic :text="'Click to copy the link'" placement="bottom">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 flex-shrink-0 cursor-pointer duration-100 hover:text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="$emit('share')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
          />
        </svg>
      </BaseTooltipClassic>
      <div
        @click="$emit('toggle-starred')"
        v-if="canStar"
        :class="canStar && 'cursor-pointer'"
        class="duration-100 hover:text-white"
        placement="bottom"
      >
        <BaseTooltipClassic :text="isStarred ? 'Unstar this Story' : 'Star this Story'" placement="bottom">
          <svg
            v-if="!isStarred"
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 flex-shrink-0"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
            />
          </svg>
          <svg
            v-if="isStarred"
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 text-yellow-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
            />
          </svg>
        </BaseTooltipClassic>
      </div>
      <template v-if="canDelete || canEdit">
        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 cursor-pointer flex-shrink-0 hover:text-white duration-100"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          v-if="canEdit"
          @click="$emit('edit')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg> -->

        <BaseTooltipClassic
          :text="'ADMIN - delete article for everyone because it is irrelevant/junk'"
          placement="bottom"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 flex-shrink-0 cursor-pointer duration-100 hover:text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            v-if="canDelete"
            @click="$emit('delete')"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </BaseTooltipClassic>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableCellPartNewsActions',
  props: {
    isStarred: Boolean,
    canStar: Boolean,
    canDelete: Boolean,
    canEdit: Boolean,
    canGlobalStar: Boolean
  }
};
</script>
