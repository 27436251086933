<template>
  <div class="flex h-full w-full flex-1">
    <div :id="pieId" class="w-full flex-1"></div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import FilterMixin from '@/mixins/filters';
export default {
  name: 'ChartDonut',
  mixins: [FilterMixin],
  props: {
    data: Array,
    centerText: String,
    centerPercent: Number,
    canClickBack: Boolean,
    canClickDown: Boolean,
    metric: String,
    widgetWidth: Number
  },
  data() {
    return {
      pieId: `pie_chart${new Date().getTime()}`,
      selectedTooltip: undefined,
      svg: undefined
    };
  },
  mounted() {
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('resize', this.onMouseUp);
  },
  beforeUnmount() {
    window.removeEventListener('mouseup', this.onMouseUp);
    window.addEventListener('resize', this.onMouseUp);
  },
  methods: {
    percentChangeToColor(percent) {
      if (percent < 0) {
        return '#f00';
      }

      return '#0f0';
    },
    percentChangeToOpacity(percent, max, low) {
      if (percent < 0) {
        return Math.max((0.9 * percent) / low, 0.1);
      } else {
        return Math.max((0.9 * percent) / max, 0.1);
      }
    },
    margin() {
      return this.pieWidth() * 0.034;
    },
    innerRadius() {
      return this.radius() * 0.4;
    },
    pieWidth() {
      return document.getElementById(`${this.pieId}`).clientWidth;
    },
    pieHeight() {
      return document.getElementById(`${this.pieId}`).clientHeight - this.margin();
    },
    radius() {
      return Math.min(this.pieWidth(), this.pieHeight()) / 2 - this.margin();
    },
    formatPercent(p) {
      const percent = Math.round(p * 100) / 100;
      const formatted = this.rounded(percent);
      return p < 0 ? `${formatted}%` : `+${formatted}%`;
    },
    drawPie() {
      const pieElm = document.getElementById(`${this.pieId}`);

      if (!pieElm) {
        return;
      }

      pieElm.innerHTML = '';

      const svg = d3
        .select(`#${this.pieId}`)
        .append('svg')
        .attr('width', this.pieWidth())
        .attr('height', this.pieHeight())
        .append('g')
        .attr('transform', 'translate(' + this.pieWidth() / 2 + ',' + this.pieHeight() / 2 + ')');

      this.svg = svg;

      const data = {};
      let maxP = Number.MAX_SAFE_INTEGER * -1;
      let minP = Number.MAX_SAFE_INTEGER;

      this.data.forEach(cat => {
        if (cat[this.metric] < minP) {
          minP = cat[this.metric];
        }

        if (cat[this.metric] > maxP) {
          maxP = cat[this.metric];
        }
      });

      this.data.forEach(cat => {
        data[cat.name] = {
          value: cat.totalMarketCap,
          percent: cat[this.metric],
          color: this.percentChangeToColor(cat[this.metric], maxP, minP),
          opacity: this.percentChangeToOpacity(cat[this.metric], maxP, minP),
          id: cat.id
        };
      });

      // Compute the position of each group on the pie:
      //   const padAngle = Math.PI / (Object.keys(data).length * 20);
      let padAngle = Math.PI / 200;

      if (Object.keys(data).length >= 16) {
        padAngle = 0;
      }

      var pie = d3
        .pie()
        .padAngle(padAngle)
        .value(function (d) {
          return d.value.value;
        });
      var data_ready = pie(d3.entries(data));

      var arcGenerator = d3
        .arc()
        .innerRadius(0)
        .outerRadius(this.radius() * 1.66);

      const circlePercent = this.percentChangeToOpacity(this.centerPercent * 100, maxP, minP);
      const circle = svg
        .append('circle')
        .attr('x', this.pieWidth() / 2)
        .attr('y', this.pieHeight() / 2)
        .attr('r', Math.max(this.innerRadius() - 3, 1))
        .attr('fill', this.percentChangeToColor(this.centerPercent))
        .attr('opacity', circlePercent);

      const middleText = svg
        .append('text')
        .attr('fill', '#fff')
        .text(this.centerText + ' ' + this.formatPercent(this.centerPercent * 100))
        .style('text-anchor', 'middle')
        .style('font-size', '12px');

      if (this.canClickBack) {
        circle.on('click', this.back);
        middleText.on('click', this.back);
        circle.style('cursor', 'pointer');
        middleText.style('cursor', 'pointer');

        const mouseOverCircle = function () {
          circle.style('opacity', circlePercent * 0.85).style('transform', 'scale(0.99)');
        };

        const mouseLeaveCircle = function () {
          circle.style('opacity', circlePercent).style('transform', 'scale(1)');
        };

        circle.on('mouseover', mouseOverCircle);
        circle.on('mouseleave', mouseLeaveCircle);
        middleText.on('mouseover', mouseOverCircle);
        middleText.on('mouseleave', mouseLeaveCircle);
      }

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      const arc = svg
        .selectAll('whatever')
        .data(data_ready)
        .enter()
        .append('path')
        .attr(
          'd',
          d3
            .arc()
            .innerRadius(this.innerRadius()) // This is the size of the donut hole
            .outerRadius(this.radius())
        )
        .attr('fill', function (d) {
          return d.data.value.color;
        })
        .attr('fill-opacity', function (d) {
          return d.data.value.opacity;
        })
        .attr('stroke', '#0f172a')
        .style('stroke-width', '0px')
        .style('opacity', 0.7)
        .on('click', this.next);

      // add text
      const arcText = svg
        .selectAll('whatever')
        .data(data_ready)
        .enter()
        .append('text')
        .text(d => {
          if (Math.abs(d.startAngle - d.endAngle) < Math.PI / 16) {
            return '';
          }

          return `${this.labelText(d.data.key)} ${this.formatPercent(d.data.value.percent)}`;
        })
        .attr('transform', function (d) {
          return 'translate(' + arcGenerator.centroid(d) + ')';
        })
        .attr('fill', '#fff')
        .style('text-anchor', 'middle')
        .style('font-size', '12px')
        .attr('dy', 0)
        .call(this.wrapHelper, 190);

      if (this.canClickDown) {
        arc.on('click', this.next);
        arc.style('cursor', 'pointer');
        arcText.on('click', this.next);
        arcText.style('cursor', 'pointer');

        const self = this;
        arc.on('mouseover', function (mousehover) {
          d3.select(this).style('opacity', '0.85').style('transform', 'scale(0.99)');
          // we use settimeout to make sure that this comes after the mouseleave event
          setTimeout(() => {
            self.selectedTooltip = mousehover;
          }, 0);
        });

        arc.on('mouseleave', function () {
          d3.select(this).style('opacity', '1').style('transform', 'scale(1)');
          self.selectedTooltip = undefined;
        });
      }
    },
    drawTooltip() {
      const d = this.selectedTooltip;
      const showTooltip = Math.abs(d.startAngle - d.endAngle) < Math.PI / 16 || this.canBeTruncated(d.data.key);

      if (!showTooltip) {
        return;
      }

      const arcGenerator = d3
        .arc()
        .innerRadius(0)
        .outerRadius(this.radius() * 1.66);

      const center = arcGenerator.centroid(this.selectedTooltip);

      const formatted = this.formatPercent(d.data.value.percent);

      this.svg
        .append('text')
        .attr('x', center[0])
        .attr('y', center[1] - 15)
        .attr('dy', 0)
        .attr('id', 'tooltip-text')
        .text(`${d.data.key} ${formatted}`)
        .attr('fill', '#fff')
        .style('text-anchor', 'left')
        .style('font-size', '12px')
        .call(this.wrapHelper, 190, center[0]);
    },
    clearTooltip() {
      this.svg.select('#tooltip').remove();
      this.svg.select('#tooltip-text').remove();
    },
    wrapHelper(text, width, x) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr('y'),
          dy = parseFloat(text.attr('dy')),
          tspan = text
            .text(null)
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', dy + 'em');
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = text
              .append('tspan')
              .attr('x', x || 0)
              .attr('y', y)
              .attr('dy', ++lineNumber * lineHeight + dy + 'em')
              .text(word);
          }
        }
      });
    },
    next(d) {
      this.$emit('next', d.data.value);
    },
    back() {
      this.$emit('back');
    },
    onMouseUp() {
      setTimeout(() => {
        this.drawPie();
      }, 10);
    },
    truncatedText(text, length) {
      return text.substring(0, length) + '...';
    },
    labelText(text) {
      return this.canBeTruncated(text) ? this.truncatedText(text, this.truncateLength) : text;
    },
    canBeTruncated(text) {
      return this.truncateLength != -1 && text.length > this.truncateLength;
    }
  },
  watch: {
    selectedTooltip() {
      if (this.selectedTooltip) {
        this.drawTooltip();
      } else {
        this.clearTooltip();
      }
    },
    data() {
      this.drawPie();
    },
    centerText() {
      this.drawPie();
    },
    canClickBack() {
      this.drawPie();
    },
    canClickDown() {
      this.drawPie();
    },
    metric() {
      this.drawPie();
    }
  },
  computed: {
    truncateLength() {
      if (this.widgetWidth >= 500) {
        return -1;
      } else if (this.widgetWidth >= 300) {
        return 15;
      } else if (this.widgetWidth >= 100) {
        return 10;
      } else {
        return 5;
      }
    }
  }
};
</script>
