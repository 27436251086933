import axios from 'axios';

export async function getTrendingFollows(params) {
  try {
    let response = await axios.get('/apefluencer', {
      params: {
        ...params,
        type: 'trending'
      }
    });
    return response.data;
  } catch {
    return [];
  }
}

export async function getRecentFollows(params) {
  try {
    let response = await axios.get('/apefluencer', {
      params: {
        ...params,
        type: 'recent'
      }
    });
    return response.data;
  } catch {
    return [];
  }
}

export async function getCategoriesList() {
  try {
    let response = await axios.get('/apefluencer', {
      params: {
        type: 'categories'
      }
    });
    return response.data;
  } catch {
    return [];
  }
}

export async function getAccountsList() {
  try {
    let response = await axios.get('/apefluencer_accounts');
    return response.data;
  } catch {
    return [];
  }
}

export function getProfileTooltipContent(profile) {
  return `<div class="p-1 space-y-2">
        <div class="flex items-center space-x-2">
          <img class="rounded-full w-8 h-8" src="${profile.profile_image_url_https}" alt="${profile.screen_name}">
          <div class="flex flex-col">
            <span class="block text-sm whitespace-nowrap truncate font-semibold text-gray-100">${profile.name}</span>
            <span class="block text-xs whitespace-nowrap font-semibold text-gray-400">@${profile.screen_name}</span>
          </div>
        </div>
        <p class="text-xs text-gray-100 max-w-sm break-words">
          ${profile.description}
        </p>
      </div>`;
}

export function getFollowersTooltipContent(followers) {
  let html = ['<div class="flex flex-col space-y-2 p-1">'];
  followers.slice(0, 25).forEach(x => {
    html.push(`<div class="flex items-center space-x-2">
                <img class="rounded-full w-4 h-4" src="${x.profile_image_url_https}" alt=""/>
                <div class="flex flex-wrap items-center">
                  <span class="block text-xs whitespace-nowrap font-semibold text-gray-100 mr-1">${x.name}</span>
                  <span class="block text-xxs font-semibold text-gray-400">@${x.screen_name}</span>
                </div>
              </div>`);
  });
  if (followers.length > 25) {
    html.push(`<div class"text-gray-100 text-xs"> & ${followers.length - 25} others </div>`);
  }
  html.push('</div>');
  return html.join('');
}
