<template>
  <BaseDialog :model-value="show" size-class="!max-w-lg" @update:model-value="$emit('close')">
    <template #header>Create New Chat </template>
    <template #default>
      <div class="mb-4 flex flex-col gap-1">
        <label for="name" class="text-xs font-medium leading-4 text-gray-100"> Name Chat</label>
        <BaseTextbox id="name" v-model="channelName" maxlength="64" />
        <span v-if="channelNameError.length > 0" class="mt-0.5 text-sm text-red-400">
          {{ channelNameError }}
        </span>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-end space-x-2 text-sm font-medium leading-5">
        <BaseButton type="secondary" @press="$emit('close')">Cancel</BaseButton>
        <BaseButton type="primary" @press="createChannel" :busy="buttonBusy"> Create </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script setup>
import { ref, computed, watch, onMounted, inject } from 'vue';
import { useMapGetter } from '@/store/map-state';
import { useStore } from 'vuex';
import notify from '@/composeables/notify';
import { saveNotificationPreference, getColor } from '@/composeables/chat';
const store = useStore();
const { channels, client, streamUser, streamUsers } = useMapGetter();
const $emit = defineEmits(['save', 'close']);
const channelName = ref('');
const buttonBusy = ref(false);
const users = ref([]);
const channelNameError = ref('');
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  alertDetail: { type: Object, default: null }
});

onMounted(() => {
  if (props.alertDetail?.name?.length) {
    channelName.value = props.alertDetail.name;
  } else if (props.alertDetail?.entity) {
    channelName.value = props.alertDetail.entity?.title;
  }
  users.value = streamUsers.value.map(x => {
    return { ...x, selected: false };
  });
});

//computed
const selectedUsers = computed(() => {
  return users.value.filter(x => x.selected);
});

const validLayout = computed(() => {
  return channelName.value.trim().length > 0;
});

// methods
async function createChannel() {
  try {
    if (channelName.value.trim().length === 0) {
      channelNameError.value = 'Please provide channel name.';
      return;
    } else if (
      channels.value.some(x => x.data.name && x.data.name.toLowerCase() === channelName.value.trim().toLowerCase())
    ) {
      channelNameError.value = 'Channel with this name already exists.';
      return;
    } else {
      buttonBusy.value = true;
      let id = Math.random().toString(16).slice(2);
      let channel_id = [channelName.value.replace(/[^\w]/g, '').substring(0, 64 - id.length - 1), id].join('_');

      let selectedTeamMembers = selectedUsers.value.filter(user =>
        user.teams ? user.team_id == streamUser.value.team_id : false
      );

      selectedTeamMembers.push(client.value.user.id);
      const channel = client.value.channel('messaging', channel_id, {
        team: 'messaging',
        name: channelName.value.trim(),
        members: selectedTeamMembers,
        background_color: getColor(),
        channel_admins: [client.value.user.id],
        message_history_access: false,
        add_member_access: false
      });

      await channel.watch();
      saveNotificationPreference([client.value.user.id], channel, 'all');

      let s_channel = channels.value.find(x => x.cid === channel.cid);

      if (!s_channel) {
        store.dispatch('addStreamChannel', s_channel);
      }

      notify(
        {
          group: 'app',
          type: 'flash',
          text: {
            type: 'success',
            message: 'New Channel Created'
          }
        },
        store.getters.shortNotification
      );

      $emit('save', channel);
    }
  } catch (error) {
    notify(
      {
        group: 'app',
        type: 'flash',
        text: {
          type: 'error',
          message: 'Error getting while Creating Channel'
        }
      },
      store.getters.shortNotification
    );
  }

  buttonBusy.value = false;
  $emit('close');
}
watch(
  () => channelName,
  () => {
    if (channelName.value) {
      channelNameError.value = '';
    }
  }
);
</script>
