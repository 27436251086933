<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="border-l-2border-blue-600 bg-gray-800/50">
    <ModalReduceCoins
      :coins="coinsToReduce"
      :show="showRecudedCoins"
      @close="showRecudedCoins = !showRecudedCoins"
      @reduced="reducedCoins"
      message="There are too many coins in this category to chart at once. Please select a maximum of 5 coins to chart in the table below, or filter based on the metrics below. "
    />
    <div class="flex px-3 py-2">
      <span class="text-xs font-medium">{{ source.name }}</span>
      <div class="ml-auto flex items-center justify-center space-x-1 text-xs font-semibold text-gray-400">
        <div @click="emit('remove')" class="cursor-pointer text-xs font-semibold text-gray-400 hover:text-gray-200">
          <IconX class="h-3.5 w-3.5" />
        </div>
      </div>
    </div>
    <div class="space-y-2">
      <div>
        <div class="flex items-center justify-between px-3" v-if="source.type === 'watchlist'">
          <span class="block text-gray-400">Watchlist:</span>
          <BaseSelect
            :searchable="true"
            :options="uidOptions"
            :model-value="source.uid"
            button-width-class="w-40 ml-3 truncate"
            dropdown-width-class="w-40 ml-3"
            @update:model-value="setSourceUid"
          />
        </div>
      </div>

      <div class="flex items-center justify-between px-3" v-if="['coin', 'category'].includes(source.type)">
        <span class="text-gray-400">{{ selectedSourceName }}:</span>
        <BaseSelect
          :searchable="true"
          :multiple="true"
          :options="uidOptions"
          :model-value="selectedUids"
          button-width-class="w-40 ml-3 truncate"
          dropdown-width-class="w-40 ml-3"
          @update:model-value="setCoinUid"
        />
      </div>

      <div class="flex items-center justify-between px-3">
        <span class="text-gray-400">Metrics:</span>
        <BaseSelect
          :searchable="true"
          :multiple="true"
          :options="metricsOptions"
          :model-value="selectedMetrics"
          button-width-class="w-40 ml-3 truncate"
          dropdown-width-class="w-40 ml-3"
          @update:model-value="setSourceMetric"
        />
      </div>
      <div class="flex items-center justify-between px-3" v-if="showOnChainOptions">
        <span class="text-gray-400">Chain:</span>
        <BaseSelect
          :options="chainOptions"
          :model-value="selectedChain"
          button-width-class="w-40 ml-3 truncate"
          dropdown-width-class="w-40 ml-3"
          @update:model-value="setSourceChain"
        />
      </div>
      <div v-if="source.type === 'watchlist' || source.type === 'category'">
        <div class="flex items-center justify-between px-3">
          <span class="text-gray-400">Display Mode:</span>
          <BaseSelect
            :options="displayModeOptions"
            :model-value="source.displayMode"
            button-width-class="w-40 ml-3 truncate"
            dropdown-width-class="w-40 ml-3"
            @update:model-value="setDisplayMode"
          />
        </div>
      </div>

      <div class="mb-2">
        <div class="flex items-center justify-between px-3">
          <span class="text-gray-400">Type:</span>
          <BaseSelect
            :options="typeOptions"
            :model-value="typeId"
            button-width-class="w-40 ml-3 truncate"
            dropdown-width-class="w-40 ml-3"
            @update:model-value="setType"
          />
        </div>
      </div>

      <div class="flex items-center justify-between px-3 pb-3">
        <span class="text-gray-400">Axis Mode:</span>
        <BaseToggle background v-model="source.relative" @update:model-value="toggleSourceRelative">
          <template #left>Absolute</template>
          <template #right>Relative</template>
        </BaseToggle>
      </div>
    </div>
  </div>
</template>

<script setup>
import ModalReduceCoins from '../modal/ModalReduceCoins.vue';
import useEmitter from '../../composeables/emitter';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
const emit = defineEmits(['change', 'remove']);
const $store = useStore();
const emitter = useEmitter();
const props = defineProps({
  source: Object,
  metricOptions: {
    type: Array,
    default: () => []
  }
});
const showRecudedCoins = ref(false);
const collapsed = ref(false);
const sourceTypeOptions = ref([
  { id: 'coin', name: 'Coin', label: 'Coins' },
  { id: 'watchlist', name: 'Watchlist', label: 'Watchlist' },
  { id: 'category', name: 'Category', label: 'Category' }
]);
const displayModeOptions = ref([
  { id: 'indexed', name: 'Indexed', label: 'Indexed' },
  { id: 'individual', name: 'Individual', label: 'Individual' }
]);
const typeOptions = ref([
  { id: 'line', name: 'Line', label: 'Line' },
  { id: 'area', name: 'Area', label: 'Area' },
  { id: 'column', name: 'Bar', label: 'Bar' }
]);
function toggleSourceRelative() {
  emit('change', {
    ...props.source,
    relative: props.source.relative
  });
}
function setType(newSourceTypeId) {
  emit('change', {
    ...props.source,
    chartType: newSourceTypeId
  });
}
function setSourceUid(newSourceTypeId) {
  emit('change', {
    ...props.source,
    uid: newSourceTypeId
  });
}
function setDisplayMode(newSourceDisplayModeId) {
  if (newSourceDisplayModeId === 'individual' && coinsToReduce.value.length > 15) {
    showRecudedCoins.value = true;
    return;
  }
  emit('change', {
    ...props.source,
    displayMode: newSourceDisplayModeId
  });
}
function setSourceMetric(newSourceTypeIds) {
  emit('change', {
    ...props.source,
    metric: newSourceTypeIds
  });
}
function setCoinUid(newSourceTypeIds) {
  emit('change', {
    ...props.source,
    uids: newSourceTypeIds
  });
}
function reducedCoins(coins) {
  showRecudedCoins.value = false;
  emit('change', {
    ...props.source,
    reducedCoins: coins,
    displayMode: 'individual'
  });
}
const metricsOptions = computed(() => {
  return props.source.type === 'coin'
    ? replicaMetricOptions
    : replicaMetricOptions.filter(a => {
        return !a.onlyCoin;
      });
});

// onChainMetrics base Chain Selection
const onChainMetricIds = ref([
  'num_inc_24',
  'num_dec_24',
  'num_txs_24h',
  'num_active_addrs_24h',
  'num_inc_30d_avg',
  'num_dec_30d_avg',
  'num_tx_30d_avg',
  'num_active_addrs_30d_avg'
]);
const chainOptions = ref([
  { id: 'ethereum', label: 'Ethereum' },
  { id: 'avalanche', label: 'Avalanche' },
  { id: 'polygon', label: 'Polygon' }
]);
const showOnChainOptions = computed(() => {
  let result = onChainMetricIds.value.filter(id => selectedMetrics.value?.includes(id))?.length > 0;
  // if (!props.source?.chain && result) setSourceChain('ethereum');
  return result;
});
const selectedChain = ref(props.source?.chain || 'ethereum');
function setSourceChain(newSourceChainId) {
  selectedChain.value = newSourceChainId;
  emit('change', {
    ...props.source,
    chain: newSourceChainId
  });
}
watch(
  () => props.source.chain,
  () => {
    selectedChain.value = props.source.chain ? props.source.chain : 'ethereum';
  },
  { immediate: true, deep: true }
);
function getCoinUidsFromWatchlistUid(uid) {
  const watchlist = $store.getters.watchlists.coins.find(w => {
    return w?.id?.toString() === uid?.toString();
  });
  if (!watchlist) {
    return [];
  }
  return watchlist.entities.map(entity => {
    return entity.uid;
  });
}
function getCoinUidsFromCategoryUid(uids) {
  const coins = [];
  const cat_ids = [];
  const subcat_ids = [];
  uids.forEach(a => {
    if (a.split('__')[0] == 'cat') {
      cat_ids.push(parseInt(a.split('__')[1]));
    } else {
      subcat_ids.push(parseInt(a.split('__')[1]));
    }
  });
  const uidNum = subcat_ids;
  if (cat_ids.length > 0) {
    ($store.state.categories.coin_sub_categories || []).forEach(x => {
      if (cat_ids.includes(parseInt(x.category_id))) uidNum.push(x.id);
    });
  }
  $store.getters.coins.forEach(coin => {
    uidNum.forEach(id => {
      if ((coin.category_ids || []).includes(parseInt(id))) {
        coins.push(coin.uid);
      }
    });
  });
  return coins.filter((v, i, a) => a.indexOf(v) === i);
}
const coinsToReduce = computed(() => {
  if (props.source.type !== 'watchlist' && props.source.type !== 'category') {
    return;
  }
  return props.source.type === 'watchlist'
    ? getCoinUidsFromWatchlistUid(props.source.uid)
    : getCoinUidsFromCategoryUid(props.source.uids);
});
const selectedSourceName = computed(() => {
  return sourceTypeOptions.value.find(s => props.source.type === s.id).name;
});
const displayModeName = computed(() => {
  return displayModeOptions.value.find(s => props.source.displayMode === s.id).name;
});
const typeName = computed(() => {
  return typeOptions.value.find(s => props.source.chartType === s.id)?.name;
});
const typeId = computed(() => {
  return typeOptions.value.find(s => props.source.chartType === s.id)?.id;
});
const selectedUidName = computed(() => {
  const uid = uidOptions.value.find(s => props.source.uid === s.id);
  return uid ? uid.name : '';
});
const replicaMetricOptions = props.metricOptions.map(mp => ({ ...mp, label: mp.name }));
const selectedMetrics = computed(() => {
  return props.source.metric.map(metricId => {
    const metric = replicaMetricOptions.find(s => metricId === s.id);
    return metricId;
  });
});
const selectedUids = computed(() => {
  if (props.source.type == 'coin') {
    return props.source.uids.map(coinUid => {
      const coin = $store.getters.coins.find(coin => {
        return coin.uid === coinUid;
      });
      return coin?.uid;
    });
  } else {
    return props.source.uids.map(coinUid => {
      const uid = uidOptions.value.find(s => coinUid == s.id);
      return coinUid;
    });
  }
});
const uidOptions = computed(() => {
  if (props.source.type === 'coin') {
    return $store.getters.coins.map(coin => {
      return {
        id: coin?.uid,
        name: coin?.name,
        searchTerm: coin?.ticker,
        label: coin?.name
      };
    });
  }
  if (props.source.type === 'category') {
    const list = [];
    ($store.state.categories.coin_sub_categories || []).map(subCategory => {
      list.push({ id: `subcat__${subCategory.id}`, name: subCategory?.name, label: subCategory?.name });
    });
    ($store.state.categories.coin || []).forEach(category => {
      list.push({ id: `cat__${category.id}`, name: category?.name, label: category?.name });
    });
    emitter.$emit('categoriesName', list);
    return list;
  }
  if (props.source.type === 'watchlist') {
    return $store.getters.watchlists.coins.map(watchlist => {
      return {
        name: watchlist?.name,
        id: watchlist?.id,
        label: watchlist?.name
      };
    });
  }
  return [];
});
</script>
