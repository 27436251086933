<template>
  <div>
    <label class="mb-1 mt-4 text-xs capitalize text-gray-400">Select Type</label>
    <BaseMultiSelect
      :classes="'text-xs'"
      :mode="'tags'"
      :close-on-select="false"
      :searchable="true"
      :hide-selected="false"
      :options="calendarFeeds"
      v-model="selectedFeedIds"
      :key="selectedFeedIds"
      :value-prop="'id'"
    />
  </div>
</template>

<script>
export default {
  name: 'WidgetConfigsCalendar',
  emits: ['update'],
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.fetchCalendarFeeds();
    if (this.config && this.config.feeds) {
      this.selectedFeedIds = this.config.feeds;
    }
  },
  data() {
    return {
      calendarFeeds: [],
      selectedFeedIds: []
    };
  },
  watch: {
    selectedFeedIds() {
      let config = {
        valid: false
      };
      if (this.selectedFeedIds.length > 0) {
        config.feeds = this.selectedFeedIds;
        config.valid = true;
      }
      this.$emit('update', config);
    }
  },
  methods: {
    fetchCalendarFeeds() {
      var self = this;
      this.$http.get('/calendar_events', { params: { only_feeds: true } }).then(response => {
        self.calendarFeeds = response.data.news_feeds.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });
    }
  }
};
</script>
