<template>
  <div class="cursor-pointer rounded-md border border-grid text-white" @click="toggleDropdown()" :id="dropdownId">
    <div class="flex items-center justify-between rounded-md bg-gray-800 py-1 px-4">
      <div class="my-px h-5 w-5 rounded leading-none" :style="`background: ${selectedColor}`"></div>
      <IconChevronDown :class="{ 'rotate-180': droppedDown }" class="transform duration-100 ease-in-out" />
    </div>
    <div class="relative z-50 text-left">
      <div
        v-if="droppedDown"
        class="absolute z-50 mt-1 max-h-96 select-none overflow-hidden overflow-y-auto rounded-md border border-gray-700 bg-gray-800 text-xs text-white shadow"
      >
        <div class="text-md block select-none bg-gray-800 py-2 px-3 capitalize text-gray-300">
          <div @click="colorSelected(defaultColor)" class="group flex cursor-pointer items-center space-x-2 py-2">
            <div
              :class="{ 'border border-gray-100': defaultColor == selectedColor }"
              class="h-5 w-5 cursor-pointer rounded group-hover:border group-hover:border-gray-400"
              :style="`background: ${defaultColor}`"
            ></div>
            <span class="block text-gray-400 group-hover:text-gray-100">Default</span>
          </div>
          <div class="grid grid-cols-5 gap-2">
            <div
              v-for="color in suggestedColors"
              :key="color"
              @click="colorSelected(color)"
              :class="{ 'border border-gray-100': color == selectedColor }"
              class="h-5 w-5 cursor-pointer rounded hover:border hover:border-gray-400"
              :style="`background: ${color}`"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DropdownSelectColor',
  props: {
    activeItem: {
      type: String,
      default: '#60A5FA'
    }
  },
  mounted() {
    this.selectedColor = this.activeItem;
    document.addEventListener('click', this.close);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.close);
  },
  watch: {
    activeItem() {
      this.selectedColor = this.activeItem;
    }
  },
  data() {
    return {
      dropdownId: `simple_dropdown_${Math.random()}`,
      droppedDown: false,
      selectedColor: '',
      defaultColor: '#0F172B',
      suggestedColors: [
        '#60A5FA',
        '#3B82F6',
        '#2563EB',
        '#1D4ED8',
        '#1E40AF',
        '#F87171',
        '#EF4444',
        '#DC2626',
        '#B91C1C',
        '#991B1B',
        '#34D399',
        '#10B981',
        '#059669',
        '#047857',
        '#065F46',
        '#FBBF24',
        '#F59E0B',
        '#D97706',
        '#845309',
        '#92400E',
        '#E879F9',
        '#D946EF',
        '#C026D3',
        '#A21CAF',
        '#86198F'
      ]
    };
  },
  methods: {
    close: function (e) {
      const drop = document.getElementById(this.dropdownId);
      if (!drop.contains(e.target) && this.droppedDown && e.target.id !== this.dropdownId) {
        this.droppedDown = false;
      }
    },
    toggleDropdown: function () {
      this.droppedDown = !this.droppedDown;
    },
    colorSelected: function (color) {
      this.selectedColor = color;
      this.$emit('selected', color);
    }
  }
};
</script>
