<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      :connectable="false"
      @expand="goToExpandLink"
      @paginate="handleScroll"
    >
      <template #body>
        <div v-if="height > 100" class="sticky top-0 left-0 flex items-center space-x-4 bg-gray-900 px-2 py-2">
          <div
            v-for="tf in timeframes"
            :key="tf.id"
            @click="setSelectedFilter(tf.id)"
            class="cursor-pointer rounded text-xs transition-all ease-linear"
            :class="tf.id === selectedTimeFrame ? 'font-semibold text-gray-100' : 'text-gray-400 hover:text-gray-100'"
          >
            {{ tf.title }}
          </div>
        </div>
        <table class="w-full text-xs font-medium text-gray-200">
          <tbody>
            <tr
              class="border-none"
              v-for="(mint, idx) in filteredMints"
              :key="idx"
              :class="idx % 2 == 0 ? 'bg-gray-800/25' : ''"
            >
              <td>
                <div class="flex items-center">
                  <a
                    :href="mint.openSeaCollectionData.externalLink"
                    target="_blank"
                    class="flex items-center hover:text-white"
                  >
                    <img
                      :src="mint.openSeaCollectionData.imageUrl"
                      class="inline h-5 w-5 rounded-full"
                      loading="lazy"
                    />
                    <div class="ml-2">
                      <span class="whitespace-nowrap text-xs font-semibold">
                        {{ truncate(mint.openSeaCollectionData.name, 20) }}
                        <IconOpen
                          class="mb-px inline h-3 w-3 text-gray-400"
                          v-if="mint.openSeaCollectionData.externalLink"
                        />
                      </span>
                      <div v-if="width <= 250" class="space-x-1">
                        <span class="whitespace-nowrap text-xs">{{ rounded(Number(mint.mints), 0) }} mints</span>
                        <span class="whitespace-nowrap text-xs text-gray-400">
                          {{ rounded(Number(mint.unique_minters), 0) }} minters
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </td>
              <td v-if="width >= 250" class="text-right text-xs">
                <span class="block whitespace-nowrap">{{ rounded(Number(mint.mints), 0) }} mints</span>
                <span v-if="width <= 400" class="block whitespace-nowrap text-xs text-gray-400">
                  {{ rounded(Number(mint.unique_minters), 0) }} minters
                </span>
              </td>
              <td v-if="width > 400" class="whitespace-nowrap text-xs text-gray-400">
                {{ rounded(Number(mint.unique_minters), 0) }} minters
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';
import FilterMixin from '@/mixins/filters';
export default {
  name: 'WidgetsTopMints',
  mixins: [WidgetsMixin, FilterMixin],
  mounted() {
    if (this.widget?.configuration && this.widget.configuration['filter']) {
      this.selectedTimeFrame = this.widget.configuration['filter'];
    }
    this.getTopMints();
  },
  data() {
    // min 3x5
    return {
      hoverActive: false,
      hoverItem: -1,
      selectedTimeFrame: '15min',
      topMints: [],
      topMintsAllData: [],
      timeframes: [
        {
          id: '15min',
          title: '15m'
        },
        {
          id: '30min',
          title: '30m'
        },
        {
          id: '1hr',
          title: '1h'
        },
        {
          id: '6hr',
          title: '6h'
        },
        {
          id: '12hr',
          title: '12h'
        },
        {
          id: '1day',
          title: '1d'
        }
      ],
      pagination: {
        page: 1,
        perPage: 50
      }
    };
  },
  computed: {
    filteredMints() {
      return this.topMints
        .filter(item => item.os_slug && item.collection_name)
        .map(x => {
          x.link = `https://opensea.io/collection/${x.os_slug}`;
          x.linkText = 'OpenSea';
          x.openSeaCollectionData = { externalLink: x.external_link, imageUrl: x.image_url, name: x.collection_name };
          return x;
        });
    }
  },
  watch: {
    selectedTimeFrame() {
      this.topMints = [];
      this.getTopMints();
    }
  },
  methods: {
    handleScroll: async function () {
      this.pagination.page++;
      this.topMints = this.topMintsAllData.slice(0, this.pagination.perPage * this.pagination.page);
    },
    showTooltip(idx) {
      this.hoverActive = true;
      this.hoverItem = idx;
    },
    hideTooltip() {
      this.hoverActive = false;
      this.hoverItem = -1;
    },
    goToExpandLink() {
      window.open('/nft/market/trends', '_blank');
    },
    updateConnectedChannel() {},
    async getTopMints() {
      const self = this;
      self.loading = true;
      try {
        let response = await this.$http.get('/top_mints', {
          params: {
            time_range: this.selectedTimeFrame
          }
        });
        self.topMintsAllData = response.data.result;
        self.topMints = self.topMintsAllData.slice(0, this.pagination.perPage);
      } catch (error) {
        self.topMints = [];
      } finally {
        self.loading = false;
      }
    },
    setSelectedFilter(filter) {
      this.selectedTimeFrame = filter;
      this.$http.patch(`/dashboard_widgets/${this.widget.id}`, { configuration: { filter: this.selectedTimeFrame } });
    }
  }
};
</script>
