<template>
  <div
    class="flex flex-col text-sm font-medium leading-4 text-gray-100"
    :class="customStyle ? 'space-y-4' : 'space-y-2'"
  >
    <BaseCheckbox v-model="settings.message_history_access"> New members are able to see message history </BaseCheckbox>
    <BaseCheckbox v-model="settings.add_member_access"> Members can add other members to the room </BaseCheckbox>
    <div v-if="externalteam" class="pt-5">
      <div class="flex items-center space-x-1 pb-3">
        <span class="text-xs font-semibold uppercase tracking-wider text-gray-400">Add Context</span>
        <span class="text-xs text-red-400"> {{ contextError }}</span>
      </div>
      <BaseTextbox v-model="settings.note" :required="true" />
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';
const props = defineProps({
  userSettings: Object,
  externalteam: {
    type: Boolean,
    default: false
  },
  customStyle: {
    type: String,
    default: null
  },
  contextError: {
    type: String,
    default: ''
  }
});
const $emit = defineEmits(['settings-updated']);

const settings = ref(props.userSettings);

watch(
  () => settings.value,
  (newVal, oldVal) => {
    $emit('settings-updated', newVal);
  },
  { deep: true }
);
</script>
