<template>
  <div v-if="imageObject" class="relative" :class="[sizeClasses]">
    <img
      v-if="imageObject.image"
      :src="imageObject.image"
      class="h-full w-full rounded-full shadow-inner"
      :class="imageObject.id == 'bot_user' ? 'border border-grid p-1' : 'bg-gray-700 object-cover'"
    />
    <div
      v-else
      class="flex h-full w-full items-center justify-center rounded-full bg-gray-700 font-semibold"
      :class="[style === '' ? 'text-gray-200' : 'text-gray-900', customClass]"
      :style="style"
    >
      <span v-if="channelName">{{ channelName[0].toUpperCase() }}</span>
      <span v-else-if="imageObject.name && imageObject.name[0] == '+'">{{ imageObject.name.toUpperCase() }}</span>
      <span v-else-if="imageObject.name">{{ imageObject.name[0].toUpperCase() }}</span>
    </div>
    <div
      class="absolute bottom-0 right-0"
      :class="positionClasses"
      v-if="!imageObject.cid && showOnline && imageObject.id != 'bot_user'"
    >
      <div
        :class="[imageObject.online ? 'bg-green-600' : 'bg-gray-600', statusClasses]"
        class="rounded-full border border-gray-900"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed, onUnmounted, inject } from 'vue';
import { getColor } from '@/composeables/chat';
import { useMapGetter } from '@/store/map-state';
const { client, streamUser } = useMapGetter();
const props = defineProps({
  item: Object,
  showOnline: {
    type: Boolean,
    default: false
  },
  channelName: String,
  customClass: String,
  textSize: {
    type: String,
    default: 'text-lg'
  },
  size: {
    type: String,
    default: 'sm'
  },
  isChannel: {
    type: Boolean,
    default: false
  }
});

const style = ref('');
const imageObject = ref(props.isChannel ? props.item.data : props.item);
const stateListener = ref(null);
const sizeClasses = ref(
  {
    xxs: 'h-4 w-4 text-xxs',
    xs: 'h-7 w-7 text-sm',
    sm: 'h-9 w-9 text-lg',
    md: 'h-10 w-10 text-xl',
    mdl: 'h-14 w-14 text-xl',
    lg: 'h-16 w-16 text-4xl',
    xl: 'h-20 w-20 text-4xl',
    xxl: 'h-36 w-36 text-7xl'
  }[props.size]
);
const statusClasses = ref(
  {
    xxs: 'h-1.5 w-1.5',
    xs: 'h-2 w-2',
    sm: 'h-2.5 w-2.5',
    md: 'h-2.5 w-2.5',
    mdl: 'h-3 w-3',
    lg: 'h-3 w-3 ',
    xl: 'h-4 w-4 border-2',
    xxl: 'h-6 w-6 border-2'
  }[props.size]
);
const positionClasses = ref(
  {
    xxs: '',
    xs: '',
    sm: '',
    md: '',
    lg: 'mr-0.5 mb-1',
    xl: 'mr-0.5 mb-1',
    xxl: 'mr-3 mb-3'
  }[props.size]
);

onMounted(() => {
  updateImageObject();
  if (props.isChannel) {
    props.item.on('channel.updated', () => {
      updateImageObject();
    });
    client.value.on('user.updated', async event => {
      let members = Object.values(props.item.state.members).map(x => x.user_id);
      if (members.includes(event.user.id)) {
        updateImageObject();
      }
    });
  }
  stateListener.value = client.value.on('user.presence.changed', event => {
    if (imageObject.value.id === event.user.id) {
      imageObject.value.online = event.user.online;
    }
  });
});

onUnmounted(() => {
  if (stateListener.value) {
    stateListener.value.unsubscribe();
  }
});

//computed
const image = computed(() => {
  return imageObject.value.image ? imageObject.value.image : imageObject.value.profile_image;
});

//methods
async function getBgColor() {
  if (imageObject.value.background_color) {
    return imageObject.value.background_color;
  } else if (props.isChannel && !imageObject.value.image && imageObject.value.cid) {
    const streamChannel = client.value.channel('messaging', imageObject.value.id);
    if (streamChannel) {
      await streamChannel.updatePartial({
        set: {
          background_color: getColor()
        }
      });
      return streamChannel.data.background_color;
    }
  }
}
function updateImageObject() {
  if (props.isChannel) {
    if (props.item.data.direct_messaging) {
      let channelMembers = Object.values(props.item.state.members);
      let member = channelMembers.filter(x => x.user_id !== streamUser.value.id);
      if (member.length > 0) {
        imageObject.value = member[0].user;
      }
    } else {
      imageObject.value = props.item.data;
    }
  } else if (imageObject.value.id === client.value.user.id) {
    imageObject.value = client.value.user;
  } else {
    imageObject.value = props.item;
  }
  getBgColor().then(color => {
    if (color) {
      style.value = 'background-color: ' + color;
    }
  });
}
watch(
  () => props.item,
  (newVal, oldVal) => {
    updateImageObject();
  }
);
</script>
