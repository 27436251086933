<template>
  <Teleport to="body">
    <div
      v-if="image"
      class="absolute top-0 bottom-0 left-0 right-0 z-[100] h-full w-full"
      style="background: hsl(0, calc(var(--saturation-factor, 1) * 0%), 0%); opacity: 0.85"
      @click="store.dispatch('setImageForPreview', null)"
    ></div>
    <div v-if="image" class="absolute top-0 bottom-0 left-0 right-0 z-[100] my-auto h-full w-full">
      <div
        class="flex h-full flex-col items-center justify-center"
        @click.self="store.dispatch('setImageForPreview', null)"
      >
        <div style="max-width: 75%">
          <img :src="image" alt="" class="max-h-104" />
          <a :href="image" target="_blank" class="mt-1 text-xs text-gray-400 underline">Open Original</a>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
const store = useStore();

const imageHeight = ref(0);
const imageWidth = ref(0);
const image = computed(() => {
  return store.state.previewImage;
});
watch(
  () => image.value,
  (newVal, oldVal) => {
    if (image.value) {
      let img = new Image();
      img.addEventListener('load', function () {
        imageWidth.value = img.naturalWidth;
        imageHeight.value = img.naturalHeight;
      });
      img.src = image.value;
    }
  }
);
</script>
