<template>
  <div :id="randomId" class="h-full w-full" v-if="yAxisSeries.length"></div>
</template>
<script>
import FilterMixin from '@/mixins/filters';
import Highcharts from 'highcharts/highcharts';
//this is work in progress
window.Highcharts = Highcharts;
import moment from 'moment';
import global from '@/mixins/global';
import colors from 'tailwindcss/colors';
import { downloadToCSV } from '@/util/csvDownload';

import * as jp from 'jsonpath';
Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E']
  }
});
export default {
  name: 'WidgetCustomTemplatesLineChart',
  mixins: [global, FilterMixin],
  mounted() {
    const self = this;
    if (this.yAxisSeries.length > 0) {
      self.generateChart();
    }
    this.$eventHub.$on(`download_${this.widget.id}`, () => {
      this.downloadData();
    });
    self.$eventHub.$on('reflow-highchart', function () {
      self.$nextTick(() => {
        self.chartObj.reflow();
      });
    });
  },
  beforeUnmount() {
    this.$eventHub.$off('reflow-highchart');
  },
  data() {
    return {
      randomId: Math.random().toString(),
      waterMarkFontSize: 16,
      chartObj: undefined,
      colorScheme: [
        { id: 'red', colors: ['#F87171', '#EF4444', '#DC2626', '#B91C1C', '#991B1B'] },
        { id: 'blue', colors: ['#60A5FA', '#3B82F6', '#2563EB', '#1D4ED8', '#1E40AF'] },
        { id: 'green', colors: ['#34D399', '#10B981', '#059669', '#047857', '#065F46'] },
        { id: 'orange', colors: ['#FBBF24', '#F59E0B', '#D97706', '#845309', '#92400E'] },
        { id: 'magenta', colors: ['#E879F9', '#D946EF', '#C026D3', '#A21CAF', '#86198F'] }
      ]
    };
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    dataSet: {
      type: [Object, Array],
      default: () => []
    }
  },

  watch: {
    config: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.generateChart();
        });
      }
    },
    dataSet: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.generateChart();
        });
      }
    }
  },

  computed: {
    chartType() {
      let type = 'line';
      let configType = this.widget.widget_template.default_configuration.config.type;
      if (configType) {
        type = configType;
        if (configType == 'stack') {
          type = 'column';
        }
      }
      return type;
    },
    plotColumnStacking() {
      return this.config.dataSeries.some(d => d.type && d.type == 'stack') || false;
    },
    reverseSeries() {
      if (this.xAxisSeries && this.xAxisSeries.format != 'text' && this.xAxisSeries.data) {
        let firstPoint = this.xAxisSeries.data[0];
        let secondPoint = this.xAxisSeries.data[1];
        if (this.xAxisSeries.format == 'datetime') {
          firstPoint = new Date(firstPoint);
          secondPoint = new Date(secondPoint);
        }
        return firstPoint > secondPoint;
      }
      return false;
    },
    config() {
      return this.widget.widget_template.default_configuration.config.chartConfigs;
    },
    xAxisSeries() {
      let xAxisAttribute = this.config.xAxis.attribute;
      let data = xAxisAttribute != '' ? jp.query(this.dataSet, xAxisAttribute) : [];
      let format = this.config.xAxis.format;
      if (format == 'datetime') {
        data = data.map(x => new Date(x).getTime());
      }
      return {
        title: this.config.xAxis.title,
        data: data,
        format: format
      };
    },
    xAxisSeriesReverse() {
      let copy = [...this.xAxisSeries.data];
      return this.reverseSeries ? copy.reverse() : this.xAxisSeries.data;
    },
    yAxisSeries() {
      let series = [];
      for (var i = 0; i < this.config.dataSeries.length; i++) {
        let dSeries = this.config.dataSeries[i];
        let seriesData = dSeries.attribute != '' ? jp.query(this.dataSet, dSeries.attribute) : [];
        seriesData = seriesData.map(x => {
          return parseFloat(x) != 0 ? parseFloat(x) : x;
        });
        if (dSeries.label && seriesData.length > 0) {
          if (dSeries.type == 'pie') {
            let xAxisAttribute = this.config.xAxis.attribute;
            let names = xAxisAttribute != '' ? jp.query(this.dataSet, xAxisAttribute) : [];

            let dSeries = this.config.dataSeries[0];
            let values = dSeries.attribute != '' ? jp.query(this.dataSet, dSeries.attribute) : [];
            seriesData = names.map((x, index) => {
              return { name: x, y: parseFloat(values[index]) };
            });
          }
          series.push({
            align: this.config.yAxis[i] ? this.config.yAxis[i].align : 'left',
            name: this.config.yAxis[i] ? this.config.yAxis[i].name : '',
            axisTitle: dSeries.label,
            format: this.config.yAxis[i] ? this.config.yAxis[i].format : 'large_number',
            type: dSeries.type ? (dSeries.type == 'stack' ? 'column' : dSeries.type) : this.chartType,
            data: seriesData,
            color: dSeries.color,
            colors: this.seriesColor(dSeries.color),
            yAxis: dSeries.axis,
            visible: true
          });
        }
      }
      return series;
    },
    chartConfig() {
      const self = this;
      let xAxisData = self.xAxisSeriesReverse;
      return {
        chart: {
          reflow: true,
          backgroundColor: 'transparent',
          marginTop: 20,
          zoomType: '',
          events: {
            load: function () {
              // self.addWatermark(this);
            }
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          // type: 'datetime',
          title: {
            text: this.xAxisSeries.title,
            style: {
              color: colors.gray[200],
              fontSize: '10px'
            }
          },
          lineColor: colors.gray[600],
          visible: true,
          categories: xAxisData,
          datetime: this.xAxisSeries.format != 'text',
          labels: {
            enabled: true,
            style: {
              color: colors.gray[200],
              fontSize: '10px',
              fontWeight: 'bold'
            },
            formatter:
              this.xAxisSeries.format != 'text'
                ? function () {
                    return window.Highcharts.dateFormat(
                      '%b %e',
                      this.value?.toString().length > 12 ? this.value : this.value * 1000
                    );
                  }
                : null
          },
          lineWidth: 0,
          tickLength: 0
        },
        yAxis: [],
        title: {
          text: '',
          style: {
            fontWeight: 'bold',
            color: colors.gray[200],
            fontSize: '14px'
          }
        },
        legend: {
          enabled: self.config.legendStatus != 'none' ? true : false,
          align: ['top', 'bottom'].includes(this.config.legendStatus) ? 'center' : this.config.legendStatus,
          verticalAlign: ['top', 'bottom'].includes(this.config.legendStatus) ? this.config.legendStatus : 'middle',
          itemHoverStyle: {
            color: colors.gray[200]
          },
          layout: ['top', 'bottom'].includes(this.config.legendStatus) ? 'horizontal' : 'vertical',
          itemStyle: {
            color: colors.gray[400],
            fontWeight: '400',
            fontSize: '10px'
          }
        },
        tooltip: {
          shared: true,
          useHTML: true,
          backgroundColor: colors.gray[900],
          style: {
            color: 'white',
            pointerEvents: 'auto'
          },
          borderWidth: 0,
          formatter: function () {
            let title = this.x;
            if (self.xAxisSeries.format != 'text') {
              title = title?.toString().length > 12 ? title : title * 1000;
              title = moment.utc(title).format('MMM DD, YYYY hh:mm:ss');
            }
            const tipHtml = `<b>${title}</b><br/>`;
            const fromattedPnts = this.points.map(point => {
              const pointSymb = this.points.length === 1 ? '' : `<span style="color:${point.color}">●</span>`;
              let roundTo = point.y > 1 ? 2 : 6;
              return (
                pointSymb +
                `<b> ${point.series.name}:</b>
                <span style="color:${point.color}; margin-left: 0.5rem;">${self.formatNumber(point.y, roundTo)}</span>`
              );
            });
            return tipHtml + fromattedPnts.join('<br/>');
          }
        },
        series: [],
        plotOptions: {
          column: {
            stacking: self.plotColumnStacking ? 'normal' : undefined,
            pointPadding: 0.2,
            borderWidth: 0
          },
          line: {
            fillOpacity: 0.5,
            animation: false,
            marker: {
              enabled: false
            }
          },
          pie: {
            opacity: 0.9,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              useHTML: true,
              color: 'white',
              formatter: function () {
                let title = this.key;
                let roundTo = this.point.y > 1 ? 2 : 6;
                const fromattedPnts = `
                <span><b>${title}</b><br/> ${self.formatNumber(this.point.y, roundTo)}</span>`;
                return fromattedPnts + '<br/>';
              }
            },
            showInLegend: true,
            borderWidth: 0,
            shadow: false
          },
          scatter: {
            animation: false,
            marker: {
              radius: 4
            }
          }
        },
        exporting: {
          enabled: false
        }
      };
    }
  },
  methods: {
    seriesColor(dSeriescolor) {
      let arrangeColorScheme = [];
      // Find selected color scheme and set selected color on top
      this.colorScheme.map(scheme => {
        scheme.colors.map((color, index) => {
          if (color == dSeriescolor) {
            scheme.colors.splice(0, 1, scheme.colors.splice(index, 1, scheme.colors[0])[0]);
            arrangeColorScheme.push(scheme);
          }
        });
      });
      // Re-arrange color scheme as per selected
      this.colorScheme.map(x => {
        if (x.id != arrangeColorScheme[0]?.id) {
          arrangeColorScheme.push(x);
        }
      });
      return arrangeColorScheme
        .map(x => {
          return x.colors;
        })
        .flat();
    },
    yAxisSeriesReverse(yAxisData) {
      let copy = [...yAxisData];
      return this.reverseSeries ? copy.reverse() : yAxisData;
    },
    generateChart() {
      const self = this;
      let config = self.chartConfig;
      let yAxisConfigs = [];
      let chartSeries = [];
      let defaultyAxisOptions = {
        visible: true,
        gridLineColor: colors.gray[800],
        lineColor: colors.gray[600],
        scaleShowLabels: true,
        title: {
          text: '',
          style: {
            color: colors.gray[300],
            fontSize: '10px'
          }
        },
        labels: {
          enabled: true,
          style: {
            color: colors.gray[200],
            fontSize: '10px',
            fontWeight: 'bold'
          }
          // need improvements
          // formatter: function () {
          //   return this.value / 1000 < 1 ? this.value + 'M' : this.value / 1000 + 'B';
          // }
        }
      };
      for (var i = 0; i < self.yAxisSeries.length; i++) {
        let axisConfig = JSON.parse(JSON.stringify(defaultyAxisOptions));
        axisConfig.opposite = self.yAxisSeries[i].align == 'right';
        axisConfig.title.text = self.yAxisSeries[i].name;
        axisConfig.visible = true;
        if (self.yAxisSeries[i].format == 'number') {
          axisConfig.labels.formatter = function () {
            return self.decimals(this.value, 2, this.value > 1 ? 2 : 6);
          };
        } else if (self.yAxisSeries[i].format == 'percent') {
          axisConfig.labels.formatter = function () {
            return self.decimals(this.value, 2, 2) + '%';
          };
        } else if (self.yAxisSeries[i].format == 'usd') {
          axisConfig.labels.formatter = function () {
            return self.usdCryptoPrice(this.value);
          };
        } else {
          axisConfig.labels.formatter = function () {
            return self.formatNumber(this.value);
          };
        }
        let axisSeries = {
          name: self.yAxisSeries[i].axisTitle,
          data: self.yAxisSeriesReverse(self.yAxisSeries[i].data),
          color: self.yAxisSeries[i].color,
          colors: self.yAxisSeries[i].colors,
          type: self.yAxisSeries[i].type,
          yAxis: self.yAxisSeries[i].yAxis
        };

        yAxisConfigs.push(axisConfig);
        chartSeries.push(axisSeries);
      }
      config.yAxis = yAxisConfigs;
      config.series = chartSeries;
      if (config.series && config.series.length > 0) {
        self.chartObj = window.Highcharts.chart(self.randomId, config);
        self.$emit('chart', self.chartObj);
      }
    },
    addWatermark(chart) {
      var xCords = chart.plotWidth / 2 + chart.plotLeft - 75,
        yCords = chart.plotHeight / 2 + chart.plotTop + 5;
      chart.renderer
        .text('The Tie Terminal', xCords, yCords)
        .css({
          fontSize: this.waterMarkFontSize + 'px',
          color: colors.gray[700]
        })
        .add();
    },
    downloadData() {
      let xAxisData = this.xAxisSeriesReverse;
      let columns = [this.xAxisSeries.title || ''];
      this.yAxisSeries.forEach(e => columns.push(e.axisTitle));

      let columnsMap = {};
      columns.forEach((e, i) => (columnsMap[i] = e));
      let csvKeys = columns.map(e => {
        return {
          key: e,
          as: e.toUpperCase()
        };
      });

      var seriesData = xAxisData.map(e => [e]);
      for (var i = 0; i < seriesData.length; i++) {
        for (var j = 0; j < this.yAxisSeries.length; j++) {
          seriesData[i].push(this.yAxisSeries[j].data[i]);
        }
      }

      let csvData = seriesData.map((e, i) => {
        let obj = {};
        columns.forEach((key, j) => {
          obj[key] = e[j];
        });
        return obj;
      });

      downloadToCSV(csvKeys, csvData, this.widget.title);
    }
  }
};
</script>
