<template>
  <div class="">
    <div class="space-y-4">
      <div class="space-y-1">
        <label for="markdown" class="text-xs text-gray-400">Markdown</label>
        <textarea
          rows="8"
          placeholder="Add your markdown here"
          class="mt-1 mb-2 text-xs"
          name="markdown"
          v-model="markdown"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from 'lodash';
export default {
  name: 'WidgetConfigsMarkdown',
  emits: ['update'],
  props: {
    config: {
      type: Object
    }
  },
  mounted() {
    if (this.config && !isEmpty(this.config) && this.config.markdown) {
      this.markdown = this.config.markdown;
    }
  },
  data() {
    return {
      markdown:
        '## Headline 2\n### Headline 3\nThis is useful for describing and organizing your dashboard layout. You can make things *italic* or **bold** or use any standard markdown syntax.'
    };
  },
  computed: {
    configuration() {
      return {
        markdown: this.markdown,
        valid: this.markdown.trim() != ''
      };
    }
  },
  watch: {
    configuration() {
      this.$emit('update', this.configuration);
    }
  }
};
</script>
