<template>
  <div class="flex w-2/6 border-l border-grid bg-gray-900 text-white" v-show="!sidebarCollapsed">
    <!-- <transition name="slide"> -->
    <div
      class="grid-rows-12 grid h-full w-full grid-cols-12 gap-px bg-gray-900 text-white"
      v-show="!sidebarCollapsedInt"
    >
      <slot />
    </div>
    <!-- </transition> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    hideCollapseBtn: Boolean,
    containsSidebar: { type: Boolean, default: undefined }
  },

  computed: {
    ...mapGetters(['sidebarCollapsed', 'sidebarCollapsedInt'])
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      lastWindowWidth: window.innerWidth
    };
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    setSidebarState(val = false) {
      this.$store.commit('setSidebarState', val);
    }
  },

  watch: {
    sidebarCollapsedInt() {
      // if (!val) {
      this.$store.commit('setSidebarCollapsed', this.sidebarCollapsedInt);
      // } else {
      //   setTimeout(() => {
      //     this.$store.commit('setSidebarCollapsed', this.sidebarCollapsedInt);
      //   }, 450);
      // }
    },
    windowWidth(newWidth) {
      if (newWidth <= 1000 && this.lastWindowWidth > 1000) {
        this.setSidebarState(true);
      }

      if (newWidth > 1000 && this.lastWindowWidth <= 1000) {
        this.setSidebarState(false);
      }

      this.lastWindowWidth = newWidth;
    },
    containsSidebar(val) {
      this.$store.commit('setHasSidebar', val);
    }
  },

  mounted() {
    if (this.containsSidebar !== undefined) {
      this.$store.commit('setHasSidebar', this.containsSidebar);
    } else {
      this.$store.commit('setHasSidebar', true);
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount() {
    this.$store.commit('setHasSidebar', false);
    window.removeEventListener('resize', this.onResize);
  }
};
</script>

<style>
.slide-enter-active {
  transition: 0.5s ease-in-out;
}
.slide-leave-active {
  transition: 0.5s ease-out;
}
</style>
