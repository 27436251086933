<template>
  <div>
    <div
      v-if="quotedMessage"
      class="flex cursor-pointer justify-between rounded-tr rounded-tl bg-gray-700/50 py-2 px-4 text-xs text-gray-200"
    >
      <div class="flex w-full flex-1 items-center space-x-1 text-xs font-semibold leading-4">
        <span class="text-gray-400">Replying to </span>
        <span class="whitespace-nowrap text-gray-100">{{ quotedMessage.user.name }}</span>
      </div>
      <div @click="$emit('remove-quoted-message')">
        <IconCross class="h-4 w-4 text-gray-100 hover:text-white"></IconCross>
      </div>
    </div>
    <form class="flex flex-col">
      <div class="rounded-t bg-gray-700/50" v-if="uploadFiles.length > 0">
        <UploadsPreview
          :files="uploadFiles"
          :external="external"
          @remove-file="uploadFiles = uploadFiles.filter(f => f.name !== $event)"
        />
      </div>
      <div class="flex items-start rounded bg-gray-800 px-2 py-1">
        <div class="flex items-center space-x-1 py-1">
          <div
            class="flex items-center justify-center rounded bg-gray-600 py-3 hover:bg-gray-500"
            :class="external ? 'h-3 w-7' : 'h-5 w-9'"
          >
            <IconPaperClip
              class="cursor-pointer text-gray-100"
              :size="external ? 'h-3.5 w-3.5' : 'h-4 w-4'"
              @click="triggerFileSelector()"
            />
          </div>
          <div
            :id="`gif-button-${channelId}`"
            tabindex="0"
            class="flex cursor-pointer items-center justify-center rounded bg-gray-600 py-3 hover:bg-gray-500"
            :class="external ? 'h-3 w-8' : 'h-5 w-10'"
            @click="$emit('show-gifs')"
          >
            <span class="font-bold leading-5" :class="external ? 'text-xs' : 'text-sm'">GIF</span>
          </div>
        </div>
        <input ref="file" type="file" style="display: none" @change="onFileChange" multiple />
        <div class="flex w-full items-center px-2">
          <resize-textarea
            ref="resizeText"
            type="text"
            :rows="1"
            class="h-9 overflow-y-hidden border-none py-2 text-sm focus:outline-none"
            v-model="message"
            :min-height="32"
            :max-height="external ? 90 : 150"
            placeholder="Send Message"
            :id="composerId"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { onMounted, ref, onBeforeUnmount, watch, inject } from 'vue';
import UploadsPreview from './ChatUploadsPreview.vue';

import { useStore } from 'vuex';
import notify from '@/composeables/notify';
const store = useStore();
const emitter = inject('eventHub');
const $emit = defineEmits(['show-gifs', 'send-message', 'scroll-to-bottom']);
const props = defineProps({
  quotedMessage: Object,
  external: Boolean,
  channelId: String
});

const message = ref('');
const composerId = ref(`composer_${Math.random()}`);
const showGIFs = ref(false);
const uploadFiles = ref([]);
const resizeText = ref(null);
const file = ref(null);
onMounted(() => {
  const messageInput = document.getElementById(composerId.value);
  messageInput.addEventListener('keypress', e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if ((message.value && message.value.trim() != '') || uploadFiles.value.length > 0) {
        $emit('send-message', { text: message.value, files: uploadFiles.value });
        uploadFiles.value = [];
        resizeText.value.textareaContent = null;
        message.value = null;
      }
    }
  });
  window.addEventListener('keyup', toggleGifSelection);
  messageInput.focus();
  if (ResizeObserver) {
    new ResizeObserver(() => {
      if (document.activeElement === messageInput) {
        $emit('scroll-to-bottom');
      }
    }).observe(messageInput);
  }
  emitter.$on('files-added', files => {
    uploadFiles.value = [...uploadFiles.value, ...files];
  });
});
onBeforeUnmount(() => {
  window.removeEventListener('keyup', toggleGifSelection);
  emitter.$off('files-added');
});
function toggleGifSelection(e) {
  const messageInput = document.getElementById(composerId.value);
  var gifButton = document.getElementById(`gif-button-${props.channelId}`);
  if (e.key == 'Tab' && (gifButton === document.activeElement || messageInput == document.activeElement)) {
    $emit('show-gifs');
  }
}
function onFileChange(e) {
  const files = e.target.files;
  if (uploadFiles.value.length + files.length > 10) {
    notify(
      {
        group: 'app',
        type: 'flash',
        text: {
          type: 'error',
          message: 'You can only upload 10 files at a time!'
        }
      },
      store.getters.shortNotification
    );
  } else if (Array.from(files).some(f => f.size > 8388608)) {
    notify(
      {
        group: 'app',
        type: 'flash',
        text: {
          type: 'error',
          message: 'Max file size is 8MB.'
        }
      },
      store.getters.shortNotification
    );
  } else {
    if (uploadFiles.value.length > 0) {
      Array.from(files).map(f => uploadFiles.value.push(f));
    } else {
      uploadFiles.value = Array.from(files);
    }
  }
  document.getElementById(composerId.value).focus();
}
function triggerFileSelector() {
  file.value.click();
}
watch(
  () => props.quotedMessage,
  (newVal, oldVal) => {
    if (props.quotedMessage) {
      document.getElementById(composerId.value).focus();
    }
  }
);
</script>

<style scoped>
textarea {
  background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
  padding: 5px !important;
}
</style>
