<template>
  <div class="h-96">
    <div class="flex">
      <BaseTags :tags="sourceTags" :selected="[editableConfiguration.source]" @toggle="toggleSource" />
    </div>
    <div class="flex flex-col gap-1">
      <label class="text-xs capitalize text-gray-400"> Select {{ editableConfiguration.source }} </label>
      <DropdownSelectCoin
        v-if="editableConfiguration.source == 'coins'"
        :starting-coins="preSelectedCoins"
        :display-count="10"
        @update="editableConfiguration.coins = $event"
      />
      <DropdownSimple
        v-else
        class="text-xs"
        :active-item="activeSourceOption"
        :items="sourceOptions"
        :truncate-value="50"
        @selected="setSourceOption"
      />
    </div>
    <div v-if="editableConfiguration.source != 'layout'" class="mt-4 space-y-1 overflow-y-auto">
      <label class="space-y-1 text-xs capitalize text-gray-400"> Select Datapoints </label>
      <div class="space-y-4">
        <div v-for="category in datapointCategories" :key="category.id" class="space-y-2">
          <div class="flex items-center">
            <span
              class="flex cursor-pointer items-center space-x-1 text-xs font-semibold text-gray-200"
              @click="category.collapsed = !category.collapsed"
            >
              <IconChevronDown :class="{ 'rotate-180': !category.collapsed }" />
              {{ category.text }}
            </span>
          </div>
          <div v-if="!category.collapsed" class="grid grid-cols-3 gap-2">
            <div
              class="truncate"
              v-for="datapoint in coinDatapoints.filter(x => x.category == category.id)"
              :key="datapoint.id"
            >
              <BaseTooltip>
                <template #target>
                  <BaseCheckbox
                    class="truncate line-clamp-1"
                    :model-value="editableConfiguration.columns.includes(datapoint.id)"
                    @update:model-value="setColumn(datapoint.id)"
                  >
                    <span>{{ truncate(datapoint.name, 18) }}</span>
                  </BaseCheckbox>
                </template>
                <template #default>
                  {{ datapoint.name }}
                </template>
              </BaseTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropdownSimple from '@/components/dropdown/DropdownSimple.vue';
import DropdownSelectCoin from '@/components/dropdown/DropdownSelectCoin.vue';
import FilterMixin from '@/mixins/filters';
import { isEmpty } from 'lodash';
export default {
  name: 'WidgetConfigsMiniScreener',
  emits: ['update'],
  mixins: [FilterMixin],
  props: {
    config: {
      type: Object
    }
  },
  components: {
    DropdownSimple,
    DropdownSelectCoin
  },
  mounted() {
    if (this.config && !isEmpty(this.config)) {
      this.editableConfiguration = JSON.parse(JSON.stringify(this.config));
      if (this.editableConfiguration.source == 'coins') {
        this.preSelectedCoins = this.coins.filter(x => this.editableConfiguration.coins.includes(x.coin_uid));
      }
    }
    this.loadLayouts();
  },
  data() {
    return {
      preSelectedCoins: [],
      datapointCategories: [
        { id: 'market', text: 'Market', collapsed: false },
        { id: 'sentiment', text: 'Sentiment', collapsed: true },
        { id: 'sigdev', text: 'SigDev', collapsed: true },
        { id: 'tvl', text: 'On-Chain', collapsed: true }
      ],
      sourceTags: [
        { id: 'coins', title: 'Coins' },
        { id: 'watchlist', title: 'Watchlists' },
        { id: 'layout', title: 'Layouts' }
      ],
      tableLayouts: [],
      editableConfiguration: {
        columns: [],
        source: 'coins',
        coins: [],
        watchlist: null,
        layout: null
      }
    };
  },
  computed: {
    coins() {
      return this.$store.getters.coins;
    },
    selectedColumns() {
      return this.editableConfiguration.columns;
    },
    coinDatapoints() {
      return this.$store.getters.coinDatapoints.filter(x => !x.default);
    },
    activeSourceOption() {
      let config = this.editableConfiguration;
      let activeOption = 0;
      if (config.source == 'watchlist' && config.watchlist) {
        activeOption = config.watchlist.id;
      }
      if (config.source == 'layout' && config.layout) {
        activeOption = config.layout.id;
      }
      if (this.sourceOptions.some(x => x.id == activeOption)) {
        return activeOption;
      }
      return 0;
    },
    sourceOptions() {
      if (this.editableConfiguration.source == 'watchlist') {
        return this.$store.getters.watchlists.coins.map(x => {
          return {
            id: x.id,
            text: x.name
          };
        });
      }
      if (this.editableConfiguration.source == 'layout') {
        return this.tableLayouts.map(x => {
          return {
            id: x.id,
            text: x.name
          };
        });
      }
      return [];
    }
  },
  watch: {
    editableConfiguration: {
      deep: true,
      handler: function () {
        let status = true;
        let source = this.editableConfiguration.source;
        if (source == 'watchlist' && !this.editableConfiguration.watchlist) {
          status = false;
        }
        if (source == 'layout' && !this.editableConfiguration.layout) {
          status = false;
        }
        this.$emit('update', { valid: status, ...this.editableConfiguration });
      }
    }
  },
  methods: {
    async loadLayouts() {
      try {
        let response = await this.$http.get('/advance_filter_layouts');
        this.tableLayouts = response.data;
      } catch {
        //do nothing
      }
    },
    toggleSource(payload) {
      if (payload != this.editableConfiguration.source) {
        this.resetConfiguration();
      }
      this.editableConfiguration.source = payload;
    },
    resetConfiguration() {
      this.editableConfiguration = {
        columns: [],
        coins: [],
        watchlist: null,
        layout: null
      };
    },
    setColumn(col) {
      if (this.editableConfiguration.columns.includes(col)) {
        this.editableConfiguration.columns = this.editableConfiguration.columns.filter(x => x != col);
      } else {
        this.editableConfiguration.columns.push(col);
      }
    },
    setSourceOption(selectedOption) {
      if (this.editableConfiguration.source == 'watchlist') {
        this.editableConfiguration.watchlist = selectedOption;
      }
      if (this.editableConfiguration.source == 'layout') {
        this.editableConfiguration.layout = selectedOption;
      }
    }
  }
};
</script>
