<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="false"
      :connectable="false"
      @expand="goToExpandLink"
    >
      <template #body>
        <div class="h-full w-full" :style="`background: ${bgColor}`" :class="['transform', scaleContent]">
          <template v-if="content == ''">
            <WidgetsErrorMessage>No link or embed code provided.</WidgetsErrorMessage>
          </template>
          <template v-else>
            <template v-if="linkOnly">
              <iframe :src="content" class="h-full w-full" :id="Math.random()"></iframe>
            </template>
            <template v-else>
              <div ref="iframeContent" class="h-full w-full" v-html="content"></div>
            </template>
          </template>
        </div>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';
export default {
  name: 'WidgetsSimpleIframe',
  mixins: [WidgetsMixin],
  data() {
    return {};
  },
  computed: {
    bgColor() {
      return this.widget.configuration ? this.widget.configuration.backgroundColor : '#0F172B';
    },
    content() {
      return this.widget.configuration ? this.widget.configuration.content : '';
    },
    linkOnly() {
      return !this.content.includes('iframe');
    },
    scaleContent() {
      return this.widget.configuration ? this.widget.configuration.scale : 'scale-100';
    },
    expandLink() {
      if (this.linkOnly) {
        return this.content;
      }
      return this.$refs.iframeContent.firstChild.getAttribute('src');
    }
  },
  // watch: {
  //   scaleContent() {
  //     this.setZoom();
  //   }
  // },
  methods: {
    goToExpandLink() {
      window.open(this.expandLink, '_blank');
    }
    // setZoom() {
    //   let elem = this.linkOnly ? this.$refs.iframeElement : this.$refs.iframeContent.firstChild;
    //   elem.contentWindow.document.body.style = `transform: scale(${0.5}) !important`;
    // }
  }
};
</script>

<style>
iframe {
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}
</style>
