<template>
  <iframe :id="randomId" v-if="dataSet" :srcdoc="dataSet" class="h-full w-full"></iframe>
</template>
<script>
import global from '@/mixins/global';
export default {
  name: 'WidgetCustomTemplatesHtml',
  mixins: [global],
  mounted() {},
  data() {
    return {
      randomId: Math.random().toString()
    };
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    dataSet: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    dataSet: {
      deep: true,
      handler() {}
    }
  },
  methods: {}
};
</script>
