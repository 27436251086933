<template>
  <div class="flex space-x-10 divide-x divide-grid">
    <div class="flex w-1/2 flex-wrap items-start justify-around space-x-4 text-center">
      <div>
        <div class="text-xs text-gray-300">Tweet Volume (24hr)</div>
        <div class="mt-1 text-2xl font-medium">
          <span> {{ rounded(tweetsData.tweet_volume, 0) }}</span>
        </div>
      </div>
      <div>
        <div class="text-xs text-gray-300">Unique Accounts (24hr)</div>
        <div class="mt-1 text-2xl font-medium">
          <span> {{ rounded(tweetsData.twitter_accounts, 0) }} </span>
        </div>
      </div>
      <div class="mt-4 w-full"></div>
      <div>
        <div class="text-xs text-gray-300">Hourly Sentiment</div>
        <div class="mt-1 text-2xl font-medium">
          <CellSentiment :sentiment="sentiments.hourly_sentiment" text-align="justify-center" icon-size="w-6 h-2" />
        </div>
      </div>
      <div>
        <div class="text-xs text-gray-300">Daily Sentiment</div>
        <div class="mt-1 text-2xl font-medium">
          <CellSentiment :sentiment="sentiments.daily_sentiment" text-align="justify-center" icon-size="w-6 h-2" />
        </div>
      </div>
      <div>
        <div class="text-xs text-gray-300">Long Term Sentiment</div>
        <div class="mt-1 text-2xl font-medium">
          <CellSentiment :sentiment="sentiments.long_term_sentiment" text-align="justify-center" icon-size="w-6 h-2" />
        </div>
      </div>
    </div>
    <div class="w-1/2 pl-8">
      <p class="text-sm text-gray-300">
        <span>Today, there are </span>
        <BasePercentClassic class="font-medium" :percent="tweetVolumeVsAvg" :hide-sign="true" v-if="tweetVolumeVsAvg" />
        <span v-if="tweetVolumeVsAvg > 0" class="font-medium text-green-400"> more </span>
        <span v-else class="font-medium text-red-400"> fewer </span>
        tweets than the usual
        <span class="font-medium text-white">{{ decimals(tweetsData.tweet_volume_30_day_avg, 0, 0) }}</span
        >.
      </p>
      <p class="mt-5 text-sm text-gray-300">
        <span>These tweets are from </span>
        <BasePercentClassic
          class="font-medium"
          :percent="uniqueAccountsVsAvg"
          :hide-sign="true"
          v-if="uniqueAccountsVsAvg"
        />
        <span v-if="uniqueAccountsVsAvg > 0" class="font-medium text-green-400"> more </span>
        <span v-else class="font-medium text-red-400"> fewer </span>
        accounts than the usual
        <span class="font-medium text-white">{{ decimals(tweetsData.twitter_accounts_30_day_avg, 0, 0) }}</span
        >.
      </p>
      <p class="mt-5 text-sm text-gray-300">
        And
        <span class="font-medium text-white">{{ roundedPercent(tweetsData.tweet_unique_sources_percent * 100) }}</span>
        of tweets are from unique accounts ({{ decimals(1 / tweetsData.tweet_unique_sources_percent, 2, 2) }} tweets /
        account).
      </p>
      <p class="mt-5 text-sm text-gray-300">
        <span
          >This makes up
          <span class="font-medium text-white">{{
            roundedPercent(tweetsData.tweet_volume_dominance * 100)
          }}</span></span
        >
        of all coin tweets.
      </p>
    </div>
  </div>
</template>

<script>
import CellSentiment from '@/components/table/cell/CellSentiment.vue';
import FilterMixin from '@/mixins/filters';

export default {
  mixins: [FilterMixin],
  name: 'BaseSentimentTweet',
  components: {
    CellSentiment
  },
  props: {
    tweetsData: {
      type: Object,
      default: null
    },
    sentiments: {
      type: Object
    }
  },
  computed: {
    tweetVolumeVsAvg() {
      return this.tweetsData.relative_tweet_volume;
    },
    uniqueAccountsVsAvg() {
      return this.tweetsData.twitter_accounts / this.tweetsData.twitter_accounts_30_day_avg - 1;
    },
    noSmaAlias() {
      return !this.tweetsData.tweet_volume || this.tweetsData.tweet_volume < 12;
    }
  }
};
</script>
