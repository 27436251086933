<template>
  <div class="flex max-w-xs flex-wrap items-center gap-1">
    <div v-for="tag in computedTags" :key="tag.id">
      <div class="bg-gray-800 text-xxs py-1 px-2 rounded-full">{{ tag.text }}</div>
    </div>
    <div v-if="tags.length > computedTags.length">
      <BaseButton type="secondary" size="xxs" @press="limit = tags.length">
        <div class="flex items-center px-1">
          {{ tags.length - limit }} More<IconDoubleArrowLeft class="h-3 w-3 rotate-180 transform" />
        </div>
      </BaseButton>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  tags: { type: Array, default: () => [] }
});

//TAGS
const limit = ref(3);
const computedTags = computed(() => {
  return props.tags.length > 0 ? props.tags.slice(0, limit.value) : [];
});
</script>
