<template>
  <ChartControls
    v-if="!widget"
    :time="time"
    :loading="loading"
    :selected-timeframe="selectedTimeframe"
    :settings="settings"
    :random-id="randomId"
    @clear:time="time = []"
    @update:timeframe="(selectedTimeframe = $event.id), (timeframe = $event), (timeframeChanged = true)"
    @export:csv="chartObj.downloadCSV()"
    @export:chart="chartObj.exportChart()"
    @update:date="dateInputChanged($event), (selectedTimeframe = ''), (timeframeChanged = false), (dateChanged = true)"
  />
  <ChartControlsWidget
    v-if="widget"
    :time="time"
    :loading="loading"
    :selected-timeframe="selectedTimeframe"
    :settings="settings"
    :random-id="randomId"
    :width="widgetWidth"
    :id="chartId"
    :layouts="dropdownLayouts"
    :layout="layout"
    :view-only-mode="viewOnly"
    @clear:time="time = []"
    @update:timeframe="(selectedTimeframe = $event.id), (timeframe = $event), (timeframeChanged = true)"
    @export:csv="chartObj.downloadCSV()"
    @export:chart="chartObj.exportChart()"
    @update:date="dateInputChanged($event), (selectedTimeframe = ''), (timeframeChanged = false), (dateChanged = true)"
  />
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import { DateTime } from 'luxon';
import 'vue-datepicker-next/index.css';
import Highstock from 'highcharts/highstock';
import ChartControls from '@/components/chart/ChartControls.vue';
import ChartControlsWidget from '@/components/chart/ChartControlsWidget.vue';
import colors from 'tailwindcss/colors';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import exportData from 'highcharts/modules/export-data';
import moment from 'moment';
import { decimals } from '../../composeables/filters';
import useEmitter from '../../composeables/emitter';
import { ref, onMounted, watch, computed, inject, nextTick, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { formatNumber, titleize } from '@/composeables/filters';

exporting(Highstock);
offlineExporting(Highstock);
exportData(Highstock);

const coinHistoryCache = {};
const otherDataHistoryCache = {};

const emit = defineEmits(['optionsChange']);
const emitter = inject('eventHub');
// var emitter = require('tiny-emitter/instance');
const $http = inject('http');
const $store = useStore();

const props = defineProps({
  settings: Object,
  showWatermark: Boolean,
  widget: Boolean,
  viewOnly: Boolean,
  widgetWidth: Number,
  dropdownLayouts: Object,
  layout: Object,
  chartId: String,
  readyToPlot: { type: Boolean, default: false },
  metricOptions: {
    type: Array,
    default: () => []
  }
});
const otherColumns = ref([
  'tvl',
  'tiktok_play_count',
  'fourchan_post_count',
  'youtube_view_count',
  'youtube_view_count_30d_avg',
  'tiktok_play_count_30d_avg',
  'fourchan_post_count_30d_avg'
]);
const onchainColumns = ref([
  'num_inc_24',
  'num_dec_24',
  'num_txs_24h',
  'num_active_addrs_24h',
  'num_inc_30d_avg',
  'num_dec_30d_avg',
  'num_tx_30d_avg',
  'num_active_addrs_30d_avg'
]);
const developmentColumns = ref(['commit_count', 'active_users_30d']);
const chartData = ref([]);
const loading = ref(false);
const time = ref([]);
const timeframe = ref({
  time: 1000 * 60 * 60 * 24 * 90,
  id: '90D',
  text: '3M'
});
const timeframeChanged = ref(false);
const dateChanged = ref(false);
const randomId = ref(`chart_${Math.random()}`);
const chartObj = ref(undefined);
const selectedTimeframe = ref('90D');
const chartConfig = ref({
  navigator: {
    enabled: false
  },
  renderTo: 'container',
  rangeSelector: {
    selected: 1
  },
  exporting: {
    enabled: false
  },
  chart: {
    // type: 'line',
    backgroundColor: 'transparent',
    zoomType: 'x',
    events: {
      load: function () {
        if (props.showWatermark) {
          addWatermark(this.renderer, 'image', 50, 50, 4.628 * 20, 20);
        }
        const container = document.getElementById(randomId.value);
        addWatermark(this.renderer, 'text', container.clientWidth / 2.3, container.clientHeight / 2.15, null, null);
      }
    },
    resetZoomButton: {
      theme: {
        fill: colors.gray[800],
        stroke: 'none',
        r: 4,
        padding: 6,
        style: {
          color: colors.gray[400]
        },
        states: {
          hover: {
            fill: colors.blue[900],
            style: {
              color: colors.blue[100]
            }
          }
        }
      }
    }
  },
  plotOptions: {
    series: {
      animation: false
    }
  },
  colors: [
    colors.blue[600],
    colors.pink[600],
    colors.green[600],
    colors.red[600],
    colors.purple[600],
    colors.orange[600],
    colors.yellow[600]
  ],
  credits: {
    enabled: false
  },

  title: {
    text: ''
  },
  legend: {
    itemStyle: {
      color: colors.gray[400],
      fontWeight: 'normal'
    }
  },
  xAxis: {
    type: 'datetime',
    lineColor: colors.gray[600]
  },
  yAxis: [],
  series: [],
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: null,
    style: {
      color: 'white',
      pointerEvents: 'auto'
    },
    borderWidth: 0,
    formatter: function () {
      const format =
        selectedTimeframe.value && ['1H', '3H', '6H', '1D', '7D'].includes(selectedTimeframe.value)
          ? 'dddd, MMM DD, YYYY HH:mm'
          : 'dddd, MMM DD, YYYY';
      const date = moment(this.x).format(format);
      const tipHtml = `<b>${date}</b></br>`;
      const fromattedPnts = this.points.map(point => {
        const pointSymb = `<span style="color:${point.color}">●</span>`;
        return (
          pointSymb +
          `<b> ${point.series.name}:</b> <span style="color:${point.color}; margin-left: 0.5rem;">${
            isPercentagePoint(point?.series?.name) ? point.y.toFixed(2) : formatNumber(point.y, 2, '')
          }${
            point?.series?.name?.includes('Circulating Supply Percent') || isPercentagePoint(point?.series?.name)
              ? '%'
              : ''
          }</span>`
        );
      });

      return (
        "<div class='max-w-lg break-words rounded border border-gray-700 bg-gray-800 px-4 py-2 text-left text-gray-200'>" +
        tipHtml +
        fromattedPnts.join('<br/>') +
        '</div>'
      );
    }
  }
});

onMounted(() => {
  // const config = this.chartConfig;
  // config.series = this.series;
  chartObj.value = Highstock.chart(randomId.value, chartConfig.value);
  Highstock.setOptions({
    lang: {
      resetZoom: 'Reset Zoom'
    },
    time: {
      timezoneOffset: DateTime.local().offset,
      useUTC: false
    }
  });
  emitter.$on('checkForChartResize', () => {
    setTimeout(fetch(), 10);
  });

  emitter.$on('reflow-highchart', () => {
    nextTick(() => {
      chartObj.value.reflow();
    });
  });
});

onBeforeUnmount(() => {
  emitter.$on('checkForChartResize');
  emitter.$on('reflow-highchart');
});

const coinColorSets = computed(() => {
  const coinColors = [];
  [colors.blue, colors.pink, colors.green, colors.red, colors.purple, colors.orange, colors.yellow].forEach(
    colorSet => {
      coinColors.push([colorSet[400], colorSet[600], colorSet[300], colorSet[500], colorSet[700]]);
    }
  );

  return coinColors;
});

const categoriesName = computed(() => {
  let list = [];
  ($store.state.categories.coin_sub_categories || []).map(subCategory => {
    list.push({ id: `subcat__${subCategory.id}`, name: subCategory?.name });
  });
  ($store.state.categories.coin || []).forEach(category => {
    list.push({ id: `cat__${category.id}`, name: category?.name });
  });
  return list;
});

const allCoins = computed(() => {
  return !loader.value ? $store.getters.coins : [];
});

const loader = computed(() => {
  return $store.getters.coinsLoader;
});

const percentageColumns = computed(() => {
  const percentColumns = $store.state.coinDatapointsConfig
    .filter(i => i.type == 'simple_percent' || i.type == 'percent')
    .map(i => i.id);

  return props.metricOptions.filter(item => percentColumns.includes(item.id));
});

watch(loader, () => {
  if (!loader.value) {
    fetch();
  }
});

watch(
  () => props.settings,
  () => {
    time.value = [
      new Date(props.settings.start).toISOString().slice(0, 10),
      new Date(props.settings.end).toISOString().slice(0, 10)
    ];

    if (chartObj.value) {
      chartObj.value.destroy();
      chartObj.value = undefined;
    }
    if (props.settings.sources.length > 0) {
      fetch();
    }
  }
);
watch(chartData, (newVal, oldVal) => {
  if (newVal != null && newVal != undefined && newVal.length > 0) {
    plotChart();
  }
});
watch(selectedTimeframe, (newVal, oldVal) => {
  if (newVal && newVal != '') {
    const newTimeframe = timeframe.value;
    const start = new Date(new Date().getTime() - newTimeframe.time);
    const end = new Date();
    time.value = [start.toISOString().slice(0, 10), end.toISOString().slice(0, 10)];
    dateInputChanged(time.value);
  }
});
watch(
  () => props.layout,
  () => {
    timeframeChanged.value = false;
    dateChanged.value = false;
    if (!props.layout) {
      timeframe.value = {
        time: 1000 * 60 * 60 * 24 * 90,
        id: '90D',
        text: '3M'
      };
      timeframeChanged.value = true;
    }
  }
);
watch(
  () => props.showWatermark,
  () => {
    plotChart();
  }
);

function cleanName(name) {
  return name;
}

function dateInputChanged(e) {
  if (e) {
    time.value = e;
    const startTime = new Date(e[0]);
    startTime.setUTCHours(0, 0, 0, 0);
    const endTime = new Date(e[1]);
    endTime.setUTCHours(23, 59, 59, 999);
    emit('optionsChange', {
      start: startTime.getTime(),
      end: endTime.getTime()
    });
  }
}

// fetches all of the chart data
async function fetch() {
  loading.value = true;
  if (!loader.value) {
    // an array of source data which is an array of
    chartData.value = await Promise.all(
      props.settings.sources.map(source => {
        return fetchSource(source);
      })
    );
  }
}

function addWatermark(renderer, renderType, x, y, w, h) {
  if (renderType == 'image') {
    renderer
      .image(
        'https://the-tie-sigdev.s3.us-east-2.amazonaws.com/the_tie_logo_white.png',
        x, // x
        y, // y
        w, // width
        h // height
      )
      .add();
  } else {
    renderer
      .text(
        'The Tie Terminal',
        x, // x
        y, // y
        true
      )
      .css({
        color: '#273041',
        fontSize: '16px',
        fontFamily: 'Inter,sans-serif',
        zIndex: '-1'
      })
      .add();
  }
}

function plotChart() {
  if (!props.readyToPlot) return;

  var sources = chartData.value;
  if (chartObj.value) {
    chartObj.value.destroy();
    chartObj.value = undefined;
  }
  const config = chartConfig.value;
  config.yAxis = [];
  config.series = [];
  let axisCount = 0;
  let hasRelativeAxis = false;

  sources.forEach((source, i) => {
    const sourceObj = props.settings.sources[i];
    if (sourceObj.relative) {
      hasRelativeAxis = true;
    }
  });

  if (hasRelativeAxis) {
    axisCount += 1;
    config.yAxis.push({
      type: props.settings.log ? 'logarithmic' : undefined,

      title: {
        text: '% Change',
        style: {
          fontSize: '12px'
        }
      },
      opposite: true,
      gridLineColor: colors.gray[800],
      lineColor: colors.gray[600],
      labels: {
        formatter: function () {
          return (this.value - 1).toFixed(3) + '%';
        }
      }
    });
  }

  const metricToAxisIndexes = {};

  if (props.settings.sameAxis) {
    sources.forEach((source, i) => {
      const sourceObj = props.settings.sources[i];

      if (sourceObj.relative) {
        return;
      }

      source.forEach(axis => {
        let metric_name;
        Object.keys(axis.metrics).forEach(metricKey => {
          if (metricToAxisIndexes[metricKey] !== undefined) {
            return;
          }
          metric_name = props.metricOptions.find(s => s.id === metricKey)?.name;
          config.yAxis.push({
            type: props.settings.log ? 'logarithmic' : undefined,
            title: {
              text: cleanName(metric_name),
              style: {
                fontSize: '12px'
              }
            },
            opposite: true,
            gridLineColor: colors.gray[800],
            lineColor: colors.gray[600],
            labels: {
              formatter: function () {
                return isPercentageColumn(metricKey, 'id')
                  ? this.value.toFixed(2).toLocaleString() + '%'
                  : this.value.toLocaleString();
              }
            }
          });

          metricToAxisIndexes[metricKey] = axisCount;
          axisCount += 1;
        });
      });
    });
  }

  const colorHashes = {};

  sources.forEach((source, i) => {
    const sourceObj = props.settings.sources[i];
    let seriesCoint = 0;
    let metric_name;
    source.forEach(axis => {
      Object.keys(axis.metrics).forEach(metricKey => {
        metric_name = props.metricOptions.find(s => s.id === metricKey)?.name;
        let assosiatedChain = axis.metrics[metricKey] || '';
        const name = cleanName(
          `${axis.name} ${metric_name} ${assosiatedChain?.chain ? '(' + titleize(assosiatedChain?.chain) + ')' : ''}`
        );
        if (!sourceObj.relative && !props.settings.sameAxis) {
          config.yAxis.push({
            type: props.settings.log ? 'logarithmic' : undefined,
            title: {
              text: name,
              style: {
                fontSize: '12px'
              }
            },
            opposite: true,
            gridLineColor: colors.gray[800],
            lineColor: colors.gray[600],
            labels: {
              formatter: function () {
                return isPercentageColumn(metricKey, 'id')
                  ? this.value.toFixed(2).toLocaleString() + '%'
                  : this.value.toLocaleString();
              }
            }
          });
        }

        let yAxisIndex = axisCount;

        if (sourceObj.relative) {
          yAxisIndex = 0;
        } else if (props.settings.sameAxis) {
          yAxisIndex = metricToAxisIndexes[metricKey];
        }

        const colorHash = name.includes('Indexed') ? name.split('Indexed')[1] : name.split(' ')[0];

        if (!colorHashes[colorHash]) {
          colorHashes[colorHash] = coinColorSets.value[Object.keys(colorHashes).length % coinColorSets.value.length];
        }

        config.series.push({
          name: name,
          type: sourceObj.chartType || 'line',
          yAxis: yAxisIndex,
          data: axis.metrics[metricKey].timeseries,
          marker: {
            enabled: false
          },
          color: colorHashes[colorHash][seriesCoint % colorHashes[colorHash].length]
        });

        if (!sourceObj.relative) {
          axisCount += 1;
        }

        seriesCoint += 1;
      });
    });
  });

  const container = document.getElementById(randomId.value);

  chartObj.value = Highstock.chart(randomId.value, config);

  chartObj.value.update({
    exporting: {
      enabled: false,
      sourceWidth: container.clientWidth,
      sourceHeight: container.clientHeight
    }
  });
  loading.value = false;
}

async function fetchCoinTvl(coin, start, end) {
  try {
    const response = await $http.get('/protocol_info', {
      params: { coin_uid: coin }
    });
    if (!response || !response.data) {
      return;
    }
    const { data } = response;
    const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
    return data
      .map(point => {
        return [
          Math.round(point.date * 1000 + (DateTime.local().offset / 60) * 60 * 60 * 1000),
          point.totalLiquidityUSD
        ];
      })
      .slice(data.length - days, data.length);
  } catch {
    return;
  }
}

async function fetchHistoricalStats(coin, s, e, type, chain) {
  try {
    const start = new Date(s);
    const end = new Date(e);
    let freq = '1day';
    const timeDiff = end.getTime() - start.getTime();
    const min = 1000 * 60;
    const hour = min * 60;
    const day = hour * 24;
    const year = day * 365;

    if (timeDiff <= hour) {
      freq = '1min';
    } else if (timeDiff <= hour * 6 || timeDiff <= day * 2) {
      // (Less then 6 hours ||  equal to 1 day)
      freq = '5min';
    } else if (timeDiff < day * 30) {
      freq = '6hour';
    } else if (timeDiff <= year) {
      freq = '1day';
    }
    const req = {
      coin_uids: coin,
      start_datetime: convertDate(start),
      end_datetime: convertDate(end),
      frequency: freq,
      type: type
    };
    if (type == 'onchain') req['chain'] = chain ? chain : 'ethereum';
    let endpoint = `/historical_stats/${type}_historical`;
    if (otherDataHistoryCache[JSON.stringify(req)]) {
      return otherDataHistoryCache[JSON.stringify(req)];
    }
    let response = await $http.post(endpoint, req);
    if (!response || !response.data) {
      return;
    }
    let data = response.data;
    if (freq == '1day') {
      data = data.map(i => {
        return { ...i, datetime: new Date(moment(i.datetime).format('YYYY-MM-DD')) };
      });
    } else {
      data = data.map(i => {
        return { ...i, datetime: new Date(moment.utc(i.datetime)) };
      });
    }

    otherDataHistoryCache[JSON.stringify(req)] = data;
    return data;
  } catch {
    return;
  }
}

// fetches a single source
async function fetchSource(source) {
  let start = props.settings.start;
  let end = props.settings.end;
  selectedTimeframe.value = timeframeChanged.value && !dateChanged.value ? timeframe.value.id : '';

  //load selected timeframe saved with layout
  if (props.settings.selectedTimeframe && !timeframeChanged.value && !dateChanged.value) {
    selectedTimeframe.value = props.settings.selectedTimeframe.id;
    timeframe.value = props.settings.selectedTimeframe;
    if (props.settings.selectedTimeframe?.time) {
      start = new Date(new Date().getTime() - props.settings.selectedTimeframe.time);
      end = new Date();
    }
  }
  if (source.type === 'coin') {
    const coins = source.uids;
    const coin_names = allCoins.value
      .filter(coin => coins.includes(coin.uid.toString()))
      .map(x => {
        return {
          uid: x.uid,
          name: x.name
        };
      });

    return Promise.all(
      coins.map(async coin => {
        const tvl = source.metric.includes('tvl');
        const tvlmcap = source.metric.includes('mcap_/_tvl');
        const metricsToFetch = source.metric.concat([]);

        if (tvlmcap && !metricsToFetch.includes('market_cap')) {
          metricsToFetch.push('market_cap');
        }

        const coinData = await coinHistory(coin, start, end, metricsToFetch);
        let metrics = await normalizeSource(
          {
            ...source,
            metric: metricsToFetch
          },
          coinData
        );
        metrics = await normalizeOtherSource(coin, metrics, source);
        // we must handle tvl differently
        if (tvl || tvlmcap) {
          const tvlData = await fetchCoinTvl(coin, start, end);

          if (tvlData && tvl) {
            metrics['tvl'] = {
              source: source.name,
              timeseries: source.relative
                ? tvlData.map(tvl => {
                    return [tvl[0], (tvl[1] / tvlData[0][1] - 1) * 100];
                  })
                : tvlData
            };
          }

          if (tvlData && tvlmcap && metrics['market_cap']) {
            const mcapTimeseries = coinData.map(row => {
              return [new Date(row.datetime).getTime(), parseFloat(row.market_cap)];
            });

            let first = 1;
            const tvlMcapData = tvlData.map((tvlPoint, i) => {
              const tvlPointTime = tvlPoint[0];
              const tvlPointValue = tvlPoint[1];
              let clostestMcapValue = 0;
              let clostestMcapTimeDiff = Number.MAX_SAFE_INTEGER;

              // there is a faster way to do this using bsearch but this is fast enough and simple/easy to read
              for (let i = 0; i < mcapTimeseries.length; i++) {
                const timeDiff = Math.abs(mcapTimeseries[i][0] - tvlPointTime);

                if (timeDiff < clostestMcapTimeDiff) {
                  clostestMcapValue = mcapTimeseries[i][1];
                  clostestMcapTimeDiff = timeDiff;
                } else {
                  // given that mcapTimeseries is sorted by time, we can break out after the diff is bigger because the diff will keep getting bigger
                  // break;
                }
              }

              if (i === 0) {
                first = clostestMcapValue / tvlPointValue;
              }

              return [
                tvlPointTime,
                source.relative
                  ? (clostestMcapValue / tvlPointValue / first - 1) * 100
                  : clostestMcapValue / tvlPointValue
              ];
            });

            metrics['mcap_/_tvl'] = {
              source: source.name,
              timeseries: tvlMcapData
            };
            // mcap / tvl, find clostest mcap for tvl point
          }
        }

        if (tvlmcap && !source.metric.includes('market_cap')) {
          delete metrics['market_cap'];
        }

        return {
          metrics,
          name: coin_names.find(x => x.uid == coin)?.name
        };
      })
    );
  }

  if (source.type === 'watchlist' || source.type === 'category') {
    var coins = source.reducedCoins ? source.reducedCoins : [];

    if (source.displayMode === 'indexed') {
      if (source.type === 'category') {
        return Promise.all(
          source.uids.map(async uid => {
            let category_coins = await getCoinUidsFromCategoryUid([uid]);
            let responseData = await coinHistory(category_coins, start, end, source.metric);
            let metrics = await normalizeSource(source, responseData);
            metrics = await normalizeOtherSource(category_coins, metrics, source);
            return {
              metrics: metrics,
              name: `indexed ${categoriesName.value.find(x => x.id == uid)?.name}`
            };
          })
        );
      } else if (source.type === 'watchlist') {
        coins = await getCoinUidsFromWatchlistUid(source.uid);
        let metrics = await normalizeSource(source, await coinHistory(coins, start, end, source.metric));
        metrics = await normalizeOtherSource(coins, metrics, source);
        return [
          {
            metrics: metrics,
            name: `indexed ${source.name}`
          }
        ];
      }
    }

    if (source.displayMode === 'individual') {
      if (coins.length == 0) {
        coins =
          source.type === 'watchlist'
            ? await getCoinUidsFromWatchlistUid(source.uid)
            : await getCoinUidsFromCategoryUid(source.uids);
      }
      const coin_names = $store.getters.coins
        .filter(coin => coins.includes(coin.uid.toString()))
        .map(x => {
          return {
            uid: x?.uid,
            name: x?.name
          };
        });
      return Promise.all(
        coins.map(async coin => {
          let metrics = await normalizeSource(source, await coinHistory(coin, start, end, source.metric));
          metrics = await normalizeOtherSource(coin, metrics, source);
          return {
            metrics: metrics,
            name: coin_names.find(x => x.uid == coin).name
          };
        })
      );
    }
  }

  return [];
}

// normalizes the source for Highstock based on the config
async function normalizeSource(source, data) {
  const lines = {};
  let cols = [otherColumns.value, onchainColumns.value, developmentColumns.value].flat();
  let metrics = source.metric.filter(e => {
    return !cols.includes(e);
  });
  metrics.forEach(metric => {
    lines[metric] = {
      source: source.name,
      timeseries: []
    };
  });

  data.forEach(row => {
    metrics.forEach(metric => {
      let rowNum = parseFloat(row[metric]);
      let firstNum = parseFloat(data[0][metric]);
      if (isPercentageColumn(metric, 'id')) {
        rowNum = rowNum * 100;
        firstNum = firstNum * 100;
      }
      const num = source.relative ? (rowNum / firstNum - 1) * 100 : rowNum;
      const time = new Date(row.datetime).getTime();
      lines[metric].timeseries.push([time, parseFloat(decimals(num, 2, 2).replaceAll(',', ''))]);
    });
  });

  return lines;
}

function isPercentageColumn(column_value, type = 'name') {
  if (type === 'name')
    return column_value ? percentageColumns.value.find(item => column_value.includes(item.name)) !== undefined : false;

  return percentageColumns.value.find(item => column_value === item.id) !== undefined;
}

function isPercentagePoint(metric_name) {
  return metric_name ? percentageColumns.value.find(item => metric_name.includes(item.name)) !== undefined : false;
}

const convertDate = date => {
  return `${date.getUTCFullYear().toString().padStart(4, '0')}-${(date.getUTCMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')} ${date
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
};

async function normalizeOtherSource(coin, metrics, source) {
  const social_points = otherColumns.value.some(r => source.metric.includes(r));
  const onchain_points = onchainColumns.value.some(r => source.metric.includes(r));
  const development_points = developmentColumns.value.some(r => source.metric.includes(r));
  let chain = source?.chain || null;
  for await (const type of ['social', 'onchain', 'development']) {
    if (
      (type == 'social' && social_points) ||
      (type == 'onchain' && onchain_points) ||
      (type == 'development' && development_points)
    ) {
      const otherData = await fetchHistoricalStats(coin, props.settings.start, props.settings.end, type, chain);
      if (otherData && otherData.length) {
        let columns =
          type == 'social'
            ? otherColumns.value.filter(r => source.metric.includes(r))
            : type == 'onchain'
            ? onchainColumns.value.filter(r => source.metric.includes(r))
            : developmentColumns.value.filter(r => source.metric.includes(r));
        columns.forEach(element => {
          let sortedOtherData = otherData.sort((a, b) => Number(a.datetime?.getTime()) - Number(b.datetime?.getTime()));
          let chartDateValue = sortedOtherData.map(i => {
            let elementValue = 0;
            if (parseFloat(i[element])) {
              elementValue = parseFloat(i[element]);
            }
            let firstElementValue = 0;
            if (sortedOtherData[0][element]) {
              firstElementValue = parseFloat(sortedOtherData[0][element]);
            }
            return [
              new Date(i.datetime).getTime(),
              source.relative ? (elementValue / firstElementValue - 1) * 100 : elementValue
            ];
          });
          metrics[element] = {
            source: source.name,
            timeseries: chartDateValue,
            chain: chain && type == 'onchain' ? chain : !chain && type == 'onchain' ? 'ethereum' : ''
          };
        });
      }
    }
  }
  return metrics;
}

async function coinHistory(coins, s, e, items) {
  const start = new Date(s);
  const end = new Date(e);

  let freq = '1day';
  const timeDiff = end.getTime() - start.getTime();
  const min = 1000 * 60;
  const hour = min * 60;
  const day = hour * 24;
  const year = day * 365;

  if (timeDiff <= hour) {
    freq = '1min';
  } else if (timeDiff <= hour * 6 || timeDiff <= day * 2) {
    // (Less then 6 hours ||  equal to 1 day)
    freq = '5min';
  } else if (timeDiff < day * 30) {
    freq = '6hour';
  } else if (timeDiff <= year) {
    freq = '1day';
  }
  let cols = [otherColumns.value, onchainColumns.value, developmentColumns.value].flat();
  const req = {
    coin_uids: coins,
    start_datetime: convertDate(start),
    end_datetime: convertDate(end),
    // remove tvl as it is fetched in a different way
    items: items
      .filter(e => {
        return !cols.includes(e);
      })
      .join(','),
    frequency: freq
  };

  if (coinHistoryCache[JSON.stringify(req)]) {
    return coinHistoryCache[JSON.stringify(req)];
  }

  const res = await $http.post('/coins/historical_grouped', req);
  let data = res.data.data;
  if (freq == '1day') {
    data = data.map(i => {
      return { ...i, datetime: new Date(moment(i.datetime).format('YYYY-MM-DD')) };
    });
  }
  coinHistoryCache[JSON.stringify(req)] = data;

  return data;
}

async function getCoinUidsFromWatchlistUid(uid) {
  const watchlist = $store.getters.watchlists.coins.find(w => {
    return w?.id?.toString() === uid?.toString();
  });

  if (!watchlist) {
    return [];
  }

  return watchlist.entities.map(entity => {
    return entity.uid;
  });
}

async function getCoinUidsFromCategoryUid(uids) {
  const coins = [];
  const cat_ids = [];
  const subcat_ids = [];
  uids.forEach(a => {
    if (a.split('__')[0] == 'cat') {
      cat_ids.push(parseInt(a.split('__')[1]));
    } else {
      subcat_ids.push(parseInt(a.split('__')[1]));
    }
  });
  const uidNum = subcat_ids;

  if (cat_ids.length > 0) {
    ($store.state.categories.coin_sub_categories || []).forEach(x => {
      if (cat_ids.includes(parseInt(x.category_id))) uidNum.push(x.id);
    });
  }

  $store.getters.coins.forEach(coin => {
    uidNum.forEach(id => {
      if ((coin.category_ids || []).includes(parseInt(id))) {
        coins.push(coin.uid);
      }
    });
  });
  return coins.filter((v, i, a) => a.indexOf(v) === i);
}
</script>
