<template>
  <BaseDialog :model-value="true" size-class="!max-w-sm" @update:model-value="$emit('close')">
    <template #header>
      <div class="flex w-full items-start justify-end">
        <div class="flex items-center space-x-1 text-gray-400">
          <div @click="$emit('close')">
            <IconCross></IconCross>
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div class="flex flex-col items-center space-y-4 text-base font-medium leading-6">
        <ChatImage :item="company" size="xxl"></ChatImage>
        <div>
          <div class="flex w-full flex-col items-center justify-center space-y-1">
            <span class="cursor-pointer text-base font-semibold leading-6" @click="openCompanyPage">
              {{ company.name }}
            </span>
            <span class="text-xs font-medium leading-4 text-gray-400">{{ company.category }}</span>
          </div>
        </div>
        <div class="flex items-center justify-center space-x-4 text-xs font-medium leading-4 text-gray-500">
          <div class="flex items-center space-x-2" v-if="company.founding_year">
            <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-2.5 w-2.5">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29798 0.279005C5.11352 0.1736 4.88707 0.1736 4.70261 0.279005L0.502615 2.67901C0.214904 2.84341 0.114946 3.20992 0.279352 3.49763C0.389982 3.69124 0.592127 3.79982 0.800195 3.80005V7.99995C0.468824 7.99995 0.200195 8.26858 0.200195 8.59995C0.200195 8.93132 0.468824 9.19995 0.800195 9.19995H9.2002C9.53157 9.19995 9.8002 8.93132 9.8002 8.59995C9.8002 8.26858 9.53157 7.99995 9.2002 7.99995V3.80005C9.40834 3.7999 9.61058 3.6913 9.72124 3.49763C9.88565 3.20992 9.78569 2.84341 9.49798 2.67901L5.29798 0.279005ZM2.6002 4.39995C2.26882 4.39995 2.0002 4.66858 2.0002 4.99995V6.79995C2.0002 7.13132 2.26882 7.39995 2.6002 7.39995C2.93157 7.39995 3.2002 7.13132 3.2002 6.79995V4.99995C3.2002 4.66858 2.93157 4.39995 2.6002 4.39995ZM4.4002 4.99995C4.4002 4.66858 4.66882 4.39995 5.0002 4.39995C5.33157 4.39995 5.6002 4.66858 5.6002 4.99995V6.79995C5.6002 7.13132 5.33157 7.39995 5.0002 7.39995C4.66882 7.39995 4.4002 7.13132 4.4002 6.79995V4.99995ZM7.4002 4.39995C7.06882 4.39995 6.8002 4.66858 6.8002 4.99995V6.79995C6.8002 7.13132 7.06882 7.39995 7.4002 7.39995C7.73157 7.39995 8.0002 7.13132 8.0002 6.79995V4.99995C8.0002 4.66858 7.73157 4.39995 7.4002 4.39995Z"
                fill="#64748B"
              />
            </svg>
            <span> Founded in {{ company.founding_year }} </span>
          </div>
          <div class="flex items-center space-x-2" v-if="company.location">
            <IconPinLocation size="w-2.5 h-2.5 text-gray-400" />
            <span>{{ company.location }}</span>
          </div>
        </div>
      </div>
      <BaseTooltip class="p-4 text-center text-xs font-medium" v-if="company.description" position="right-end">
        <template #target>
          <p class="text-gray-100 line-clamp-3">{{ company.description }}</p>
        </template>
        <template #default>{{ company.description }}</template>
      </BaseTooltip>
      <div class="flex justify-center pt-4">
        <BaseButton @press="emitter.$emit('show-create-channel-modal', company.name)"> Message </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { ref, computed, inject } from 'vue';
import ChatImage from './ChatImage.vue';

const emitter = inject('eventHub');
const $emit = defineEmits(['close']);
const props = defineProps({
  company: { type: Object }
});
const descriptionElm = ref('description');
const showMore = ref(false);
const showSeeMore = ref(false);

function isTrimmed() {
  setTimeout(() => {
    if (document.getElementById('description').clientHeight >= 48) {
      showSeeMore.value = true;
    }
  });
}

const seeMoreEnabled = computed(() => {
  isTrimmed();
  return showSeeMore.value;
});

function openCompanyPage() {
  window.open('/company/' + props.company.slug);
}
</script>
