<template>
  <div class="ml-2 flex h-full flex-col py-2 text-right">
    <BasePercentClassic :percent="item.relative_change" class="text-xs font-medium" />
    <span class="text-xs font-normal">{{ rounded(Number(item.total_volume || ''), 2) }}</span>
  </div>
</template>
<script>
import FilterMixin from '@/mixins/filters';
export default {
  mixins: [FilterMixin],
  name: 'CellOnchainChanges',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  }
};
</script>
