<template>
  <div class="w-full">
    <div class="flex w-full animate-pulse items-center px-1">
      <div class="my-1 h-9 w-9 rounded-full bg-gray-800"></div>
      <div class="flex w-full items-center justify-between">
        <div class="w-full flex-1 space-y-2 py-1 px-2">
          <div class="h-4 w-1/2 rounded bg-gray-800"></div>
          <div class="h-3 w-1/4 rounded bg-gray-800"></div>
        </div>
        <div>
          <div class="h-4 w-1/2 rounded bg-gray-800 px-10"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadTemplatesSearchMessenger'
};
</script>
