<template>
  <TableBase
    class="pb-10"
    :config="{ ...config, ...tableOveride }"
    :data="actionItems"
    :is-focus-out="isFocusOut"
    :loading="loading"
    :paginate="paginate"
  >
    <slot />
  </TableBase>
</template>

<script>
export default {
  name: 'TableNewsAction',
  inheritAttrs: false,
  props: {
    data: Array,
    loading: Boolean,
    paginate: Function,
    tableOveride: Object,
    sourceShift: Boolean,
    leaveOffTime: Date,
    isFocusOut: Boolean,
    perPage: Number
  },
  data() {
    return {
      teamNewsItems: [],
      newsItems: [],
      config: {
        alternateBg: false,
        headerClass: 'hidden',
        rowClass: '',
        cols: [
          {
            type: 'news_action',
            size: 12,
            name: 'News',
            id: 'news'
          }
        ],
        perPage: this.perPage || 20
      }
    };
  },
  methods: {
    newsItemsUpdated() {
      this.$emit('news-items-updated', this.newsItems);
    }
  },
  async mounted() {
    this.newsItems = this.data;

    const { data } = await this.$http.get('/team_news_items');

    this.teamNewsItems = data;

    this.$eventHub.$on('newsItemUpdated', async newsItem => {
      if (newsItem.action === 'hidden') {
        this.newsItems = this.newsItems.filter(item => {
          return item.id !== newsItem.news_item_id;
        });
        this.newsItemsUpdated();
        return;
      }

      if (newsItem.action === 'starred') {
        this.newsItems = this.newsItems.map(item => {
          if (item.id === newsItem.news_item_id) {
            return {
              ...item,
              is_starred_by_the_tie: newsItem.is_starred_by_the_tie,
              starred_by_the_tie_user: newsItem.user
            };
          }

          return item;
        });
        this.newsItemsUpdated();
        return;
      }

      if (newsItem.action === 'added tag' || newsItem.action === 'deleted tag') {
        this.newsItems = this.newsItems.map(item => {
          if (item.id === newsItem.news_item.id) {
            return newsItem.news_item;
          }

          return item;
        });
        this.newsItemsUpdated();
        return;
      }

      let found = false;
      this.teamNewsItems = this.teamNewsItems.map(item => {
        if (item.news_item_id === newsItem.team_news_item.news_item_id) {
          found = true;
          return {
            ...item,
            is_starred_by_my_team: newsItem.team_news_item.is_starred_by_my_team,
            starred_by_team_member: newsItem.starred_by_team_member
          };
        }

        return item;
      });

      if (!found) {
        const addedNewsItem = {
          ...newsItem.team_news_item,
          starred_by_team_member: newsItem.starred_by_team_member
        };

        this.teamNewsItems.push(addedNewsItem);
      }

      this.newsItemsUpdated();
    });
  },
  computed: {
    actionItems() {
      const canEditByPost = {};
      const isStarredByPosed = {};

      if (!this.newsItems) {
        return [];
      }

      this.teamNewsItems.forEach(item => {
        canEditByPost[item.news_item_id] =
          item.starred_by_team_member && item.starred_by_team_member.email === this.$store.getters.userEmail;

        if (!item.is_starred_by_my_team) {
          return;
        }

        isStarredByPosed[item.news_item_id] = item.starred_by_team_member;
      });

      let gotToCheckpoint = false;
      return this.newsItems.map(newsItem => {
        const canEdit =
          canEditByPost[newsItem.id] === undefined
            ? true
            : canEditByPost[newsItem.id] || !isStarredByPosed[newsItem.id];
        const user = isStarredByPosed[newsItem.id] || { email: this.$store.getters.userEmail };
        const name = user.first_name ? `${user.first_name} ${user.last_name}` : user.email;
        const hasCheckpoint =
          !gotToCheckpoint && this.leaveOffTime
            ? new Date(newsItem.timestamp).getTime() <= new Date(this.leaveOffTime).getTime()
            : undefined;

        if (hasCheckpoint) {
          gotToCheckpoint = true;
        }

        return {
          ...newsItem,
          hasCheckpoint,
          checkpoint: this.leaveOffTime,
          sourceShift: this.sourceShift,
          starredInfo: {
            isStarred: isStarredByPosed[newsItem.id] ? true : false,
            canChange: canEdit,
            name
          }
        };
      });
    }
  },
  watch: {
    data() {
      this.newsItems = this.data;
    }
  }
};
</script>
