<template>
  <div :id="randomId" :class="sizeClass"></div>
</template>
<script>
import Highcharts from 'highcharts/highcharts';
import colors from 'tailwindcss/colors';
import global from '@/mixins/global';
import FilterMixin from '@/mixins/filters';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);
export default {
  mounted() {
    const self = this;
    self.generateChart();
    self.$eventHub.$on('reflow-highchart', function () {
      self.$nextTick(() => {
        self.chartObj.reflow();
      });
    });
  },
  beforeUnmount() {
    this.$eventHub.$off('reflow-highchart');
  },
  mixins: [global, FilterMixin],
  props: {
    series: { type: Array, default: () => [] },
    truncateLength: { type: Number, default: 10 },
    pieConfig: { type: Object, default: () => {} },
    downloadImage: { type: Boolean, default: false },
    sizeClass: { type: String, default: 'w-full' },
    inner: { type: Boolean, default: true },
    clickEvent: { type: Boolean, default: true }
  },
  computed: {
    randomId() {
      return Math.random().toString(36).substr(0, 8);
    },
    config() {
      const self = this;
      return {
        chart: {
          backgroundColor: 'transparent',
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        exporting: {
          enabled: false
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        colors: [
          colors.blue[900],
          colors.blue[800],
          colors.blue[700],
          colors.blue[600],
          colors.blue[500],
          colors.blue[400],
          colors.blue[300],
          colors.blue[200],
          colors.blue[100]
        ],
        series: this.inner
          ? [
              {
                type: 'pie',
                name: '',
                size: self.pieConfig?.size || '80%',
                dataLabels: {
                  enabled: false
                }
              },
              {
                type: 'pie',
                name: '',
                colorByPoint: true,
                size: self.pieConfig?.size || '70%',
                innerSize: '40%',
                dataLabels: {
                  distance: self.pieConfig?.distance || 30,
                  formatter: function () {
                    return this.point.name.length > this.truncateLength
                      ? self.truncate(this.point.name, 6) + this.point.name.slice(this.point.name.length - 7)
                      : this.point.name;
                  },
                  color: colors.gray[300],
                  style: {
                    textOutline: false
                  }
                }
              }
            ]
          : [
              {
                type: 'pie',
                name: '',
                colorByPoint: true,
                size: self.pieConfig?.size || '70%',
                dataLabels: false
              }
            ],
        tooltip: {
          shared: true,
          useHTML: true,
          backgroundColor: null,
          style: {
            color: 'white',
            pointerEvents: 'auto'
          },
          borderWidth: 0,
          formatter: function () {
            const tag =
              this.point && this.point.tag
                ? `<span class="select-none mr-4 px-2 py-1 my-1 text-xs
               rounded-sm cursor-pointer font-medium bg-gray-900 text-white ml-2 mt-">${this.point.tag}</span></span>`
                : '';
            const balance =
              this.point && this.point.balance
                ? `<br><b>Balance: </b><span>${self.formatNumber(this.point.balance, 2, '')}</span>`
                : '';
            return (
              "<div class='max-w-md break-words rounded border border-gray-700 bg-gray-800 px-4 py-2 text-left text-gray-200'>" +
              this.point.name +
              `<br><span>${self.roundSmallPercent(this.point.y, 2, 2)}% of total supply</span>` +
              tag +
              balance +
              '</div>'
            );
          }
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            events: {
              click: this.clickEvent ? self.graphClickEvent : null
            }
          },
          pie: {
            size: '100%',
            center: self.pieConfig?.center || ['50%', '50%'],
            animation: false,
            startAngle: 120,
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              distance: -30,
              color: colors.gray[300],
              style: {
                textOutline: false
              },
              formatter: function () {
                return '<b>' + this.point.name + '</b>: <br/>' + this.point.y;
              }
            }
          }
        }
      };
    }
  },
  data() {
    return {
      chartObj: undefined
    };
  },
  watch: {
    series(newVal, oldVal) {
      if (!this.arraysAreSame(newVal, oldVal)) {
        this.generateChart();
      }
    },
    downloadImage(val) {
      if (val) {
        this.chartObj.exportChart({
          type: 'image/png',
          filename: 'Chart'
        });
      }
    }
  },
  methods: {
    generateChart() {
      const self = this;
      const config = self.config;
      config.plotOptions.pie.data = self.series;
      self.chartObj = Highcharts.chart(self.randomId, config);
    },
    arraysAreSame(a, b) {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((x, idx) => x.name == b[idx].name && x.y == b[idx].y)
      );
    },
    graphClickEvent(event) {
      if (event.shiftKey) {
        this.$emit('remove', event.point.options);
      } else if (event.point.name != 'Others') {
        window.open('https://etherscan.io/address/' + event.point.address, '_blank');
      }
    }
  }
};
</script>
