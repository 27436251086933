<template>
  <div class="h-full overflow-y-auto">
    <PrismEditor
      :id="id"
      class="code-editor"
      v-model="editorInput"
      :highlight="highlighter"
      :line-numbers="true"
    ></PrismEditor>
  </div>
</template>
<script setup>
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

import { ref, watch } from 'vue';
const props = defineProps({
  modelValue: String,
  language: {
    type: String,
    default: 'js'
  }
});
const emit = defineEmits(['update:modelValue']);
const editorInput = ref(props.modelValue);
const id = ref(Math.random());
watch(editorInput, () => {
  emit('update:modelValue', editorInput.value);
});
watch(
  () => props.modelValue,
  (newVal, oldVal) => {
    editorInput.value = newVal;
  }
);
function highlighter(code) {
  if (props.language == 'css') {
    return highlight(code, languages.css);
  }
  if (props.language == 'python') {
    return highlight(code, languages.py);
  }
  return highlight(code, languages.js);
}
</script>
<style scoped>
.code-editor {
  background: #1e293b;
  border-radius: 4px;
  font-size: 12px;
  color: #ccc;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  line-height: 1.5;
  padding: 5px;
}
.prism-editor__textarea:focus {
  outline: none;
}
.prism-editor__textarea {
  width: 100%;
  height: 100%;
}
</style>
