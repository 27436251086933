<template>
  <tr class="table-source font-medium hover:bg-gray-800" :class="[index % 2 === 1 ? '' : 'bg-gray-800/25']">
    <td
      v-for="(column, i) in tableConfig"
      :key="column.name"
      class="px-1"
      :class="[i > 0 ? 'pl-4' : 'pl-1', i < tableConfig.length - 1 ? 'pr-4' : 'pr-1']"
      :style="{ 'text-align': column.textAlign }"
    >
      <template v-if="column.type == 'text'">
        <span v-if="source[column.name]">{{ source[column.name] }}</span>
      </template>
      <template v-else-if="column.type == 'percent'">
        <span v-if="source[column.name]"> {{ decimals(source[column.name], 2, 2) }}% </span>
      </template>
      <template v-else-if="column.type == 'pos_neg_percent'">
        <span v-if="source[column.name]" :class="source[column.name] > 0 ? 'text-green-400' : 'text-red-400'">
          {{ decimals(source[column.name], 2, 2) }}%
        </span>
      </template>
      <template v-else-if="column.type == 'number'">
        <span v-if="source[column.name]">{{ formatNumber(source[column.name]) }}</span>
      </template>
      <template v-else-if="column.type == 'usd'">
        <span v-if="source[column.name]">{{ usdCryptoPrice(source[column.name]) }}</span>
      </template>
      <template v-else-if="column.type == 'date'">
        <span v-if="source[column.name]"> {{ readableDate(source[column.name]) }} </span>
      </template>
      <template v-else-if="column.type == 'image'">
        <img v-if="source[column.name]" class="h-5 w-5" :src="source[column.name]" />
      </template>
      <template v-else-if="column.type == 'link'">
        <template v-if="source[column.name].text">
          <a v-if="source[column.name]" :href="source[column.name].link" target="_blank">{{
            source[column.name].text
          }}</a>
        </template>
        <template v-else>
          <a :href="source[column.name]" target="_blank">{{ source[column.name] }}</a>
        </template>
      </template>
      <template v-else-if="column.type == 'markdown'">
        <Markdown v-if="source[column.name]" :source="source[column.name]" :html="true" :linkify="true" />
      </template>
      <template v-else-if="column.type == 'html'">
        <div v-if="source[column.name]" v-html="source[column.name]"></div>
      </template>
      <template v-else-if="column.type == 'coin'">
        <CellWidgetCoin
          v-if="source[column.name]"
          :coin="source[column.name]"
          @coin-clicked="$emit('coin-clicked', $event)"
        />
      </template>
    </td>
  </tr>
</template>
<script>
import Markdown from 'vue3-markdown-it';
import CellWidgetCoin from '@/components/table/cell/CellWidgetCoin.vue';
import FilterMixin from '@/mixins/filters';
export default {
  mixins: [FilterMixin],
  components: {
    Markdown,
    CellWidgetCoin
  },
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default: () => {}
    },
    tableConfig: {
      type: Array,
      default: () => []
    }
  }
};
</script>
