<template>
  <div>
    <div>
      <div v-for="(source, index) in settings.sources" :key="index" class="py-1">
        <ChartSource
          :metric-options="metricOptions"
          :source="source"
          @change="changeSource($event, index)"
          @remove="removeSource(index)"
        />
      </div>
      <div v-if="settings.sources.length === 0" class="mt-4">
        <div class="">
          <BaseMenu
            @action="addNewSource($event)"
            :items="sourceTypeOptions"
            width-class="w-36"
            class="mx-auto flex w-36 justify-center whitespace-nowrap rounded-full bg-blue-900 py-1.5 hover:bg-blue-800"
          >
            <span class="pr-1">+ Add New Data</span>
          </BaseMenu>
        </div>
      </div>
      <div v-if="widgetOnly && savedLayouts.length > 0" class="mt-8 text-left">
        <div class="mb-8 text-sm font-medium uppercase tracking-wider text-gray-400">OR</div>
        <BaseSelect
          searchable
          :options="savedLayouts"
          v-model="selectedLayout"
          placeholder="Select a layout"
          button-width-class="w-52 truncate"
          dropdown-width-class="w-52"
          @update:model-value="handleLayoutSelect"
        />
      </div>
    </div>
    <div class="mt-2 flex items-center justify-between pb-20" v-if="settings.sources.length > 0">
      <BaseMenu
        @action="addNewSource($event)"
        :items="sourceTypeOptions"
        width-class="w-36"
        position="right"
        class="mx-auto flex w-36 justify-center whitespace-nowrap rounded-full bg-blue-900 py-1.5 hover:bg-blue-800"
      >
        <span class="pr-1">+ Add New Data</span>
      </BaseMenu>
    </div>
  </div>
</template>

<script setup>
import ChartSource from './ChartSource.vue';
import DropdownSearch from '../dropdown/DropdownSearch.vue';
import { ref, onMounted, computed, watch } from 'vue';
import { useStore } from 'vuex';
const emit = defineEmits(['change', 'selected']);
const $store = useStore();
const props = defineProps({
  settings: Object,
  anchor: String,
  savedLayouts: Array,
  widgetOnly: Boolean,
  currentLayout: Object,
  metricOptions: {
    type: Array,
    default: () => []
  }
});
const title = ref('Select a Layout');
const sourceTypeOptions = ref([
  { id: 'coin', name: 'Coin', label: 'Coin', action: 'coin' },
  { id: 'category', name: 'Category', label: 'Category', action: 'category' }
]);
onMounted(() => {
  if ($store.getters.watchlists.coins.length) {
    sourceTypeOptions.value.push({ id: 'watchlist', name: 'Watchlist', label: 'Watchlist', action: 'watchlist' });
  }
  emit('change', props.settings);
});
watch(
  () => props.currentLayout,
  () => {
    if (props.currentLayout) {
      title.value = props.currentLayout.name;
    } else {
      title.value = 'Select a Layout';
    }
  }
);
function removeSource(index) {
  const sources = props.settings.sources;
  sources.splice(index, 1);
  emit('change', {
    ...props.settings,
    sources
  });
}
function addNewSource(typeId) {
  let type = sourceTypeOptions.value.find(sto => sto.id == typeId);
  const sources = props.settings.sources;
  sources.push({
    type: type.id,
    name: `Source ${props.settings.sources.length + 1}`,
    relative: false,
    metric: ['price'],
    chain: 'ethereum',
    chartType: 'line',
    ...(type.id === 'coin'
      ? {
          uids: ['bitcoin']
        }
      : {}),
    ...(type.id === 'category'
      ? {
          displayMode: 'indexed',
          uids: [`cat__${$store.state.categories.coin[0]?.id}`]
        }
      : {}),
    ...(type.id === 'watchlist'
      ? {
          displayMode: 'indexed',
          uid: $store.getters.watchlists.coins[0]?.id
        }
      : {})
  });
  emit('change', {
    ...props.settings,
    sources
  });
}

const selectedLayout = ref(0);
function handleLayoutSelect(id) {
  const layout = props.savedLayouts.find(x => x.id == id);
  emit('selected', layout);
}

function changeSource(newSource, index) {
  emit('change', {
    ...props.settings,
    sources: props.settings.sources.map((source, i) => {
      if (i === index) {
        return newSource;
      }
      return source;
    })
  });
}
const templates = computed(() => {
  const ethEcosystem = $store.state.categories.coin_sub_categories
    ? $store.state.categories.coin_sub_categories.find(subCategory => {
        return subCategory.name === 'Ethereum Ecosystem';
      })
    : undefined;
  return [
    {
      name: 'BTC & ETH Relative Price vs Tweet Volume',
      sources: [
        {
          type: 'coin',
          name: 'Source 1',
          relative: true,
          metric: ['price', 'tweet_volume'],
          chartType: 'line',
          uids: ['bitcoin', 'ethereum']
        }
      ],
      sameAxis: true
    },
    {
      name: 'Ethereum TVL Metrics',
      sources: [
        {
          type: 'coin',
          name: 'Source 1',
          relative: false,
          metric: ['tvl', 'mcap_/_tvl'],
          chartType: 'line',
          uids: ['ethereum']
        }
      ],
      sameAxis: true
    },
    {
      name: 'Ethereum Ecosystem Performance',
      sources: [
        {
          type: 'category',
          name: 'Source 1',
          displayMode: 'individual',
          relative: true,
          metric: ['price'],
          chartType: 'line',
          uid: ethEcosystem ? `subcat__${ethEcosystem.id}` : undefined,
          reducedCoins: ['ethereum', 'shibainu', 'axieinfinity', 'aave', 'thegraph']
        }
      ],
      sameAxis: true
    }
  ];
});
</script>
