<template>
  <div class="mt-4 space-y-4">
    <div class="space-y-1">
      <span class="block pb-2 text-xs text-gray-400">Token (required)</span>
      <DropdownSelectOnChainToken
        key="token-select-dropdown"
        :placeholder="`Search token`"
        :only-one="true"
        :starting-tokens="preSelectedTokens"
        @token-changed="selectedToken = $event"
      />
    </div>
    <div class="space-y-1">
      <span class="block text-xs text-gray-400">Only show Transactions made by Wallets holding over</span>
      <div class="flex text-xs capitalize text-gray-400">
        <BaseTags :tags="filteredWallet" :selected="[selectedWalletTag]" @toggle="selectedWalletTag = $event" />
        <BaseNumberInput
          class="w-1/4"
          v-if="selectedWalletTag == 'custom'"
          placeholder="Custom"
          v-model="customWallet"
        />
      </div>
      <span class="text-xs text-red-500" v-if="walletError">Please enter value greater than 100k</span>
    </div>
    <div class="space-y-1">
      <span class="block text-xs text-gray-400">Only show Transactions bigger than</span>
      <div class="flex text-xs capitalize text-gray-400">
        <BaseTags :tags="filteredTxn" :selected="[selectedTxnTag]" @toggle="selectedTxnTag = $event" />
        <BaseNumberInput class="w-1/4" v-if="selectedTxnTag == 'custom'" placeholder="Custom" v-model="customTxn" />
      </div>
      <span class="text-xs text-red-500" v-if="txnError">Please enter value greater than 100k</span>
    </div>
  </div>
</template>
<script>
import DropdownSelectOnChainToken from '@/components/dropdown/DropdownSelectOnChainToken.vue';
import { isEmpty } from 'lodash';
export default {
  name: 'WidgetConfigsDexFilters',
  emits: ['update'],
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    if (this.config && !isEmpty(this.config)) {
      if (this.config.transactionSize != '') {
        let index = this.txnFilters.findIndex(x => x.id == this.config.transactionSize);
        if (index >= 0) {
          this.selectedTxnTag = this.config.transactionSize;
        } else {
          this.selectedTxnTag = 'custom';
          this.customTxn = this.config.transactionSize;
        }
      }
      if (this.config.holderSize != '') {
        let index = this.walletFilters.findIndex(x => x.id == this.config.holderSize);
        if (index >= 0) {
          this.selectedWalletTag = this.config.holderSize;
        } else {
          this.selectedWalletTag = 'custom';
          this.customWallet = this.config.holderSize;
        }
      }
    }
  },
  components: {
    DropdownSelectOnChainToken
  },
  computed: {
    walletError() {
      return this.selectedWalletTag == 'custom' && this.customWallet < 100000;
    },
    txnError() {
      return this.selectedTxnTag == 'custom' && this.customTxn < 100000;
    },
    validConfig() {
      return this.selectedToken.length > 0 && !this.walletError && !this.txnError;
    },
    configuration() {
      return {
        valid: this.validConfig,
        token: this.selectedToken.length > 0 ? this.selectedToken[0].token : '',
        coin_uid: this.selectedToken.length > 0 ? this.selectedToken[0].coin_uid : '',
        transactionSize: this.selectedTxnTag == 'custom' ? this.customTxn : this.selectedTxnTag,
        holderSize: this.selectedWalletTag == 'custom' ? this.customWallet : this.selectedWalletTag
      };
    },
    filteredWallet() {
      return this.selectedWalletTag == 'custom' ? this.walletFilters.filter(i => i.id != 'custom') : this.walletFilters;
    },
    filteredTxn() {
      return this.selectedTxnTag == 'custom' ? this.txnFilters.filter(i => i.id != 'custom') : this.txnFilters;
    }
  },
  data() {
    return {
      selectedToken: '',
      customWallet: '',
      customTxn: '',
      selectedWalletTag: '',
      selectedTxnTag: '',
      walletFilters: [
        { id: '500000', title: '$500k' },
        { id: '1000000', title: '$1m' },
        { id: '3000000', title: '$3m' },
        { id: 'custom', title: 'Custom' }
      ],
      txnFilters: [
        { id: '100000', title: '$100k' },
        { id: '250000', title: '$250k' },
        { id: '500000', title: '$500k' },
        { id: 'custom', title: 'Custom' }
      ],
      preSelectedTokens: this.config ? [this.config.token] : []
    };
  },
  watch: {
    configuration() {
      this.$emit('update', this.configuration);
    }
  }
};
</script>
<style>
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
