<template>
  <div class="relative inline-block text-left">
    <div @mouseenter="droppedDown = true" @mouseleave="droppedDown = false">
      <div
        class="flex w-full cursor-pointer select-none items-center justify-center space-x-0.5 text-xs text-gray-400"
        :id="dropdownId"
      >
        <IconUser />
        <IconChevronDown :class="{ 'rotate-180': droppedDown }" class="-ml-0.5 transform duration-100 ease-in-out" />
      </div>

      <div class="absolute right-0 z-50" v-if="droppedDown">
        <DropdownBase
          :items="dropdownItems"
          :link-items="true"
          custom-classes="mt-0"
          @clicked="handleClicked($event)"
          dropdown-width="200px"
        />
      </div>
      <ModalFeedback :show="showFeedbackModal" :savvycal-link="this.savvycalLink" @close="showFeedbackModal = false" />
    </div>
  </div>
</template>

<script>
import DropdownBase from '@/components/dropdown/DropdownBase.vue';
import ModalFeedback from '@/components/modal/ModalFeedback.vue';
export default {
  components: {
    DropdownBase,
    ModalFeedback
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.close);
  },
  props: {
    userEmail: String,
    userFirstName: String,
    userLastName: String,
    savvycalLink: String
  },
  methods: {
    close: function (e) {
      const drop = document.getElementById(this.dropdownId);
      if (!drop.contains(e.target) && this.droppedDown && e.target.id !== this.dropdownId) {
        this.droppedDown = false;
      }
    },
    toggleDropdown: function () {
      this.droppedDown = !this.droppedDown;
      this.showDropdown = !this.showDropdown;
    },
    handleClicked: function (id) {
      if (id == 'feedback') {
        this.showFeedbackModal = !this.showFeedbackModal;
      } else if (id === 'sign_out') {
        localStorage.removeItem('selectedChats');
        localStorage.removeItem('closedChats');
        localStorage.removeItem('chatSidebarOpened');
        window.location.replace('/users/sign_out');
      }
    }
  },
  data() {
    return {
      showFeedbackModal: false,
      dropdownId: `profile_dropdown_${Math.random()}`,
      droppedDown: false,
      items: [
        { link: '/settings', text: 'Settings' },
        { link: '/sidekiq', text: 'Sidekiq', permission: 'sidekiq' },
        { link: '/admin/settings', text: 'Admin', permission: 'admin_settings' },
        { text: 'Send Feedback', id: 'feedback', openModal: true },
        { link: '/users/sign_out', text: 'Sign Out', id: 'sign_out' }
      ],
      showDropdown: false
    };
  },
  computed: {
    dropdownItems() {
      return this.items.filter(item => !item.permission || this.$store.getters.permissions.includes(item.permission));
    }
  }
};
</script>
