<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :overrides="overrides"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      @expand="goToExpandLink"
      @channel-update="handleChannelUpdate"
    >
      <template #body>
        <template v-if="resourceNotFound">
          <div class="mt-10">
            <WidgetsErrorMessage>{{ titleize(widget.configuration.source) }} is missing.</WidgetsErrorMessage>
          </div>
        </template>
        <template v-if="height >= 150">
          <TableBase
            :config="tableConfig"
            :data="tableCoins"
            :default-sort-order="currentSortConfig ? currentSortConfig.order : undefined"
            :default-sort-column="currentSortConfig ? currentSortConfig.col : undefined"
            @coin-clicked="handleCoinClick"
            @sort-screener="currentSortConfig = $event"
          />
        </template>
        <template v-else>
          <div class="flex space-x-1 pt-2">
            <div
              v-for="coin in sortedCoins"
              :key="coin.coin_uid"
              @click="handleCoinClick(coin)"
              class="w-14 flex-shrink-0 cursor-pointer items-center justify-center rounded py-1 px-2 hover:bg-gray-800"
              :class="currentActiveCoin && currentActiveCoin.coin_uid == coin.coin_uid ? 'bg-blue-900/25' : ''"
            >
              <div class="flex flex-col items-center justify-center">
                <BaseCoinIcon :coin="coin.coin_uid" class="mt-1 h-6 w-6" :style-class="''" />
                <span class="mt-1 text-sm text-gray-100">{{ coin.ticker }}</span>
              </div>
            </div>
          </div>
        </template>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';
import FilterMixin from '@/mixins/filters';
export default {
  mixins: [WidgetsMixin, FilterMixin],
  data() {
    return {
      watchlists: [],
      watchlistCoins: [],
      advanceLayout: {},
      currentActiveCoin: null,
      currentSortConfig: null,
      readyToDisplay: false,
      resourceNotFound: false
    };
  },
  beforeMount() {
    if (this.selectedResource && this.selectedResource.entity != 'coins') {
      this.getEntity();
    } else {
      this.readyToDisplay = true;
    }

    if (this.subscribeOnlyMode && this.overrides) {
      if (this.overrides.active_coin) {
        this.currentActiveCoin = this.overrides.active_coin;
      }
      if (this.overrides.sorting) {
        this.currentSortConfig = this.overrides.sorting;
      }
      if (this.overrides.connected_channel) {
        this.writeToStore(this.overrides.connected_channel);
      }
    } else {
      const config = this.widget.configuration;
      if (config && config.active_coin) {
        this.currentActiveCoin = config.active_coin;
      }
      if (config && config.sorting) {
        this.currentSortConfig = config.sorting;
      }
      this.writeToStore(this.widget.connected_channel);
    }
  },
  beforeUnmount() {
    this.$eventHub.$off('widget-coin-click');
  },
  computed: {
    selectedResource() {
      let config = this.widget.configuration;
      if (config) {
        let id = 0;
        if (config.watchlist) {
          id = config.watchlist.id;
        }
        if (config.layout) {
          id = config.layout.id;
        }
        return { entity: config.source, id: id };
      }
      return null;
    },
    allDatapoints() {
      return this.$store.getters.coinDatapoints;
    },
    allCoins() {
      return this.$store.getters.coins;
    },
    tableConfig() {
      let general = {
        alternateBg: true,
        cols: [],
        headerClass: 'sticky top-0 bg-gray-900 z-30 capitalize',
        horizontalScroll: true,
        rowClass: 'py-2 hover:bg-gray-800 duration-100',
        highlightRow: this.currentActiveCoin ? this.currentActiveCoin.coin_uid : null
      };
      if (this.readyToDisplay) {
        let widgetConfig = this.widget.configuration;

        let selectedCols = widgetConfig ? widgetConfig.columns : [];
        if (widgetConfig && widgetConfig.layout && this.advanceLayout) {
          general.cols = this.advanceLayout.filter.columns;
        } else {
          general.cols = this.allDatapoints.filter(x => x.default || selectedCols.includes(x.id));
        }

        //replace coins with widget_coin type
        general.cols = general.cols.map(x => {
          if (x.type == 'coin') {
            return { ...x, type: 'widget_coin', cellClass: '!pl-0', headerClass: '!pl-0 px-1' };
          }
          if (x.id == '#') {
            return {
              ...x,
              cellClass: '!pl-1',
              headerClass: '!pl-1'
            };
          }
          return x;
        });

        if (this.width < 350) {
          general.cols = general.cols.filter(x => x.id != '#');
        }
      }
      return general;
    },
    tableCoins() {
      let filteredCoins = [...this.allCoins];
      if (this.readyToDisplay) {
        const config = this.widget.configuration;
        if (config) {
          if (config.source == 'coins' && config.coins.length > 0) {
            filteredCoins = filteredCoins.filter(x => config.coins.includes(x.coin_uid));
          } else if (config.source == 'watchlist' && config.watchlist) {
            if (!this.watchlistCoins.length) {
              filteredCoins = [];
            } else {
              let coins = this.watchlistCoins.map(x => x.uid);
              filteredCoins = filteredCoins.filter(x => coins.includes(x.coin_uid));
            }
          } else if (config.source == 'layout' && this.advanceLayout.filter) {
            //apply advance filtering here
            let activeFilters = this.advanceLayout.filter.search.filters.filter(x => {
              if (x.type == 'array' && x.data.length > 0) {
                return true;
              }
              if (x.type == 'minmax' && (x.data.min != '' || x.data.max != '')) {
                return true;
              }
              if (x.type == 'search' && x.data.trim().length > 0) {
                return true;
              }
              if (x.type == 'selected_coins' && x.data.length > 0) {
                return true;
              }
              return false;
            });
            if (this.advanceLayout.filter.search.filter_by_top_100) {
              filteredCoins = filteredCoins.slice(0, 100);
            }
            filteredCoins = filteredCoins.filter(coin => {
              return activeFilters.every(filter => {
                if (filter.key == 'selected_coins') {
                  let uids = filter.data.map(x => x.uid);
                  return uids.includes(coin.coin_uid);
                } else {
                  let coinData = parseFloat(coin[filter.key]);
                  if (['percent', 'simple_percent'].includes(filter.datatype)) {
                    coinData = filter.multiply != false ? coinData * 100 : coinData; //percentages should be multiplied by 100 before filter
                  }
                  if (filter.key == 'category_ids') {
                    return coin.category_ids.some(category_id => filter.data.includes(category_id));
                  } else if (filter.key == 'watchlist_ids') {
                    let wl = this.watchlists.find(wl => filter.data == wl.watchlist);
                    if (wl) {
                      return wl.coins.filter(e => e == coin.uid).length > 0;
                    }
                    return false;
                  } else if (filter.data.min && filter.data.max) {
                    return coinData && coinData >= filter.data.min && coinData <= filter.data.max;
                  } else if (filter.data.min) {
                    return coinData && coinData >= filter.data.min;
                  } else if (filter.data.max) {
                    return coinData && coinData <= filter.data.max;
                  } else if (!filter.data.min && !filter.data.max) {
                    return true;
                  }
                }
                return true;
              });
            });
          }
        }
      }
      return filteredCoins.map(x => {
        return {
          ...x,
          width: this.width,
          highlight: (x.id == this.currentActiveCoin?.id ? x.coin_uid : null) || null
        };
      });
    },
    sortedCoins() {
      let sorted = this.tableCoins;
      if (this.currentSortConfig) {
        let sortMethod = this.currentSortConfig.type;
        let sortCol = this.currentSortConfig.col;
        if (sortMethod === 'number' || sortMethod === 'alphabetical' || sortMethod === 'timestamp') {
          const mutpl = this.currentSortConfig.order === 'ASC' ? -1 : 1;
          sorted = sorted.sort((a, b) => {
            let aVal = '';
            let bVal = '';
            if (sortMethod === 'timestamp') {
              aVal = a[sortCol.id] ? new Date(a[sortCol.id]) : '';
              bVal = b[sortCol.id] ? new Date(b[sortCol.id]) : '';
            } else {
              aVal = sortCol.type === 'coin' ? a[sortCol.id].name : a[sortCol.id];
              bVal = sortCol.type === 'coin' ? b[sortCol.id].name : b[sortCol.id];
            }

            const condition = sortMethod === 'number' ? parseFloat(aVal) > parseFloat(bVal) : aVal > bVal;

            if (condition) {
              return (aVal === null) - (bVal === null) || 1 * mutpl;
            }

            return (aVal === null) - (bVal === null) || (aVal === undefined) - (bVal === undefined) || -1 * mutpl;
          });
        }
      }
      return sorted;
    },
    widgetOutputValue() {
      if (this.currentActiveCoin) {
        return [this.currentActiveCoin];
      }
      return [];
    }
  },
  watch: {
    selectedResource: {
      deep: true,
      handler: function () {
        this.getEntity();
      }
    },
    'widget.configuration': {
      deep: true,
      handler: function () {
        if (this.widget.configuration?.source != 'coins') {
          this.getEntity();
        }
      }
    },
    currentSortConfig() {
      this.updateWidgetConfig();
    }
  },
  methods: {
    handleChannelUpdate(payload) {
      this.$store.commit('clearWidgetOutputChannel', payload.from);
      this.writeToStore(payload.to);
      if (!payload.to) {
        this.currentActiveCoin = null;
        this.updateWidgetConfig();
      }
    },
    handleCoinClick(coin) {
      const channel = this.overrides?.connected_channel || this.widget.connected_channel;
      if (!channel) {
        window.open(`/coin/${coin.coin_uid}`, '_blank');
      } else {
        if (this.currentActiveCoin && coin.id == this.currentActiveCoin.id) {
          this.currentActiveCoin = null;
        } else {
          this.currentActiveCoin = coin;
        }
        this.updateWidgetConfig();
        this.writeToStore(channel);
      }
    },
    goToExpandLink() {
      window.open('/screener', '_blank');
    },
    writeToStore(channel) {
      if (channel) {
        this.$store.commit('setWidgetOutputChannel', {
          channel: channel,
          value: this.widgetOutputValue
        });
      }
    },
    async updateWidgetConfig() {
      if (!this.viewOnlyMode) {
        if (this.subscribeOnlyMode) {
          await await this.$http.post('/widget_overrides', {
            widget_override: {
              dashboard_widget_id: this.widget.id,
              overrides: {
                active_coin: this.currentActiveCoin,
                sorting: this.currentSortConfig
              }
            }
          });
        } else {
          let payload = {
            configuration: {
              ...this.widget.configuration,
              active_coin: this.currentActiveCoin,
              sorting: this.currentSortConfig
            }
          };
          await this.$http.patch(`/dashboard_widgets/${this.widget.id}`, payload);
        }
      }
    },
    async getEntity() {
      if (this.selectedResource && this.selectedResource.id > 0) {
        try {
          this.loading = true;
          this.readyToDisplay = false;
          let response = await this.$http.post('/shared_resources', this.selectedResource);
          if (this.selectedResource.entity == 'watchlist') {
            this.watchlistCoins = response.data.extras;
          }
          if (this.selectedResource.entity == 'layout') {
            this.watchlists = response.data.extras;
            this.advanceLayout = response.data.resource;
          }
          this.readyToDisplay = true;
          this.loading = false;
        } catch {
          this.loading = false;
          this.resourceNotFound = true;
          //do nothing
        }
      }
    }
  }
};
</script>
