<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="false"
      :connectable="false"
      @expand="goToExpandLink"
    >
      <template #body>
        <div>
          <div>
            <div class="flex items-center py-2">
              <input
                class="block w-44 rounded border border-gray-700 bg-transparent px-4 py-1 text-xs outline-none focus:border-gray-500 focus:outline-none"
                placeholder="Search Profile"
                v-model="searchQuery"
                ref="search"
                @input="applySearch"
                @click="$refs.search.focus()"
              />
            </div>
          </div>
          <div>
            <table class="w-full text-xs font-medium text-gray-200">
              <thead>
                <tr class="sticky top-0 border-0 bg-gray-900 text-gray-400">
                  <th class="whitespace-nowrap py-2 text-left">Indicator Profile</th>
                  <th class="whitespace-nowrap py-2 text-left">Date Followed</th>
                  <th class="whitespace-nowrap py-2 text-left">Followed Profile</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td colspan="3">
                      <div class="flex w-full justify-center">
                        <BaseLoader />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <template v-if="transformedRecentFollows.length">
                    <tr
                      class="border-none text-xs"
                      v-for="(follow, idx) in transformedRecentFollows"
                      :key="idx"
                      :class="idx % 2 == 0 ? 'bg-gray-800/25' : ''"
                    >
                      <td class="pr-2">
                        <CellProfile
                          class="whitespace-nowrap"
                          :profile="follow.indicator_user"
                          image-size="w-5 h-5"
                          :name-character-limit="15"
                        />
                      </td>
                      <td class="pr-2">{{ minimalTimeWithinToday(follow.datetime_followed) }}</td>
                      <td>
                        <CellProfile :profile="follow.followed_user" image-size="w-5 h-5" :name-character-limit="15" />
                      </td>
                      <td></td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="3">
                        <NoDataFound class="text-xs" />
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';
import { getRecentFollows } from '@/api/apefluencer';
import CellProfile from '@/components/table/cell/CellProfile.vue';
import { minimalTimeWithinToday } from '@/composeables/filters';
import moment from 'moment';
import { debounce } from 'lodash';
export default {
  name: 'WidgetsApefluencerFollowsTracker',
  mixins: [WidgetsMixin],
  mounted() {
    this.getData();
  },
  components: {
    CellProfile
  },
  data() {
    return {
      minimalTimeWithinToday: minimalTimeWithinToday,
      startDate: moment().utc().subtract(1, 'day').format('YYYY-MM-DD'),
      searchQuery: '',
      recentFollows: []
    };
  },
  computed: {
    parameters() {
      return {
        page: 0,
        search: this.searchQuery,
        start_datetime: this.startDate
      };
    },
    transformedRecentFollows() {
      return this.recentFollows.map(x => {
        return {
          datetime_followed: x.datetime_followed,
          indicator_user: {
            name: x.indicator_metadata?.screen_name,
            subtext: x.indicator_metadata?.description,
            externalLink: x.indicator_metadata?.profile_url_https,
            imageUrl: x.indicator_metadata?.profile_image_url_https
          },
          followed_user: {
            name: x.user_followed_metadata?.screen_name,
            externalLink: x.user_followed_metadata?.profile_url_https,
            imageUrl: x.user_followed_metadata?.profile_image_url_https
          },
          num_follows: x.user_followed_metadata?.followers_count,
          description: x.user_followed_metadata?.description,
          indicator_group: ''
        };
      });
    }
  },
  methods: {
    applySearch: debounce(function () {
      this.getData();
    }, 500),
    async getData() {
      try {
        this.loading = true;
        const response = await getRecentFollows(this.parameters);
        if (response && response.data) {
          this.recentFollows = response.data;
        } else {
          this.recentFollows = [];
        }
      } finally {
        this.loading = false;
      }
    },
    goToExpandLink() {
      window.open('/tools/apefluencer', '_blank');
    }
  }
};
</script>
