<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      :connectable="false"
      :hidden-actions="['expand']"
    >
      <template #body>
        <template v-if="!loading">
          <template v-if="height > 100">
            <table class="w-full">
              <thead>
                <tr>
                  <th class="h-8 bg-gray-900 px-1 text-xs font-medium text-gray-400"></th>
                  <th class="h-8 bg-gray-900 px-1 text-right text-xs font-medium text-gray-400">Standard</th>
                  <th class="h-8 bg-gray-900 px-1 text-right text-xs font-medium text-gray-400">Fast</th>
                  <th class="h-8 bg-gray-900 px-1 text-right text-xs font-medium text-gray-400">Instant</th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-none">
                  <td class="py-0"></td>
                  <template v-for="(fee, idx) in gweiFee" :key="idx">
                    <td class="whitespace-nowrap px-3 text-right font-semibold">{{ decimals(fee.gwei, 0, 0) }} Gwei</td>
                  </template>
                </tr>
                <tr v-for="(gf, idx) in gasFees" class="border-none" :key="idx">
                  <td class="whitespace-nowrap px-3 py-1 text-sm text-gray-300">{{ gf.description }}</td>
                  <td class="px-3 py-1 text-right">
                    <span class="text-sm text-white">${{ decimals(gf.fees.standard.usd, 2, 2) }}</span>
                  </td>
                  <td class="px-3 py-1 text-right">
                    <span class="text-sm text-white">${{ decimals(gf.fees.fast.usd, 2, 2) }}</span>
                  </td>
                  <td class="px-3 py-1 text-right">
                    <span class="text-sm text-white">${{ decimals(gf.fees.instant.usd, 2, 2) }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <div class="flex w-full">
              <div v-for="t in ['standard', 'fast', 'instant']" :key="t" class="w-1/3">
                <span class="block text-xs font-medium capitalize text-gray-400">{{ t }}</span>
                <span class="whitespace-nowrap font-semibold">{{ decimals(gweiFee[t].gwei, 0, 0) }} Gwei</span>
              </div>
            </div>
          </template>
        </template>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>
<script>
import WidgetsMixin from '@/mixins/widgets';
import FilterMixin from '@/mixins/filters';
export default {
  name: 'WidgetsGasFee',
  mixins: [WidgetsMixin, FilterMixin],
  mounted() {
    this.loading = true;
    this.getGasFees();
    setInterval(() => {
      this.getGasFees();
    }, 60000);
  },
  data() {
    return {
      gweiFee: {
        standard: { gwei: 0 },
        fast: { gwei: 0 },
        instant: { gwei: 0 }
      },
      gasFees: {}
    };
  },
  methods: {
    getGasFees() {
      this.$http.get('/gas_fees').then(response => {
        this.gasFees = response.data;
        this.gweiFee = this.gasFees['swap_transaction'].fees;
        this.loading = false;
      });
    }
  }
};
</script>
