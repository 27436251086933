<template>
  <div>
    {{ rounded(item.token, 2, '$') }}<span class="text-gray-400"> ({{ formatNumber(item.amount, 2, '$') }})</span>
  </div>
</template>

<script>
import FilterMixin from '@/mixins/filters';
export default {
  name: 'CellTokenAmount',
  mixins: [FilterMixin],
  props: {
    item: Object
  }
};
</script>
