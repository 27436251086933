<template>
  <div class="cursor-pointer text-white" :class="width ? width : ''" @click="toggleDropdown()" :id="dropdownId">
    <div :class="roundedClass" class="flex items-center justify-between bg-gray-800 py-2 px-4">
      <span
        v-if="typeof selectedItem == 'object' && selectedItem.html"
        class="block"
        :class="capitalize ? 'capitalize' : ''"
        v-html="selectedItem.html"
      ></span>
      <span v-else class="block" :class="capitalize ? 'capitalize' : ''">
        {{ truncate(selectedItem.text, truncateValue) }}
      </span>
      <IconChevronDown :class="{ 'rotate-180': droppedDown }" class="transform duration-100 ease-in-out" />
    </div>
    <div class="relative z-50 text-left">
      <DropdownBase
        v-if="droppedDown"
        class="mt-1"
        :items="items"
        :custom-classes="dropdownItemClasses"
        :max-height="maxHeight"
        :capitalize="capitalize"
        :sort-options="sortOptions"
        :sort-key="sortKey"
        :sort-order="sortOrder"
        @clicked="itemSelected($event)"
      />
    </div>
  </div>
</template>

<script>
import DropdownBase from '@/components/dropdown/DropdownBase.vue';
import FilterMixin from '@/mixins/filters';
export default {
  mixins: [FilterMixin],
  emits: ['selected'],
  components: {
    DropdownBase
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.close);
  },
  props: {
    items: { type: Array, default: () => [] },
    activeItem: { type: [Number, String], default: null },
    placeholder: { type: String, default: 'Select' },
    truncateValue: { type: Number, default: 12 },
    maxHeight: { type: String, default: 'max-h-64' },
    dropdownItemClasses: { type: String, default: '' },
    roundedClass: { type: String, default: 'rounded' },
    capitalize: { type: Boolean, default: true },
    width: { type: String, default: null },
    sortOptions: { type: Boolean, default: false },
    sortKey: { type: String, default: 'label' },
    sortOrder: { type: String, default: 'asc' }
  },
  data() {
    return {
      dropdownId: `simple_dropdown_${Math.random()}`,
      droppedDown: false,
      activeItemId: this.activeItem || 0
    };
  },
  watch: {
    activeItem(newVal) {
      this.activeItemId = newVal;
    }
  },
  computed: {
    selectedItem() {
      const item = this.items.find(x => x.id == this.activeItemId);
      return item || { text: this.placeholder };
    }
  },
  methods: {
    close: function (e) {
      const drop = document.getElementById(this.dropdownId);
      if (drop && !drop.contains(e.target) && this.droppedDown && e.target.id !== this.dropdownId) {
        this.droppedDown = false;
      }
    },
    toggleDropdown: function () {
      this.droppedDown = !this.droppedDown;
    },
    itemSelected: function (id) {
      const item = this.items.find(x => x.id == id);
      this.activeItemId = item.id;
      this.$emit('selected', item);
    }
  }
};
</script>
