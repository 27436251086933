<template>
  <div class="text-sm">
    <a :href="item.link" target="_blank"><p class="line-clamp-1" v-html="item.headline"></p></a>
    <div class="mt-px flex space-x-1 text-xs">
      <div class="flex space-x-2">
        <div v-for="tag in item.tags" :key="tag.id">
          <span
            :class="[
              'font-medium',
              getTagColor(tag.tag_subgroup.name),
              tag.tag_subgroup.name == 'Coins' ? 'cursor-pointer' : ''
            ]"
            @click="tag.tag_subgroup.name == 'Coins' ? onCoinClick(tag) : null"
          >
            {{ tag.name }}
          </span>
        </div>
      </div>
      <span class="text-gray-700">|</span>
      <span class="text-secondary"> {{ item.sources.map(s => s.name).join(',') }}</span>
    </div>
  </div>
</template>

<script>
import global from '../../../mixins/global';
export default {
  name: 'CellSmallNews',
  mixins: [global],
  props: {
    item: Object,
    handleCoinClick: Function
  },
  methods: {
    onCoinClick: function (tag) {
      if (this.handleCoinClick) {
        var coin = tag.coin ? tag.coin.uid : '';
        this.handleCoinClick(coin);
        return;
      }
    }
  },
  data() {
    return {};
  }
};
</script>
