<template>
  <div class="absolute top-0 left-0 right-0 bottom-0">
    <div class="absolute top-0 left-0 right-0 bottom-0 z-30 flex items-center justify-center text-white">
      <div class="pb-48 text-center text-lg font-medium">
        Coming Soon
        <div class="text-base text-gray-400">Stay tuned</div>
      </div>
    </div>
    <div class="black absolute top-0 left-0 right-0 bottom-0 z-20 bg-gray-900 opacity-80" />
  </div>
</template>
