<template>
  <div class="relative flex h-screen flex-1 flex-col overflow-y-hidden bg-gray-900 font-medium text-white antialiased">
    <NavBar v-bind="auth" />
    <slot></slot>
    <ModalAddToWatchlist />
    <ModalConfirmation />
    <ModalCoinSideBar />
    <ChatTerminal v-if="addMessagingTerminal" />
    <NotificationBase :flash-messages="flashMessages" />
    <BaseLeaveImpersonate v-if="impersonating" @click.prevent="leaveImpersonate" />
  </div>
</template>

<script setup>
import ModalAddToWatchlist from '@/components/modal/ModalAddToWatchlist.vue';
import ModalEditTags from '@/components/modal/ModalEditTags.vue';
import ChatImagePreview from '@/components/chat/ChatImagePreview.vue';
import ChatTerminal from '@/components/chat/ChatTerminal.vue';
import NavBar from './NavBar.vue';
import ModalCoinSideBar from './modal/ModalCoinSideBar.vue';
import NotificationBase from './notification/NotificationBase.vue';
import BaseLeaveImpersonate from './base/BaseLeaveImpersonate.vue';

import { leaveImpersonate } from '@/api/sessionApi';
import { createConsumer } from '@rails/actioncable';
import {
  getCurrentInstance,
  ref,
  computed,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  onUnmounted,
  provide
} from 'vue';
import { useStore } from 'vuex';
import { usePage } from '@inertiajs/inertia-vue3';

import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';
import { setupChat, unsubscribeStreamChannel } from '@/composeables/chat';
import { Inertia } from '@inertiajs/inertia';

const page = usePage();
const store = useStore();
const $http = useHttp();
const emitter = useEmitter();

const currentInstance = getCurrentInstance();
const auth = computed(() => usePage().props.value.auth);
const flashMessages = computed(() => usePage().props.value['flash-messages']);
const impersonating = computed(() => auth.value.impersonating);

const contentOverflow = computed(() => {
  return page.url.value.includes('admin') || page.url.value.includes('settings');
});

const addMessagingTerminal = computed(() => {
  return store.state.messagingAccess && !page.url.value.includes('messenger');
});

const allowYScroll = computed(() => {
  return window.location && window.location.pathname.includes('screener');
});

onBeforeMount(() => {
  store.dispatch('setCoins');
  store.dispatch('setUniversal');
  store.dispatch('loadCommonAppData');
  store.dispatch('loadUserNotifications');
});

onMounted(() => {
  subscribeToUpdates();
  subscribeToTeamNewsItems();
  subscribeToAdminNewsItems();
  subscribeToAlerts();
  subscribeToUserNotifications();

  //WIP - need to shift to Socket as well
  setInterval(() => {
    if (store.getters.loggedIn) {
      store.dispatch('updateCoins');
      store.dispatch('setUniversal');
    }
  }, 60000);

  window.document.onkeydown = event => {
    if (event.key === 'Escape') {
      store.commit('setSearchMode', false);
      store.dispatch('resetCoinSidebar');
    }
    if (event.key === '/') {
      // if they are typing in an input element, don't trigger the search
      if (event.target && ['input', 'textarea'].includes(event.target.localName)) {
        return;
      }

      store.commit('setSearchMode', true);
    }
  };
});

const navItems = ref(null);
const setNavItems = items => (navItems.value = items);

provide('navItems', navItems);
provide('setNavItems', setNavItems);

onMounted(async () => {
  await setupChat();
});

onUnmounted(() => {
  unsubscribeStreamChannel();
});

const cable = computed(() => {
  const host = window.location.hostname;
  const protocol = host.includes('localhost') ? 'ws://' : 'wss://';
  const port = host.includes('localhost') ? ':3000' : '';
  return createConsumer(`${protocol + host + port}/cable`);
});

onBeforeUnmount(() => {
  cable.value.subscriptions.forEach(sub => sub.unsubscribe());
});

const copyToClipboard = (textToCopy, message = 'Copied to clipboard!') => {
  window.navigator.clipboard.writeText(textToCopy).then(() => {
    flashMessage({
      type: 'success',
      message: message
    });
  });
};

const subscribeToUpdates = () => {
  cable.value.subscriptions.create('UpdatesChannel', {
    connected: function () {},
    disconnected: function () {},
    received: function (data) {
      if (data.payload.event == 'prices') {
        store.dispatch('updateLivePrices', data.payload.data);
      }
    }
  });
};

const subscribeToTeamNewsItems = () => {
  cable.value.subscriptions.create('TeamNewsItemsChannel', {
    received: event => {
      emitter.$emit('newsItemUpdated', event);
      if (!event.team_news_item.is_starred_by_my_team) {
        return;
      }

      flashMessage({
        type: 'success',
        title: 'Starred by Your Team',
        message: `${
          event.starred_by_team_member.first_name
            ? `${event.starred_by_team_member.first_name} ${event.starred_by_team_member.last_name}`
            : event.starred_by_team_member.email
        } starred "${event.news_item.translated_headline || event.news_item.headline}"`,
        onClick: () => {
          window.open(event.news_item.link, '_blank').focus();
        }
      });
    }
  });
};

const subscribeToAdminNewsItems = () => {
  cable.value.subscriptions.create('AdminNewsItemsChannel', {
    received: event => {
      emitter.$emit('newsItemUpdated', event);
    }
  });
};

const subscribeToAlerts = () => {
  cable.value.subscriptions.create('UserAlertsChannel', {
    received: event => {
      let payload = {
        title: event.alert.name,
        message: event.message
      };
      let clickEvent = null;
      if (event.url) {
        clickEvent = () => {
          if (event.open_in_new_tab) {
            window.open(event.url, '_blank');
          } else {
            window.open(event.url);
          }
        };
      }
      store.commit('addNewUserNotification', event);
      emitter.$emit('resetActiveLogs');
      emitter.$emit('refreshAlertLogs');
      flashMessage(payload, clickEvent);
    }
  });
};

const subscribeToUserNotifications = () => {
  cable.value.subscriptions.create('UserNotifyChannel', {
    received: event => {
      if (event.message) {
        let payload = {
          type: event.type || 'notice',
          message: event.message
        };
        flashMessage(payload);
      }
    }
  });
};

const flashMessage = (content, clickEvent = null) => {
  currentInstance.appContext.config.globalProperties.$notify(
    {
      group: 'app',
      type: 'flash',
      text: content,
      onClick: clickEvent
    },
    store.getters.shortNotification
  );
};

provide('flashMessage', flashMessage);

const fixScrolling = action => {
  let hoverElem = document.querySelector('.element-hover');
  if (hoverElem !== null) {
    if (action === 'down') {
      hoverElem.scrollIntoView({ block: 'nearest' });
    } else if (action === 'up' && hoverElem.previousElementSibling) {
      hoverElem.previousElementSibling.scrollIntoView({ block: 'nearest' });
    }
  }
};

const createEvent = async params => {
  $http.post('/mixpanel_events', params);
};
</script>
