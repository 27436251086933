<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      :connectable="false"
      @expand="goToExpandLink"
    >
      <template #body>
        <div class="h-full space-y-2 py-2">
          <div class="flex text-sm font-medium text-gray-400">
            <div class="flex w-full items-center justify-center space-x-2">
              <button @click="goToPrevious" class="outline-none focus:outline-none">
                <IconChevronLeftSolid class="h-5 w-5 duration-100 hover:text-blue-100" />
              </button>
              <div v-if="width >= 400" class="w-32 whitespace-nowrap text-center">
                {{ monthName(cursor.getMonth()) }} {{ cursor.getFullYear() }}
              </div>
              <span v-else class="w-32 whitespace-nowrap text-center">
                {{ dayName(cursor.getDay()) }}, {{ monthAbr(cursor.getMonth()) }} {{ cursor.getDate() }}
              </span>
              <button @click="goToNext" class="outline-none focus:outline-none">
                <IconChevronLeftSolid class="h-5 w-5 rotate-180 duration-100 hover:text-blue-100" />
              </button>
            </div>
          </div>
          <template v-if="!loadEvents">
            <template v-if="width >= 400">
              <div class="flex">
                <template v-for="mode in ['week', 'month']" :key="mode">
                  <div
                    :class="[
                      view == mode ? ' bg-blue-900 text-blue-200' : ' bg-gray-800 text-gray-300',
                      'items-left relative mt-auto flex cursor-pointer py-1 px-4 text-xs font-medium capitalize duration-100 hover:bg-gray-600 hover:text-white'
                    ]"
                    @click="saveViewMode(mode)"
                  >
                    {{ mode }}
                  </div>
                </template>
              </div>
              <template v-if="view == 'month'">
                <div class="relative mt-4 text-gray-400">
                  <div class="grid grid-cols-7">
                    <div v-for="day in 7" :key="day" class="text-center text-xs font-medium capitalize">
                      {{ dayAbr(day - 1) }}
                    </div>
                  </div>
                  <div class="bg-grid" style="height: 77%">
                    <div v-for="(week, i) in weeks" :key="i" class="grid h-full grid-cols-7 border-grid">
                      <div
                        v-for="(day, j) in week"
                        :key="j"
                        class="h-16 border border-grid bg-gray-900"
                        :class="day && isToday(day) ? 'border-blue-500' : 'border-grid'"
                      >
                        <div class="h-full overflow-y-auto">
                          <div
                            class="sticky top-0 bg-gray-900 pt-1 text-center text-xs font-semibold"
                            :class="day && isToday(day) ? 'text-white' : 'text-gray-400'"
                          >
                            {{ day && day.getDate() }}
                          </div>
                          <div class="flex flex-wrap">
                            <template v-if="day">
                              <div v-for="(event, k) in eventsForDay(day)" :key="k" class="mx-1 py-1">
                                <!-- Not changing to BaseTooltip due to link -->
                                <BaseTooltipClassic :text="event.title.text">
                                  <a :href="event.title.href" target="_blank">
                                    <div class="h-3 w-3 rounded-full bg-blue-500 p-1 hover:bg-blue-600"></div>
                                  </a>
                                </BaseTooltipClassic>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="view == 'week'">
                <div class="relative mt-4 text-gray-400">
                  <div class="grid grid-cols-7">
                    <div v-for="day in 7" :key="day" class="text-center text-xs font-medium capitalize">
                      {{ dayAbr(day - 1) }}
                    </div>
                  </div>
                </div>
                <div class="bg-grid" style="height: 77%">
                  <div v-for="(week, i) in currentWeek" :key="i" class="grid h-full grid-cols-7 border-grid">
                    <div
                      v-for="(day, j) in week"
                      :key="j"
                      class="border border-grid bg-gray-900"
                      :class="day && isToday(day) ? 'border-blue-500' : 'border-grid'"
                    >
                      <div class="h-full overflow-y-auto">
                        <div
                          class="sticky top-0 bg-gray-900 pt-1 text-center text-xs font-semibold"
                          :class="day && isToday(day) ? 'text-white' : 'text-gray-400'"
                        >
                          {{ day && day.getDate() }}
                        </div>
                        <div class="flex flex-wrap">
                          <template v-if="day">
                            <div v-for="(event, k) in eventsForDay(day)" :key="k" class="mx-1 py-1">
                              <BaseTooltipClassic :text="event.title.text">
                                <a :href="event.title.href" target="_blank">
                                  <div class="h-3 w-3 rounded-full bg-blue-500 p-1 hover:bg-blue-600"></div>
                                </a>
                              </BaseTooltipClassic>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <template v-if="width < 400">
              <template v-if="eventsForDay(cursor).length">
                <table class="mt-2 text-sm">
                  <div v-for="(event, i) in eventsForDay(cursor)" :key="i" class="flex items-center space-y-1">
                    <div class="mt-1 mr-2 h-2 w-2 rounded-full bg-blue-500"></div>
                    <a
                      class="block flex-1 text-sm font-medium text-gray-200 hover:text-gray-100"
                      :href="event.title.href"
                      target="_blank"
                    >
                      {{ event.title.text }}
                    </a>
                  </div>
                </table>
              </template>
              <template v-else>
                <div class="pt-8 text-center text-xs font-medium text-gray-400">No events for the day.</div>
              </template>
            </template>
          </template>
          <template v-else>
            <div class="flex items-center justify-center pt-10">
              <BaseLoader />
            </div>
          </template>
        </div>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import WidgetsMixin from '@/mixins/widgets';
export default {
  name: 'WidgetsCalendar',
  mixins: [WidgetsMixin],
  mounted() {
    this.fetchEvents();
  },
  data() {
    //min 3x5
    return {
      events: [],
      view: 'week',
      cursor: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      loadEvents: true
    };
  },
  computed: {
    weeks: function () {
      const cursor = this.cursor;
      const year = cursor.getFullYear();
      const month = cursor.getMonth();
      let date = new Date(year, month);
      let rows = [];
      while (date.getMonth() === month) {
        const copyOfDate = new Date(date.getTime());
        const dayOfWeek = date.getDay();
        if (dayOfWeek === 0) {
          // make new row
          rows.push([copyOfDate, undefined, undefined, undefined, undefined, undefined, undefined]);
        } else {
          // add to existing row
          if (rows.length === 0) {
            rows.push([undefined, undefined, undefined, undefined, undefined, undefined, undefined]);
          }
          rows[rows.length - 1][dayOfWeek] = copyOfDate;
        }
        date.setDate(date.getDate() + 1);
      }
      return rows;
    },
    currentWeek: function () {
      const cursor = this.cursor;
      let date = new Date(new Date().getFullYear(), new Date().getMonth, new Date().getDate());
      let current = [];
      this.weeks.forEach(week => {
        if (!week) {
          return;
        }
        week.forEach(day => {
          if (!day) {
            return;
          }
          if (
            date.getDate() === day.getDate() &&
            date.getMonth() === day.getMonth() &&
            date.getFullYear() === day.getFullYear()
          ) {
            current = week;
            return;
          }
          if (cursor.getDate() === day.getDate()) {
            current = week;
            return;
          }
        });
      });
      return [current];
    }
  },
  watch: {
    'widget.configuration.feeds'() {
      this.fetchEvents();
    }
  },
  methods: {
    goToExpandLink() {
      window.open(`/tools`, '_blank');
    },
    async fetchEvents() {
      try {
        this.loadEvents = true;
        const lastDayOfMonth =
          this.cursor.getMonth() === 11
            ? new Date(this.cursor.getFullYear() + 1, 0, 1)
            : new Date(this.cursor.getFullYear(), this.cursor.getMonth() + 2, 0);

        const firstDayOfMonth =
          this.cursor.getMonth() === 0
            ? new Date(this.cursor.getFullYear() - 1, 11, 1)
            : new Date(this.cursor.getFullYear(), this.cursor.getMonth() - 1, 1);

        const feedIds = this.widget.configuration.feeds || undefined;
        const req = await this.$http.get('/calendar_events', {
          params: {
            calendar_start_date: firstDayOfMonth.toISOString().slice(0, 10),
            calendar_end_date: lastDayOfMonth.toISOString().slice(0, 10),
            news_feed_ids: feedIds
          }
        });
        this.events = req.data.calendar_events;
        this.loadEvents = false;
      } catch {
        this.loadEvents = false;
      }
    },
    async saveViewMode(mode) {
      this.view = mode;
      const payload = {
        configuration: {
          ...this.widget.configuration,
          view: this.view
        }
      };
      await this.$http.patch(`/dashboard_widgets/${this.widget.id}`, payload);
    },
    goToNext() {
      // month view
      if (this.cursor.getMonth() === 11) {
        this.cursor = new Date(this.cursor.getFullYear() + 1, 0, 1);
      } else {
        if (this.width >= 400) {
          if (this.view == 'week') {
            this.cursor = new Date(this.cursor.getFullYear(), this.cursor.getMonth(), this.cursor.getDate() + 7);
          } else {
            this.cursor = new Date(this.cursor.getFullYear(), this.cursor.getMonth() + 1, 1);
          }
        } else {
          this.cursor = new Date(this.cursor.getFullYear(), this.cursor.getMonth(), this.cursor.getDate() + 1);
        }
      }
    },
    goToPrevious() {
      if (this.cursor.getMonth() === 0) {
        this.cursor = new Date(this.cursor.getFullYear() - 1, 11, 1);
      } else {
        if (this.width >= 400) {
          if (this.view == 'week') {
            this.cursor = new Date(this.cursor.getFullYear(), this.cursor.getMonth(), this.cursor.getDate() - 7);
          } else {
            this.cursor = new Date(this.cursor.getFullYear(), this.cursor.getMonth() - 1, 1);
          }
        } else {
          this.cursor = new Date(this.cursor.getFullYear(), this.cursor.getMonth(), this.cursor.getDate() - 1);
        }
      }
    },
    dayAbr: function (dateNum) {
      const days = ['sun', 'mon', 'tues', 'wed', 'thu', 'fri', 'sat'];
      return days[dateNum];
    },
    monthName: function (dateNum) {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];
      return months[dateNum];
    },
    monthAbr: function (dateNum) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return months[dateNum];
    },
    dayName: function (dateNum) {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return days[dateNum];
    },
    isToday(d1) {
      const d2 = new Date();
      return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    },
    eventsForDay(day) {
      if (!day) {
        return;
      }
      const results = this.events
        .map(event => {
          const createdAtDate = new Date(event.created_at);
          const timeOfEvent = new Date(event.datetime);
          return {
            title: {
              text: event.description,
              href: event.news_item.link
            },
            released: createdAtDate.toLocaleString(),
            time: `${timeOfEvent.getUTCMonth() + 1}/${timeOfEvent.getUTCDate()}/${timeOfEvent.getUTCFullYear()}`,
            datetime: event.datetime
          };
        })
        .filter(event => {
          // making sure they are on the same day
          const dayKey = `${day.getFullYear()}_${day.getMonth()}_${day.getDate()}`;
          const eventDate = new Date(event.datetime);
          const eventKey = `${eventDate.getUTCFullYear()}_${eventDate.getUTCMonth()}_${eventDate.getUTCDate()}`;
          return dayKey === eventKey;
        });
      return results;
    }
  }
};
</script>
