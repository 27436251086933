<template>
  <div class="mt-4">
    <div class="space-y-4">
      <div class="space-y-1">
        <label for="content" class="text-xs text-gray-400">Coin</label>
        <DropdownSimple class="text-xs" :active-item="coin" :items="coinOptions" @selected="coin = $event.id" />
      </div>
    </div>
  </div>
</template>
<script>
import DropdownSimple from '@/components/dropdown/DropdownSimple.vue';
import { isEmpty } from 'lodash';

export default {
  name: 'WidgetConfigsDerivatives',
  emits: ['update'],
  props: {
    config: {
      type: Object
    }
  },
  components: {
    DropdownSimple
  },
  mounted() {
    if (this.config && !isEmpty(this.config)) {
      this.derivatives_id = this.config.derivatives_id;
      this.coin = this.config.coin || 'BTC';
    }
  },
  data() {
    return {
      coin: 'BTC',
      derivatives_id: undefined
    };
  },
  computed: {
    configuration() {
      return {
        valid: true,
        coin: this.coin,
        derivatives_id: this.derivatives_id
      };
    },
    coinOptions() {
      return [
        { id: 'BTC', text: 'Bitcoin' },
        { id: 'ETH', text: 'Ethereum' }
      ];
    }
  },
  watch: {
    configuration() {
      this.$emit('update', this.configuration);
    }
  }
};
</script>
