<template>
  <div class="flex flex-col text-white">
    <div class="flex items-center space-x-1">
      <CellProfile
        v-for="user in filteredUser"
        :profile="user"
        :image-only="true"
        :image-size="imageSize"
        :key="user.externalLink"
        class="flex-shrink-0"
      />
      <BaseTooltip v-if="othersCount > 0 && this.filteredUser.length">
        <template #target>
          <p class="mt-1 flex-shrink-0 text-xs text-gray-400" v-if="othersCount > 0">
            {{ `+${othersCount}` }}
          </p>
        </template>
        <template #default>
          {{ othersName }}
        </template>
      </BaseTooltip>
    </div>
  </div>
</template>
<script>
import CellProfile from './CellProfile.vue';
export default {
  name: 'CellMultipleUsers',
  components: {
    CellProfile
  },
  props: {
    users: {
      type: Array,
      default: () => []
    },
    imageSize: {
      type: String
    },
    totalUser: {
      type: Number,
      default: 0
    },
    maxUser: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      othersCount: 0,
      othersName: ''
    };
  },
  computed: {
    filteredUser() {
      return this.users
        .map(x => {
          return { name: x.name, imageUrl: x.profile_image_url_https, externalLink: x.profile_url };
        })
        .slice(0, this.maxUser);
    },
    firstPath() {
      return this.$store.state.firstPath;
    }
  },
  mounted() {
    this.othersCount = this.totalUser - this.maxUser;
    this.othersName = this.users
      .map(x => x.name)
      .slice(4)
      .join(' | ')
      .replace(/,(?=[^,]*$)/, ' &');
  }
};
</script>
