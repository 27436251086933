<template>
  <div
    id="gif-div"
    class="z-100 overflow-y-hidden rounded bg-gray-800"
    :class="external ? 'h-80 w-64 space-y-1 py-1' : 'h-96 w-80 space-y-2 py-2'"
  >
    <div :class="external ? 'mx-1' : 'mx-2'">
      <input
        class="h-8 w-full rounded border-none bg-gray-900 px-3 text-xs font-normal leading-4 outline-none focus:border-none"
        v-model="searchedTerm"
        placeholder="Search GIF"
        maxlength="64"
        @keyup="getGIFS()"
      />
    </div>
    <div class="flex h-81 justify-center overflow-y-auto pb-4" id="gif-container">
      <div class="flex items-center" v-show="loading || !imageLoaded">
        <BaseLoader :background="800" />
      </div>
      <div v-if="!loading && gifs && gifs.length == 0" class="flex w-full justify-center pl-2 text-xs text-gray-400">
        No GIF found for the searched term
      </div>
      <div
        v-show="!loading && imageLoaded"
        :transition-duration="'0s'"
        :horizontal-order="true"
        item-selector=".item"
        class="mx-2 w-full overflow-hidden"
        v-masonry
      >
        <div
          v-for="(item, index) in gifs"
          :key="index"
          :class="external ? 'p-0' : 'p-1'"
          class="item w-1/2 cursor-pointer"
        >
          <img
            class="rounded-md border-2 border-transparent hover:border-blue-600"
            :src="item.images.downsized.url"
            @load="
              $redrawVueMasonry();
              imageLoaded = true;
            "
            :width="external ? 120 : item.images.fixed_width.width"
            :height="
              external
                ? (item.images.fixed_width.height / item.images.fixed_width.width) * 120
                : item.images.fixed_width.height
            "
            @click="$emit('send-message', { gif: item })"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { useStore } from 'vuex';
const store = useStore();

const props = defineProps({
  external: Boolean,
  channelId: String
});
const $emit = defineEmits(['close', 'send-message']);

const gifs = ref([]);
const searchedTerm = ref('');
const offset = ref(0);
const loading = ref(false);
const imageLoaded = ref(false);

onMounted(() => {
  getGIFS();
  document.getElementById('gif-container').addEventListener('scroll', handleGIFScroll);
  document.addEventListener('mouseup', handleGIF);
});

onBeforeUnmount(() => {
  document.removeEventListener('mouseup', handleGIF);
  document.getElementById('gif-container').removeEventListener('scroll', handleGIFScroll);
});

function handleGIF(e) {
  var container = document.getElementById('gif-div');
  var gifButton = document.getElementById(`gif-button-${props.channelId}`);
  if (container && !container.contains(e.target) && gifButton && !gifButton.contains(e.target)) {
    $emit('close');
  }
}

function handleGIFScroll() {
  var scrollTop = document.getElementById('gif-container').scrollTop;
  var contentHeight =
    document.getElementById('gif-container').scrollHeight - document.getElementById('gif-container').offsetHeight;
  if (contentHeight <= scrollTop) {
    getGIFS(offset.value);
  }
}

async function getGIFS(offsetVal = 0) {
  if (offsetVal == 0) {
    loading.value = true;
    imageLoaded.value = false;
    gifs.value = [];
  }
  const gf = new GiphyFetch(store.getters.giphyKey);
  let gifsData = null;
  if (searchedTerm.value == '') {
    gifsData = await gf.trending({ limit: 20, offset: offsetVal });
  } else {
    gifsData = await gf.search(searchedTerm.value, { sort: 'relevant', limit: 20, offset: offsetVal });
  }
  loading.value = false;
  if (gifsData) {
    offsetVal == 0 ? (gifs.value = gifsData.data) : gifs.value.push(...gifsData.data);
    offset.value = offsetVal + 20;
  }
}
</script>
