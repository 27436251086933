<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="10"
    height="10"
    viewBox="0 0 172 172"
    style="fill: #000000"
    class="inline-block"
  >
    <g
      fill="none"
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="#e74c3c">
        <path
          d="M156.63786,7.16667l-70.63786,0.007l-70.63786,-0.007c-8.46842,0 -15.36214,6.93571 -15.36214,15.46712c0,2.33057 0.5319,4.59115 1.57471,6.71875c11.44987,23.50163 30.6543,56.82943 47.59814,86.23796c8.85335,15.36914 17.2168,29.88444 23.26367,40.91439c2.86247,5.21403 7.92952,8.32845 13.57048,8.32845c5.63395,0 10.70101,-3.11442 13.56348,-8.32845c6.03988,-11.02295 14.39632,-25.53125 23.24268,-40.88639c16.95085,-29.41553 36.15527,-62.75732 47.61214,-86.25195c1.04281,-2.1346 1.57471,-4.40218 1.57471,-6.73275c0,-8.53141 -6.89372,-15.46712 -15.36214,-15.46712z"
        ></path>
      </g>
    </g>
  </svg>
</template>
