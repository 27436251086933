<template>
  <div class="flex w-full items-center overflow-x-auto overflow-y-hidden" :class="external ? 'h-40' : 'h-60'">
    <div v-for="(file, idx) in files" :key="`file_${idx}`" class="relative p-2" :class="imageFile(file) ? 'pb-8' : ''">
      <div v-if="imageFile(file)" :class="external ? 'h-20 w-20' : 'h-40 w-40'">
        <div class="flex h-full items-center justify-center space-y-1 rounded-t bg-gray-900/50 px-1 py-1">
          <div class="flex h-full items-center">
            <img :src="getUrl(file)" :alt="file.name" class="max-h-full" />
          </div>
        </div>
        <div
          class="whitespace-nowrap rounded-bl rounded-br bg-gray-900/50 px-1 py-1 text-xs text-gray-400 line-clamp-1"
        >
          {{ truncate(file.name, truncateLength) }}
        </div>
      </div>
      <div v-else-if="file.type.startsWith('video/')">
        <div
          class="flex flex-col items-center justify-center space-y-1 rounded-t bg-gray-900/50 px-1 py-1"
          :class="external ? 'h-20' : 'h-40 w-40'"
        >
          <IconFile :mime-type="file.type" size="h-16 w-16 text-gray-400" />
        </div>
        <div
          class="whitespace-nowrap rounded-bl rounded-br bg-gray-900/50 px-1 py-1 text-xs text-gray-400 line-clamp-1"
        >
          {{ truncate(file.name, truncateLength) }}
        </div>
      </div>
      <div v-else>
        <div
          class="flex flex-col items-center justify-center space-y-1 rounded-t bg-gray-900/50 px-1 py-1"
          :class="external ? 'h-20' : 'h-40 w-40'"
        >
          <IconFile :mime-type="file.type" size="h-16 w-16 text-gray-400" />
        </div>
        <div
          class="whitespace-nowrap rounded-bl rounded-br bg-gray-900/50 px-1 py-1 text-xs text-gray-400 line-clamp-1"
        >
          {{ truncate(file.name, truncateLength) }}
        </div>
      </div>
      <div class="absolute right-0" style="top: -3px" @click="$emit('remove-file', file.name)">
        <IconDelete size="w-5 h-5 cursor-pointer text-gray-400 hover:text-red-500" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { truncate } from '@/composeables/filters';
const props = defineProps({
  files: Array,
  external: {
    type: Boolean,
    default: false
  }
});
const truncateLength = computed(() => {
  return props.external ? 10 : 20;
});

function getUrl(file) {
  return URL.createObjectURL(file);
}
function imageFile(file) {
  return file.type.startsWith('image/') && !file.type.endsWith('.photoshop');
}
</script>
