<template>
  <div class="mt-1 inline-flex items-center">
    <BaseTags :tags="tags" :disabled="true" :hover="false" />
  </div>
</template>
<script>
export default {
  name: 'CellInfoTags',
  props: {
    tags: Array
  }
};
</script>
