<template>
  <div class="h-full" :id="id">
    <WidgetsBaseComponent
      :widget="widget"
      :view-only-mode="viewOnlyMode"
      :subscribe-only-mode="subscribeOnlyMode"
      :loading="loading"
      @channel-update="handleChannelUpdate"
      @expand="goToExpandLink"
    >
      <template #body>
        <div class="relative flex h-full flex-col overflow-hidden pt-2">
          <div class="flex">
            <div class="flex-1">
              <div class="flex items-center space-x-4 bg-gray-900 px-2 pt-1" v-if="!both">
                <div
                  v-for="tf in views"
                  :key="tf.id"
                  @click="selectedView = tf.id"
                  class="cursor-pointer rounded text-xs transition-all ease-linear"
                  :class="tf.id === selectedView ? 'font-semibold text-gray-100' : 'text-gray-400 hover:text-gray-100'"
                >
                  {{ tf.title }}
                </div>
              </div>
            </div>
            <div class="ml-auto">
              <div class="flex space-x-0">
                <div
                  v-if="canClickBack"
                  class="flex cursor-pointer items-center justify-center px-4 text-xs text-gray-400 hover:text-white"
                  :class="width < 370 ? 'mt-1' : ''"
                  @click="back"
                >
                  <div>
                    <IconArrowLeft class="h-4 w-4 text-gray-400" />
                  </div>
                </div>

                <span v-if="width > 370">
                  <DropdownSearch
                    class="-mt-0.5"
                    input-class="text-gray-400 hover:text-white text-xs"
                    :hide-clear="true"
                    :name="truncatedMetricTitle"
                    @selected="selectMetric"
                    :items="metrics"
                    dropdown-width="300px"
                    :down-icon="true"
                    :just-text="true"
                  />
                </span>
                <div style="width: 150px; transform: scale(0.7)" class="-mt-1.5"></div>

                <div
                  style="width: 200px; transform: scale(0.7); margin-right: -22px; margin-top: -7px"
                  class="absolute right-0 z-10"
                >
                  <Treeselect
                    style=""
                    :options="categoryTypeOptions"
                    :multiple="false"
                    :clearable="false"
                    :searchable="true"
                    :placeholder="centerText"
                    v-model="selectedCategory"
                    :key="selectedCategory"
                  />
                </div>
              </div>
            </div>
          </div>
          <span v-if="width < 370">
            <DropdownSearch
              class="-mt-0.5"
              input-class="float-right text-gray-400 px-0 pt-3 pr-1 hover:text-white text-xs"
              :hide-clear="true"
              :name="truncatedMetricTitle"
              @selected="selectMetric"
              :items="metrics"
              dropdown-width="300px"
              :down-icon="true"
              :just-text="true"
            />
          </span>

          <div :class="`flex h-full w-full flex-1 pb-6 ${both ? 'space-x-6' : ''}`">
            <div v-show="selectedView === 'pie' || both" class="flex h-full max-h-full w-full flex-1">
              <ChartDonut
                :data="donutData"
                :center-text="centerText"
                :center-percent="centerPercent"
                :can-click-down="canClickDown"
                :can-click-back="canClickBack"
                :metric="selectedMetric"
                :widget-width="width"
                @next="next"
                @back="back"
              />
            </div>
            <div v-if="selectedView === 'table' || both" class="relative h-full max-h-full flex-1 overflow-y-scroll">
              <div class="absolute left-0 right-0 top-0 bottom-0">
                <TableBase :config="tableConfig" :data="tableData" @row-clicked="rowClicked" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </WidgetsBaseComponent>
  </div>
</template>

<script>
import DropdownSearch from '@/components/dropdown/DropdownSearch.vue';
import WidgetsMixin from '@/mixins/widgets';
import ChartDonut from '@/components/chart/ChartDonut.vue';
import IconArrowLeft from '@/components/icon/IconArrowLeft.vue';
import Treeselect from 'vue3-treeselect';
import 'vue3-treeselect/dist/vue3-treeselect.css';

export default {
  name: 'WidgetsMarketSectorOverview',
  mixins: [WidgetsMixin],
  components: {
    DropdownSearch,
    Treeselect,
    IconArrowLeft,
    ChartDonut
  },
  mounted() {
    setTimeout(() => {
      this.drawSavedCategory();
    }, 1000);
  },
  data() {
    return {
      views: [
        {
          title: 'Pie',
          id: 'pie'
        },
        {
          title: 'Table',
          id: 'table'
        }
      ],

      selectedView: 'pie',
      selectedCategory: undefined,

      selectedMetric: 'price_return_24_hours',
      metrics: [
        // price metrics
        {
          name: '1hr Price Change',
          id: 'price_return_1_hour'
        },
        {
          name: '24hr Price Change',
          id: 'price_return_24_hours'
        },
        {
          name: '7d Price Change',
          id: 'price_return_7_days'
        },
        {
          name: '30d Price Change',
          id: 'price_return_30_days'
        },
        {
          name: '1hr vs BTC Price Change',
          id: 'price_return_btc_1_hour'
        },
        {
          name: '24hr vs BTC Price Change',
          id: 'price_return_btc_24_hours'
        },
        {
          name: '7d vs BTC Price Change',
          id: 'price_return_btc_7_days'
        },
        {
          name: '30d vs BTC Price Change',
          id: 'price_return_btc_30_days'
        },

        // sentiment metrics
        {
          name: 'Daily Sentiment Change 7 Days',
          id: 'daily_sentiment_change_7_days'
        },

        // news metrics
        {
          name: 'Relative News Volume',
          id: 'relative_news_v2'
        },

        // twitter metrics
        {
          name: 'Relative Tweet Volume',
          id: 'relative_tweet_volume'
        },

        // volume metrics
        {
          name: 'Relative Trade Volume',
          id: 'relative_trade_volume'
        }
      ],

      donutData: [],
      centerText: '',
      activeCoin: null
    };
  },
  methods: {
    drawSavedCategory() {
      const config = this.widget.configuration;
      if (config) {
        if (config.active_coin) {
          this.activeCoin = config.active_coin;
        }

        if (config.active_category) {
          this.selectedCategory = config.active_category;
        } else {
          this.selectedCategory = 'all';
        }

        if (config.view) {
          this.selectedView = config.view;
        }

        if (config.selected_metric) {
          this.selectedMetric = config.selected_metric;
        }
      } else {
        this.selectedCategory = 'all';
      }
      this.writeToStore(this.widget.connected_channel);
    },
    updateForSelectedId() {
      if (!this.selectedCategory) {
        return;
      }

      const parts = this.selectedCategory.split('-');
      const type = parts[0];

      if (type === 'all') {
        this.drawMain();
      } else if (type === 'category') {
        this.drawSubCategory(parseInt(parts[1]));
      } else if (type === 'subcategory') {
        this.drawCoin(parseInt(parts[1]));
      }
    },
    next(e) {
      const parts = this.selectedCategory.split('-');
      const type = parts[0];

      if (type === 'all') {
        this.drawSubCategory(e.id);
      } else if (type === 'category') {
        this.drawCoin(e.id);
      } else if (type === 'subcategory') {
        const coin = this.coins.find(coin => {
          return coin.uid == e.id;
        });

        this.onCoinClick(coin);
      }
    },
    back() {
      const parts = this.selectedCategory.split('-');
      const type = parts[0];

      if (type === 'category') {
        this.drawMain();
      } else if (type === 'subcategory') {
        this.drawSubCategory(
          this.subCategories.find(cat => {
            return cat.id === parseInt(parts[1]);
          }).category_id
        );
      }
    },
    unSelectCoin() {
      const channel = this.widget.connected_channel;
      if (channel && this.activeCoin) {
        this.activeCoin = null;
        this.writeToStore(channel);
      }
    },
    onCoinClick(coin) {
      const channel = this.widget.connected_channel;
      if (channel && coin) {
        if (this.activeCoin && coin.coin_uid == this.activeCoin.coin_uid) {
          this.activeCoin = null;
        } else {
          this.activeCoin = {
            id: coin.id,
            ticker: coin.ticker,
            coin_uid: coin.coin_uid,
            tag_id: coin.tag_id
          };
        }
        this.writeToStore(channel);
        this.updateWidgetConfig();
      } else {
        window.open(`/coin/${coin.uid}/`, '_blank');
      }
    },
    rowClicked(row) {
      this.next(row);
    },
    selectCategory(handle) {
      this.selectedCategory = handle.id;
      this.updateForSelectedId();
    },
    selectMetric(handle) {
      this.selectedMetric = handle.id;
    },
    handleChannelUpdate(payload) {
      this.$store.commit('clearWidgetOutputChannel', payload.from);
      if (!payload.to) {
        this.activeCoin = null;
        this.updateWidgetConfig();
      }
    },
    drawMain() {
      this.selectedCategory = 'all';
      this.donutData = this.metricWeightedByCategories;
      this.centerText = 'All Coins';
    },
    drawSubCategory(id) {
      this.selectedCategory = `category-${id}`;
      this.donutData = this.metricWeightedBySubCategories(id);
      this.centerText = this.categories.find(cat => {
        return cat.id == id;
      }).name;
    },
    drawCoin(id) {
      this.selectedView = 'table';
      this.selectedCategory = `subcategory-${id}`;
      this.donutData = this.coinsInSubcategory(id);
      this.centerText = this.subCategories.find(sub => {
        return sub.id == id;
      }).name;
    },
    metricWeightedBySubCategories(categoryId) {
      const metric = this.selectedMetric;

      return this.subCategories
        .map(subCategory => {
          if (subCategory.category_id != categoryId) {
            return undefined;
          }

          let sum = 0;
          let total = 0;
          let realMarketCapTotal = 0;

          const coinsInCategory = this.coinsBySubCategory[subCategory.id];

          if (coinsInCategory) {
            coinsInCategory.forEach(coin => {
              const mCap = parseFloat(coin.market_cap);
              const metricVal = parseFloat(coin[metric]);

              if (mCap && metricVal) {
                total += mCap;
                sum += metricVal * mCap;
              }

              if (mCap) {
                realMarketCapTotal += mCap;
              }
            });
          }

          return {
            name: subCategory.name,
            id: subCategory.id,
            totalMarketCap: realMarketCapTotal,
            [metric]: (sum / total) * 100
          };
        })
        .filter(cat => {
          return cat;
        });
    },
    coinsInSubcategory(subCategoryId) {
      const metric = this.selectedMetric;
      const subCat = this.subCategories.find(s => {
        return s.id === subCategoryId;
      });

      return this.coinsBySubCategory[subCat.id].map(coin => {
        return {
          name: coin.name,
          id: coin.uid,
          totalMarketCap: coin.market_cap,
          [metric]: coin[metric] * 100
        };
      });
    },
    getCategoryFromSubCategory(subCategoryId) {
      const { category_id } = this.subCategories.find(e => {
        return e.id == subCategoryId;
      });

      return this.categories.find(cat => {
        return category_id == cat.id;
      });
    },
    async updateWidgetConfig() {
      if (!this.viewOnlyMode) {
        let payload = {
          configuration: {
            ...this.widget.configuration,
            active_category: this.selectedCategory,
            active_coin: this.activeCoin,
            view: this.selectedView,
            selected_metric: this.selectedMetric
          }
        };
        await this.$http.patch(`/dashboard_widgets/${this.widget.id}`, payload);
      }
    },
    goToExpandLink() {
      window.open('/coins', '_blank');
    },
    writeToStore(channel) {
      if (channel) {
        this.$store.commit('setWidgetOutputChannel', {
          channel: channel,
          value: this.widgetOutputValue
        });
      }
    }
  },
  computed: {
    truncatedMetricTitle() {
      const title = this.metrics.find(metric => {
        return metric.id === this.selectedMetric;
      }).name;

      if (title.length > 18 && !this.both) {
        return title.slice(0, 16) + '...';
      }

      return title;
    },
    tableData() {
      const data = this.donutData
        .map(data => {
          return {
            ...data,
            [this.selectedMetric]: data[this.selectedMetric] / 100
          };
        })
        .sort((a, b) => {
          return b.totalMarketCap - a.totalMarketCap;
        });

      if (this.selectedCategory && this.selectedCategory.includes('subcategory')) {
        return data.map(d => {
          const coin = this.coins.find(coin => {
            return coin.uid === d.id;
          });

          return {
            ...coin,
            ...d,
            highlight: (d.id == this.activeCoin?.coin_uid ? d.id : null) || null
          };
        });
      }

      return data;
    },
    metricWeightedByCategories() {
      const metric = this.selectedMetric;

      return this.categories.map(category => {
        let sum = 0;
        let total = 0;
        let realMarketCapTotal = 0;

        const coinsInCategory = this.coinsByCategory[category.id];

        if (coinsInCategory) {
          coinsInCategory.forEach(coin => {
            const mCap = parseFloat(coin.market_cap);
            const metricVal = parseFloat(coin[metric]);

            if (mCap && metricVal) {
              total += mCap;
              sum += metricVal * mCap;
            }

            if (mCap) {
              realMarketCapTotal += mCap;
            }
          });
        }

        return {
          name: category.name,
          id: category.id,
          totalMarketCap: realMarketCapTotal,
          [metric]: (sum / total) * 100
        };
      });
    },

    coinsByCategory() {
      const byCategory = {};
      const coinsBySubCategory = this.coinsBySubCategory;

      if (!coinsBySubCategory) {
        return [];
      }

      this.subCategories.forEach(subCategory => {
        const parentCategory = subCategory.category_id;

        if (!byCategory[parentCategory]) {
          byCategory[parentCategory] = {};
        }

        (coinsBySubCategory[subCategory.id] || []).forEach(coin => {
          byCategory[parentCategory][coin.uid] = coin;
        });
      });

      const byCategoryList = {};

      Object.keys(byCategory).forEach(k => {
        byCategoryList[k] = Object.values(byCategory[k]);
      });

      return byCategoryList;
    },

    coinsBySubCategory() {
      const bySubCategory = {};

      this.coins.forEach(coin => {
        coin.category_ids.forEach(subCategoryId => {
          if (!bySubCategory[subCategoryId]) {
            bySubCategory[subCategoryId] = [];
          }

          bySubCategory[subCategoryId].push(coin);
        });
      });

      return bySubCategory;
    },
    categories() {
      return this.$store.state.categories.coin;
    },
    subCategories() {
      return this.$store.state.categories.coin_sub_categories;
    },
    coins() {
      return this.$store.getters.coins;
    },
    categoryTypeOptions() {
      const options = [
        {
          label: 'All Coins',
          id: 'all'
        }
      ];

      this.categories.forEach(category => {
        options.push({
          label: category.name,
          id: `category-${category.id}`,
          children: this.subCategories
            .filter(subCat => {
              return subCat.category_id === category.id;
            })
            .map(elm => {
              return {
                label: elm.name,
                id: `subcategory-${elm.id}`
              };
            })
        });
      });
      return options;
    },
    both() {
      return this.w >= this.h * 1.66 || this.w >= 70;
    },
    canClickDown() {
      return true;
    },
    canClickBack() {
      return this.selectedCategory !== 'all';
    },
    centerPercent() {
      if (!this.selectedCategory) {
        return 0;
      }

      const parts = this.selectedCategory.split('-');
      const type = parts[0];

      if (type === 'all') {
        // for our global data
        if (
          this.selectedMetric === 'price_return_24_hours' &&
          this.$store.state.data.universal.market_cap_return_24_hours
        ) {
          return parseFloat(this.$store.state.data.universal.market_cap_return_24_hours);
        } else if (
          this.selectedMetric === 'relative_trade_volume' &&
          this.$store.state.data.universal.relative_trading_volume
        ) {
          return parseFloat(this.$store.state.data.universal.relative_trading_volume);
        } else if (
          this.selectedMetric === 'relative_tweet_volume' &&
          this.$store.state.data.universal.relative_tweet_volume
        ) {
          return parseFloat(this.$store.state.data.universal.relative_tweet_volume);
        }

        // for everthing else we can maunally aggergate
        let marketCapSum = 0;
        let count = 0;

        this.metricWeightedByCategories.forEach(cat => {
          let metric = cat[this.selectedMetric];
          let mcap = cat['totalMarketCap'];

          marketCapSum += mcap * metric;
          count += mcap;
        });

        return marketCapSum / count / 100;
      } else if (type === 'category') {
        return (
          this.metricWeightedByCategories.find(cat => {
            return cat.id == parts[1];
          })[this.selectedMetric] / 100
        );
      } else if (type === 'subcategory') {
        const subcategory = this.subCategories.find(cat => {
          return cat.id == parts[1];
        });

        return (
          this.metricWeightedBySubCategories(subcategory.category_id).find(cat => {
            return cat.id == parts[1];
          })[this.selectedMetric] / 100
        );
      }

      return 0;
    },
    tableConfig() {
      return {
        alternateBg: true,
        headerClass: 'py-8 sticky top-0 bg-gray-900 capitalize',
        rowClass: 'hover:bg-gray-800 duration-100 cursor-pointer text-xs',
        useParentContainer: false,
        highlightRow: this.activeCoin ? this.activeCoin.coin_uid : null,
        cols: [
          {
            type: 'index',
            size: 1,
            name: '#'
          },
          {
            type: this.selectedCategory && this.selectedCategory.includes('subcategory') ? 'widget_coin' : 'text',
            size: 2,
            name: this.selectedCategory && this.selectedCategory.includes('subcategory') ? 'Coin' : 'Sector',
            id: 'name',
            searchable: true,
            enabled: true
          },
          {
            type: 'dollar_format',
            size: 2,
            name: 'Market Cap',
            id: 'totalMarketCap',
            searchable: true,
            enabled: true
          },
          {
            type: 'percent',
            size: 2,
            name: this.metrics.find(metric => {
              return metric.id === this.selectedMetric;
            }).name,
            id: this.selectedMetric,
            decimals: 2,
            enabled: true
          }
        ]
      };
    },
    widgetOutputValue() {
      if (this.activeCoin) {
        return [this.activeCoin];
      }
      return [];
    }
  },
  watch: {
    selectedTooltip() {
      if (this.selectedTooltip) {
        this.drawTooltip();
      } else {
        this.clearTooltip();
      }
    },
    selectedCategory(newVal, oldVal) {
      if (oldVal) {
        this.unSelectCoin();
      }
      this.updateWidgetConfig();
      this.updateForSelectedId();
    },
    selectedMetric() {
      this.updateWidgetConfig();
      this.updateForSelectedId();
    },
    selectedView() {
      this.updateWidgetConfig();
    },
    coins() {
      this.updateForSelectedId();
    }
  }
};
</script>
