<template>
  <div class="flex h-full w-full items-center justify-center">
    <div class="space-y-8">
      <div class="px-2">
        <div class="text-xl font-medium leading-7 text-gray-400">Welcome to</div>
        <div class="text-2xl font-semibold leading-8">SigDev Messenger</div>
      </div>
      <div class="flex space-x-4 px-2">
        <div class="flex w-1/2 flex-col space-y-8 rounded border border-gray-700 p-6 text-gray-100">
          <div class="flex w-full items-center justify-between">
            <div class="text-lg font-semibold">New Chat</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-7 w-7 text-gray-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
              <path
                d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"
              />
            </svg>
          </div>
          <div class="space-y-1 text-sm font-medium leading-5 text-gray-400">
            <div v-for="item in chatList" :key="item" class="flex items-center space-x-2">
              <div class="h-2 w-2 rounded-full bg-gray-700"></div>
              <div>{{ item }}</div>
            </div>
          </div>
          <BaseButton @press="emitter.$emit('show-create-channel-modal')" size="md">+ Create</BaseButton>
        </div>
        <div class="flex w-1/2 flex-col space-y-8 rounded border border-gray-700 p-6 text-gray-100">
          <div class="flex w-full items-center justify-between">
            <div class="text-lg font-semibold">Profile</div>
            <IconUserFilled class="h-7 w-7 text-gray-600"></IconUserFilled>
          </div>
          <div class="space-y-1 text-sm font-medium leading-5 text-gray-400">
            <div v-for="item in profileList" :key="item" class="flex items-center space-x-2">
              <div class="h-2 w-2 rounded-full bg-gray-700"></div>
              <div>{{ item }}</div>
            </div>
          </div>
          <BaseButton @press="emitter.$emit('show-settings-modal')" type="secondary" size="md">Edit</BaseButton>
        </div>
      </div>
      <div class="space-y-3">
        <div class="flex items-center space-x-5 p-2">
          <div v-for="t in tabs" :key="t.id">
            <div
              class="cursor-pointer py-1 text-sm font-semibold leading-5"
              :class="t.id === tab ? 'border-b-2 border-blue-600' : 'text-gray-400'"
              @click="
                tab = t.id;
                startingIndex = 0;
              "
            >
              {{ t.title }}
            </div>
          </div>
        </div>
        <div class="relative flex items-center px-2" style="width: 832px; height: 215px">
          <div class="flex items-center space-x-4 overflow-x-auto rounded-md">
            <UserCard v-for="obj in selectedChunk" :key="obj.id" :object="obj" :is-user="tab == 'team'"></UserCard>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-center space-x-2 text-xs font-medium leading-4 text-gray-400"
        v-if="pages > 0"
      >
        <div
          class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-gray-700 hover:border-white"
          :class="startingIndex != 0 ? '' : 'cursor-not-allowed text-gray-400'"
          @click="startingIndex != 0 ? (startingIndex = startingIndex - 4) : null"
        >
          <IconChevronLeft class="h-3 w-3" :class="startingIndex != 0 ? 'text-white' : 'text-gray-400'" />
        </div>
        <span class="text-xs font-medium leading-4">{{ startingIndex / 4 + 1 }} of {{ pages }}</span>
        <div
          class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-gray-700 hover:border-white"
          :class="startingIndex + 4 < selected.length ? '' : 'cursor-not-allowed text-gray-400'"
          @click="startingIndex + 4 < selected.length ? (startingIndex = startingIndex + 4) : null"
        >
          <IconChevronRight
            class="h-3 w-3"
            :class="startingIndex + 4 < selected.length ? 'text-white' : 'text-gray-400'"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, computed, watch, inject } from 'vue';
import UserCard from './ChatUserCard.vue';
import { useMapGetter } from '@/store/map-state';
import { getCompaniesByCategory } from '@/composeables/chat';
import { useStore } from 'vuex';
const { streamUser, streamUsers } = useMapGetter();

const store = useStore();
const emitter = inject('eventHub');

onMounted(() => {
  companies.value = getCompaniesByCategory('All Companies', externalUsers.value, []);
  pages.value = selected.value.length > 0 ? Math.ceil(selected.value.length / 4) : 0;
});

const chatList = reactive([
  'Send and receive messages',
  'Connect with team and external users',
  'Sort and oversee chat rooms'
]);
const profileList = reactive(['Update login credentials', 'Manage name, role and bio', 'Upload avatar image']);
const tabs = reactive([
  { id: 'team', title: 'Team', selected: true },
  { id: 'company', title: 'Companies' }
]);
const tab = ref('team');
const startingIndex = ref(0);
const pages = ref(0);
const companies = ref([]);

//computed
const selected = computed(() => {
  return tab.value == 'team' ? teamUsers.value : companies.value;
});
const selectedChunk = computed(() => {
  return selected.value.filter((user, idx) => idx >= startingIndex.value && idx < startingIndex.value + 4);
});
const teamUsers = computed(() => {
  return streamUsers.value
    .filter(x => x.id != store.state.session.user_id && (x.teams ? x.team_id === streamUser.value.team_id : false))
    .sort((a, b) => a.name.localeCompare(b.name));
});
const externalUsers = computed(() => {
  return streamUsers.value.filter(
    x => x.id != store.state.session.user_id && x.teams && x.team_id !== streamUser.value.team_id
  );
});
watch(
  () => streamUsers.value,
  (newVal, oldVal) => {
    companies.value = getCompaniesByCategory('All Companies', externalUsers.value, []);
  }
);
watch(
  () => selected.value,
  (newVal, oldVal) => {
    pages.value = selected.value.length > 0 ? Math.ceil(selected.value.length / 4) : 0;
  }
);
</script>
