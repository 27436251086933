<template>
  <div class="flex flex-wrap">
    <div v-for="tag in orderedTags" :key="tag.id">
      <span
        :class="['mr-2 font-medium', getTagColor(tag.tag_subgroup.name), clickableTag(tag) ? 'cursor-pointer' : '']"
        @click="$emit('handle-tag-click', tag, item)"
      >
        <svg
          v-if="tag.tag_subgroup.name == 'Coins'"
          xmlns="http://www.w3.org/2000/svg"
          class="mb-px inline h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
          />
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          v-else-if="tag.tag_subgroup.name == 'SigDevs'"
          xmlns="http://www.w3.org/2000/svg"
          class="mb-px inline h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          v-else-if="tag.tag_subgroup.name == 'Companies'"
          xmlns="http://www.w3.org/2000/svg"
          class="mb-px inline h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          class="mb-px inline h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
          />
        </svg>
        {{ tag.name }}
      </span>
    </div>
  </div>
</template>

<script>
import global from '../../../../mixins/global';

export default {
  name: 'TableCellPartNewsTags',
  mixins: [global],
  props: {
    item: Object
  },
  methods: {
    clickableTag(tag) {
      if (tag.tag_subgroup.name == 'Coins' && tag.coin) {
        return true;
      }
      if (this.companyTagGroups.includes(tag.tag_subgroup.name)) {
        return this.$store.state.companySlugs.some(x => x.tag == tag.id);
      }
      return false;
    }
  },
  computed: {
    companyTagGroups() {
      return this.$store.state.companyTagGroups;
    },
    orderedTags() {
      const res = [];

      const addSubgroup = subgroup => {
        (this.item.tags || []).forEach(tag => {
          if ((tag.tag_subgroup || {}).name === subgroup) {
            res.push(tag);
          }
        });
      };

      addSubgroup('SigDevs');
      addSubgroup('Coins');
      addSubgroup('Companies');

      (this.item.tags || []).forEach(tag => {
        let found = false;

        res.forEach(haveTag => {
          if (tag.id === haveTag.id) {
            found = true;
          }
        });

        if (!found) {
          res.push(tag);
        }
      });

      return res;
    }
  }
};
</script>
