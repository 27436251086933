<template>
  <div class="py-1">
    <div v-if="attachment.type == 'giphy'" :style="`height: ${imageHeight}px`">
      <BaseImageLoader :custom-style="`height: ${imageHeight}px; width: ${external ? '150px' : '250px'}`">
        <template #img>
          <img
            :src="attachment.thumb_url"
            :style="`display: block; max-height: ${imageHeight}px; width: auto; height: auto`"
          />
        </template>
      </BaseImageLoader>
    </div>
    <div v-else-if="attachment.title && attachment.og_scrape_url" :url="attachment.og_scrape_url">
      <template v-if="validUrl">
        <div
          :class="displayColumn ? 'flex-col' : 'justify-between space-x-2'"
          class="mt-1 flex space-y-2 rounded !bg-gray-700/50 p-4"
          :style="external ? 'width: 15rem' : 'width: 25rem'"
        >
          <div class="space-y-2">
            <div v-if="attachment.author_name" class="mb-2 text-xs text-gray-400 line-clamp-1">
              {{ attachment.author_name }}
            </div>
            <a
              :href="attachment.title_link"
              class="cursor-pointer font-semibold text-blue-500 line-clamp-2 hover:underline"
              >{{ attachment.title }}</a
            >
            <div v-if="attachment.type != 'video'" class="text-gray- text-xs line-clamp-6">{{ attachment.text }}</div>
          </div>
          <img
            v-if="attachment.image_url || attachment.thumb_url"
            class="w-full rounded text-xxs"
            :style="!displayColumn ? 'display: block; max-width:80px; max-height:80px; width: auto; height: auto;' : ''"
            :src="attachment.image_url || attachment.thumb_url"
            :alt="attachment.title"
            @load="emitter.$emit('scroll-to-bottom')"
          />
        </div>
      </template>
    </div>
    <img
      v-else-if="attachment.og_scrape_url"
      class="rounded"
      :src="attachment.og_scrape_url"
      :style="`max-height:${imageHeight}px; height:auto; width:auto`"
      @load="emitter.$emit('scroll-to-bottom')"
    />
    <BaseImageLoader
      v-else-if="attachment.type === 'image'"
      :custom-style="`height: ${imageHeight}px; width: ${external ? '250px' : '400px'}`"
    >
      <template #img>
        <img
          :src="attachment.image_url ? `${attachment.image_url}&w=400` : ''"
          :alt="attachment.title"
          class="cursor-pointer rounded"
          :style="`max-height:${imageHeight}px; height:auto; width:auto`"
          @load="emitter.$emit('scroll-to-bottom')"
          @click="store.dispatch('setImageForPreview', attachment.image_url)"
        />
      </template>
    </BaseImageLoader>
    <video
      v-else-if="attachment.type === 'video' && !attachment.og_scrape_url"
      playsinline
      controls
      height="225"
      preload="metadata"
      class="max-h-64 max-w-sm rounded"
      :class="external ? 'w-56' : ''"
      :src="attachment.asset_url"
    ></video>
    <div v-else-if="attachment.type === 'file' && attachment.mime_type && attachment.mime_type.startsWith('audio/')">
      <audio controls :src="attachment.asset_url" :class="external ? 'w-56' : ''" />
    </div>
    <div v-else-if="attachment.type === 'file'">
      <div
        class="flex h-10 items-center justify-between rounded !bg-gray-700/50 px-2"
        :class="external ? 'w-56' : 'w-64'"
      >
        <a
          :href="attachment.asset_url"
          class="whitespace-nowrap text-sm font-medium text-blue-600 underline"
          ref="asset_url"
        >
          {{ truncate(attachment.title, external ? 20 : 25) }}
        </a>
        <div class="cursor-pointer" @click="$refs.asset_url.click()">
          <IconDownload />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { nextTick, ref, onMounted, inject } from 'vue';
import { truncate } from '@/composeables/filters';

import { useStore } from 'vuex';
const store = useStore();
const emitter = inject('eventHub');
const props = defineProps({
  attachment: Object,
  external: Boolean
});
onMounted(() => {
  nextTick(() => {
    emitter.$emit('scroll-to-bottom');
  });

  let url = props.attachment.image_url || props.attachment.thumb_url;
  if (url && !url.includes('giphy')) {
    var img = new Image();
    img.src = url;
    img.addEventListener('load', function () {
      displayColumn.value =
        img.naturalHeight != 0 && img.naturalWidth != 0 && img.naturalWidth <= img.naturalHeight ? false : true;
    });
  }
  const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
  validUrl.value = regex.test(props.attachment.og_scrape_url);
});
const displayColumn = ref(true);
const imageHeight = ref(props.external ? 150 : 250);
const validUrl = ref(false);
</script>
